import { JSONValue } from '@twilio/conversations';
import { UserBubbleUser } from 'components/UserBubble';
import { TwilioConversation } from 'store/twilio-conversations/conversations-reducer';
import { ConversationMetadata } from 'types/ApiModels/conversations';
import { EncounterCategory, PatientEncounter } from 'types/ApiModels/Patients/encounter';
import ConversationAttributes from 'types/Shared/conversation-attributes';

export const getConversationAttributes = (twilioAttributes: JSONValue): ConversationAttributes => {
  const result: ConversationAttributes = {};

  //todo: This is a patch, tried to make it better but got caught in a TS nightmare
  if (typeof twilioAttributes === 'object') {
    result.subject = twilioAttributes?.['subject'];
    result.subject_type = twilioAttributes?.['subject_type'];
    result.conversation_type = twilioAttributes?.['conversation_type'];
    result.patient_identity = twilioAttributes?.['patient_identity'];
    result.identityMembers = twilioAttributes?.['identityMembers'];
    result.patient_id = twilioAttributes?.['patient_id'];
    result.encounter_id = twilioAttributes?.['encounter_id'];
  }
  return Object.keys(result).length > 0 ? result : null;
};

export const conversationMetadataToUserBubble = (
  cm: ConversationMetadata
): Pick<PatientEncounter, 'photo_thumbnail' | 'full_name'> => {
  try {
    return { photo_thumbnail: cm.photo_thumbnail, full_name: cm.full_name };
  } catch (error) {
    console.error('Could not convert from ConversationMetadata to UserBubble');
  }
};

export const mapConversationMetadataAllSettledToTwilioConversation =
  (metadataAllSettled) =>
  (c, idx): TwilioConversation => {
    const md = metadataAllSettled[idx];
    let result = {
      lastMessage: undefined,
      participants: [],
      unreadCount: 0,
    };
    if (md.status === 'fulfilled') {
      result = md.value;
    } else {
      console.error(md.reason);
    }
    return {
      ref: c,
      ...result,
    };
  };
