import React from 'react';
import { formatMessageTimeStamp } from 'util/dateUtils';
import UserBubble, { UserBubbleUser } from '../UserBubble';

interface TextMessageAltProps {
  message: string;
  from: UserBubbleUser;
  className?: string;
  timeStampClassName?: string;
  messageClassName?: string;
  dataClassName?: string;
  bubbleClassName?: string;
  createdAt: Date;
  TimeItems?: () => JSX.Element;
}

export default function TextMessageAlt({
  message,
  createdAt,
  from,
  className = '',
  timeStampClassName = '',
  messageClassName = '',
  dataClassName = '',
  bubbleClassName = '',
  TimeItems = () => <></>,
}: TextMessageAltProps) {
  const dateCreatedStr = formatMessageTimeStamp(createdAt);

  return (
    <div className={className}>
      <div className={bubbleClassName}>
        <UserBubble user={from} />
      </div>
      <div className={`d-flex ${messageClassName || 'flex-column'}`}>
        <span className={dataClassName}>{message}</span>
        {TimeItems && (
          <span>
            <TimeItems />
          </span>
        )}
        <span className={timeStampClassName}>{dateCreatedStr}</span>{' '}
      </div>
    </div>
  );
}
