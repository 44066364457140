import { MouseEventHandler } from 'react';
import { CarePlanActionType } from 'types/ApiModels/CarePlan/ActionType';
import Device from 'types/ApiModels/Patients/device';
import DeviceMeasureType from 'types/ApiModels/Patients/device-measure-type';
import GenericColumn from 'types/Shared/GenericColumn';
import { formatDottedDate, formatVytracDate } from 'util/dateUtils/shared';
import { DeviceMeasuresCell, DeviceModelCell, DeviceStatusCell } from '../device-table-cells';

interface DeviceRow {
  id: number;
  model: string;
  deviceId: string;
  measures: CarePlanActionType[];
  added: string;
  status: boolean;
  isFdaApproved;
}

interface DevicesColumnParams {
  handleStatusClick: (id: number) => () => void;
  hoveringId: number;
  handleHover: (id: number) => MouseEventHandler<HTMLParagraphElement>;
  handleHoverOut: MouseEventHandler<HTMLParagraphElement>;
}
export const devicesColumns = ({
  handleStatusClick,
  hoveringId,
  handleHover,
  handleHoverOut,
}: DevicesColumnParams): GenericColumn[] => [
  {
    key: 'model',
    title: 'Model',
    render: (row: DeviceRow) =>
      DeviceModelCell({ isFdaApproved: row.isFdaApproved, name: row.model }),
  },
  {
    key: 'deviceId',
    title: 'Device ID',
  },
  {
    key: 'measures',
    title: 'Measures',
    render: (row: DeviceRow) =>
      DeviceMeasuresCell({
        types: row.measures,
        handleHover: handleHover(row.id),
        handleHoverOut: handleHoverOut,
        showDetail: hoveringId === row.id,
      }),
  },
  {
    key: 'added',
    title: 'Added',
    render: (row: DeviceRow) => formatDottedDate(new Date(row.added)),
  },
  {
    key: 'status',
    title: 'status',
    render: (row: DeviceRow) =>
      DeviceStatusCell({ status: row.status, onClick: handleStatusClick(row.id) }),
  },
];

export const mapDeviceToDeviceRow = (
  device: Device,
  deviceMeasureTypes: DeviceMeasureType[]
): DeviceRow => {
  return {
    id: device.id,
    model: device.model,
    deviceId: device.device_id,
    measures: device.type?.measures?.map(
      (id) => deviceMeasureTypes?.find((dmt) => dmt.id === id)?.name
    ),
    added: device.created_at,
    status: device.is_active,
    isFdaApproved: device.type?.is_fda_approved,
  };
};
