import { JSONValue } from '@twilio/conversations';
import { vytracAxios } from 'ajax';
import { getMessageAttributes } from 'screens/Patients/Content/components/PatientTabs/components/team-notes/utils';
import {
  ConversationMessage,
  ConversationMessageList,
  ConversationMetadata,
  TwilioToken,
  MessageSimplified,
} from 'types/ApiModels/conversations';
import { PaginatedResponse } from 'types/ApiModels/General';
import MentionItemCustom from 'types/Shared/MentionItemCustom';
import { requestWrapper } from 'util/utils';

const URI = 'conversations';
const conversationUris = {
  token: `${URI}/token/`,
  conversationBySid: (sid: string) => `${URI}/${sid}/`,
  conversationMetadata: `${URI}/metadata/`,
  messages: `${URI}/messages/`,
  message: (conversationSid: string, messageSid) =>
    `${URI}/${conversationSid}/messages/${messageSid}/`,
};

export const getTokenForIdentity = (identity: string) => {
  return requestWrapper(vytracAxios.post<TwilioToken>(conversationUris.token, { identity }));
};

export const getPatientInfoByIdentities = (identities: string[]) => {
  return requestWrapper(
    vytracAxios.post<ConversationMetadata[]>(conversationUris.conversationMetadata, {
      twilio_identities: [...identities],
    })
  );
};

export const registerMessageMention = (registerMessageMentionRequest: ConversationMessage) => {
  return requestWrapper(vytracAxios.post(conversationUris.messages, registerMessageMentionRequest));
};

export const searchConversations = async (searchConversationQuery: {
  conversation: string;
  search?: string;
  keyword?: string;
  mention?: string;
}) => {
  try {
    const res = await vytracAxios.get<PaginatedResponse<ConversationMessageList>>(
      conversationUris.messages,
      {
        params: searchConversationQuery,
      }
    );
    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error('Error while trying to search conversation');
  }
};

export const getMessageFromConversation = async (
  conversationSid: string,
  messageSid: string
): Promise<MessageSimplified> => {
  try {
    const res = await vytracAxios.get<MessageSimplified>(
      conversationUris.message(conversationSid, messageSid)
    );
    return res.data;
  } catch (err) {
    console.error(err);
    return null;
  }
};
