import ProfileImage from 'components/ProfileImage';
import Stopwatch from 'components/Stopwatch';
import React, { CSSProperties } from 'react';
import { User } from 'types/ApiModels/Users';
import { getUserDisplayName } from 'util/userUtils';

interface TrackingProps {
  timerIsTracking: boolean;
  patientId: number;
  elapsedTime: number;
  activityId: number;
  stopwatchClick: () => void;
}

interface ListItemProps {
  user: Partial<User>;
  className?: string;
  onClick?: () => void;
  style?: CSSProperties;
  nameStyle?: CSSProperties;
  onNameClick?: (topPosition: number) => void;
  patientId?: number;
  showStopwach?: boolean;
  tracking?: TrackingProps;
}

const ListItem = ({
  user,
  className,
  onClick,
  style,
  nameStyle,
  onNameClick,
  patientId,
  showStopwach,
  tracking,
}: ListItemProps) => {
  const displayName = getUserDisplayName({
    username: user?.email,
    first_name: user?.first_name,
    last_name: user?.last_name,
  });

  const getTopClickPosition = (event: React.MouseEvent) => {
    const topPosition = event.clientY;
    if (onNameClick) onNameClick(topPosition);
  };

  return (
    <div
      className={`d-flex align-items-center cursor-pointer ${className || ''}`}
      onClick={onClick}
      style={style}
    >
      {showStopwach ? (
        <Stopwatch
          isTracking={tracking.timerIsTracking && patientId === tracking.patientId}
          toggleIsTracking={tracking.stopwatchClick}
          disabled={!tracking.activityId}
          style={{ marginRight: 18 }}
          size={40}
          buttonSize={15}
          background={
            <ProfileImage src={user.imageUrl} sex={user.sex} style={{ width: 35, height: 35 }} />
          }
          elapsedTimeInSeconds={tracking.elapsedTime}
        />
      ) : (
        <ProfileImage
          src={user.imageUrl}
          sex={user.sex}
          style={{ width: 35, height: 35, marginLeft: 3, marginRight: 20 }}
        />
      )}
      <span style={nameStyle} className="font-size-medium link" onClick={getTopClickPosition}>
        {displayName}
      </span>
    </div>
  );
};
export default ListItem;
