import { vytracAxios } from 'ajax';
import { AxiosError } from 'axios';
import { PatientQuickFilter } from 'screens/Patients/Content/components/PatientListWithFilters/QuickFilters';
import { PaginatedResponse } from 'types/ApiModels/General';
import { IPatientCarePlan, IPatientCarePlanAction } from 'types/ApiModels/Patients/CarePlan';
import Device, { DeviceCreate, DevicePatch } from 'types/ApiModels/Patients/device';
import DeviceMeasureType from 'types/ApiModels/Patients/device-measure-type';
import DeviceType from 'types/ApiModels/Patients/device-type';
import { IGoalThreshold } from 'types/ApiModels/Patients/Goal';
import Insurance from 'types/ApiModels/Patients/Insurance';
import Patient, { Allergy, FamilyHistory, Surgery } from 'types/ApiModels/Patients/Patient';
import PatientGet from 'types/ApiModels/Patients/PatientGet';
import PatientGetSingle from 'types/ApiModels/Patients/PatientGetSingle';
import { PatientsSearch } from 'types/ApiModels/Patients/PatientsSearch';
import PatientUpdateSingle from 'types/ApiModels/Patients/PatientUpdateSingle';
import { objectToUrlParams } from 'util/utils';
import {
  mockedAlertsData,
  mockedPopulationsData,
} from '../screens/Patients/Content/components/PatientTabs/components/PatientReports/utils/mockedPatientsReportData';
import mockedSchedules from '../screens/Patients/Content/components/PatientTabs/components/team-notes/utils/mockedSchedules';
import mockedStickyNotes from '../screens/Patients/Content/components/PatientTabs/components/team-notes/utils/mockedStickyNotes';

const URI = 'patient';

const patientUris = {
  base: `${URI}/`,
};

export const getPatients = async (page: number, listSize: number) => {
  try {
    const response = await vytracAxios.get<PaginatedResponse<PatientGet>>(
      `patient/?page=${page}&size=${listSize}`
    );
    return response.data.results;
  } catch (exception) {
    throw exception;
  }
};

export const getPaginatedPatients = async (
  searchValue: string,
  listFilter: PatientQuickFilter,
  page: number,
  listSize: number
) => {
  let parameters = `?search=${searchValue ?? ''}&page=${page}&size=${listSize}`;
  if (listFilter) parameters = `${parameters}&ordering=${listFilter}`;

  try {
    const response = await vytracAxios.get<PaginatedResponse<PatientGet>>(`patient/${parameters}`);
    console.log('response patient data', response.data)
    return response.data;
  } catch (exception) {
    console.log(exception);
    throw exception;
  }
};

export const getSinglePatient = async (patientId: number) => {
  try {
    const res = await vytracAxios.get<PatientGetSingle>(`patient/${patientId}/`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getPatientsAutocompleteList = async (searchValue: string, listSize: number) => {
  try {
    const response = await vytracAxios.get<PaginatedResponse<PatientsSearch>>(
      `patient/autocomplete/?search=${searchValue}&size=${listSize}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateSinglePatient = async (patient: PatientGetSingle) => {
  try {
    const response = await vytracAxios.put<PatientGetSingle>(`patient/${patient.id}/`, patient);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const partialUpdateSinglePatient = async (
  id: number,
  patient: Partial<PatientUpdateSingle>
) => {
  try {
    const response = await vytracAxios.patch<PatientGetSingle>(`patient/${id}/`, patient);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deletePatient = async (id: number) => {
  try {
    const response = await vytracAxios.delete(`patient/${id}/`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const archivePatient = async (id: number) => {
  try {
    const response = await vytracAxios.patch<Partial<PatientGetSingle>>(`patient/archive/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const enrollPatient = async (id: number) => {
  try {
    const response = await vytracAxios.patch<Partial<PatientGetSingle>>(`patient/enroll/${id}/`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCarePlan = async (patientId: number) => {
  try {
    const response = await vytracAxios.get<IPatientCarePlan>(`patient/${patientId}/rpm_plan/`);
    return response.data;
  } catch (exception) {
    throw exception;
  }
};

export const partialUpdateCarePlan = async (
  patientId: number,
  carePlan: Partial<IPatientCarePlan>
) => {
  try {
    const response = await vytracAxios.patch<IPatientCarePlan>(
      `patient/${patientId}/rpm_plan/`,
      carePlan
    );
    return response.data;
  } catch (exception) {
    throw exception;
  }
};

export const getPatientActions = async (patientId: number) => {
  try {
    const response = await vytracAxios.get<IPatientCarePlanAction[]>(
      `patient/${patientId}/rpm_plan/action/`
    );
    return response.data;
  } catch (exception) {
    throw exception;
  }
};

export const createPatientAction = async (patientId: number, action: IPatientCarePlanAction) => {
  try {
    const response = await vytracAxios.post<IPatientCarePlanAction>(
      `patient/${patientId}/rpm_plan/action/`,
      action
    );
    return response.data;
  } catch (exception) {
    throw exception;
  }
};

export const createPatientActions = async (
  patientId: number,
  actions: IPatientCarePlanAction[]
) => {
  try {
    const response = await vytracAxios.post<IPatientCarePlanAction[]>(
      `patient/${patientId}/rpm_plan/action/`,
      actions
    );
    return response.data;
  } catch (exception) {
    throw exception;
  }
};

export const updatePatientAction = async (patientId: number, action: IPatientCarePlanAction) => {
  try {
    const response = await vytracAxios.put<IPatientCarePlanAction>(
      `patient/${patientId}/rpm_plan/action/${action.id}/`,
      action
    );
    return response.data;
  } catch (exception) {
    throw exception;
  }
};

export const partialUpdatePatientAction = async (
  patientId: number,
  actionId: number,
  action: Partial<IPatientCarePlanAction>
) => {
  try {
    const response = await vytracAxios.patch<IPatientCarePlanAction>(
      `patient/${patientId}/rpm_plan/action/${actionId}/`,
      action
    );
    return response.data;
  } catch (exception) {
    throw exception;
  }
};

export const deletePatientAction = async (patientId: number, actionId: number) => {
  try {
    const response = await vytracAxios.delete(`patient/${patientId}/rpm_plan/action/${actionId}/`);
    return response.data;
  } catch (exception) {
    throw exception;
  }
};

export const deletePatientActions = async (patientId: number, actionIds: number[]) => {
  try {
    const response = await vytracAxios.delete(`patient/${patientId}/rpm_plan/remove_actions/`, {
      data: {
        actions_ids: actionIds,
      },
    });
    return response.data;
  } catch (exception) {
    throw exception;
  }
};

export async function getTeamNotes(patientId: number) {
  try {
    const responseNotes = await vytracAxios.get(`notes/V2/${patientId}/`);
    const response = {
    data: {
        notes: [],
        stickyNotes: responseNotes.data.results,
        schedules: mockedSchedules,
    }
    };
    return response.data;
  } catch (exception) {
    throw exception;
  }
}

export async function createStickyNote(stickyNote) {
  try {
    const response = await vytracAxios.post('notes/V2/', stickyNote);
    console.log(response);
  }catch(exception){
    throw exception;
  }
}

export async function editStickyNote(stickyNote, id) {
  try {
    const response = await vytracAxios.put(`notes/V2/${id}/`, stickyNote);
    console.log(response);
  }catch(exception){
    throw exception;
  }
}

export const getGoalThresholds = async (patientId: number) => {
  try {
    const response = await vytracAxios.get<IGoalThreshold[]>(`patient/${patientId}/goals/`);
    return response.data;
  } catch (exception) {
    throw exception;
  }
};

export const createGoalThreshold = async (patientId: number, goal: IGoalThreshold) => {
  try {
    const response = await vytracAxios.post<IGoalThreshold>(`patient/${patientId}/goals/`, goal);
    return response.data;
  } catch (exception) {
    throw exception;
  }
};

export const updateGoalThreshold = async (patientId: number, goal: IGoalThreshold) => {
  if (!goal.patient_id) {
    console.log('Edited goal is default. A new goal will be created');
    const responseData = await createGoalThreshold(patientId, { ...goal, patient_id: patientId });
    return responseData;
  }

  try {
    const response = await vytracAxios.put<IGoalThreshold>(
      `patient/${goal.patient_id}/goals/${goal.id}/`,
      goal
    );
    return response.data;
  } catch (exception) {
    throw exception;
  }
};

export const partialUpdateGoalThreshold = async (
  patientId: number,
  goal: AtLeast<IGoalThreshold, 'id'>
) => {
  try {
    const response = await vytracAxios.patch<IGoalThreshold>(
      `patient/${patientId}/goals/${goal.id}/`
    );
    return response.data;
  } catch (exception) {
    throw exception;
  }
};

export const createPopulationPatient = async(
  patientId: number,
  populations: any
) => {
  try{
    const response = await vytracAxios.put(
      `patient/population-link-patient/${patientId}/`,
      populations
    )
    return response.data;
  } catch(exception) {
    throw exception;
  }
}

export const getAlertReport = async () => mockedAlertsData;

export const getPopulationsReport = async () => mockedPopulationsData;

export const updatePatient = async (body, patientId): Promise<Patient> => {
  try {
    const updatedUser = await vytracAxios.put<Patient>(`patient/${patientId}/`, body);
    return updatedUser.data;
  } catch (error) {
    throw error;
  }
};

export const createAllergyBulk = async (
  patientId: number,
  allergies: Omit<Allergy, 'id' | 'deleted'>[]
): Promise<[res: Allergy[], error: any]> => {
  if (!allergies.length) return [null, null];
  try {
    const res = await vytracAxios.post<Allergy[]>(
      `patient/patient_allergy/${patientId}/`,
      allergies
    );
    return [res.data, null];
  } catch (error) {
    return [null, (error as AxiosError).response.data];
  }
};

export const deleteAllergyBulk = async (
  patientId: number,
  allergyIds: number[]
): Promise<[res: any, error: any]> => {
  if (!allergyIds.length) return [null, null];
  try {
    const res = await vytracAxios.delete(`patient/patient_allergy/${patientId}/`, {
      data: allergyIds.map((id) => ({ id })),
    });
    return [res.data, null];
  } catch (error) {
    return [null, (error as AxiosError).response.data];
  }
};

export const updateAllergyBulk = async (patientId: number, allergies: Allergy[]) => {
  if (!allergies?.length) return [null, null];
  try {
    const res = await vytracAxios.put(`patient/patient_allergy/${patientId}/`, allergies);
    return [res.data, null];
  } catch (error) {
    return [null, (error as AxiosError).response.data];
  }
};

export const createFamilyHistoryBulk = async (
  patientId: number,
  familyHistory: Omit<FamilyHistory, 'id' | 'deleted'>[]
): Promise<[res: FamilyHistory[], error: any]> => {
  if (!familyHistory.length) return [null, null];
  try {
    const res = await vytracAxios.post<FamilyHistory[]>(
      `patient/patient_familyhistory/${patientId}/`,
      familyHistory
    );
    return [res.data, null];
  } catch (error) {
    return [null, (error as AxiosError).response.data];
  }
};

export const deleteFamilyHistoryBulk = async (
  patientId: number,
  familyHistoryIds: number[]
): Promise<[res: any, error: any]> => {
  if (!familyHistoryIds.length) return [null, null];
  try {
    const res = await vytracAxios.delete(`patient/patient_familyhistory/${patientId}/`, {
      data: familyHistoryIds.map((id) => ({ id })),
    });
    return [res.data, null];
  } catch (error) {
    return [null, (error as AxiosError).response.data];
  }
};

export const updateFamilyHistoryBulk = async (
  patientId: number,
  familyHistories: FamilyHistory[]
) => {
  if (!familyHistories?.length) return [null, null];
  try {
    const res = await vytracAxios.put(
      `patient/patient_familyhistory/${patientId}/`,
      familyHistories
    );
    return [res.data, null];
  } catch (error) {
    return [null, (error as AxiosError).response.data];
  }
};

export const createSurgeryBulk = async (
  patientId: number,
  surgeries: Omit<Surgery, 'id' | 'deleted'>[]
): Promise<[res: Surgery[], error: any]> => {
  if (!surgeries.length) return [null, null];
  try {
    const res = await vytracAxios.post<Surgery[]>(
      `patient/patient_surgery/${patientId}/`,
      surgeries
    );
    return [res.data, null];
  } catch (error) {
    return [null, (error as AxiosError).response.data];
  }
};

export const deleteSurgeryBulk = async (
  patientId: number,
  surgeryIds: number[]
): Promise<[res: any, error: any]> => {
  if (!surgeryIds.length) return [null, null];
  try {
    const res = await vytracAxios.delete(`patient/patient_surgery/${patientId}/`, {
      data: surgeryIds.map((id) => ({ id })),
    });
    return [res.data, null];
  } catch (error) {
    return [null, (error as AxiosError).response.data];
  }
};

export const updateSurgeryBulk = async (patientId: number, surgeries: Surgery[]) => {
  if (!surgeries?.length) return [null, null];
  try {
    const res = await vytracAxios.put(`patient/patient_surgery/${patientId}/`, surgeries);
    return [res.data, null];
  } catch (error) {
    return [null, (error as AxiosError).response.data];
  }
};

export const getInsurances = async () => {
  try {
    const res = await vytracAxios.get<PaginatedResponse<Insurance>>('insurances/');

    return res.data.results;
  } catch (error) {
    console.error(error);
  }
};

export const getPatientDeviceTypes = async () => {
  try {
    const res = await vytracAxios.get<PaginatedResponse<DeviceType>>(`patient/device_type/`);

    return res.data;
  } catch (error) {
    console.error(error);
  }
};

export const searchPatientDeviceTypes = async (search: string) => {
  try {
    const res = await vytracAxios.get<PaginatedResponse<DeviceType>>(
      `patient/device_type/${objectToUrlParams({ search })}`
    );
    return res.data;
  } catch (error) {
    console.error(error);
  }
};

export const getPatientDevices = async (patientId: number) => {
  try {
    const res = await vytracAxios.get<Device[]>(`patient/${patientId}/device/`);
    return res.data;
  } catch (error) {
    console.error(error);
  }
};

export const getPatientDevicesMeasureTypes = async () => {
  try {
    const res = await vytracAxios.get<DeviceMeasureType[]>('patient/device_mesaures_types/');
    return res.data;
  } catch (error) {
    console.error(error);
  }
};

export const patchPatientDevice = async (
  patientId: number,
  deviceId: number,
  device: DevicePatch
): Promise<[res: Device, error: AxiosError]> => {
  try {
    const res = await vytracAxios.patch(`patient/${patientId}/device/${deviceId}/`, device);
    return [res.data, null];
  } catch (error) {
    return [null, error];
  }
};

export const createPatientDevice = async (patientId: number, device: DeviceCreate) => {
  try {
    const res = await vytracAxios.post(`patient/${patientId}/device/`, device);
    return [res.data, null];
  } catch (error) {
    return [null, error];
  }
};

export const searchPatients = async (searchQuery: { search: string; care_team?: number }) => {
  try {
    const res = await vytracAxios.get<PaginatedResponse<PatientGet>>(patientUris.base, {
      params: searchQuery,
    });
    return res.data.results;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getPatientEncounters = async () => {
  try {
    const response = await vytracAxios.get('patient/encounter/');
    return response.data;
  } catch (error) {
    throw error;
  }
};
