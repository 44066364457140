import { FC, ReactNode, useMemo } from 'react';
import { PermissionKeys } from 'types/ApiModels/Administration';
import { ApiRolePermission, ApiRolePermissionType } from 'types/ApiModels/Administration/Role';
import { useAuthContext } from './store';

interface CanProps {
  /**
   * Key from permissions
   */
  access: PermissionKeys;
  do?: ApiRolePermissionType;
  children: ReactNode;
}

export const canAccess = (
  permissions: ApiRolePermission[],
  { access, do: canDo }: Pick<CanProps, 'access' | 'do'>
) => {

  // console.log('*****************');
  // console.log(access, canDo);
  
  

  const permission = permissions.find((p) => p.section === access);
  if (!permission) {
    const splitPermission = access.split(':');
    //console.log(splitPermission);
    
    const isGeneric = splitPermission.length > 1;

    if (isGeneric) {
      const genericRoute = permissions.find((p) => p.section.split(':')?.[0] === access);
      //console.log(!!genericRoute);
      
      //we don't care whether this route has view or manage access since it's generic we should allow at least to view.
      return !!genericRoute;
    } else {
      //we did not find it so MAYBE a more generic permission covers it
      const genericPermission = splitPermission?.[0];
      const coveringPermission = permissions.find((p) => p.section === genericPermission);
      return (
        !!coveringPermission &&
        (coveringPermission.type === canDo || coveringPermission.type === 'manage')
      );
    }
  }
  return permission && (permission.type === canDo || permission.type === 'manage');
};

const Can: FC<CanProps> = ({ access, do: canDo = 'view', children }: CanProps) => {
  const {
    currentUser: { permissions },
  } = useAuthContext();

  const hasAccess = useMemo(
    () => canAccess(permissions, { access, do: canDo }),
    [access, canDo, permissions]
  );

  if (hasAccess) return <>{children}</>;
};
export default Can;
