import Card from 'components/Card';
import GenericTable from 'components/GenericTable';
import InvitePatientModal from 'components/InvitePatientModal/InvitePatientModal';
import { PaginationControlsAlt } from 'components/Pagination/PaginationControls';
import { useEffect, useMemo } from 'react';
import {
  clearFormErrors,
  setFormErrors,
  useAdministrationContext,
  useAdministrationContextV2,
} from 'screens/Administration/store';
import { useAdminPatients } from 'screens/Administration/store/hooks';
import { toggleShowInvitePatient } from 'screens/Administration/store/Patients';
import { setPatientSelected } from 'screens/Administration/store/Patients/patientsActionCreators';
import { AdminPatientsActions } from '../AdminPatients';
import { AdminPatientListHeader, AdminPatientQuickFiltersHeader } from './AdminPatientListHeader';
import {
  AdminPatientRow,
  adminPatientTableColumns,
} from './AdminPatientsTable/utils/adminPatientsTableUtils';
import { mapPatientToList } from './utils';


const AdminPatientsMain = () => {
  const [{ showInvitePatient, patientsBook }, paginatedRequest, dispatch] = useAdminPatients();
  const { setActions, setBreadcrumbPathList, breadcrumbPathList } = useAdministrationContext();
  //const {dispatch: dispatchV2,administrationStateV2: { formError },} = useAdministrationContextV2();


  // useEffect(() => {
  //   dispatch(setPatientSelected(null));
  // }, [dispatch]);

  useEffect(() => {
    if (breadcrumbPathList.length !== 3) {
      setBreadcrumbPathList(breadcrumbPathList.slice(0, 3));
    }
  }, [breadcrumbPathList]);

  // useEffect(() => {
  //   setActions(AdminPatientsActions);
  // }, []);


  

  const patientBookCurrentPage = useMemo(() => {
    return patientsBook?.[paginatedRequest.currentPage];
  }, [paginatedRequest.currentPage, patientsBook]);

  const patientsMappedToList = useMemo<AdminPatientRow[]>(
    () => patientBookCurrentPage?.results?.map<AdminPatientRow>(mapPatientToList) ?? [],
    [patientBookCurrentPage]
  );

  const handleModalClose = () => {
    dispatch(toggleShowInvitePatient());
  };

  const handleModalConfirm = () => {
    dispatch(toggleShowInvitePatient());
  };

  return (
    <Card
      className="card-bg-border"
      headers={[
        <AdminPatientListHeader
          patientsCount={patientsBook?.[1]?.count}
          key="admin-patient-list-header"
        />,
        <AdminPatientQuickFiltersHeader key="admin-patient-quick-filters-header" />,
      ]}
    >
      <GenericTable columns={adminPatientTableColumns} data={patientsMappedToList} />
      {/* <GenericTable columns={adminPatientTableColumns} data={patientList.complete} /> */}
      <div className="d-flex justify-content-end">
        <PaginationControlsAlt
          currentPage={paginatedRequest.currentPage}
          handleLast={paginatedRequest.last}
          handleFirst={paginatedRequest.first}
          handleNext={paginatedRequest.next}
          handlePrevious={paginatedRequest.previous}
          maxPage={paginatedRequest.pages}
        />
      </div>
      <InvitePatientModal
        handleClose={handleModalClose}
        handleConfirm={handleModalConfirm}
        show={showInvitePatient}
      />
    </Card>
  );
};
export default AdminPatientsMain;
