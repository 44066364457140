import shieldLogo from 'assets/images/secure-shield.png';
import DigitToken from 'components/DigitToken';
import { Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useHistory } from 'react-router-dom';
import { sendVerificationCodeEmail, verifySecurityTokenForPasswordReset, sendTechnicalSupportEmail, chooseTypeToSendCode, chooseTypeToSendCodePost } from 'services/userService';
import { VerificationCode } from 'types/ApiModels/Login';
import { loginViews } from 'types/Login';
import { setBreadcrumTitle } from 'util/themeUtils';
import { throttle } from 'util/utils';
import { onSubmit } from '../../Login/LoginForm/util';
import { LOGIN_2MFA, VERIFICATION_CODE_LENGTH } from '../../../shared/global-constants';
import Card from 'components/Card';

const VerificationToken = ({
  typeSendCode,
  previousValues,
  changeView,
  setPreviousValues,
  isResetting,
  setIsResetting,
  userEmail,
  setValidCode,
  onCancel = () => null,
  userId
}) => {
  const [numbers, setNumbers] = useState([]);
  const [code, setCode] = useState('');
  const [codeIsInvalid, setCodeIsInvalid] = useState(false);
  const [requestErrorMsg, setRequestErrorMsg] = useState('');
  const [failsTrials, setFailTrials] = useState(0);
  const [messageError, setMessageError] = useState('');
  const [wrongCode, setWrongCode] = useState(false);
  const [showSuccessComponent, setShowSuccessComponent] = useState(false);

  const history = useHistory();

  useEffect(() => {

  }, [failsTrials, requestErrorMsg]);

  useEffect(() => {
    setCode(numbers.join(''));
  }, [numbers]);

  const resetCodeValidity = () => setCodeIsInvalid(false);

  const handleTokenChange = () => {
    resetCodeValidity();
    setRequestErrorMsg("");
    setWrongCode(false);
  };

  const successComponent = () => {
    return (
      <Card
        className={`vytrac-card}`}
        bodyClassName="d-flex flex-column gap-md text-center"
      >
        <h1 className="font-size-extra-large font-weight-md">
          &#127881; <br />
         The email was send
        </h1>
        <p className="font-size-large">Hi {userEmail}, please check in your email account the email to update your password</p>  
        <div className="d-flex flex-column gap-lg justify-content-center align-items-center">
          {/* Extra content */}
        </div>
      </Card>
    );
  };

  return (
    <div className="d-flex h-100 flex-column">
      {showSuccessComponent
      ? successComponent()
      : <><div>
        <Row className="vertical-container">
          <Col className="line-container">
            <div className="grey-line-inv"></div>
          </Col>
          <Col className="line-container">
            <div className="green-line-inv"></div>
          </Col>
        </Row>

        <div className="centered-logo">
          <div className="logo-container">
            <img className="shield-logo" alt="" src={shieldLogo} />
          </div>
        </div>

        <p className="text-left normal-text top25 dynamic-font-heading" style={{ marginTop: 30 }}>
          Welcome to <span className="bold-blue text-bolder">{setBreadcrumTitle()}</span>
        </p>

        <p className="text-left smaller-text dynamic-font-normal">
          <b>Enter your 6 digit verification code sent to: {typeSendCode=="email"? previousValues.emailAddress:previousValues.cellPhoneNumber}</b>
          . Didn&apos;t receive your code yet?{' '}
          <button
            type="button"
            className="link"
            // onClick={throttle(
            //   () => sendVerificationCodeEmail(previousValues.emailToMessage || userEmail),
            //   5000
            // )}
            onClick={ () => {
              //sendVerificationCodeEmail(previousValues.emailToMessage || userEmail)
              //await verifySecurityTokenForPasswordReset(previousValues.emailToMessage, code);          
              chooseTypeToSendCodePost(userId, 'email');
            }
            }
          >
            Send it again .
          </button>
        </p>
      </div>
      <Formik 
        initialValues={{ usernameEmailPhone: '' }}
        onSubmit={async (values, { setFieldError }) => {
          if (isResetting) {
            const res = await verifySecurityTokenForPasswordReset(userEmail, code);

            if (res.status === 200) {
              const json: VerificationCode = await res.json();
              setValidCode(json);
              //setShowSuccessComponent(true);
              //setTimeout(() => changeView(loginViews.login), 2500);
              
            } else {
              setCodeIsInvalid(true);
            } 
            return;
          }
          //TODO: this looks wonky, We should not spread here, just send what is needed
          const isValidAccess = await onSubmit(
            {
              ...previousValues,
              email: previousValues?.email ? previousValues.email : userEmail,
              code
            },
            changeView,
            history,
            setPreviousValues,
            setRequestErrorMsg,
            setFailTrials,
            failsTrials,
            LOGIN_2MFA,
            true
          );
          

          if(isValidAccess){
            //setTimeout(() => changeView(loginViews.login), 2500);
            changeView(loginViews.login)
            return;
          }


          if (!isValidAccess) {
            setFailTrials(failsTrials + 1);
            setWrongCode(true);
          }


          // if (failsTrials == 1) {
          //   // Send technical support email notification
          //   const emailUser: string = previousValues.email || userEmail;
          //   if (emailUser) sendTechnicalSupportEmail(previousValues.email || userEmail)
          // }

          if (failsTrials >= 3) {
            // When the user reaches the 4th trial the app have to redirect to the login
            setMessageError('You reach the maximum trials for the OTP');
            //setRequestErrorMsg('You reach the maximum trials for the OTP');
            //setTimeout(() => changeView(loginViews.login), 2500);
          }
        }}
      >
        {({ handleBlur, handleChange }) => (
          <Form className="d-flex flex-column flex-grow-1">
            <div className="d-flex flex-column flex-grow-1 gap-lg justify-content-center align-items-center">
              <div className="d-flex justify-content-center">
                <DigitToken
                  length={VERIFICATION_CODE_LENGTH}
                  onChange={handleTokenChange}
                  setValue={setNumbers}
                  value={numbers}
                  className="dynamic-font-huge"
                />
              </div>
              {(codeIsInvalid || wrongCode) ? (
                <div className="dynamic-font-normal text-danger">
                  The code you supplied is not correct, please try again.
                </div>
              ) : null}

              {failsTrials >= 2 && (
                <div className="dynamic-font-normal text-danger">
                  A system administrator will contact you.
                </div>
              )}
            </div>
            <div className="d-flex justify-content-center">
              <Field
                name="usernameEmailPhone"
                type="hidden"
                defaultValue=""
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>

            <Row className="d-flex justify-content-between">
              <Button onClick={onCancel} className="dynamic-font-normal text-bold" variant="light">
                CANCEL
              </Button>
              <Button
                disabled={numbers.filter((n) => n >= 0).length !== VERIFICATION_CODE_LENGTH}
                type="submit"
                className="dynamic-font-normal text-bold"
              >
                CONTINUE
              </Button>
            </Row>
          </Form>
        )}
      </Formik>
      </>}
    </div>
  );
};

export default VerificationToken;
