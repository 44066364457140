enum AccountSettingsFormikKeys {
  GENERAL_EMAIL = 'generalEmail',
  GENERAL_PHONE = 'generalPhone',
  TECH_SUPPORT_EMAIL = 'techSupportEmail',
  ADDRESS = 'address',
  SECOND_ADDRESS = 'secondAddress',
  APT='apt',
  CITY='city',
  STATE='state',
  ZIP_CODE='zip_code',
  IOS_PATIENT_APP = 'iosPatientApp',
  IOS_PROVIDER_APP = 'iosProviderApp',
  ANDROID_PATIENT_APP = 'androidPatientApp',
  ANDROID_PROVIDER_APP = 'androidProviderApp',
  CARE_CHAMPIONS_ANDORID_APP = 'androidProviderApp',
  CARE_CHAMPIONS_IOS_APP = 'androidProviderApp',
  PROVIDER_WEB_PORTAL = 'patientWebPortal',
  COUNTRY_CODE = 'countryCode',
  TIMEZONE = 'timezone',
  DATE_FORMAT = 'dateFormat',
  BRAND_NAME = 'brandName',
  BRAND_REWARDS_STORE_FEED= 'brandRewardsStoreFeed',
  BRAND_REWARDS_REDEEM_LINK= 'brandRewardsRedeemLink'

}

export default AccountSettingsFormikKeys;
