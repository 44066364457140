import React, { useEffect, useState, FC } from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'components/Button';
import VyTracModal from 'components/Modal';

interface SessionExpirationModalProps {
    show: boolean;
    onConfirm: () => void;
  }

const SessionExpirationModal: FC<SessionExpirationModalProps> = ({
    show,
    onConfirm,
  }: SessionExpirationModalProps) => {
  return (
    <VyTracModal
      show={show}
      title="Are you still there?"
      onClose={onConfirm}
      body={
        <div className="d-flex flex-column gap">
          Are you still there?
        </div>
      }
      footer={
        <div className="d-flex w-100 justify-content-between">
          <Button label="confirm" onClick={() => onConfirm() } />
        </div>
      }
    />
  );
};

export default SessionExpirationModal;