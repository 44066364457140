import { useAuthContext } from 'auth';
import { History } from 'history';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Tab as BootstrapTab, Tabs as BootstrapTabs } from 'react-bootstrap';
import { useHistory, useRouteMatch } from 'react-router';
import { usePatientFormikContext } from 'screens/Patients/store';
import { usePatientContext } from 'screens/Patients/store/PatientContext';
import { patientsInitialBreadcrumbPathList } from 'screens/Patients/util/initialBreadcrumbPathList';
import PatientGetSingle from 'types/ApiModels/Patients/PatientGetSingle';
import { setActions, setBreadcrumbPathList } from 'util/commonContext/NavigationContext';
import tabActionsComponentMap from './util/tabActionsComponentMap';
import tabComponentMap from './util/tabComponentMap';
import { tabOptions } from './util/tabEnum';
import tabNameMap from './util/tabNameMap';
import tabRouteResolverMap from './util/tabRouteResolverMap';

export interface IPatientTab {
  tab: string;
  patientId: string;
}
const PatientTabs = ({ tab, patientId }: IPatientTab) => {
  // const { setState } = useContext(Context);
  const {
    dispatch,
    patientState: {
      patientNavigation: { breadcrumbPathList },
    },
  } = usePatientContext();

  const { selectedPatient } = usePatientFormikContext();
  const { providerInfo } = useAuthContext();
  const { url } = useRouteMatch();
  const history = useHistory();

  const updateBreadcrumbPathList = useCallback(() => {
   // const { first_name, last_name } = selectedPatient.user;
   const { first_name, last_name } = selectedPatient;
    dispatch(
      setBreadcrumbPathList([
        ...patientsInitialBreadcrumbPathList,
        {
          title: `${first_name} ${last_name}`,
          //title: `Cesar Arguijo`,
          url: null,
        },
        {
          title: tabNameMap[tab],
          url: tabRouteResolverMap[tab](patientId),
        },
      ])
    );
  }, [breadcrumbPathList, dispatch, patientId, tab]);

  const handleSelect = (tabKey: string, history: History) => {
    if (tabKey === tab) return;
    history.push(tabRouteResolverMap[tabKey](patientId));
  };

  useEffect(() => {
    updateBreadcrumbPathList();
    dispatch(setActions(tabActionsComponentMap[tab]));
  }, [tab, dispatch, url, patientId]);

  const isProviderPartOfPatientCareTeam = useMemo(
    () => providerInfo?.care_team?.id === selectedPatient?.care_team?.id,
    [providerInfo?.care_team?.id, selectedPatient?.care_team?.id]
  );

  return (
    <BootstrapTabs
      id="patients-tab"
      onSelect={(tabKey) => handleSelect(tabKey, history)}
      className="tabs"
      activeKey={tab}
      mountOnEnter
    >
      {Object.keys(tabComponentMap).map((tabKey) => {
        if (tabOptions[tabKey].isCareTeamRestricted && !isProviderPartOfPatientCareTeam) {
          return null;
        }
        const Component: ({ patient }: { patient?: PatientGetSingle }) => JSX.Element =
          tabComponentMap[tabKey];

        return (
          <BootstrapTab
            key={tabKey}
            tabClassName={tab === tabKey ? 'tab-selected' : 'tab'}
            eventKey={tabKey}
            title={tabNameMap[tabKey]}
          >
            <Component />
          </BootstrapTab>
        );
      })}
    </BootstrapTabs>
  );
};

export default PatientTabs;
