import PatientSettingItem from 'types/ApiModels/Administration/patient-setting-item';
import { TrackingActivityTemplate } from 'types/ApiModels/Administration';
import { PaginatedResponse } from 'types/ApiModels/General';

export enum PatientActivitiesActionTypes {
  SET_BOOK_PA = 'SET_BOOK_PA',
  SET_TEMPLATES_PA = 'SET_TEMPLATES_PA',
  CLEAR_CHANGES_PA = 'CLEAR_CHANGES_PA',
  SET_TEMPLATES_SUBMIT_PA = 'SET_TEMPLATES_SUBMIT_PA',
  UPSERT_CREATED_PA = 'UPSERT_CREATED_PA',
  REMOVE_CREATED_PA = 'REMOVE_CREATED_PA',
  UPSERT_UPDATED_PA = 'UPSERT_UPDATED_PA',
  APPEND_DELETED_PA = 'APPEND_DELETED_PA',
  SET_CURRENT_TEMPLATE_DEFAULT_PA = 'SET_CURRENT_TEMPLATE_DEFAULT_PA',
  SET_RESET_CHANGES_PA = 'SET_RESET_CHANGES_PA',
  SYNC_REMOVE_TEMPLATES_PA = 'SYNC_REMOVE_TEMPLATES_PA',
  UPSERT_TEMPLATES_PA = 'UPSERT_TEMPLATES_PA',
}

export type PatientActivitiesAction =
  | {
      type: PatientActivitiesActionTypes.SET_BOOK_PA;
      payload: {
        book: Record<number, PaginatedResponse<PatientSettingItem>>;
      };
    }
  | {
      type: PatientActivitiesActionTypes.SET_TEMPLATES_PA;
      payload: {
        templates: TrackingActivityTemplate[];
      };
    }
  | {
      type: PatientActivitiesActionTypes.CLEAR_CHANGES_PA;
    }
  | {
      type: PatientActivitiesActionTypes.SET_TEMPLATES_SUBMIT_PA;
      payload: {
        submit: () => void;
      };
    }
  | {
      type: PatientActivitiesActionTypes.UPSERT_CREATED_PA;
      payload: {
        createdId: number;
      };
    }
  | {
      type: PatientActivitiesActionTypes.REMOVE_CREATED_PA;
      payload: {
        createdId: number;
      };
    }
  | {
      type: PatientActivitiesActionTypes.UPSERT_UPDATED_PA;
      payload: {
        updatedId: number;
      };
    }
  | {
      type: PatientActivitiesActionTypes.APPEND_DELETED_PA;
      payload: {
        deletedId: number;
      };
    }
  | {
      type: PatientActivitiesActionTypes.SET_CURRENT_TEMPLATE_DEFAULT_PA;
      payload: {
        id: number;
      };
    }
  | {
      type: PatientActivitiesActionTypes.SET_RESET_CHANGES_PA;
      payload: {
        reset: () => void;
      };
    }
  | {
      type: PatientActivitiesActionTypes.UPSERT_TEMPLATES_PA;
      payload: {
        templates: TrackingActivityTemplate[];
      };
    }
  | {
      type: PatientActivitiesActionTypes.SYNC_REMOVE_TEMPLATES_PA;
      payload: {
        templateIds: number[];
      };
    };
