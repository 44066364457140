import { Conversation } from '@twilio/conversations';
import { useConversationMetadata } from 'hooks';
import { useMemo } from 'react';
import { useDeleteAllCreatedConversations } from 'services/twilio';
import { useTwilioClient } from 'store/twilio-client';
import useSubscribedConversations from './use-subscribed-conversations';

const useMessagesCenter = (filterConversationFunction: (c: Conversation) => boolean) => {
  const {
    client: twilioClient,
    creationError: twilioError,
    isLoading: isTwilioLoading,
  } = useTwilioClient();

  //conversations
  const {
    areConversationsLoading,
    conversations,
    loadMoreConversations,
    conversationParticipantIdentities,
    identityByParticipantSID,
  } = useSubscribedConversations(twilioClient, filterConversationFunction);

  // useDeleteAllCreatedConversations(twilioClient);
  const metadataCache = useConversationMetadata(conversationParticipantIdentities);

  return useMemo(
    () => ({
      conversations,
      loadMoreConversations,
      areConversationsLoading,
      isTwilioLoading,
      twilioError,
      metadataCache,
      identityByParticipantSID,
    }),
    [
      areConversationsLoading,
      conversations,
      isTwilioLoading,
      loadMoreConversations,
      metadataCache,
      twilioError,
      identityByParticipantSID,
    ]
  );
};

export default useMessagesCenter;
