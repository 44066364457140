import { Dispatch } from 'react';
import { useAdministrationContextV2 } from '..';
import { AllAdminActions } from '../administrationReducer';
import { AdminPatientsState } from '../Patients/adminPatientsreducer';

/**
 * Similar to useAdminPatients but does not include the paginated request since singlepatients does not need it.
 * @returns
 */
export const useAdminSinglePatient = (): [AdminPatientsState, Dispatch<AllAdminActions>] => {
  const {
    administrationStateV2: { patients },
    dispatch,
  } = useAdministrationContextV2();

  return [patients, dispatch];
};
