import { vytracAxios } from 'ajax';
import { PaginatedResponse } from 'types/ApiModels/General';
import { APIResourceCategory, APIResourceResult } from 'types/ApiModels/Resources/Resources';
import { IResourceCategory, IResourceResult } from 'types/Resources/Resources';

const resourceToApi = (resource: IResourceResult): APIResourceResult => {
  return {
    id: resource.id,
    name: resource.name,
    type: resource.type.toLowerCase(),
    location: resource.location.toLowerCase(),
    url: resource.url,
    cover: resource.cover,
    content: resource.url,
    categories_ids: resource.categories.map((c) => c.id.toString()),
  };
};

const apiResultsToResource = (apiResource) => {
  const parsedData: IResourceResult[] = [];
  apiResource.forEach((apiCategory) => {
    parsedData.push({
      id: apiCategory.id,
      name: apiCategory.name,
      categories: apiCategory.categories,
      categoriesId: apiCategory.categories_ids,
      type: apiCategory.type,
      location: apiCategory.location,
      url: apiCategory.url,
      cover: apiCategory.cover,
      content: apiCategory.content,
      dateCreated: apiCategory.date_created,
      createdBy: apiCategory.created_by,
    });
  });

  return parsedData;
};

const apiToResource = (apiResource) => {
  const parsedData: IResourceResult = {
    id: apiResource.id,
    name: apiResource.name,
    categories: apiResource.categories,
    categoriesId: apiResource.categories_ids,
    type: apiResource.type,
    location: apiResource.location,
    url: apiResource.url,
    cover: apiResource.cover,
    content: apiResource.content,
    dateCreated: apiResource.date_created,
    createdBy: apiResource.created_by,
  };

  return parsedData;
};

const resourceCategoryToApi = (category: IResourceCategory): APIResourceCategory => {
  return {
    id: category.id,
    name: category.name,
    date_created: category.dateCreated,
    order: category.order,
    created_by: category.createdBy,
    population: category.population,
  };
};

const apiToResourceCategory = (apiCategory) => {
  const parsedData: IResourceCategory = {
    id: apiCategory.id,
    name: apiCategory.name,
    dateCreated: apiCategory.date_created,
    order: apiCategory.order,
    createdBy: apiCategory.created_by,
    population: apiCategory.population,
  };

  return parsedData;
};

const apiResultsToResourceCategory = (apiCategoryResults: APIResourceCategory[]) => {
  const parsedData: IResourceCategory[] = [];
  apiCategoryResults.forEach((apiCategory) => {
    parsedData.push({
      id: apiCategory.id,
      name: apiCategory.name,
      dateCreated: apiCategory.date_created,
      order: apiCategory.order,
      createdBy: apiCategory.created_by,
      population: apiCategory.population,
    });
  });

  return parsedData;
};

export async function getResourceList() {
  try {
    const response = await vytracAxios.get<PaginatedResponse<APIResourceResult>>('resource/');
    return apiResultsToResource(response.data.results);
  } catch (exception) {
    throw exception;
  }
}

export async function createResource(data: IResourceResult) {
  try {
    const apiResource = resourceToApi(data);
    const response = await vytracAxios.post('resource/', apiResource);
    return apiToResource(response.data);
  } catch (exception) {
    throw exception;
  }
}

export async function getResourceCategoryList() {
  try {
    const response = await vytracAxios.get<IResourceCategory[]>('resource/category/');
    return apiResultsToResourceCategory(response.data);
  } catch (exception) {
    throw exception;
  }
}

export async function createCategory(data: IResourceCategory) {
  try {
    const apiCategory = resourceCategoryToApi(data);
    const response = await vytracAxios.post('resource/category/', apiCategory);
    return apiToResourceCategory(response.data);
  } catch (exception) {
    throw exception;
  }
}

export async function getResourceCategory(id: string) {
  try {
    const response = await vytracAxios.get<IResourceCategory>(`resource/category/${id}`);
    return apiToResourceCategory(response.data);
  } catch (exception) {
    throw exception;
  }
}

export async function updateResourceCategory(data: IResourceCategory) {
  try {
    const response = await vytracAxios.post<IResourceCategory>(
      `resource/category/${data.id}/`,
      data
    );
    return apiToResourceCategory(response.data);
  } catch (exception) {
    throw exception;
  }
}

export async function updateResource(data: IResourceResult) {
  try {
    const apiResource = resourceToApi(data);
    const response = await vytracAxios.put<IResourceResult>(`resource/${data.id}/`, apiResource);
    return apiToResource(response.data);
  } catch (exception) {
    throw exception;
  }
}

export async function deleteResource(id: string) {
  try {
    await vytracAxios.delete(`resource/${id}/`);
  } catch (exception) {
    throw exception;
  }
}
