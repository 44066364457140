export const getCurrentPermissionBySection = (permissions, permissionKey) =>
  permissions.filter(function (data) {
    // console.log(data.section, data.type);
    return data.section == permissionKey;
  });

export const PERMISSION_TYPE = {
  MANAGE: 'manage',
  VIEW: 'view',
};

export const PAGE = {
  ADMINISTRATION: {
    DASHBOARD:           'ADMINISTRATION_dashboard',
    PROVIDERS:           'ADMINISTRATION_providers',
    ROLES:               'ADMINISTRATION_roles',
    PATIENTS:            'ADMINISTRATION_patients',
    PATIENTS_SETTINGS:   'ADMINISTRATION_patient_settings',
    NOTIFICATIONS:       'ADMINISTRATION_notifications',
    FORMS:               'ADMINISTRATION_forms',
    EMAIL_SETTINGS:      'ADMINISTRATION_email_settings',
    ACCOUNT_SETTINGS:    'ADMINISTRATION_account_settings',
    REPORTS:             'ADMINISTRATION_reports',
    RULES_ENGINE:        'ADMINISTRATION_rules_engine',
    MESSAGES:            'ADMINISTRATION_messages',
    CARE_PLAN_TEMPLATES: 'ADMINISTRATION_care_plan_templates',
    AUDIT_TRACKING:      'ADMINISTRATION_audit_tracking',
  },
  PATIENTS: {
    
  },
  TEMPLATES: {

  }
};

const checkPermissions = (userPermissions, allowedPermissions) => {
  if (allowedPermissions.length === 0) {
    return true;
  }

  return userPermissions.some(permission =>
    allowedPermissions.includes(permission)
  );
};

const AccessControl = ({
  user,
  userPermissions,
  allowedPermissions,
  children,
  renderNoAccess,
  accessCheck,
  extraAccessData,
}) => {
  let permitted;
  // when an accessCheck function is provided, ensure that passes as well as the permissions
  if (accessCheck) {
    permitted =
      accessCheck(extraAccessData, user) &&
      checkPermissions(userPermissions, allowedPermissions);
  } else {
    // otherwise only check permissions
    permitted = checkPermissions(userPermissions, allowedPermissions);
  }

  if (permitted) {
    return children;
  }
  return renderNoAccess();
};

AccessControl.defaultProps = {
  allowedPermissions: [],
  userPermissions: [],
  renderNoAccess: () => null,
};

