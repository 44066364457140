import { Role } from 'types/ApiModels/Administration';
import { Provider, ProviderUpdateRequest } from 'types/ApiModels/Providers/Provider';

export const mapProviderToUpdateProvider = (p: Provider): ProviderUpdateRequest => ({
  address: p.address,
  date_birth: p.date_birth,
  email_address: p.email_address,
  first_name: p.first_name,
  language: p.language,
  last_name: p.last_name,
  middle_name: p.middle_name,
  phone_number1: p.cell_phone_number,
  phone_number2: p.phone_number2,
  roles_id: p.roles.map((r) => r.id),
  sex: p.sex,
  speciality_id: p.specialities.map((s) => s.id),
  title_id: p.title ? p.title.id : null,
  // photo: p.photo ? 'photoplaceholder' : p.photo,
  active: p.active,
});

export interface ProviderRow {
  provider: {
    first_name: string;
    last_name: string;
    image_url?: string;
    username?: string;
    email?:string;
  };
  id: number;
  patients?: number;
  'follow-ups'?: number;
  'open-cases'?: number;
  completed?: number;
  satisfaction?: number;
  title?: string;
  roles?: number;
  speciality?: number;
  status?: string;
  action?: string;
  active?: boolean;
}

export const mapProviderToProviderRowOriginal = (p: Provider): ProviderRow => ({
  'follow-ups': p.follow,
  'open-cases': p.open_cases,
  action: '',
  active: p.active,
  completed: p.completed_cases,
  id: p.id,
  patients: p.patients,
  //roles: p.roles.map((r) => r.name).join(', '),
  satisfaction: p.satisfaction ?? 0,
  //speciality: p.speciality.map((s) => s.text),
  //TODO: what is this. Active, vacation mode? where should this come from
  status: 'Active',
  title: p.title?.name,
  provider: {
    username: p.username,
    first_name: p.first_name,
    last_name: p.last_name,
    image_url: p.photo,
  },
});

export const mapProviderToProviderRow = (p: Provider): ProviderRow => {
  return (
  {
  id: p.id,
  roles: p['rol'],
  speciality: p['speciality'],
  //roles: p.roles.map((r) => r.name).join(', '),
  //roles: p.roles[0],
  //speciality: p.speciality[0],
  //TODO: what is this. Active, vacation mode? where should this come from
  active: p['active'],
  status: p['active'] ? 'Active' : 'Inactive',
  title: String(p['title'] ?? ""),
  provider: {
    username: p.email_address,
    first_name: p.first_name,
    last_name: p.last_name,
  },
})};