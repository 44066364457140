import { useEffect, useState } from 'react';
import { getEncounterById } from 'services/encounterService';
import { PatientEncounter } from 'types/ApiModels/Patients/encounter';

const useEncounterPatient = (encounterId: number, patientId: number) => {
  const [selectedPatientId, setSelectedPatientId] = useState(null);
  const [patient, setPatient] = useState<PatientEncounter>();

  const fetchEncounterById = async (encounterId: number) => {
    const [encounter, error] = await getEncounterById(encounterId);
    if (error) {
      console.error('Error trying to fetch encounter information', error);
      return;
    }
    setPatient(encounter.patient);
  };
  useEffect(() => {
    if (encounterId && patientId && selectedPatientId !== patientId) {
      fetchEncounterById(encounterId);
      setSelectedPatientId(patientId);
    }
  }, [encounterId, patientId, selectedPatientId]);

  return patient;
};

export default useEncounterPatient;
