import DefaultImage from 'components/DefaultImage';
import {
  IdentificationFormikKeys,
  PatientManagementFormikKeys,
} from 'components/PatientManagementForm/types';
import PatientManagementInput from 'components/PatientManagementInput';
import PatientManagementSelect from 'components/PatientManagementSelect';
import { LabeledField, SelectFormik, TextInputFormik } from 'components/FormikComponents';
import { useContext, useState } from 'react';
import { Form } from 'react-bootstrap';
import { mockPatientList } from 'screens/ProviderDashboard/utils';
import { useAppQuery } from 'store/use-app-query';
import PatientGetSingle from 'types/ApiModels/Patients/PatientGetSingle';
import { PatientFormikContext } from '../../../../../../store/PatientFormikContext';
import styles from './styles.module.css';

interface IPatientIdentification {
  patient: PatientGetSingle;
  formData: any;
  handleChange: any;
  errors: any;
}

const formikFieldMap = {
  firstName: {
    label: 'Legal first name',
    name: 'identification.firstName',
  },
  lastName: {
    label: 'Legal last name',
    name: 'identification.lastName',
  },
  middleName: {
    label: 'Legal middle name',
    name: 'identification.middleName',
  },
  dateOfBirth: {
    label: 'Date of birth',
    name: 'identification.dateOfBirth',
  }
};

const PatientIdentification = ({
  patient,
  formData,
  handleChange,
  errors,
}: IPatientIdentification) => {
  // Context
  const { data: sexOptions } = useAppQuery('sex');

  return (
    <div className="dashboard-detail">
      <div className="dashboard-item-header font-weight-md">Identification</div>
      <div className={styles.body}>
        <div className={styles.photoContainer}>
          {patient.user?.imageUrl ? (
            <img className={styles.photo} src={patient.user?.imageUrl} alt="" />
          ) : (
            <DefaultImage sex={patient.user?.sex} className={styles.photo} />
          )}
        </div>
        <div className={styles['info-container']}>
          <div>
            <div className={`${styles.value} font-size-medium`}>
              <p className={`${styles.title} font-size-medium`}>Legal first name</p>
              <PatientManagementInput
                value={patient.first_name}
                name={`${PatientManagementFormikKeys.IDENTIFICATION}.${IdentificationFormikKeys.FIRST_NAME}`}
                error={errors ? errors[IdentificationFormikKeys.FIRST_NAME] : undefined}
                onChange={handleChange}
              />
            </div>
          </div>

          <div>
            <p className={`${styles.title} font-size-medium`}>Legal last name</p>
            <div className={`${styles.value} font-size-medium`}>
              <PatientManagementInput
                value={patient.last_name}
                name={`${PatientManagementFormikKeys.IDENTIFICATION}.${IdentificationFormikKeys.LAST_NAME}`}
                onChange={handleChange}
                error={errors ? errors[IdentificationFormikKeys.LAST_NAME] : undefined}
              />
            </div>
          </div>

          <div>
            <p className={`${styles.title} font-size-medium`}>Legal middle name</p>
            <div className={`${styles.value} font-size-medium`}>
              <PatientManagementInput
                value={patient.middle_name_initial}
                name={`${PatientManagementFormikKeys.IDENTIFICATION}.${IdentificationFormikKeys.MIDDLE_NAME}`}
                onChange={handleChange}
                error={errors ? errors[IdentificationFormikKeys.MIDDLE_NAME] : undefined}
              />
            </div>
          </div>

          <div>
            <p className={`${styles.title} font-size-medium`}>Date of birth</p>
            <div className={`${styles.value} font-size-medium`}>
              <PatientManagementInput
                value={patient.date_of_birth}
                name={`${PatientManagementFormikKeys.IDENTIFICATION}.${IdentificationFormikKeys.BIRTHDATE}`}
                onChange={handleChange}
                type="date"
                error={errors ? errors[IdentificationFormikKeys.BIRTHDATE] : undefined}
              />
            </div>
          </div>

          <div>
            <p className={`${styles.title} font-size-medium`}>Sex assigned at birth</p>
            <div className={`${styles.value} font-size-medium`}>
              <PatientManagementSelect
                value={patient.sex}
                name={`${PatientManagementFormikKeys.IDENTIFICATION}.${IdentificationFormikKeys.SEX}`}
                onChange={handleChange}
                error={errors ? errors[IdentificationFormikKeys.SEX] : undefined}
                options={sexOptions}
                renderOption={({ id, name }) => (
                  <option value={id} key={id}>
                    {name}
                  </option>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientIdentification;
