import { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useAdministrationContext } from 'screens/Administration/store';
import BreadCrumbPath from 'types/Shared/BreadCrumbPath';
import './overrideStyles.css';
import styles from './styles.module.css';

interface IBreadcrumbs {
  items: BreadCrumbPath[];
}
const Breadcrumbs = ({ items }: IBreadcrumbs) => {
  const history = useHistory();

  const handleBreadCrumbClick = (url?: string, callback?: () => void) => {
    callback && callback();

    if (url) {
      history.push(url)
    }
  }

  return (
    <>
      <hr className={styles.horizontalLine} />
      <nav aria-label={`breadcrumb`} className={styles.nav}>
        <ol className="breadcrumb bg-transparent">
          {items.map(({ title, url, callback }, index, array) => (
            <Fragment key={`${index}-${title}-${url}`}>
              {index !== array.length - 1 ? (
                <li className="breadcrumb-item">
                  <button className={styles.link} onClick={() => handleBreadCrumbClick(url, callback)}>
                    {title}
                  </button>
                </li>
              ) : (
                <li className="breadcrumb-item active">{title}</li>
              )}
            </Fragment>
          ))}
        </ol>
      </nav>
      <hr className={styles.horizontalLine} />
    </>
  );
};

export default Breadcrumbs;
