export const setToken = (token: { token: string; refresh: string, id: string, email: string, rol_id: string, fullname: string}, rememberMe = false) => {
  if (rememberMe) { 
    localStorage.setItem('access', token.token);
    localStorage.setItem('refresh', token.refresh);
    localStorage.setItem('id',token.id)
    localStorage.setItem('token',token.token)
    localStorage.setItem('email',token.email)
    localStorage.setItem('rol_id',token.rol_id)
    localStorage.setItem('fullname',token.fullname)
  } else {
    sessionStorage.setItem('access', token.token);
    sessionStorage.setItem('refresh', token.refresh);
    sessionStorage.setItem('id',token.id)
    sessionStorage.setItem('token',token.token)
    sessionStorage.setItem('email',token.email)
    sessionStorage.setItem('rol_id',token.rol_id)
    sessionStorage.setItem('fullname',token.fullname)
  }

};

export const  getAccessToken = () => {
  const localToken = localStorage.getItem('token');
  const localuserid = localStorage.getItem('id');

  const sessionToken = sessionStorage.getItem('token');
  const sessionuserid = sessionStorage.getItem('id');
  return { token: localToken || sessionToken, rememberMe: !!localToken , userid: localuserid || sessionuserid};
};
export const getRefreshToken = () => {
  return localStorage.getItem('token') || sessionStorage.getItem('token');
};

export const clearTokensLogout= () => {
  localStorage.removeItem('id')
  localStorage.removeItem('token')
  localStorage.removeItem('email')
  localStorage.removeItem('rol_id')
  localStorage.removeItem('fullname')

  sessionStorage.removeItem('id')
  sessionStorage.removeItem('token')
  sessionStorage.removeItem('email')
  sessionStorage.removeItem('rol_id')
  sessionStorage.removeItem('fullname')
};


export const clearTokens = () => {
  localStorage.removeItem('access');
  localStorage.removeItem('refresh');
  //localStorage.removeItem('id')
  //localStorage.removeItem('token')
  //localStorage.removeItem('email')
  //localStorage.removeItem('rol_id')
  //localStorage.removeItem('fullname')



  sessionStorage.removeItem('access');
  sessionStorage.removeItem('refresh');
  //sessionStorage.removeItem('id')
  //sessionStorage.removeItem('token')
  //sessionStorage.removeItem('email')
  //sessionStorage.removeItem('rol_id')
  //sessionStorage.removeItem('fullname')
};
