import { KeywordsTemplate } from 'types/ApiModels/Administration';
import { PatientNotesKeywords } from 'types/ApiModels/Administration';
import { PaginatedResponse } from 'types/ApiModels/General';
import { NotesKeywordsAction, NotesKeywordsActionTypes } from './notes-keywords-action-types';

export interface NotesKeywordsState {
  book: Record<number, PaginatedResponse<PatientNotesKeywords>>;
  templates: KeywordsTemplate[];
  resetTemplateChanges: () => void;
  deletedTemplates: number[];
  updatedTemplates: number[];
  createdTemplates: number[];
  submitTemplates: () => void;
  currentDefaultKeywordTemplateId: number;
}

export const notesKeywordsInitialState: NotesKeywordsState = {
  book: null,
  templates: null,
  resetTemplateChanges: () => undefined,
  deletedTemplates: [],
  updatedTemplates: [],
  createdTemplates: [],
  submitTemplates: () => undefined,
  currentDefaultKeywordTemplateId: null,
};

export const notesKeywordsReducer = (
  state: NotesKeywordsState,
  action: NotesKeywordsAction
): NotesKeywordsState => {
  switch (action.type) {
    case NotesKeywordsActionTypes.SET_BOOK_NK: {
      return { ...state, book: action.payload.book };
    }
    case NotesKeywordsActionTypes.SET_TEMPLATES_NK: {
      return { ...state, templates: action.payload.templates };
    }
    case NotesKeywordsActionTypes.CLEAR_CHANGES_NK: {
      return {
        ...state,
        updatedTemplates: [],
        createdTemplates: [],
        deletedTemplates: [],
        currentDefaultKeywordTemplateId: null,
      };
    }
    case NotesKeywordsActionTypes.SET_SUBMIT_NK: {
      return { ...state, submitTemplates: action.payload.submit };
    }
    case NotesKeywordsActionTypes.UPSERT_CREATED_NK: {
      //it may happen that user wants to update a just-created activity template so we must look for an already-queued created template

      const existingIdx = state.createdTemplates.findIndex((c) => c === action.payload.createdId);
      if (existingIdx === -1) {
        return {
          ...state,
          createdTemplates: [...state.createdTemplates, action.payload.createdId],
        };
      }
      const copy = [...state.createdTemplates];
      copy[existingIdx] = action.payload.createdId;
      return { ...state, createdTemplates: copy };
    }
    case NotesKeywordsActionTypes.UPSERT_UPDATED_NK: {
      const existingIdx = state.updatedTemplates.findIndex(
        (uat) => uat === action.payload.updatedId
      );
      if (existingIdx !== -1) {
        const copy = [...state.updatedTemplates];
        copy[existingIdx] = action.payload.updatedId;
        return { ...state, updatedTemplates: copy };
      }
      return {
        ...state,
        updatedTemplates: [...state.updatedTemplates, action.payload.updatedId],
      };
    }
    case NotesKeywordsActionTypes.APPEND_DELETED_NK: {
      return {
        ...state,
        deletedTemplates: [...state.deletedTemplates, action.payload.deletedId],
      };
    }
    case NotesKeywordsActionTypes.REMOVE_CREATED_NK: {
      return {
        ...state,
        createdTemplates: state.createdTemplates.filter((c) => c == action.payload.createdId),
      };
    }
    case NotesKeywordsActionTypes.SET_CURRENT_DEFAULT_ID_NK: {
      //consider that if are only setting the default but we're not editing/updating anyhow something we should add the value here as well. However we don't have the local changes here to append the updated

      const defaultIdx =
        action.payload.id < 0
          ? state.createdTemplates.findIndex((id) => id === action.payload.id)
          : state.updatedTemplates.findIndex((id) => id === action.payload.id);
      //cascade changes
      const updatedActivityTemplates =
        action.payload.id > 0 && defaultIdx === -1
          ? [...state.updatedTemplates, action.payload.id]
          : state.updatedTemplates;
      const createdActivityTemplates =
        action.payload.id < 0 && defaultIdx === -1
          ? [...state.createdTemplates, action.payload.id]
          : state.createdTemplates;
      return {
        ...state,
        updatedTemplates: updatedActivityTemplates,
        createdTemplates: createdActivityTemplates,
        currentDefaultKeywordTemplateId: action.payload.id,
      };
    }
    case NotesKeywordsActionTypes.SET_RESET_CHANGES_NK: {
      return { ...state, resetTemplateChanges: action.payload.reset };
    }
    case NotesKeywordsActionTypes.UPSERT_TEMPLATES_NK: {
      const { templates } = action.payload;
      const copy = [...state.templates];
      const append = [];
      for (const t of templates) {
        const found = copy.findIndex((c) => c.id === t.id);
        if (found !== -1) copy[found] = t;
        else append.push(t);
      }
      return { ...state, templates: [...copy, ...append] };
    }

    case NotesKeywordsActionTypes.SYNC_REMOVE_TEMPLATES_NK: {
      return {
        ...state,
        templates: state.templates.filter((t) => !action.payload.templateIds.includes(t.id)),
      };
    }
    default:
      return state;
  }
};

export default notesKeywordsReducer;
