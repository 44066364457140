import { useAuthContext } from 'auth/store/AuthContext';
import Card from 'components/Card';
import { CardAddHeader } from 'components/CardAdd';
import SaveChangesConfirmationModal from 'components/SaveChangesConfirmationModal';
import TextSurroundedWithLines from 'components/TextSurroundedWithLines';
import { useEffect, useState } from 'react';
import { useAdminPatientActivityTemplates } from 'screens/Administration/store/hooks';
import { PatientActivitiesActionCreators } from 'screens/Administration/store/patient-settings/patient-activities';
import {
  createTrackingActivityTemplateBulk,
  deleteTrackingActivityTemplateBulk,
  patchTrackingActivityTemplatesBulk,
} from 'services/trackingService';
import { TrackingActivityTemplate } from 'types/ApiModels/Administration';
import { getCurrentPermissionBySection } from 'util/permissionUtils';
import { negativeLocalIdCreator } from 'util/utils';
import PatientSettingsItem from '../../patient-settings-item';
import AddTimeTrackingActivityTemplateModal, {
  AddTemplateFormikValues,
} from './add-time-tracking-activity-template-modal';
import styles from './styles.module.css';

const createNegativeLocalId = negativeLocalIdCreator();

const {
  appendDeleted,
  clearTemplateChanges,
  removeCreated,
  setCurrentTemplateDefaultId,
  setResetTemplateChanges,
  setTemplateSubmit,
  upsertCreated,
  upsertUpdated,
  upsertTemplate,
  syncRemoveTemplates,
} = PatientActivitiesActionCreators;

const TimeTrackingActivityTemplateCard = () => {
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [showAddTemplateModal, setShowAddTemplateModal] = useState(false);
  const [showAddConfirmationModal, setShowAddConfirmationModal] = useState(false);
  const [currentActivityTemplate, setCurrentActivityTemplate] =
    useState<TrackingActivityTemplate>(null);
  const [{ templates, createdTemplates, updatedTemplates, deletedTemplates }, dispatch] =
    useAdminPatientActivityTemplates();
  const [localPatientActivityTemplates, setLocalPatientActivityTemplates] =
    useState<TrackingActivityTemplate[]>(null);

  useEffect(() => {
    setLocalPatientActivityTemplates(templates);
  }, [templates]);

  const handleAdd = () => {
    setShowAddTemplateModal(true);
  };
  const handleModalClose = () => {
    setCurrentActivityTemplate(null);
    setShowAddTemplateModal(false);
  };

  const handleActiveChange = (id: number) => () => {
    id < 0 ? dispatch(upsertCreated(id)) : dispatch(upsertUpdated(id));
    setLocalPatientActivityTemplates((lpat) =>
      lpat.map((at) => (at.id === id ? { ...at, is_active: !at.is_active } : at))
    );
  };
  const handleDelete = (id: number) => () => {
    setLocalPatientActivityTemplates((lpat) => lpat.filter((at) => at.id !== id));
    if (id > 0) {
      dispatch(appendDeleted(id));
    } else {
      dispatch(removeCreated(id));
    }
  };
  const handleEdit = (id: number) => () => {
    setCurrentActivityTemplate(localPatientActivityTemplates.find((lpat) => lpat.id === id));
    setShowAddTemplateModal(true);
  };
  const handleDefaultChange = (id: number) => () => {
    // dispatch(toggleDefaultActivityTemplate(id));
    dispatch(setCurrentTemplateDefaultId(id));
    setLocalPatientActivityTemplates((ts) => ts.map((t) => ({ ...t, is_default: t.id === id })));
  };

  const handleSubmitAddUpdate = (values: AddTemplateFormikValues) => {
    if (values.id < 0) {
      dispatch(upsertCreated(values.id));
    } else {
      dispatch(upsertUpdated(values.id));
    }
    setLocalPatientActivityTemplates((lpat) => {
      const existingIdx = lpat.findIndex((c) => c.id === values.id);
      if (existingIdx === -1) {
        return [...lpat, values];
      }
      const copy = [...lpat];
      copy[existingIdx] = values;
      return copy;
    });
  };

  const handleCommit = async () => {
    const toUpdate = localPatientActivityTemplates.flatMap<TrackingActivityTemplate>((lpat) => {
      // we must tell apart what was modified and what wasn't;
      const previousValues = templates.find((t) => t.id === lpat.id);
      if (updatedTemplates.includes(lpat.id)) {
        const purged = {
          id: lpat.id,
          is_active: lpat.is_active === previousValues.is_active ? undefined : lpat.is_active,
          is_default: lpat.is_default === previousValues.is_default ? undefined : lpat.is_default,
          is_readonly: lpat.is_readonly === previousValues.is_default ? undefined : lpat.is_default,
          name: lpat.name === previousValues.name ? undefined : lpat.name,
        };
        return Object.keys(purged).length === 0 ? [] : [purged];
      }
      return [];
    });
    const toCreate = localPatientActivityTemplates.filter((lpat) =>
      createdTemplates.includes(lpat.id)
    );
    // create
    const [created, createError] = await createTrackingActivityTemplateBulk(
      toCreate.map((c) => ({ ...c, id: undefined }))
    );
    if (created) {
      dispatch(upsertTemplate(created));
    }
    // update
    const [updated, updateError] = await patchTrackingActivityTemplatesBulk(toUpdate);
    if (updated) {
      dispatch(upsertTemplate(updated));
    }
    // delete
    const [, deleteError] = await deleteTrackingActivityTemplateBulk(
      deletedTemplates.map((id) => ({ id }))
    );
    if (!deleteError) {
      syncRemoveTemplates(deletedTemplates);
    }
    setShowAddConfirmationModal(false);
    [createError, updateError, deleteError].forEach((err) => err && console.error(err));
  };

  useEffect(() => {
    dispatch(
      setTemplateSubmit(() => {
        setShowAddConfirmationModal(true);
      })
    );
  }, [dispatch]);

  useEffect(() => {
    const reset = () => {
      dispatch(clearTemplateChanges());
      setLocalPatientActivityTemplates(templates);
    };
    dispatch(setResetTemplateChanges(reset));
  }, [dispatch, templates]);

    const currentSection = 'ADMINISTRATION_patient_settings';
    let permissions = {};
    const { currentUser } = useAuthContext();
    permissions = currentUser.permissions;
    const permissionKey = getCurrentPermissionBySection(permissions, currentSection);

  return permissionKey[0].type ==  'manage' ? 
  (
    <Card
      className="card-bg-border"
      headers={[
        <CardAddHeader onAdd={handleAdd} title="Activity templates" key="card-add-header" />,
      ]}
    >
      {localPatientActivityTemplates?.length ? (
        <>
          <div className="d-flex mb-1">
            <div className={`flex-basis-10 ${styles.text}`}>DEFAULT</div>
            <div className="flex-grow-1"></div>
          </div>
          <div className="d-flex flex-column gap-sm">
            {localPatientActivityTemplates?.map((pat, idx) => (
              <PatientSettingsItem
                handleActiveChange={handleActiveChange(pat.id)}
                handleDefaultchange={handleDefaultChange(pat.id)}
                handleDelete={handleDelete(pat.id)}
                handleEdit={handleEdit(pat.id)}
                is_active={pat.is_active}
                is_default={pat.is_default}
                is_readonly={pat.is_readonly}
                name={pat.name}
                containerClassName={idx === 0 ? 'flex-grow-10' : ''}
                key={pat.id}
              />
            ))}
          </div>
        </>
      ) : (
        <TextSurroundedWithLines text="No activities added" />
      )}

      <AddTimeTrackingActivityTemplateModal
        existingNames={localPatientActivityTemplates?.map((lpat) => lpat.name) ?? []}
        show={showAddTemplateModal}
        handleClose={handleModalClose}
        handleConfirm={handleSubmitAddUpdate}
        activityTemplate={currentActivityTemplate}
        idCreator={createNegativeLocalId}
      />
      <SaveChangesConfirmationModal
        onCancel={() => {
          setShowAddConfirmationModal(false);
        }}
        show={showAddConfirmationModal}
        onConfirm={handleCommit}
      />
      <SaveChangesConfirmationModal
        onCancel={() => {
          setShowConfirmDeleteModal(true);
        }}
        onConfirm={() => {}}
        show={showConfirmDeleteModal}
      />
    </Card>
  ) :
  <Card
      className="card-bg-border"
      headers={[
        <CardAddHeader onAdd={null} title="Activity templates" key="card-add-header" />,
      ]}
    >
      {localPatientActivityTemplates?.length ? (
        <>
          <div className="d-flex mb-1">
            <div className={`flex-basis-10 ${styles.text}`}>DEFAULT</div>
            <div className="flex-grow-1"></div>
          </div>
          <div className="d-flex flex-column gap-sm">
            {localPatientActivityTemplates?.map((pat, idx) => (
              <PatientSettingsItem
                handleActiveChange={null}
                handleDefaultchange={null}
                handleDelete={null}
                handleEdit={null}
                is_active={pat.is_active}
                is_default={pat.is_default}
                is_readonly={pat.is_readonly}
                name={pat.name}
                containerClassName={idx === 0 ? 'flex-grow-10' : ''}
                key={pat.id}
              />
            ))}
          </div>
        </>
      ) : (
        <TextSurroundedWithLines text="No activities added" />
      )}
    </Card>
};
export default TimeTrackingActivityTemplateCard;
