import LanguagesModal from 'components/BulletedModals/LanguagesModal';
import { Form, getIn, useFormikContext } from 'formik';
import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { PatientManagementFormikValuesV2 } from 'screens/Administration/AdministrationContent/AdminTabs/AdminPatients/AdminPatientDetails/AdminPatientDetails';
import { SurgeryFamilyAllergyHandlers } from 'screens/Administration/AdministrationContent/AdminTabs/AdminPatients/AdminPatientDetails/hooks';
import BillingProvider from 'screens/Patients/Content/components/PatientTabs/components/PatientManagement/BillingProvider/BillingProvider';
import CareChampions from 'screens/Patients/Content/components/PatientTabs/components/PatientManagement/CareChampions/CareChampions';
import EmergencyContacts from 'screens/Patients/Content/components/PatientTabs/components/PatientManagement/EmergencyContacts';
import PatientDocuments from 'screens/Patients/Content/components/PatientTabs/components/PatientManagement/PatientDocuments/PatientDocuments';
import PatientIdentification from 'screens/Patients/Content/components/PatientTabs/components/PatientManagement/PatientIdentification/PatientIdentification';
import PatientPopulation from 'screens/Patients/Content/components/PatientTabs/components/PatientManagement/PatientPopulation';
import PatientSatisfaction from 'screens/Patients/Content/components/PatientTabs/components/PatientManagement/PatientSatisfaction/PatientSatisfaction';
import PersonalInformation from 'screens/Patients/Content/components/PatientTabs/components/PatientManagement/PersonalInformation';
import { usePatientFormikContext } from 'screens/Patients/store';
import { useAppQuery } from 'store/use-app-query';
import DeviceMeasureType from 'types/ApiModels/Patients/device-measure-type';
import Insurance from 'types/ApiModels/Patients/Insurance';
import PatientGet from 'types/ApiModels/Patients/PatientGet';
import PatientGetSingle from 'types/ApiModels/Patients/PatientGetSingle';
import { PopulationWithCount } from 'types/ApiModels/Patients/Population';
import { Language } from 'types/ApiModels/Users';
import Ethnicity from 'types/ApiModels/Users/Ethnicity';
import Race from 'types/ApiModels/Users/Race';
import Religion from 'types/ApiModels/Users/Religion';
import Sex from 'types/ApiModels/Users/Sex';
import { negativeLocalIdCreator } from 'util/utils';
import AllergiesCard from './AllergiesCard';
import CareTeamCard from './CareTeamCard';
import CreateAllergyModal from './CreateAllergyModal';
import CreateFamilyHistoryModal from './CreateFamilyHistoryModal';
import CreateSurgeryHistoryModal from './CreateSurgeryHistoryModal';
import { DevicesCard } from './devices-card';
import FamilyHistoryCard from './FamilyHistoryCard';
import HealthScoringCard from './HealthScoringCard';
import PatientStatusCard from './PatientStatusCard';
import SurgeryHistoryCard from './SurgeryHistoryCard';
import {
  PatientManagementFormikKeys,
  PatientManagementFormikValues,
  PersonalInformationFormikKeys,
} from './types';

interface PatientManagementFormProps {
  //selectedPatientV2: PatientGetSingle
  surgeryFamilyAllergyHandlers: SurgeryFamilyAllergyHandlers;
}

const componentContainerClassName =
  'dashboard-detail-container patients-management-component-container fit-content';

const formName = 'PATIENT_MANAGEMENT_FORM';

const createNegativeId = negativeLocalIdCreator();

const PatientManagementForm = ({
  //selectedPatient: selectedPatient,
  surgeryFamilyAllergyHandlers: { allergy, familyHistory, surgery },
}: PatientManagementFormProps) => {
  const [showLanguagesModal, setShowLanguagesModal] = useState(false);
  const [showAllergyModal, setShowAllergyModal] = useState(false);
  const [showFamilyHistoryModal, setShowFamilyHistoryModal] = useState(false);
  const [showSurgeryHistoryModal, setShowSurgeryHistoryModal] = useState(false);
  const { data: ethnicities } = useAppQuery<Ethnicity[]>('ethnicities');
  const { data: languages } = useAppQuery<Language[]>('languages');
  const { data: populations } = useAppQuery<PopulationWithCount[]>('populations');
  const { data: races } = useAppQuery<Race[]>('races');
  const { data: religions } = useAppQuery<Religion[]>('religions');
  const { data: insurances } = useAppQuery<Insurance[]>('insurances');
  const { data: devicesMeasureTypes } = useAppQuery<DeviceMeasureType[]>('devicesMeasureTypes');
  const { data: maritalStatus } = useAppQuery<MaritalStatus[]>('maritalStatus');
  const { data: sexOptions } = useAppQuery<Sex[]>('sex');
  const { selectedPatient, setSelectedPatient } = usePatientFormikContext();

  const [currentId, setCurrentId] = useState(null);

  const { values, handleChange, touched, errors, setFieldValue, setFieldTouched } =
    useFormikContext<PatientManagementFormikValuesV2>();
    
    //TODO: Verify this function to avoid null data -> PatientManagementFormikValues
    //console.log("Formik values", values[PatientManagementFormikKeys.IDENTIFICATION]);
    


  const handleEdit = (setModalVisible) => (id: number) => {
    setCurrentId(id);
    setModalVisible(true);
  };

  const handleModalClose = (setShowModal) => () => {
    setShowModal(false);
    setCurrentId(null);
  };

  const handleModalOpen = (setShowModal) => () => {
    setShowModal(true);
  };

  return (
    <Form id={formName}>
      <Container className="patients-tab-content patients-management-container">
        <Row>
          <Col className="px-0" lg={9}>
            <Row>
              <Col xs={6} className={componentContainerClassName}>
                <PatientIdentification
                  patient={selectedPatient}
                  formData={values[PatientManagementFormikKeys.IDENTIFICATION]}
                  handleChange={handleChange}
                  errors={
                    touched[PatientManagementFormikKeys.IDENTIFICATION] &&
                    errors[PatientManagementFormikKeys.IDENTIFICATION]
                      ? errors[PatientManagementFormikKeys.IDENTIFICATION]
                      : undefined
                  }
                />
              </Col>
              <Col md={6} lg={2} className={componentContainerClassName}>
                <PatientPopulation patient={selectedPatient} populations={populations} />
              </Col>
            </Row>
            <Row>
              <Col lg={12} className={componentContainerClassName}>
                {/* <CareChampions
                  formData={values[PatientManagementFormikKeys.CARE_CHAMPIONS]}
                  handleChange={handleChange}
                  errors={
                    errors[PatientManagementFormikKeys.CARE_CHAMPIONS]
                      ? errors[PatientManagementFormikKeys.CARE_CHAMPIONS]
                      : undefined
                  }
                  touched={touched[PatientManagementFormikKeys.CARE_CHAMPIONS]}
                /> */}
              </Col>
            </Row>
            <Row>
              <Col className={componentContainerClassName}>
                <SurgeryHistoryCard
                  handleAdd={handleModalOpen(setShowSurgeryHistoryModal)}
                  queueDelete={surgery.queueDelete}
                  handleEdit={handleEdit(setShowSurgeryHistoryModal)}
                />
              </Col>
              <Col className={componentContainerClassName}>
                {/* <FamilyHistoryCard
                  handleAdd={handleModalOpen(setShowFamilyHistoryModal)}
                  handleEdit={handleEdit(setShowFamilyHistoryModal)}
                  queueDelete={familyHistory.queueDelete}
                /> */}
              </Col>
              <Col className={componentContainerClassName}>
                {/* <AllergiesCard
                  handleAdd={handleModalOpen(setShowAllergyModal)}
                  handleEdit={handleEdit(setShowAllergyModal)}
                  queueDelete={allergy.queueDelete}
                /> */}
              </Col>
            </Row>
            <Row>
              <Col lg={12} className={componentContainerClassName}>
                {/* <PersonalInformation
                  formData={values[PatientManagementFormikKeys.PERSONAL_INFORMATION]}
                  handleChange={handleChange}
                  errors={
                    errors[PatientManagementFormikKeys.PERSONAL_INFORMATION]
                      ? errors[PatientManagementFormikKeys.PERSONAL_INFORMATION]
                      : undefined
                  }
                  touched={touched[PatientManagementFormikKeys.PERSONAL_INFORMATION]}
                  languages={languages}
                  handleAddLanguage={() => setShowLanguagesModal(true)}
                  ethnicities={ethnicities}
                  races={races}
                  religions={religions}
                  insurances={insurances}
                  maritalStatus={maritalStatus}
                  sexOptions={sexOptions}
                /> */}
              </Col>
            </Row>
            <Row>
              <Col className={componentContainerClassName}>
                <HealthScoringCard />
              </Col>
            </Row>
            <Row>
              <Col className={componentContainerClassName}>
                <DevicesCard
                  devicesMeasureTypes={devicesMeasureTypes}
                  patientId={selectedPatient.id}
                />
              </Col>
            </Row>
          </Col>
          <Col className="px-0" lg={3}>
            <Row>
              <Col className={componentContainerClassName}>
                <PatientStatusCard
                  patient={selectedPatient}
                  setSelectedPatient={setSelectedPatient}
                />
              </Col>
            </Row>
            <Row>
              <Col className={componentContainerClassName}>
                <CareTeamCard careTeam={selectedPatient.care_team} />
              </Col>
            </Row>
            <Row>
              <Col lg={12} className={componentContainerClassName}>
                <PatientSatisfaction />
              </Col>
            </Row>
            <Row>
              <Col lg={12} className={componentContainerClassName}>
                {/* <EmergencyContacts
                  formData={values[PatientManagementFormikKeys.EMERGENCY_CONTACTS]}
                  handleChange={handleChange}
                  errors={
                    errors[PatientManagementFormikKeys.EMERGENCY_CONTACTS]
                      ? errors[PatientManagementFormikKeys.EMERGENCY_CONTACTS]
                      : undefined
                  }
                  touched={touched?.[PatientManagementFormikKeys.EMERGENCY_CONTACTS]}
                /> */}
              </Col>
            </Row>
            <Row>
              <Col lg={12} className={componentContainerClassName}>
                <BillingProvider patient={selectedPatient} />
              </Col>
            </Row>
            <Row>
              <Col lg={12} className={componentContainerClassName}>
                <PatientDocuments patient={selectedPatient} />
              </Col>
            </Row>
          </Col>
        </Row>
        {showLanguagesModal ? (
          <LanguagesModal
            assignedLanguagesIds={getIn(
              values,
              `${PatientManagementFormikKeys.PERSONAL_INFORMATION}.${PersonalInformationFormikKeys.DEMOGRAPHIC_INFO}.language`
            )}
            handleClose={() => setShowLanguagesModal(false)}
            handleConfirm={(lids) => {
              const name = `${PatientManagementFormikKeys.PERSONAL_INFORMATION}.${PersonalInformationFormikKeys.DEMOGRAPHIC_INFO}.language`;
              setFieldTouched(name, true);
              setFieldValue(name, lids, true);
              setShowLanguagesModal(false);
            }}
            languages={languages}
            show={showLanguagesModal}
          />
        ) : null}
        {/* <CreateSurgeryHistoryModal
          idCreator={createNegativeId}
          onClose={handleModalClose(setShowSurgeryHistoryModal)}
          show={showSurgeryHistoryModal}
          patientId={selectedPatient.id}
          currentId={currentId}
          queueEdit={surgery.queueEdit}
        /> */}
        {/* <CreateFamilyHistoryModal
          idCreator={createNegativeId}
          onClose={handleModalClose(setShowFamilyHistoryModal)}
          show={showFamilyHistoryModal}
          patientId={selectedPatient.id}
          currentId={currentId}
          queueEdit={familyHistory.queueEdit}
        />
        <CreateAllergyModal
          idCreator={createNegativeId}
          onClose={handleModalClose(setShowAllergyModal)}
          show={showAllergyModal}
          patientId={selectedPatient.id}
          currentId={currentId}
          queueEdit={allergy.queueEdit}
        /> */}
      </Container>
    </Form>
  );
};
export default PatientManagementForm;
