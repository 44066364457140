import { FC } from 'react';
import { diffStrDateDays } from 'util/dateUtils/shared';
import { AdminPatientRow } from '../AdminPatientsMain/AdminPatientsTable/utils/adminPatientsTableUtils';

const AdminPatientAdherenceElement: FC<Pick<AdminPatientRow, 'adherence'>> = ({
 // adherence: { adherence_days, care_plan_from, care_plan_to },
 adherence
}: Partial<AdminPatientRow>) => {
 // const diffDays = diffStrDateDays(care_plan_from, care_plan_to);
  //const text = care_plan_from && care_plan_to ? `${adherence_days.length} out of ${diffDays}` : '-';
  //return <div>{text}</div>;
  return <div>{adherence}</div>;
};
export default AdminPatientAdherenceElement;
