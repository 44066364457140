import { useAuthContext } from 'auth/store/AuthContext';
import Button from 'components/Button';
import PermissionsGate from 'components/PermissionsGate/PermissionsGate';
import { getCurrentPermissionBySection } from 'util/permissionUtils';
import PlatformRules from './PlatformRules';
import styles from './styles.module.css';

export const RulesEngineActions = () => {
  const AbstractButton = (props) => <Button {...props} className="mx-1" />;

  const currentSection = 'ADMINISTRATION_rules_engine';
  let permissions = {};
  const { currentUser } = useAuthContext();
  permissions = currentUser.permissions;
  const permissionKey = getCurrentPermissionBySection(permissions, currentSection);

  return (
    <div className="d-flex">
      <PermissionsGate can={permissionKey[0].type}>
        <AbstractButton label="cancel" variant="cancel" />
        <AbstractButton disabled label="save changes" variant="confirm" />
        <AbstractButton label="add new rule" color="#1890FF" />
      </PermissionsGate>
    </div>
  );
};

const RulesEngine = () => (
  <div className={styles.main}>
    <PlatformRules />
  </div>
);

export default RulesEngine;
