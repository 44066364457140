import React, { useEffect, useRef } from 'react';
import Checkbox from '../form/Checkbox';
import styles from './styles.module.css';
import { ReactComponent as Arrow } from '../../assets/icons/arrow_left.svg';
import { useState } from 'react';
import { Collapse } from 'bootstrap';
/**
 * Render table cell
 * @name render
 * @param {Object} props
 * @returns {React.ReactNode} - Table cell
 */

// /**
//  * @name onRowClick
//  * @param {Object} rowData
//  * @returns {MouseEventHandler}
//  */

// /**
//  * GenericTable
//  * @param {Object[]} data - Array of data with the corresponding structure
//  * @param {Object[]} columns - Array of columns
//  * @param {String} columns[].title - Title of the column
//  * @param {String} columns[].key - Key of the column
//  * @param {render} columns[].render - Type of the column
//  * @param {Boolean} columns[].hidden - Class name of the column
//  * @param {string} columns[].textAlign - String that represents the alignment in the column
//  * @param {Object} columns[].style - Object that applies style to column element
//  * @param {onRowClick} onRowClick - Callback function
//  */
const GenericPatientTableWithDropdown = ({
  tableClassName,
  columns = [],
  data = [],
  onRowClick = () => null,
  rowClassName,
  dataRowClassName,
  rowChildren = null,
  selectable = false,
  selected = [],
  setSelected = () => null,
}) => {
  const arrowRef = useRef([]);
  const collapsableRef = useRef([]);

  const [opened, setOpened] = useState(-1);

  useEffect(() => {
    if (arrowRef.current.length > 0) {
      if (opened === -1) {
        arrowRef.current.forEach((arrow, index) => {
          if (arrow.className === styles.arrowContainerOpened) {
            arrow.className = styles.arrowContainer;
            new Collapse(collapsableRef.current[index]).hide();
          }
        });
      } else {
        arrowRef.current.forEach((arrow, index) => {
          if (arrow.className === styles.arrowContainerOpened) {
            arrow.className = styles.arrowContainer;
            new Collapse(collapsableRef.current[index]).hide();
          }
        });
        arrowRef.current[opened].className = styles.arrowContainerOpened;
        new Collapse(collapsableRef.current[opened]).show();
      }
    }
  }, [opened]);

  return (
    <div className={`${styles.tableContainer} ${tableClassName || ''} accordion`}>
      <div className={`${styles.row} ${rowClassName || ''}`}>
        {selectable && (
          <div className={styles.header} style={{ flexBasis: '2%' }}>
            <Checkbox
              value={!!(selected && selected.length > 0 && selected.every((item) => item === true))}
              onChange={() => {
                if (selected.every((item) => item === true)) {
                  setSelected([...Array(data.length).keys()].map((i) => false));
                } else {
                  setSelected([...Array(data.length).keys()].map((i) => true));
                }
              }}
            />
          </div>
        )}
        {columns.map(({ title, key, render, hidden, textAlign, style }, index) => (
          <div
            className={styles.header}
            style={{ justifyContent: textAlign, ...style }}
            key={index}
          >
            {title}
          </div>
        ))}
      </div>
      {data.map((rowData, rowIndex) => (
        <React.Fragment key={rowIndex}>
          <div
            className={`${styles.row} ${rowClassName || ''} ${dataRowClassName || ''}`}
            style={{ border: opened === rowIndex && '0' }}
            onClick={() => onRowClick(rowData)}
          >
            {selectable && (
              <div className={styles.element} style={{ flexBasis: '2%' }}>
                <Checkbox
                  value={!!selected[rowIndex]}
                  onChange={() => {
                    const newSelected = [...selected];
                    newSelected[rowIndex] = !selected[rowIndex];
                    setSelected(newSelected);
                  }}
                />
              </div>
            )}
            {columns.map(({ key, render, hidden, textAlign, style }, index) => (
              <div
                hidden={hidden}
                className={styles.element}
                style={{ justifyContent: textAlign, ...style }}
                //TODO: add better key
                key={`${key}-${index}`}
              >
                {index === 0 && (
                  <div
                    ref={(ref) =>
                      ref && !arrowRef.current.includes(ref)
                        ? (arrowRef.current = [...arrowRef.current, ref])
                        : null
                    }
                    className={styles.arrowContainer}
                    onClick={() => setOpened(rowIndex === opened ? -1 : rowIndex)}
                  >
                    <Arrow fill="#393E48" className={styles.noSelect} />
                  </div>
                )}
                {render?.(rowData) || rowData[key]}
              </div>
            ))}
          </div>
          <div
            className={`collapse w-100 mb-1`}
            id={`collapse_${rowIndex}`}
            ref={(ref) =>
              ref && !collapsableRef.current.includes(ref)
                ? (collapsableRef.current = [...collapsableRef.current, ref])
                : null
            }
          >
            {rowChildren?.(rowData)}
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default GenericPatientTableWithDropdown;
