import { vytracAxios } from 'ajax';
import { AlertVytal } from 'types/ApiModels/Dashboard';
import { DashboardAdherenceGraphic } from 'types/ApiModels/Dashboard/DashboardAdherenceGraphic';
import DashboardRedAlertDetail from 'types/ApiModels/Dashboard/DashboardRedAlertDetail';
import { IDashboardToDo, IDashboardToDoSingle } from 'types/ApiModels/Dashboard/DashboardToDo';
import DashboardYellowAlert from 'types/ApiModels/Dashboard/DashboardYellowAlert';
import DashboardYellowAlertDetail from 'types/ApiModels/Dashboard/DashboardYellowAlertDetail';
import { PaginatedResponse } from 'types/ApiModels/General';
import { IProviderAction, IProviderActionResponse } from 'types/ApiModels/Providers/ProviderAction';
import { IAlertVytal } from 'types/Dashboard';
import IVytalChart from 'types/Dashboard/VytalChart';
import { iconType } from 'types/Patients/constants';

export const getSingleRedAlert = async (
  userId: number,
  date_from: string
): Promise<DashboardRedAlertDetail> => {
  try {
    const response = await vytracAxios.get<DashboardRedAlertDetail>(
      `providers/dashboard/red_alerts/${userId}/?date_from=${date_from}`
    );
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

export const getYellowAlerts = async (
  date_from: string,
  date_to: string
): Promise<DashboardYellowAlert[]> => {
  try {
    //ByPass daniel const response = await vytracAxios.get<DashboardYellowAlert[]>(`providers/dashboard/yellow_alerts/?date_from=${date_from}&date_to=${date_to}`);
    
    //return response.data;
    return JSON.parse('{}');
  } catch (e) {
    console.error(e);
  }
};

export const getSingleYellowAlert = async (
  userId: number,
  date_from: string,
  date_to: string
): Promise<DashboardYellowAlertDetail> => {
  try {
    //Daniel Bypasss to dashboard
    //const response = await vytracAxios.get<DashboardYellowAlertDetail>(`providers/dashboard/yellow_alerts/${userId}/?date_from=${date_from}&date_to=${date_to}`);
    const response=JSON.parse('{data{}}') 
    return { ...response.data, id: userId };
  } catch (e) {
    console.error(e);
  }
};

export const getVytalChart = async (
  type: iconType,
  days: number,
  patientId: number
): Promise<IVytalChart> => {
  try {
    const response = await vytracAxios.get<AlertVytal[]>(
      `vytals/?type=${type}&days=${days}&patient=${patientId}`
    );
    return { vytals: response.data, type };
  } catch (e) {
    console.error(e);
  }
};

export const getAdherenceGraphic = async (dateFrom: string) => {
  try {
    const response = await vytracAxios.get<DashboardAdherenceGraphic[]>(
      `providers/dashboard/adherence_graphic/?date_from=${dateFrom}`
    );
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

export const getPatientToDoList = async (dateFrom: string) => {
  try {
    const response = await vytracAxios.get<PaginatedResponse<IDashboardToDo>>(
      `providers/dashboard/todo/?size=1000&date_from=${dateFrom}`
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const getSinglePatientToDo = async (patientId: number, dateFrom: string) => {
  try {
    const response = await vytracAxios.get<IDashboardToDoSingle>(
      `providers/dashboard/todo/${patientId}/?date_from=${dateFrom}`
    );
    console.log(response.data);
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const takeActionOnAlert = async (action: Partial<IProviderAction>) => {
  try {
    const response = await vytracAxios.post<IProviderActionResponse>(
      `providers/action_todo/`,
      action
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};
