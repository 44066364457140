import { DevicesCard } from 'components/PatientManagementForm/devices-card';
import { useFetchList } from 'hooks';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { usePatientFormikContext } from 'screens/Patients/store';
import {
  setCarePlanState,
  setPatientActions,
} from 'screens/Patients/store/PatientCarePlan/patientCarePlanActionCreators';
import { usePatientContext } from 'screens/Patients/store/PatientContext';
import {
  getCarePlan,
  getPatientActions,
  getPatientDevicesMeasureTypes,
} from 'services/patientService';
import CarePlanBreakdown from './components/CarePlanBreakdown';
import PatientCarePlanTimeline from './components/PatientCarePlanTimeline';
import './styles.css';

const PatientCarePlan = () => {
  const { selectedPatient } = usePatientFormikContext();
  const [devicesMeasureTypes] = useFetchList(getPatientDevicesMeasureTypes);
  const { dispatch } = usePatientContext();

  useEffect(() => {
    const fetchCarePlan = async (patientId: number) => {
      try {
        dispatch(setCarePlanState(await getCarePlan(patientId)));
      } catch (exception) {
        throw exception;
      }
    };

    const fetchPatientActions = async (patientId: number) => {
      try {
        dispatch(setPatientActions(await getPatientActions(patientId)));
      } catch (exception) {
        throw exception;
      }
    };

    fetchCarePlan(selectedPatient.id);
    fetchPatientActions(selectedPatient.id);
  }, [dispatch, selectedPatient.id]);

  return (
    <Container className="patients-tab-content rpm-plan-container">
      <Row>
        <Col lg={12} className="px-2 pt-4">
          {/* <PatientCarePlanTimeline /> */}
        </Col>
      </Row>
      <Row className="pb-5">
        <Col lg={6} className="px-2 pt-4">
          {/* <DevicesCard patientId={selectedPatient.id} devicesMeasureTypes={devicesMeasureTypes} /> */}
        </Col>
        <Col lg={6} className="px-2 pt-4">
          <CarePlanBreakdown />
        </Col>
      </Row>
    </Container>
  );
};

export default PatientCarePlan;
