import { authUserAxios, fakeAuthUserAxios, vytracAxios } from 'ajax';
import { useConversationsStore } from 'store/twilio';
import { PermissionKeys } from 'types/ApiModels/Administration/permission-keys';
import { PaginatedResponse } from 'types/ApiModels/General';
import { LoginRequest, VerificationCode } from 'types/ApiModels/Login';
import RegisterUserRequest from 'types/ApiModels/Login/RegisterUserRequest';
import { IPatientCarePlanAction } from 'types/ApiModels/Patients/CarePlan';
import { Language, MinifiedUser, User } from 'types/ApiModels/Users';
import Ethnicity from 'types/ApiModels/Users/Ethnicity';
import Race from 'types/ApiModels/Users/Race';
import Religion from 'types/ApiModels/Users/Religion';
import Sex from 'types/ApiModels/Users/Sex';
import { IColorScheme } from 'types/ApiModels/Users/User';
import { objectToUrlParams } from 'util/utils';
import { host } from '../util/apiUtils';
import { clearTokens, clearTokensLogout, getAccessToken, getRefreshToken, setToken } from './tokenService';
import { clearTwilioTokenFromLocalStorage } from './twilio';
import { useAuthContext } from 'auth';

import { ApiRolePermission, ApiRolePermissionType } from 'types/ApiModels/Administration/Role';
import axios from 'axios';

const baseEndpoint = 'users';


const getGenericHeaders = (token?: string) => ({
  'Content-Type': 'application/json',
  Accept: 'application/json',
  Authorization: `Bearer ${token || getAccessToken().token}`,
});

const noAuthHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const getCurrentUserInfo = async () => {
  try {
    const id=getAccessToken().userid
    const res = await authUserAxios.get(`v2/${id}/`);
    const json: MinifiedUser = await res.data;
    //  const json: MinifiedUser= fakeuser;
    //setPortalScheme(json.color_scheme);
    return json; 
  } catch (error) {
    console.error(error);
  }
};

export const updateUser = async (
  id,
  first_name,
  last_name,
  middle_name,
  birth_date,
  sex,
  email,
  primary_phone_number,
  second_phone_number,
  languages
): Promise<User> => {
  try {
    const response = await authUserAxios.put(`/${id}/`, {
      first_name,
      last_name,
      middle_name,
      birth_date: birth_date.toISOString(),
      email,
      sex: sex,
      phone_number: primary_phone_number,
      second_phone_number,
      languages,
    });

    return await response.data;
  } catch (exception) {
    throw exception;
  }
};

export const getSexOptions = async () => {
  try {
    const response = await authUserAxios.get<Sex[]>('/sex/');
    return response.data;
  } catch (exception) {
    throw exception;
  }
};

export const getEthnicityOptions = async () => {
  try {
    const response = await vytracAxios.get<Ethnicity[]>('/patient/ethnicity/');
    return response.data;
  } catch (exception) {
    throw exception;
  }
};

export const getRaceOptions = async () => {
  try {
    const response = await vytracAxios.get<Race[]>('/patient/race/');
    return response.data;
  } catch (exception) {
    throw exception;
  }
};

export const getReligionOptions = async () => {
  try {
    const response = await vytracAxios.get<Religion[]>('/patient/religion/');
    return response.data;
  } catch (exception) {
    throw exception;
  }
};

export const getMaritalStatusOptions = async () => {
  try {
    const response = await vytracAxios.get<MaritalStatus[]>('/patient/maritalstatus/');
    return response.data;
  } catch (exception) {
    throw exception;
  }
};

export const logOut = () => {
  try {
    const res = true;
    clearTokensLogout();
    clearTokens();
    clearTwilioTokenFromLocalStorage();
    useConversationsStore.getState().clearConversationsStore();
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const login = async (values: LoginRequest) => {
  Object.keys(values).forEach((key) => {
    if (values[key] === '' || values[key] === null) {
      delete values[key];
    }
  });

  try { 
    const response = await fetch(`${host}users/login/v2/`, {
      method: 'POST',
      headers: noAuthHeaders,
      body: JSON.stringify(values),
    });
    
    let result = {};

    if ([200, 202].includes(response.status)) {
      result = await response.json();
    }
    else {  
      result = {
        error: response.status,
        message: await response.json(),
      };
    }

    return result;
  } catch (exception) {
    console.error(exception);
    throw new Error(`Error in login function in the file: ${__filename}`);
  }
};

export const Auth2factor = async (values: LoginRequest) => {
  Object.keys(values).forEach((key) => {
    if (values[key] === '' || values[key] === null) {
      delete values[key];
    }
  });

  try {
    const response = await fetch(`${host}users/validate-code/`, {
      method: 'POST',
      headers: noAuthHeaders,
      body: JSON.stringify(values),
    });

    let result = {};
    
    if ([200, 202].includes(response.status)) {
      result = await response.json();
    } else {
      result = {
        error: response.status,
        message: await response.json(),
      };
    }

    return result;
  } catch (exception) {
    console.error(exception);
    throw new Error(`Error in Auth2factor function in the file: ${__filename}`);
  }
};

export const checkInvitation = async (email, hash) => {
  try {
    const response = await authUserAxios.get(`validate-invitation/?hash_token=${hash}`)
    if (response.status === 200) {
      return response;
    } else throw new Error(`Request return with status code: ${response.status}`);
  } catch (exception) {
    throw exception;
  }
};

export const sendVerificationCodeEmail = (email: string) => {
  try {
    return fetch(`${host}${baseEndpoint}/request-reset-email/`, {
      method: 'POST',
      headers: noAuthHeaders,
      body: JSON.stringify({
        email,
      }),
    });
  } catch (exception) {
    //swalllow, if the request fails we don't want to show anything (however request should never fail for client)
    console.log(exception);
    
  }
};

export const verifySecurityTokenForPasswordReset = (email: string, token: string) => {
  try {
    return fetch(`${host}${baseEndpoint}/request-reset-email/`, {
      method: 'POST',
      headers: noAuthHeaders,
      body: JSON.stringify({
        email,
        code: token,
      }),
    });
  } catch (error) {}
};

export const requestPasswordReset = (password: string, hashToken: string) => {
  try {
    return fetch(`${host}${baseEndpoint}/password-reset-complete/`, {
      method: 'PATCH',
      headers: noAuthHeaders,
      body: JSON.stringify({ password, hash_token: hashToken}),
    });
  } catch (error) {
    console.error('Error in the service method requestPasswordReset, could not reset password');
  }
};

export const registerUser = (values: RegisterUserRequest) => {
  //TODO: do we really this here?
  Object.keys(values).forEach((key) => {
    if (values[key] === '' || values[key] === null) {
      delete values[key];
    }
  });
  try {
    return fetch(`${host}/${baseEndpoint}/account/`, {
      method: 'POST',
      headers: noAuthHeaders,
      body: JSON.stringify(values),
    });
  } catch (exception) {
    throw exception;
  }
};

export const getLanguages = async () => {
  try {
    const res = await authUserAxios.get<Language[]>('language/');
    return res.data;
  } catch (error) {
    console.error(error);
  }
};

export const uploadProfilePicture = async (photoData: Blob) => {
  try {
    const data = new FormData();
    data.append('photo', photoData);
    const res = await authUserAxios.post('profile_picture/', data);
    return res.data;
  } catch (error) {
    console.error(error);
  }
};

export const addActionsToCarePlan = async (
  patientId: number,
  actions: IPatientCarePlanAction[]
) => {
  try {
    const res = await vytracAxios.post(`/patient/${patientId}/rpm_plan/action/`, actions);
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const checkEmail = async (email: string) => {
  try {
    const params = objectToUrlParams({ email });
    const data = await authUserAxios.get(`checkemail/${params}`);
    // if (data.status === 200) return false;
    return data?.data;
  } catch (error) {
    throw error;
  }
};

export const setPortalScheme = (scheme: IColorScheme) => {
  const css = document.documentElement;
  css?.style.setProperty('--primary_color', scheme.primary_color);
  css?.style.setProperty('--secundary_color', scheme.secondary_color);
  css?.style.setProperty('--hover_color', scheme.hover_color);
};

export const sendTechnicalSupportEmail = async (email: string) => {
  try {
    const { data } = await vytracAxios.post('administration/technical-support-email/', { email_address: email });
    console.log('Send technical support email', data)
  } catch (err) {
    console.error(err);
  }
}

export const validateEmailAndLastName = async (email: string, lastName: string,birth_day: string) => {
  try {
    return await authUserAxios.get(`validate-email-lastname/?email=${email}&last_name=${lastName}&birth_day=${birth_day}`);
  } catch (error) {
    console.error(error);
    throw new Error(`Error in the service call GET validate-email-lastname/`)
  }
}

export const chooseTypeToSendCode = (userId: number, type: string) => {
  try {
    vytracAxios.patch(`administration/choose-type-to-send-code/${userId}/${type}/`);
  } catch (error) {
    console.log(error);
    throw new Error(`Error in call the service PUT choose-type-to-send-code/?user_id`);
  }
};

export const chooseTypeToSendCodePost = (userId: number, type: string, number?: string) => {
  try {
    vytracAxios.post(`administration/choose-type-to-send-code/${userId}/${type}/`+(number?number:""));
  } catch (error) {
    console.log(error);
    throw new Error(`Error in call the service PUT choose-type-to-send-code/?user_id`);
  }
};

export const changePasswordUser = (userId: number, hash: string,password: string,cellphone: string) => {
  try {
    vytracAxios.put(`administration/change-password/${userId}/`,{hash,password,cellphone});
  } catch (error) {
    console.log(error);
    throw new Error(`Error in call the service PUT change the password`);
  }
};


export const requestCode = (emailAddress: string) => {
  try {
     authUserAxios.post(`resend-code/`, { email_address: emailAddress });
  } catch (error) {
    console.error(error);
    throw new Error(`Error in the service POST resend-code/`);
  }

}

export const forgotPassword = async (emailAddress: string,userInfo) => {
  try {
    const data = await authUserAxios.post(`forgot-password/`, { email_address: emailAddress,deviceInfo:userInfo });
    return data
  } catch (error) {
    return error
  }
}

export const getCurrentDeviceInfo = async () => {
  try {
    const data = await axios.get(`https://ipapi.co/json/`);
    return data
  } catch (error) {
    throw new Error(`Error in the service POST forgotPassword/`);
  }

}


export const checkToken = async (token: string) => {
  try {
    const data = await authUserAxios.get(`forgot-password/${token}/`);
    return data;
  } catch (error) {
    throw error;
  }
};
