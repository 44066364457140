import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAdministrationContext } from "screens/Administration/store";
import BreadCrumbPath from "types/Shared/BreadCrumbPath";

function useChangeLastBreadcrumb (title: string, ensureBreadCrumbsBefore?: BreadCrumbPath[]) {

  const { setBreadcrumbPathList, breadcrumbPathList } = useAdministrationContext();
  const location = useLocation()

  useEffect(() => {
    // get first 2 BreadCrumbs from breadcrumbPathList
    let list = breadcrumbPathList.slice(0, 2);

    // check if the ensureBreadCrumbsBefore are in the list and if not add them
    if (ensureBreadCrumbsBefore) {
      ensureBreadCrumbsBefore.forEach((item) => {
        if (!list.find((i) => i.url === item.url && i.title === item.title)) {
          list = [...list, item];
        }
      });
    }

    // add the last BreadCrumb
    setBreadcrumbPathList([...list, { title, url: location.pathname }]);
  }, [])
}

export default useChangeLastBreadcrumb
