import { useAuthContext } from 'auth/store/AuthContext';
import Button from 'components/Button';
import Card from 'components/Card';
import PermissionsGate from 'components/PermissionsGate/PermissionsGate';
import SearchFilterHeader from 'components/SearchFilterHeader';
import { useAdministrationContext } from 'screens/Administration/store';
import { PermissionKeys } from 'types/ApiModels/Administration/permission-keys';
import { getCurrentPermissionBySection } from 'util/permissionUtils';
import TableBody from './TableBody';

export const NotificationsActions = () => {
  const { state } = useAdministrationContext();

  // const currentSection = 'ADMINISTRATION_notifications';
  //   let permissions = {};
  //   const { currentUser } = useAuthContext();
  //   permissions = currentUser.permissions;
  //   const permissionKey = getCurrentPermissionBySection(permissions, currentSection)
  //   //console.log('---------------', permissionKey[0].section, permissionKey[0].type);

  return (
    <div className="d-flex">
      {/* <PermissionsGate can={permissionKey[0].type}> */}
      <Button
        variant="cancel"
        label="Cancel"
        className="mr-2"
        disabled={!state.roles?.hasUnsavedChanges}
      />
      <Button
        variant="confirm"
        label="Save Changes"
        className="mr-2"
        disabled={!state.roles?.hasUnsavedChanges}
        onClick={() => {}}
      />
      <Button label="Add notification" onClick={() => {}} />
      {/* </PermissionsGate> */}
    </div>
  );
};

const Notifications = () => {
  return (
    <div className="p-3">
      <Card
        headers={[
          <SearchFilterHeader
            handleSearch={() => {}}
            search={null}
            onFiltersClick={() => undefined}
            title="Notification rules"
            searchPlaceholder="Search specialties..."
            key="notifications-header"
          />,
        ]}
        bodyClassName="bg-white p-0"
        className="bg-white"
        style={{ borderRadius: '5px' }}
      >
        <TableBody />
      </Card>
    </div>
  );
};

export default Notifications;
0;
