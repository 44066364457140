import { useAuthContext } from 'auth/store/AuthContext';
import PermissionsGate from 'components/PermissionsGate/PermissionsGate';
import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { usePatientFormikContext } from 'screens/Patients/store';
import { getCurrentPermissionBySection } from 'util/permissionUtils';
import Button from '../../../../../../../components/Button';
import EmergencyButton from '../../../EmergencyButton';
import tabEnum from '../../util/tabEnum';
import routeEnum from './util/routeEnum';
import routeMapEnum from './util/routeMapEnum';

const QuestionnaireActions = () => {
  const history = useHistory();
  const { selectedPatient } = usePatientFormikContext();

  const originRoute = `/patients/${selectedPatient?.id}/${tabEnum.QUESTIONNAIRES}`;
  const route = routeMapEnum[routeEnum.CREATE_QUESTIONNAIRE](originRoute);

  const AbstractButton = (props) => <Button {...props} className="mx-1" />;

  const currentSection = 'PATIENTS_questionnaires';
  let permissions = {};
  const { currentUser } = useAuthContext();
  permissions = currentUser.permissions;
  const permissionKey = getCurrentPermissionBySection(permissions, currentSection);
  
  return (
    <div className="d-flex">
      <PermissionsGate can={permissionKey[0].type}>
        <Switch>
          <Route exact path={originRoute}>
            <AbstractButton
              label="Create new questionnaire"
              variant="add"
              onClick={() => {
                history.push(route);
              }}
            />
          </Route>
        </Switch>
        <EmergencyButton />
      </PermissionsGate>
    </div>
  );
};

export default QuestionnaireActions;
