export const mapPatientToList = (p) => ({
  username: p.first_name,
  //adherence: p.adherence,
  adherence: "Test",
  alertsNumber: 0,
   // p.avatar_status.normal_readings + p.avatar_status.read_alerts + p.avatar_status.yellow_alerts,
  //TODO: check what is this
  issuesNumber: 0,
  patient: {
    firstName: p.first_name,
    lastName: p.last_name,
    //profileImage: p.user.imageUrl,
    profileImage: "",
    id: p.id,
  },
  profileCompleted: {
    completed: 0,
    totalSections: 0,
  },
  satisfaction: p.satisfaction,
  score: p.health_score,
  // populations: p.populations.map((p) => ({
  //   text: p.name,
  // })),
  populations: [{id: 1, name: "Anxiety"}, {id: 1, name: "Depression"}, {id: 1, name: "High BP"}].map((p) => ({
    text: p.name
  })),
  //TODO: check what this is exactly and how it should display
  timeSpent: '',
});
