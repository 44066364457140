import { Client } from '@twilio/conversations';
import { createContext, useContext } from 'react';
import { validateContext } from 'util/utils';

export const TwilioContext = createContext<{
  client: Client;
  isLoading: boolean;
  creationError: string;
}>(null);

export const useTwilioClient = () => {
  const context = useContext(TwilioContext);
  validateContext(context);
  return context;
};
