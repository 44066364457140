import { Message, Paginator } from '@twilio/conversations';

export enum TwilioMessagesActionTypes {
  SET_MESSAGES = 'SET_MESSAGES',
  SET_MESSAGES_PAGINATOR = 'SET_MESSAGES_PAGINATOR',
  ADD_MESSAGE = 'ADD_MESSAGE',
  PREPEND_MESSAGES = 'PREPEND_MESSAGES',
    SET_MESSAGE_LOADING_STATUS = 'SET_MESSAGE_LOADING_STATUS',
//--AppendType
}

export type TwilioMessagesAction =
  | {
      type: TwilioMessagesActionTypes.SET_MESSAGES;
      payload: {
        messages: Message[];
      };
    }
  | {
      type: TwilioMessagesActionTypes.SET_MESSAGES_PAGINATOR;
      payload: {
        paginator: Paginator<Message>;
      };
    }
  | {
      type: TwilioMessagesActionTypes.ADD_MESSAGE;
      payload: {
        message: Message;
      };
    }
  | {
      type: TwilioMessagesActionTypes.PREPEND_MESSAGES;
      payload: {
        messages: Message[];
      };
    } | {
  type:TwilioMessagesActionTypes.SET_MESSAGE_LOADING_STATUS,
  payload:{ 
      isLoading:boolean
  }
}/**--AppendAction */;
