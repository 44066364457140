import { Conversation, ConversationUpdateReason } from '@twilio/conversations';
import { useAuthContext } from 'auth';
import Card from 'components/Card';
import { SearchBarAlt } from 'components/SearchBar';
import { useEffect, useMemo } from 'react';
import { usePatientMessages } from 'screens/messages/hooks';
import MessagesList from 'screens/messages/messages-content/messages-list';
import { mapToChatData } from 'screens/messages/messages-content/utils';
import { usePatientFormikContext } from 'screens/Patients/store';
import { useTwilioClient } from 'store/twilio-client';
import { getConversationAttributes } from 'screens/messages/utils';
import { ConversationSelectors, useConversationsStore } from 'store/twilio';
import { useTwilioConversationsGlobal } from 'store/twilio/use-twilio-conversations-global';

const usePatientMessagesListeners = (patientId: number) => {
  const { client } = useTwilioClient();
  const { upsertSingleConversation } = useTwilioConversationsGlobal();

  useEffect(() => {
    if (!client) return;
    const onConversationUpdated = ({
      conversation,
      updateReasons,
    }: {
      conversation: Conversation;
      updateReasons: ConversationUpdateReason[];
    }) => {
      const attributes = getConversationAttributes(conversation.attributes);
      if (attributes.patient_id !== patientId) {
        return;
      }
      upsertSingleConversation({ sid: conversation.sid });
    };
    const onConversationAdded = (conversation: Conversation) => {
      const attributes = getConversationAttributes(conversation.attributes);
      if (attributes.patient_id !== patientId) {
        return;
      }
      upsertSingleConversation({ sid: conversation.sid, reHydrateMetadata: true });
    };
    client.on('conversationAdded', onConversationAdded);
    client.on('conversationUpdated', onConversationUpdated);

    return () => {
      client.removeListener('conversationAdded', onConversationAdded);
      client.removeListener('conversationUpdated', onConversationUpdated);
    };
  }, [client, patientId, upsertSingleConversation]);
};

export const PatientMessagesList = () => {
  const { selectedPatient } = usePatientFormikContext();
  const { currentUser } = useAuthContext();
  const conversationsMetadataByIdentity = useConversationsStore(
    ConversationSelectors.conversationsMetadataByIdentity
  );
  const { conversations, isLoading } = usePatientMessages(selectedPatient?.id);

  usePatientMessagesListeners(selectedPatient?.id);

  const chats = useMemo(() => {
    return (
      conversations?.map(
        mapToChatData(conversationsMetadataByIdentity, currentUser?.twilio_identity)
      ) ?? []
    );
  }, [conversations, conversationsMetadataByIdentity, currentUser?.twilio_identity]);

  return (
    <Card className="card-bg-border" headers={['Patient messages']}>
      <SearchBarAlt placeholder="Search messages" />
      <MessagesList areChatsLoading={isLoading} chats={chats} />
    </Card>
  );
};
