import { FC } from 'react';
import { Form } from 'react-bootstrap';

interface DeviceStatusCellProps {
  status: boolean;
  onClick: () => void;
}

const DeviceStatusCell: FC<DeviceStatusCellProps> = ({
  status,
  onClick,
}: DeviceStatusCellProps) => {
  return (
    <button
      className="unstyled-button cursor-pointer"
      style={{ outline: 'none' }}
      type="button"
      onClick={onClick}
    >
      <Form.Switch checked={status} readOnly />
    </button>
  );
};
export default DeviceStatusCell;
