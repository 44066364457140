import Card from 'components/Card';
import GenericTable from 'components/GenericTable';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useAdminPopulations } from 'screens/Administration/store/hooks';
import { PopulationWithCount } from 'types/ApiModels/Patients/Population';
import PopulationCardHeader from './PopulationCardHeader/PopulationCardHeader';
import { PopulationRow } from './types';
import { populationColumns } from './utils';
import styles from './styles.module.css';
import { useAdministrationContext } from 'screens/Administration/store';
import AdminPopulationActions from './AdminPopulationActions';
import SaveChangesConfirmationModal from 'components/SaveChangesConfirmationModal';
import { deletePopulations, updatePopulations } from 'services/administrationService';
import { useDialog } from 'components/VyTracLayout/store/hooks';
import {
  clearPopulationChanges,
  setAdminPopulations,
  appendUpdatePopulation,
  appendDeletePopulation,
  setSubmitPopulationChanges,
  setResetPopulationChanges,
} from 'screens/Administration/store/Populations';

const AdminPopulation = () => {
  const { setActions } = useAdministrationContext();
  const [search, setSearch] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { confirmationDialog, errorDialog } = useDialog();

  const [populations, dispatch] = useAdminPopulations();
  const [populationsFromTable, setPopulationsFromTable] = useState<PopulationWithCount[]>([]);

  useEffect(() => {
    setActions(AdminPopulationActions);
  }, []);

  useEffect(() => {
    setPopulationsFromTable(populations?.list ?? []);
  }, [populations?.list]);

  const populationRows: PopulationRow[] = useMemo(() => {
    return populationsFromTable.flatMap<PopulationRow>((pft) => {
      if (search && !pft.population.toLowerCase().includes(search.toLowerCase())) {
        return [];
      }
      return [
        {
          id: pft.id,
          action: '',
          carePlan: '',
          name: pft.population,
          patientsCount: pft.patients_count,
          status: pft.is_active,
        },
      ];
    });
  }, [populationsFromTable, search]);

  const handleResetChanges = useCallback(() => {
    setPopulationsFromTable(populations.list);
    dispatch(clearPopulationChanges());
  }, [populations.list, dispatch]);

  const handleSubmitChanges = async () => {
    const updatedMapped = populations.updatedIsActivePopulations.map((u) => ({
      id: u.id,
      is_active: u.value,
    }));
    const deletedMapped = populations.deletedPopulations.map((d) => ({ id: d }));

    const [[, updateError], [, deleteError]] = await Promise.all([
      updatePopulations(updatedMapped),
      deletePopulations(deletedMapped),
    ]);
    setShowConfirmModal(false);
    if (!(updateError || deleteError)) {
      confirmationDialog('Success', 'Information saved successfully');
      dispatch(setAdminPopulations(populationsFromTable));
      dispatch(clearPopulationChanges());
      return;
    }

    let errors;
    if (updateError && deleteError) {
      errors =
        typeof updateError !== 'string'
          ? Object.values(updateError).join(', ')
          : '' + ' ' + typeof deleteError !== 'string'
          ? Object.values(deleteError).join(', ')
          : '';
    } else {
      const error = updateError ? updateError : deleteError;
      errors = typeof error !== 'string' ? Object.values(error).join(', ') : '';
    }
    errorDialog('Error', 'Failed to save information: ' + errors);
  };

  useEffect(() => {
    dispatch(setSubmitPopulationChanges(() => setShowConfirmModal(true)));
  }, [dispatch]);

  useEffect(() => {
    dispatch(setResetPopulationChanges(handleResetChanges));
  }, [dispatch, handleResetChanges]);

  const handleDeletePopulation = (populationId) => {
    dispatch(appendDeletePopulation(populationId));
    setPopulationsFromTable((pft) => pft.filter((p) => p.id !== populationId));
  };
  const handleUpdateIsActivePopulation = (updatedValue: { id: number; value: boolean }) => {
    dispatch(appendUpdatePopulation(updatedValue));
    setPopulationsFromTable((pft) => {
      const updatedIdx = pft.findIndex((p) => p.id === updatedValue.id);
      const copy = [...pft];
      copy[updatedIdx].is_active = updatedValue.value;
      return copy;
    });
  };

  return (
    <Card
      className="card-bg-border"
      headers={[
        <PopulationCardHeader
          key="population-card-header"
          handleSearch={(e) => {
            setSearch(e.target.value);
          }}
          onFiltersClick={() => undefined}
          search={search}
        />,
      ]}
    >
      <GenericTable
        columns={populationColumns(styles, handleDeletePopulation, handleUpdateIsActivePopulation)}
        data={populationRows}
        headerRowClassName="d-flex gap align-items-center"
        rowClassName="d-flex gap align-items-center"
      />
      <SaveChangesConfirmationModal
        show={showConfirmModal}
        onConfirm={handleSubmitChanges}
        onCancel={() => setShowConfirmModal(false)}
      />
    </Card>
  );
};
export default AdminPopulation;
