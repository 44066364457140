import styles from './styles.module.css';
import Content from './Content';
import PatientGoalsTopBar from 'components/PatientGoalsTopBar';
import { usePatientContext } from 'screens/Patients/store/PatientContext';
import { VytalType } from 'types/ApiModels/Patients/Vytal';
import { setSelectedGoal } from 'screens/Patients/store/PatientGoalsThresholds';

const GoalsThreshold = () => {
  const {
    dispatch,
    patientState: {
      patientGoalsThresholds: { selectedGoal, thresholds },
    },
  } = usePatientContext();

  const getDisabledOptions = () => {
    if (!thresholds) return [];
    const options: VytalType[] = [];
    Object.values(VytalType).forEach((value) => {
      // (!thresholds[value].is_enabled) options.push(value);
    });
    return options;
  };

  const updateSelectedGoal = (newGoal: VytalType) => dispatch(setSelectedGoal(newGoal));

  return (
    <div className={styles.main}>
      <PatientGoalsTopBar
        selectedGoal={selectedGoal}
        setSelectedGoal={updateSelectedGoal}
        disabledOptions={getDisabledOptions()}
      />
      <Content />
    </div>
  );
};

export default GoalsThreshold;
