import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { searchEncounter } from 'services/encounterService';
import { PaginatedResponse } from 'types/ApiModels/General';
import { EncounterConversation } from 'types/ApiModels/Patients/encounter';

export const useEncounterConversationsByPatient = (patientId: number) => {
  const [encounterConversations, setEncounterConversations] = useState<EncounterConversation[]>([]);
  const { data: encounterConversationsPaginator, isLoading } = useQuery<
    PaginatedResponse<EncounterConversation>
  >(['encounterConversations', patientId], () => {
    return searchEncounter({ patient: patientId.toString() });
  });

  useEffect(() => {
    //sync if paginator changes
    setEncounterConversations((ec) => {
      if (!encounterConversationsPaginator) return ec;
      return [...ec, ...encounterConversationsPaginator.results];
    });
  }, [encounterConversationsPaginator]);

  return useMemo(
    () => ({
      encounterConversationsPaginator,
      encounterConversations,
      isLoading,
    }),
    [encounterConversations, encounterConversationsPaginator, isLoading]
  );
};
