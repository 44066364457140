import StickyNotes from "types/ApiModels/TeamNotes/StickyNotes";
import { StickyAction, StickyNotesActionType } from "./stickyActionType";

export const setSitckyNotes =( stickyNotes: StickyNotes[]): StickyAction => ({
    type: StickyNotesActionType.SET_STICKY_NOTES,
    payload: { stickyNotes }
});

export const toggleShowStickyNote = (): StickyAction => ({
    type: StickyNotesActionType.TOGGLE_SHOW_ADD_STICKY,
});

export const addStickyNote = (saveStickyNotes: () => void): StickyAction => ({
    type: StickyNotesActionType.ADD_STICKY_NOTES,
    payload: { saveStickyNotes },
})