import PropTypes from 'prop-types';
import bellIconSrc from '../../assets/icons/bell.png';
import { formatVytracDate } from '../../util/dateUtils';
import styles from './styles.module.css';

const StickyNote = ({id, title, content, notificationDate, handleEditNote= (id, text) => {} }) => {
  return (
    <div onClick={() => handleEditNote(id, content)}  className={`d-flex flex-column gap card-shadow-hover ${styles['sticky-note-container']}`}  >
      <div>{title}</div>
      <div className="d-flex gap align-items-center">
        <div className={`${styles['notification-date']}`}>{formatVytracDate(notificationDate)}</div>
        <div>
          <img src={bellIconSrc} height={10} width={10} alt="bell icon" />
        </div>
      </div>
      <div>{content}</div>
    </div>
  );
};
StickyNote.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  notificationDate: PropTypes.instanceOf(Date),
};
export default StickyNote;
