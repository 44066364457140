import CareTeamAsyncSelect from 'components/CareTeamAsyncSelect';
import { SelectFormik, TextInputFormik } from 'components/FormikComponents';
import DatePickerFormik from 'components/FormikComponents/date-picker-formik';
import MultiSelectFormik from 'components/FormikComponents/MultiSelectFormik';
import vytracSelectStyle from 'components/VytracStyledSelect/vytracSelectStyle';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import { useEffect, useMemo, useRef } from 'react';
import InvitePatient from 'types/ApiModels/Administration/InvitePatient';
import { CareTeam } from 'types/ApiModels/CareTeam';
import { PopulationWithCount } from 'types/ApiModels/Patients/Population';
import Sex from 'types/ApiModels/Users/Sex';
import { getStyles } from '../../utils/getStyles';
import styles from '../styles.module.css';
import PatientAccessSwitch from './PatientAccessSwitch';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { convertDateToDatePickValue } from 'util/dateUtils';
import { useAppQuery } from 'store/use-app-query';
import MaskedInput from 'react-text-mask'

export const Step1 = () => {
  const { data: sex } = useAppQuery<Sex[]>('sex');
  const { setFieldValue, values, errors, touched } = useFormikContext<InvitePatient>();

  const mapSexToSelect = (sex: Sex) => {
    const label = sex.name;
    return { label, value: sex.id };
  };

  const defaultSex = useMemo(() => {
    const selectedSex = sex?.find((s) => s.id === values.sexAssignedAtBirth);
    if (!selectedSex) return null;
    return { label: selectedSex.name, value: selectedSex.id };
  }, [values.sexAssignedAtBirth, sex]);

  const phoneNumberMask = [
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ];

  const inputRefPhone = useRef<HTMLInputElement>();

  return (
    <div className="d-flex flex-column gap mb-2 px-3 pt-3">
      <div className="d-flex">
        <PatientAccessSwitch />
      </div>
      <div className="d-flex">
        <div className={`d-flex ${styles['form-label']} font-size-medium`}>First name*</div>
        <div className={`flex-grow-1 d-flex flex-column gap`}>
          <TextInputFormik
            className="w-100"
            name="firstName"
            style={getStyles(errors, touched, 'firstName')}
          />
          <ErrorMessage name="firstName">
            {(msg) => <div className="text-danger font-size-small">{msg}</div>}
          </ErrorMessage>
        </div>
      </div>
      <div className="d-flex">
        <div className={`d-flex ${styles['form-label']} font-size-medium`}>Middle name</div>
        <div className={`flex-grow-1 d-flex flex-column gap`}>
          <TextInputFormik className="w-100" name="middleName" />
          <ErrorMessage name="middleName">
            {(msg) => <div className="text-danger font-size-small">{msg}</div>}
          </ErrorMessage>
        </div>
      </div>
      <div className="d-flex">
        <div className={`d-flex ${styles['form-label']} font-size-medium`}>Last name*</div>
        <div className={`flex-grow-1 d-flex flex-column gap`}>
          <TextInputFormik
            className="w-100"
            name="lastName"
            style={getStyles(errors, touched, 'lastName')}
          />
          <ErrorMessage name="lastName">
            {(msg) => <div className="text-danger font-size-small">{msg}</div>}
          </ErrorMessage>
        </div>
      </div>
      <div className="d-flex">
        <div className={`d-flex ${styles['form-label']} font-size-medium`}>Patient phone number*</div>
        <div className={`flex-grow-1 d-flex flex-column gap`}>
          {/* <TextInputFormik
            className="w-100"
            name="phoneNumber"
            style={getStyles(errors, touched, 'phoneNumber')}
          /> */}

                <Field
                    name="phoneNumber"
                    render={({ field }) => (
                      <MaskedInput
                        {...field}
                        mask={phoneNumberMask}
                        id="phone"
                        placeholder="xxx-xxx-xxxx"
                        type="text"
                        onChange={e => {
                          const value = e.target.value || '';
                          setFieldValue('phoneNumber', value);
                        }}
                        onBlur={null}
                        ref={inputRefPhone}
                        className="w-100 font-size-medium vytrac-input rounded"
                        name="phoneNumber"
                      />
                    )}
                  />


          <ErrorMessage name="phoneNumber">
            {(msg) => <div className="text-danger font-size-small">{msg}</div>}
          </ErrorMessage>
        </div>
      </div>
      <div className="d-flex">
        <div className={`d-flex ${styles['form-label']} font-size-medium`}>
          Patient email{values['isAppUser'] ? '*' : ''}
        </div>
        <div className={`flex-grow-1 d-flex flex-column gap`}>
          <TextInputFormik
            className="w-100"
            name="email"
            style={getStyles(errors, touched, 'email')}
          />
          <ErrorMessage name="email">
            {(msg) => <div className="text-danger font-size-small">{msg}</div>}
          </ErrorMessage>
        </div>
      </div>
      <div className="d-flex">
        <div className={`d-flex ${styles['form-label']} font-size-medium`}>Date of birth*</div>
        <div className={`flex-grow-1 d-flex flex-column gap`}>
          <DatePickerFormik
            name="dateOfBirth"
            inputClassName={'vytrac-input'}
            inputContainerClass={`px-2 vytrac-input rounded d-flex justify-content-between align-items-center ${styles['date-picker-container']}`}
            inputComputedStyle={getStyles(errors, touched, 'dateOfBirth')}
            maximumDate={convertDateToDatePickValue(new Date())}
          >
            <CalendarIcon />
          </DatePickerFormik>
          <ErrorMessage name="dateOfBirth">
            {(msg) => <div className="text-danger font-size-small">Required</div>}
          </ErrorMessage>
        </div>
        <div className={`d-flex ${styles['form-label']} pr-2 font-size-medium pl-2`}>
          Sex assigned at birth
        </div>
        <div className={`flex-grow-1 d-flex flex-column gap`}>
          <SelectFormik
            options={sex?.map(mapSexToSelect)}
            name="sexAssignedAtBirth"
            placeholder="Assign sex..."
            defaultValue={defaultSex}
            styles={getStyles(errors, touched, 'sexAssignedAtBirth')}
          />
          <ErrorMessage name="sexAssignedAtBirth">
            {(msg) => <div className="text-danger font-size-small">{msg}</div>}
          </ErrorMessage>
        </div>
      </div>
      <div className="d-flex overflow-visible">
        <div className={`d-flex ${styles['form-label']} font-size-medium `}>Care team</div>
        <div className="flex-grow-1 d-flex flex-column gap">
          <Field name="careTeam">
            {() => (
              <CareTeamAsyncSelect
                onChange={(value: { value: number; label: string; careTeam: CareTeam }) => {
                  setFieldValue('careTeam', value.value);
                }}
                styles={{
                  control: (base) => ({
                    ...vytracSelectStyle.control(base),
                    ...getStyles(errors, touched, 'careTeam', true),
                    width: '100%',
                  }),
                }}
              />
            )}
          </Field>
          <ErrorMessage name="careTeam">
            {(msg) => <div className="text-danger font-size-small">{msg}</div>}
          </ErrorMessage>
        </div>
      </div>
    </div>
  );
};
export default Step1;
