import { ProviderUpdateRequest } from 'types/ApiModels/Providers/Provider';
import { convertDatepickToDate } from 'util/dateUtils';
import type { ProviderDetailsFormValues } from '../ProviderDetails';

export const mapProviderFormToProvider = (
  values: ProviderDetailsFormValues,
  isActive: boolean
): ProviderUpdateRequest => {
  const {
    identification: {
      firstName: first_name,
      accessRoles: roles_id,
      dateOfBirth,
      gender: sex,
      lastName: last_name,
      middleName: middle_name,
      title: title_id,
      language: language
    },
    personalInformation: {
      addressInformation,
      contactInformation: { email, phoneNumber1: cell_phone_number, phoneNumber2: office_extension },
    },
    specialties: speciality_id,
  } = values;
  return {
    first_name,
    address: addressInformation.map((a) => ({
      street_address_line_1: a.home,
      apt: a.apt,
      state: a.state,
      city: a.city,
      zip_code: a.zipCode,
      id: a.id
    })),
    date_birth: convertDatepickToDate(dateOfBirth).toISOString(),
    email_address:values.personalInformation.contactInformation.email,
    language,
    last_name,
    middle_name,
    phone_number1:values.personalInformation.contactInformation.phoneNumber1,
    phone_number2:values.personalInformation.contactInformation.phoneNumber2,
    roles_id,
    sex,
    speciality_id,
    title_id,
    // photo: 'photoplaceholder',
    active: isActive,
  };
};
