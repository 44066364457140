import { Client } from '@twilio/conversations';
import { useCallback, useEffect, useMemo } from 'react';
import { getConversationAttributes } from 'screens/messages/utils';
import { getSingleConversationMetadata } from 'services/twilio';
import { useTwilioClient } from 'store/twilio-client';
import { ConversationSelectors, ConversationSimplified, useConversationsStore } from '.';

export const hydrateConversationInfo = async (client: Client, sid: string) => {
  const c = await client.getConversationBySid(sid);
  const attributes = getConversationAttributes(c.attributes);
  const cMetadata = await getSingleConversationMetadata(c);
  const participantsMapped = cMetadata.participants.map((p) => ({
    participantSid: p.sid,
    identity: p.identity,
  }));
  const lastMessageParticipant = participantsMapped.find(
    (p) => p.participantSid === cMetadata.lastMessage?.participantSid
  );
  const unreadCount = (() => {
    if (c.lastReadMessageIndex === null && c.lastMessage) return c.lastMessage.index + 1;
    else if (!c.lastMessage) return 0;
    else return c.lastMessage.index - c.lastReadMessageIndex;
  })();
  return {
    sid: c.sid,
    lastMessage: {
      body: cMetadata.lastMessage?.body,
      dateCreated: cMetadata.lastMessage?.dateCreated,
      idx: cMetadata.lastMessage?.index,
    },
    unreadCount,
    lastMessageParticipant,
    participants: participantsMapped,
    attributes,
  };
};

export const hydrateConversationInfoBulk = async (client: Client, sids: string[]) => {
  const conversationsAllSettled = await Promise.allSettled(
    sids.map<Promise<ConversationSimplified>>((sid) => hydrateConversationInfo(client, sid))
  );
  const resultConversations = conversationsAllSettled.flatMap((cas) => {
    if (cas.status === 'rejected') {
      console.error(cas.reason);
      return [];
    }
    return [cas.value];
  });

  return resultConversations;
};

/**
 * Middleware to update conversations. Uses twilio client to fetch metadata and adapt basic conversation information into remote-hydrated data to save it to store.
 * @returns
 */
export const useTwilioConversationsGlobal = () => {
  const { client } = useTwilioClient();
  const setConversationsLoading = useConversationsStore(
    ConversationSelectors.setConversationsLoading
  );
  const hydrateMetadata = useConversationsStore(ConversationSelectors.hydrateMetadata);
  const upsertConversationsToStore = useConversationsStore(
    ConversationSelectors.upsertBulkConversations
  );
  const upsertSingleConversationToStore = useConversationsStore(
    ConversationSelectors.upsertConversation
  );

  useEffect(() => {
    setConversationsLoading(true);
  }, [setConversationsLoading]);

  const upsertConversations = useCallback(
    async (conversationSids: string[]) => {
      if (!client) return;
      const resultConversations = await hydrateConversationInfoBulk(client, conversationSids);
      upsertConversationsToStore(resultConversations);
      const participantIdentities = resultConversations.flatMap((c) =>
        c.participants.map((p) => p.identity)
      );
      hydrateMetadata(participantIdentities);
      setConversationsLoading(false);
    },
    [client, hydrateMetadata, setConversationsLoading, upsertConversationsToStore]
  );

  const upsertSingleConversation = useCallback(
    async ({ sid, reHydrateMetadata }: { sid: string; reHydrateMetadata?: boolean }) => {
      if (!client) return;
      try {
        const conversationSimplified = await hydrateConversationInfo(client, sid);
        upsertSingleConversationToStore(conversationSimplified);
        reHydrateMetadata &&
          hydrateMetadata(conversationSimplified.participants.map((p) => p.identity));
      } catch (error) {
        console.error(error);
      }
      setConversationsLoading(false);
    },
    [client, hydrateMetadata, setConversationsLoading, upsertSingleConversationToStore]
  );

  return useMemo(
    () => ({
      upsertConversations,
      upsertSingleConversation,
    }),
    [upsertConversations, upsertSingleConversation]
  );
};
