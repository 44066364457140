import { Form, Formik } from 'formik';
import React, { useEffect, useRef } from 'react';
import Card from 'components/Card';
import GoalCard from '../GoalChartCard';
import GoalAlerts from './GoalAlerts';
import styles from './styles.module.css';
import createFormikInitialValue from './util/createFormikInitialValue';
import formName from './util/formName';
import goalDetailAlertsValidationSchema from './util/goalDetailAlertsValidationSchema';
import { IGoalThreshold } from 'types/ApiModels/Patients/Goal';
import { isDoubleThreshold } from 'util/goalChart';
import { Chart } from 'chart.js';
import { usePatientContext } from 'screens/Patients/store/PatientContext';

interface GoalDetailsProps {
  goalThreshold: IGoalThreshold;
}
const GoalDetails = ({ goalThreshold }: GoalDetailsProps) => {
  const {
    patientState: {
      patientGoalsThresholds: { selectedGoal },
    },
  } = usePatientContext();
  const chartRef = useRef<Chart<'line'>>();

  // useEffect(() => {
  //   chartRef.current.render();
  // }, [selectedGoal]);

  return (
    <Formik
      initialValues={null}
      //initialValues={createFormikInitialValue(isDoubleThreshold(goalThreshold.type), goalThreshold)}
      validationSchema={goalDetailAlertsValidationSchema}
      onSubmit={() => null}
      validateOnBlur={false}
      enableReinitialize
    >
      <Form id={formName}>
        <div className="w-100 pt-4 d-flex h-100">
          <GoalCard goalThreshold={goalThreshold} className="w-75 mr-4" chartRef={chartRef} />
          <Card
            headers={['Adjust alert details']}
            headerClassName={styles.controlsHeader}
            className={styles.controls}
            bodyClassName={`${styles.controlsBody} p-0`}
          >
            <GoalAlerts goal={goalThreshold} />
          </Card>
        </div>
      </Form>
    </Formik>
  );
};

export default GoalDetails;
