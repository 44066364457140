import { useAuthContext } from 'auth/store/AuthContext';
import PermissionsGate from 'components/PermissionsGate/PermissionsGate';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { getCurrentPermissionBySection } from 'util/permissionUtils';

interface CarePlanEditElementProps {
  id: number;
}

const CarePlanEditElement: FC<CarePlanEditElementProps> = ({ id }: CarePlanEditElementProps) => {
    const currentSection = 'ADMINISTRATION_patient_settings';
    let permissions = {};
    const { currentUser } = useAuthContext();
    permissions = currentUser.permissions;
    const permissionKey = getCurrentPermissionBySection(permissions, currentSection);

  return (
    <div>
      <PermissionsGate can={permissionKey[0].type}>
        <Link to="/administration/providers/careteam" className="link">
          Edit
        </Link>
      </PermissionsGate>
    </div>
  );
};
export default CarePlanEditElement;
