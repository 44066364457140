import Card from 'components/Card';
import CardHeaderSearchFilter from 'components/card-header-search-filter';
import GenericTable from 'components/GenericTable';
import { PaginationControlsAlt } from 'components/Pagination/PaginationControls';
import { useDebouncedSearch } from 'hooks';
import { useMemo, useState } from 'react';
import { useNotesKeywords } from 'screens/Administration/store/hooks';
import { searchNotesKeywordPatients } from 'services/administrationService';
import KeywordsCountModal from '../keywords-count-modal';
import { mapPatientNotesKeywordsToRow, getPatientNotesKeywordsColumns } from './utils';

const KeywordsCard = () => {
  const [, pagination] = useNotesKeywords();
  const handleFiltersChange = () => {};
  const { handleSearch, mappedSearchResult, search } = useDebouncedSearch(
    searchNotesKeywordPatients,
    mapPatientNotesKeywordsToRow
  );
  const [showKeywordsCountModal, setShowKeywordsCountModal] = useState(false);
  const [currentPatientNotesKeywordsId, setCurrentPatientNotesKeywordsId] = useState<number>(null);

  const currentPageData = useMemo(() => {
    if (!pagination?.book) return null;
    return pagination.book[pagination.currentPage]?.results ?? [];
  }, [pagination.book, pagination.currentPage]);

  const rowData = useMemo(
    () => currentPageData?.map(mapPatientNotesKeywordsToRow),
    [currentPageData]
  );

  const currentPatientNotesKeyword = useMemo(
    () => currentPageData?.find((d) => d.id === currentPatientNotesKeywordsId),
    [currentPageData, currentPatientNotesKeywordsId]
  );

  const handleKeywordCountClick = (id) => () => {
    setCurrentPatientNotesKeywordsId(id);
    setShowKeywordsCountModal(true);
  };
  const handleKeywordsModalClosed = () => {
    setCurrentPatientNotesKeywordsId(null);
    setShowKeywordsCountModal(false);
  };

  return (
    <Card
      className="card-bg-border"
      headers={[
        <CardHeaderSearchFilter
          handleFiltersChange={handleFiltersChange}
          handleSearch={handleSearch}
          search={search}
          key="header"
          title="Keywords"
        />,
      ]}
    >
      <GenericTable
        columns={getPatientNotesKeywordsColumns({ handleKeywordCountClick })}
        data={search ? mappedSearchResult : rowData}
        headerRowClassName="d-flex align-items-center"
        rowClassName="d-flex align-items-center"
      />
      <div className="d-flex justify-content-end">
        <PaginationControlsAlt
          currentPage={pagination.currentPage}
          handleFirst={pagination.first}
          handleLast={pagination.last}
          handleNext={pagination.next}
          handlePrevious={pagination.previous}
          maxPage={pagination.pages}
        />
      </div>
      <KeywordsCountModal
        currentPatient={currentPatientNotesKeyword}
        onClose={handleKeywordsModalClosed}
        show={showKeywordsCountModal}
      />
    </Card>
  );
};
export default KeywordsCard;
