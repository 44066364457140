import { Message, Paginator } from '@twilio/conversations';
import { TwilioMessagesAction, TwilioMessagesActionTypes } from './twilio-messages-action-types';

export interface TwilioMessagesState {
  messages: Message[];
  messagesPaginator: Paginator<Message>;
  areMessagesLoading: boolean;
}

export const twilioMessagesInitialState: TwilioMessagesState = {
  messages: [],
  messagesPaginator: null,
  areMessagesLoading: false,
};

const twilioMessagesReducer = (
  state: TwilioMessagesState,
  action: TwilioMessagesAction
): TwilioMessagesState => {
  switch (action.type) {
    case TwilioMessagesActionTypes.SET_MESSAGES: {
      return { ...state, messages: action.payload.messages };
    }
    case TwilioMessagesActionTypes.SET_MESSAGES_PAGINATOR: {
      return { ...state, messagesPaginator: action.payload.paginator };
    }
    case TwilioMessagesActionTypes.ADD_MESSAGE: {
      return { ...state, messages: [...state.messages, action.payload.message] };
    }
    case TwilioMessagesActionTypes.PREPEND_MESSAGES: {
      const newMessages = [...action.payload.messages, ...state.messages];
      return { ...state, messages: newMessages };
    }
    case TwilioMessagesActionTypes.SET_MESSAGE_LOADING_STATUS: {
      return { ...state, areMessagesLoading: action.payload.isLoading };
    }
    //--AppendCase
    default:
      //this is TS check so that we don't leave any type off the grid
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const neverState: never = action;
      return state;
  }
};

export default twilioMessagesReducer;
