import { MessageWithMention, splitMentionMultiple } from 'components/message-with-mention';
import UserBubble from 'components/UserBubble';
import { FC, useMemo } from 'react';
import { ConversationMetadata } from 'types/ApiModels/conversations';
import { formatMessageTimeStamp } from 'util/dateUtils';
import TeamNotesMessageAttributes from '../../types/team-notes-message-attributes';
import styles from './styles.module.css';

interface TeamNotesMessageProps {
  dateCreated: Date;
  body: string;
  attributes: TeamNotesMessageAttributes;
  senderMetadata: ConversationMetadata;
  selfMetadata: ConversationMetadata;
  containerClassName?: string;
}

const TeamNotesMessage: FC<TeamNotesMessageProps> = ({
  dateCreated,
  //TODO: will be used for threads and message rendering
  attributes,
  body,
  senderMetadata,
  selfMetadata,
  containerClassName = '',
}: TeamNotesMessageProps) => {
  const dateCreatedStr = formatMessageTimeStamp(dateCreated);

  const [first_name, last_name] = useMemo(
    () => senderMetadata?.full_name?.split(' ') ?? [],
    [senderMetadata?.full_name]
  );

  const mentionsSortedByIndex = useMemo(() => {
    const copy = attributes.mentions ? [...attributes.mentions] : [];
    copy.sort((a, b) => (a.plainTextIndex > b.plainTextIndex ? -1 : 1));
    return copy;
  }, [attributes.mentions]);

  const splitBody = useMemo(() => {
    if (!mentionsSortedByIndex?.length) return [];
    return splitMentionMultiple(body, attributes.mentions);
  }, [attributes.mentions, body, mentionsSortedByIndex?.length]);

  const isSelfMessage = useMemo(
    () => senderMetadata?.twilio_identity === selfMetadata?.twilio_identity,
    [selfMetadata?.twilio_identity, senderMetadata?.twilio_identity]
  );

  return (
    <div
      className={`${styles['message']} ${containerClassName} ${
        isSelfMessage ? 'flex-row-reverse' : ''
      }`}
    >
      <div>
        <UserBubble user={{ first_name, last_name, imageUrl: senderMetadata?.photo_thumbnail }} />
      </div>
      <div className={`d-flex ${styles['message-text']}`}>
        <span>
          {splitBody?.length ? (
            <MessageWithMention body={splitBody} senderId={senderMetadata?.id} />
          ) : (
            body
          )}
        </span>
        <span className={styles['message-time']}>{dateCreatedStr}</span>{' '}
      </div>
    </div>
  );
};
export default TeamNotesMessage;
