import addCircleButtonSrc from 'assets/icons/plus_button_icon.svg';
import FiltersButton from 'components/FiltersButton';
import { useEffect, useReducer } from 'react';
import { useAdministrationContextV2 } from 'screens/Administration/store';
import { useStickyContext } from '../../store/hooks/use-admin-stickynotes';
import { stickyNotesInitialState, stickyNotesReducer, useStickyNotesContext } from '../../store/sticky-notes';
import { MostrarStickyNote } from '../../store/sticky-notes/sticky-notes-action-creators';
import { toggleShowStickyNote } from '../../store/sticky-notes/stickyActionCreator';

const StickyNotesCardHeader = ({ stickyNotesLength, handleFiltersButtonClick = () => {}, handleShowModal = () => {}, }) => {

  return (
    <div className="d-flex justify-content-between w-100 align-items-center">
      <div>Sticky Notes {stickyNotesLength ? `(${stickyNotesLength})` : ''}</div>
      <div className="d-flex align-items-center gap">
        <img src={addCircleButtonSrc} height={20} width={20} alt="add sticky note button" onClick={handleShowModal}/>
        <FiltersButton onFiltersClick={handleFiltersButtonClick} />
      </div>
    </div>
  );
};
export default StickyNotesCardHeader;
