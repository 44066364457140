import { Fragment, useEffect } from 'react';
import Button from 'components/Button';
import { getGoalThresholds } from 'services/patientService';
import GoalCard from './components/GoalChartCard';
import GoalDetails from './components/GoalDetails';
import styles from './styles.module.css';
import { usePatientContext } from 'screens/Patients/store/PatientContext';

import { setSelectedGoal, setThresholds } from 'screens/Patients/store/PatientGoalsThresholds';
import { usePatientFormikContext } from 'screens/Patients/store';
import { VytalType } from 'types/ApiModels/Patients/Vytal';
import { setPreviousThresholds } from 'screens/Patients/store/PatientGoalsThresholds';

const Content = () => {
  const {
    dispatch,
    patientState: {
      patientGoalsThresholds: { selectedGoal, thresholds },
    },
  } = usePatientContext();
  const { selectedPatient } = usePatientFormikContext();

  //Fetch patient thresholds and data
  useEffect(() => {
    const fetchGoalsThresholds = async (patientId: number) => {
      try {
        const data = await getGoalThresholds(patientId);
        dispatch(setThresholds(data));
      } catch (exception) {
        throw exception;
      }
    };

    fetchGoalsThresholds(selectedPatient.id);
    dispatch(setPreviousThresholds());
  }, [dispatch, selectedPatient.id]);

  if (!thresholds) return null;

  if (selectedGoal)
    return <GoalDetails key={selectedGoal} goalThreshold={thresholds[selectedGoal]} />;

  return (
    <div className={styles.container}>
      {Object.values(VytalType).map((vytal, index) => (
        <Fragment key={index}>
          <GoalCard goalThreshold={thresholds[vytal]}>
            <Button
              label="show alert details"
              className="w-100"
              containerClassName="flex-grow-1 mt-3"
              onClick={() => dispatch(setSelectedGoal(vytal))}
              //disabled={!thresholds[vytal].is_enabled}
            />
          </GoalCard>
        </Fragment>
      ))}
    </div>
  );
};

export default Content;
