import { authUserAxios, vytracAxios } from 'ajax';
import { PaginatedResponse } from 'types/ApiModels/General';
import {
  Provider,
  ProvidersSearch,
  ProviderUpdateRequest,
  Title,
} from 'types/ApiModels/Providers/Provider';
import { IProviderAction } from 'types/ApiModels/Providers/ProviderAction';
import Specialty from 'types/ApiModels/Providers/Specialty';

export const getProviders = async () => {
  try {
    const response = await vytracAxios.get<PaginatedResponse<Provider>>('administration/staff/list/');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSingleProvider = async (providerId: number,userid?: number) => {
  try {
    const response = await vytracAxios.get<Provider>(`administration/staff/list/${userid}/`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const searchProviders = async (search: string) => {
  try {
    const response = await vytracAxios.get<PaginatedResponse<Provider>>(
      //`providers/?search=${search}`
      `/administration/staff/list/?search=${search}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getProvidersAutocompleteList = async (searchValue: string, listSize: number) => {
  try {
    const response = await vytracAxios.get<PaginatedResponse<ProvidersSearch>>(
      `providers/autocomplete/?search${searchValue}&size=${listSize}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateProvider = async (providerId: number, provider: ProviderUpdateRequest) => {
  try {
    const response = await authUserAxios.put(`/v2/edit/${providerId}/`, provider);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteProvider = async (providerId: number) => {
  try {
    const response = await authUserAxios.delete(`/v2/edit/${providerId}/`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSpecialties = async () => {
  try {
    const response = await vytracAxios.get<PaginatedResponse<Specialty>>('administration/specialities/list/');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSpecialtiesV2 = async () => {
  try {
    const response = await vytracAxios.get<PaginatedResponse<Specialty>>('administration/specialities/list/v2/');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const searchSpecialties = async (search: string) => {
  try {
    const response = await vytracAxios.get<PaginatedResponse<Specialty>>(
      `providers/specialities/?search=${search}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addSpecialtyBulk = async (specialties: Specialty[]) => {
  try {
    const response = await vytracAxios.post<Specialty[]>('administration/specialities/v2/', specialties);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateSpecialtyBulk = async (specialties: Specialty[]) => {
  try {
    const response = await vytracAxios.put<Specialty[]>(`administration/specialities/v2/edit/`, specialties);
    
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteSpecialtyBulk = async (specialtyIds: number[]) => {
  try {
    const response = await vytracAxios.delete(`administration/specialities/v2/delete/`, {
      data: specialtyIds.map((id) => ({
        id,
      })),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getProvidersBySpecialty = async (specialtyId: number) => {
  try {
    
    const response = await vytracAxios.get<PaginatedResponse<Provider>>(
    //const response = await vytracAxios.get<Provider>(
      `administration/specialities/v2/${specialtyId}/`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getProvidersBySpecialtyCount = async() => {
  try {
    const response = await vytracAxios.get<PaginatedResponse<Provider>>(
    //const response = await vytracAxios.get<Provider>(
      `administration/specialities/providers/v2/`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getProvidersTitles = async () => {
  try {
    const response = await vytracAxios.get<PaginatedResponse<Title>>('providers/titles/');
    console.log(response.data);
    console.log('getProvidersTitles')
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const sendAction = async (action: IProviderAction) => {
  try {
    const response = await vytracAxios.post<IProviderAction>('providers/action_todo/', action);
    return response.data;
  } catch (e) {
    throw e;
  }
};
