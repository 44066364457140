import CardAdd from 'components/CardAdd';
import { useFormikContext } from 'formik';
import { FC } from 'react';
import { PatientManagementFormikValues } from '../types';
import SurgeryList from './SurgeryList';

interface SurgeryHistoryProps {
  handleAdd: () => void;
  queueDelete: (id: number) => void;
  handleEdit: (id: number) => void;
}
const SurgeryHistoryCard: FC<SurgeryHistoryProps> = ({
  handleAdd,
  queueDelete,
  handleEdit,
}: SurgeryHistoryProps) => {
  const { values, setFormikState } = useFormikContext<PatientManagementFormikValues>();
  const handleDeleteSurgery = (id: number) => {
    id > 0 && queueDelete(id);
    setFormikState((prev) => ({
      ...prev,
      values: {
        ...prev.values,
        SURGERIES: prev.values.SURGERIES.filter((s) => s.id !== id),
      },
    }));
    id > 0 && queueDelete(id);
    return;
  };
  return (
    // <CardAdd count={values.SURGERIES?.length ?? 0} handleAdd={handleAdd} title="Surgery history">
    <CardAdd count={0} handleAdd={handleAdd} title="Surgery history">
      <SurgeryList
        //surgeries={values.SURGERIES ?? []}
        surgeries={[]}
        handleDeleteSurgery={handleDeleteSurgery}
        handleEditSurgery={handleEdit}
      />
    </CardAdd>
  );
};
export default SurgeryHistoryCard;
