import { PaginatedRequestHook, usePaginatedRequest } from 'hooks';
import { Dispatch } from 'react';
import { PatientNotesKeywords } from 'types/ApiModels/Administration';
import { useAdministrationContextV2 } from '../AdministrationContextV2';
import { NotesKeywordsAction, NotesKeywordsState } from '../patient-settings/notes-keywords';

export const useNotesKeywords = (): [
  notesState: NotesKeywordsState,
  pagination: PaginatedRequestHook<PatientNotesKeywords>,
  dispatch: Dispatch<NotesKeywordsAction>
] => {
  const {
    administrationStateV2: { notesKeywords },
    dispatch,
  } = useAdministrationContextV2();

  const pagination = usePaginatedRequest<PatientNotesKeywords>(
    `administration/patientsettings/noteskeywords/`,
    12
  );

  return [notesKeywords, pagination, dispatch];
};
