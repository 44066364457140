import { registerUser, validateEmailAndLastName } from 'services/userService';
import RegisterUserRequest from 'types/ApiModels/Login/RegisterUserRequest';
import { getIn } from 'formik';

export const onSubmit = async (values: RegisterUserRequest) => {
  try {
    const response = await registerUser(values);
    if (response.status === 200 || response.status === 201) return await response.json();
    else return { error: response.status, message: await response.json() };
  } catch (error) {
    throw error;
  }
};

export function getStyles(errors, fieldName) {
  if (getIn(errors, fieldName)) {
    return {
      borderBottom: '1px solid red',
      color: 'red',
    };
  }
};

export const handleOnSubmitValidateData = async (email, lastName, birth_day) => {
  try {
    const response = await validateEmailAndLastName(email, lastName, birth_day);
    return response;
  } catch (error) {
    throw error;
  }
};