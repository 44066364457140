import crossIcon from 'assets/icons/cross.png';
import { TextInputFormik } from 'components/FormikComponents';
import { ErrorMessage, useFormikContext } from 'formik';
import { createLocalAddressId } from 'screens/Administration/AdministrationContent/AdminTabs/AdminProviders/ProviderDetails/ProviderDetails';
import styles from './styles.module.css';

const formikFields = (index) => [
  {
    label: 'address',
    name: `personalInformation.addressInformation[${index}].home`,
  },
  {
    label: 'apt #',
    name: `personalInformation.addressInformation[${index}].apt`,
  },
  {
    label: 'city',
    name: `personalInformation.addressInformation[${index}].city`,
  },
  {
    label: 'state',
    name: `personalInformation.addressInformation[${index}].state`,
  },
  {
    label: 'zip code',
    name: `personalInformation.addressInformation[${index}].zipCode`,
  },
];

interface AddressFormValues {
  home: string;
  apt: string;
  state: string;
  city: string;
  zipCode: string;
  id: number;
}

interface ValuesFormikForAddressInformation {
  personalInformation: {
    addressInformation: AddressFormValues[];
  };
}

const AddressTableRow = ({ formikFields }: { formikFields: { label: string; name: string }[] }) => {
  return (
    <>
      {formikFields.map((ff) => {
        return (
          <td key={ff.name}>
            <div className="d-flex flex-column px-1 gap">
              <TextInputFormik name={ff.name} />
              <div className={styles['error-reserved']}>
                <ErrorMessage name={ff.name}>
                  {(msg) => <div className="text-danger font-size-small">{msg}</div>}
                </ErrorMessage>
              </div>
            </div>
          </td>
        );
      })}
    </>
  );
};

const AddressInformation = () => {
  const { values, setFormikState } = useFormikContext<ValuesFormikForAddressInformation>();
  const handleAddAddress = () => {
    setFormikState((state) => {
      return {
        ...state,
        values: {
          ...state.values,
          personalInformation: {
            ...state.values.personalInformation,
            addressInformation: [
              ...values.personalInformation.addressInformation,
              { apt: '', city: '', home: '', state: '', zipCode: '', id: createLocalAddressId() },
            ],
          },
        },
      };
    });
  };
  const handleRemoveAddress = (addressId: number) => {
    setFormikState((state) => ({
      ...state,
      values: {
        ...state.values,
        personalInformation: {
          ...state.values.personalInformation,
          addressInformation: state.values.personalInformation.addressInformation.filter(
            (a) => a.id !== addressId
          ),
        },
      },
    }));
  };
  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th className="d-flex gap">
            <div className="text-uppercase font-weight-md font-size-big">Address</div>
            <button type="button" className="link" onClick={handleAddAddress}>
              Add
            </button>
          </th>
          {['Street Address', 'Suite #', 'City', 'State', 'Zip Code', ''].map((t, idx) => (
            <th key={idx}>{t}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Primary address</td>
          <AddressTableRow formikFields={formikFields(0)} />
        </tr>
        {values.personalInformation.addressInformation.slice(1).map((e, idx) => (
          <tr key={e.id}>
            <td>Other address</td>
            <AddressTableRow formikFields={formikFields(idx + 1)} />
            <td className="d-flex">
              <div className="pt-1">
                <img
                  src={crossIcon}
                  height={10}
                  width={10}
                  className={`${styles['remove-address']} cursor-pointer`}
                  onClick={() => handleRemoveAddress(e.id)}
                />
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default AddressInformation;
