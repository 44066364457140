import StickyNotes from "types/ApiModels/TeamNotes/StickyNotes";

export enum StickyNotesActionType {
    SET_STICKY_NOTES = "SET_STICKY_NOTES",
    TOGGLE_SHOW_ADD_STICKY = "TOGGLE_SHOW_ADD_STICKY",
    ADD_STICKY_NOTES = "ADD_STICKY_NOTES"
}

export type StickyAction = 
| { type: StickyNotesActionType.SET_STICKY_NOTES;
    payload: { stickyNotes: StickyNotes[]};
  }
| { type: StickyNotesActionType.TOGGLE_SHOW_ADD_STICKY }
| { type: StickyNotesActionType.ADD_STICKY_NOTES, payload: { saveStickyNotes: () => void }}