import { Conversation, Message } from '@twilio/conversations';
import { useCallback, useEffect, useMemo, useReducer } from 'react';
import {
  addMessage,
  prependMessages,
  setMessageLoadingStatus,
  setMessages,
  setMessagesPaginator,
} from '../twilio-messages-action-creators';
import twilioMessagesReducer, { twilioMessagesInitialState } from '../twilio-messages-reducer';

export const useTwilioMessages = (conversation: Conversation) => {
  const [messagesState, dispatch] = useReducer(twilioMessagesReducer, twilioMessagesInitialState);
  useEffect(() => {
    if (!conversation) return;
    conversation.getMessages().then((paginator) => {
      dispatch(setMessagesPaginator(paginator));
      dispatch(setMessages(paginator.items));
    });
  }, [conversation]);

  useEffect(() => {
    if (!conversation) return;
    const onAddMessage = (message: Message) => {
      dispatch(addMessage(message));
    };
    conversation.on('messageAdded', onAddMessage);

    return () => {
      conversation.removeListener('messageAdded', onAddMessage);
    };
  }, [conversation]);

  const loadMoreMessages = useCallback(async () => {
    dispatch(setMessageLoadingStatus(true));
    try {
      const prevPaginator = messagesState.messagesPaginator.hasPrevPage
        ? await messagesState.messagesPaginator.prevPage()
        : null;
      if (!prevPaginator?.items?.length) return;
      dispatch(prependMessages(prevPaginator.items));
      dispatch(setMessagesPaginator(prevPaginator));
      return prevPaginator.items;
    } finally {
      dispatch(setMessageLoadingStatus(false));
    }
  }, [messagesState.messagesPaginator]);

  return useMemo(
    () => ({
      messagesState,
      /**
       * Fetch older messages if there are any else returns undefined. Prepends messages to state
       */
      loadMoreMessages,
    }),
    [loadMoreMessages, messagesState]
  );
};
