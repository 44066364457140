import { Client, Conversation } from '@twilio/conversations';
import { useEffect } from 'react';
import { getTokenForIdentity } from 'services/conversations-service';

/**
 * @deprecated use with discretion, I need this for testing purposes only
 */
export const deleteAllCreatedConversations = async (client: Client) => {
  let conversations: Conversation[];
  try {
    conversations = (await client.getSubscribedConversations()).items;
    console.debug({
      currentUserId: client.user.identity,
      conversationCreators: conversations.map((c) => c.createdBy),
    });
  } catch (error) {
    console.error(
      'error while trying to remove subscribed conversations',
      JSON.stringify(error.reason, undefined, 2)
    );
  }
  const allSettled = await Promise.allSettled(
    conversations.map(async (c) => {
      if (c.createdBy !== client.user.identity) return;
      await c.delete();
    })
  );
  console.debug(JSON.stringify({ allSettled }, undefined, 2));
};

/**
 *
 * @param client
 * @deprecated use with discretion, deletes all conversations created by the given client
 */
export const useDeleteAllCreatedConversations = (client) => {
  useEffect(() => {
    if (!client) return;
    deleteAllCreatedConversations(client);
  }, [client]);
};

export const leaveConversations = (client: Client, conversationSids: string[]) => {
  conversationSids.map(async (sid) => {
    const conversation = await client.getConversationBySid(sid);
    conversation.leave();
  });
};

export const getSingleConversationMetadata = async (c: Conversation) => {
  const [lastMessagePaginator, participants] = await Promise.all([
    c.getMessages(1),
    c.getParticipants(),
  ]);
  const unreadCount = (() => {
    if (c.lastReadMessageIndex === null && c.lastMessage) return c.lastMessage.index + 1;
    else if (!c.lastMessage) return 0;
    else return c.lastMessage.index - c.lastReadMessageIndex;
  })();
  return {
    lastMessage: lastMessagePaginator.items?.[0],
    participants,
    unreadCount,
  };
};

export const getConversationsMetadata = (conversations: Conversation[]) => {
  return Promise.allSettled(conversations.map(getSingleConversationMetadata));
};

export const impersonatePatient = async (identity: string) => {
  try {
    const [token, error] = await getTokenForIdentity(identity);
    const client = new Client(token.token);
    return client;
  } catch (error) {
    console.error('Error creating twilio client', error);
  }
};

/**
 * @deprecated used only for testing purposes
 */
export const impersonateSystem = async () => {
  try {
    const systemIdentity = 'c749cd8e-b0de-44bf-afd1-423433d9caa5';
    // const [token, error] = await getTokenForIdentity(systemIdentity);
    // const client = new Client(token.token);
    const client = new Client(
      'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsImN0eSI6InR3aWxpby1mcGE7dj0xIn0.eyJqdGkiOiJTS2E5NzVlNDY5YTlkOWRkZTU2ZmUyNWNjMDc3MGUzMDRhLTE2NTM1MDI5NjUiLCJncmFudHMiOnsiY2hhdCI6eyJzZXJ2aWNlX3NpZCI6IklTNzBlZmEyMDIxOTFmNDljY2JiNjhjODcyNjZiY2RiZGMifSwiaWRlbnRpdHkiOiJjNzQ5Y2Q4ZS1iMGRlLTQ0YmYtYWZkMS00MjM0MzNkOWNhYTUifSwiaXNzIjoiU0thOTc1ZTQ2OWE5ZDlkZGU1NmZlMjVjYzA3NzBlMzA0YSIsImV4cCI6MTY1MzU4OTM2NSwibmJmIjoxNjUzNTAyOTY1LCJzdWIiOiJBQzQzYzFkZGMwYjMyNTNjMzg5NzRlYTA4OTA3ZGRjMTMyIn0.XxSHR0lnujRttjaG6gdObY0UPKjqkkMDnmqbgdCWhHQ'
    );
    return client;
  } catch (error) {
    console.error('Error creating system twilio client', error);
  }
};

export const createSystemConversation = async (addIdentities: string[]) => {
  const client = await impersonateSystem();
  const conversation = await client.createConversation({ attributes: { testing: true } });
  const promises = addIdentities.map((id) => conversation.add(id));
  const result = await Promise.allSettled(promises);
  return conversation?.sid;
};
