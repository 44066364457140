import { Dispatch, useCallback, useEffect } from 'react';
import { getTrackingActivities } from 'services/trackingService';
import { useAdministrationContextV2 } from '../AdministrationContextV2';
import {
  PatientActivitiesAction,
  PatientActivitiesState,
  PatientActivitiesActionCreators,
} from '../patient-settings/patient-activities';

export const useAdminPatientActivityTemplates = (): [
  templates: PatientActivitiesState,
  dispatch: Dispatch<PatientActivitiesAction>
] => {
  const {
    administrationStateV2: { patientActivities },
    dispatch,
  } = useAdministrationContextV2();

  const fetchTemplates = useCallback(async () => {
    const res = await getTrackingActivities();
    res && dispatch(PatientActivitiesActionCreators.setTemplates(res));
  }, [dispatch]);

  useEffect(() => {
    if (!patientActivities.templates) {
      fetchTemplates();
    }
  }, [fetchTemplates, patientActivities.templates]);

  return [patientActivities, dispatch];
};
