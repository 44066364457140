import Button from 'components/Button';
import VyTracModal from 'components/Modal/VytracModal';
import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  archivePatient,
  deletePatient,
  enrollPatient,
  partialUpdateSinglePatient,
} from 'services/patientService';
import PatientGetSingle from 'types/ApiModels/Patients/PatientGetSingle';
import DeleteModal from './DeleteModal';
import styles from './styles.module.css';

interface IPatientStatusCard {
  patient: PatientGetSingle;
  setSelectedPatient: Dispatch<SetStateAction<PatientGetSingle>>;
}
const PatientStatusCard = ({ patient, setSelectedPatient }: IPatientStatusCard) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const { active, is_archived, has_mobile_app } = patient;

  const onArchivePatient = async () => {
    await archivePatient(patient.id);
    setSelectedPatient((prevPatient) => ({ ...prevPatient, is_archived: true }));
  };

  const onReactivatePatient = async () => {
    const updatedValues = {
      is_archived: false,
      is_active: true,
    };
    await partialUpdateSinglePatient(patient.id, updatedValues);
    setSelectedPatient({ ...patient, ...updatedValues });
  };

  const onEnrollPatient = async () => {
    await enrollPatient(patient.id);
    setSelectedPatient((prevPatient) => ({ ...prevPatient, is_active: true }));
  };

  const onSendInvitation = () => {
    console.log('send invitation functionality pending');
  };

  const onDeletePatient = async () => {
    await deletePatient(patient.id);
    setShowDeleteModal(false);
    console.log('define what should happen next');
  };

  return (
    <div className="dashboard-detail">
      <div className="dashboard-item-header">Status</div>
      {is_archived ? (
        <div className={styles.patientStatusBody}>
          <div className="d-flex">
            <div className="font-weight-md">Patient status</div>
            <div style={{ color: '#F5485C', paddingLeft: '30px' }}>Patient has been archived</div>
          </div>
          <Button
            label={'reactivate patient'}
            style={{ marginTop: '20px' }}
            variant="confirm"
            onClick={onReactivatePatient}
          />
        </div>
      ) : active && !is_archived ? (
        <div className={styles.patientStatusBody}>
          <div className="d-flex">
            <div>Patient status</div>
            <div style={{ color: '#74A851', paddingLeft: '30px' }}>Patient is active</div>
          </div>
          <Button
            label={'archive patient'}
            style={{ marginTop: '20px' }}
            variant="delete"
            onClick={onArchivePatient}
          />
        </div>
      ) : !active && !is_archived && !has_mobile_app ? (
        <div className={styles.patientStatusBody}>
          <div className="d-flex">
            <div>Patient status</div>
            <div style={{ color: '#F5485C', paddingLeft: '30px' }}>Patient is not enrolled</div>
          </div>
          <Button
            label={'enroll patient'}
            style={{ marginTop: '20px' }}
            onClick={onEnrollPatient}
          />
        </div>
      ) : !active && !is_archived && has_mobile_app ? (
        <div className={styles.patientStatusBody}>
          <div className="d-flex">
            <div>Patient status</div>
            <div style={{ color: '#8FAAC3', paddingLeft: '30px' }}>
              Patient created, invitation has not been sent
            </div>
          </div>
          <div className="d-flex justify-content-between" style={{ marginTop: '20px' }}>
            <Button
              label={'send invitation'}
              style={{ marginTop: '20px' }}
              onClick={onSendInvitation}
            />
            <Button
              label={'archive patient'}
              style={{ marginTop: '20px' }}
              variant="delete"
              onClick={onArchivePatient}
            />
            <Button
              label={'delete patient'}
              style={{ marginTop: '20px' }}
              variant="delete"
              onClick={() => setShowDeleteModal(true)}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      <DeleteModal
        name={patient.first_name}
        onClose={() => setShowDeleteModal(false)}
        onDelete={onDeletePatient}
        show={showDeleteModal}
      />
    </div>
  );
};
export default PatientStatusCard;
