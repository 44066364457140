import axios from 'axios';
import { clearTokens, getAccessToken, getRefreshToken, setToken } from 'services/tokenService';
import { logOut } from 'services/userService';
import { host, usersPath } from 'util/apiUtils';

export const vytracAxios = axios.create({
  baseURL: host,
});

export const authUserAxios = axios.create({
  baseURL: `${host}${usersPath}`,
});

export const fakeAuthUserAxios = axios.create({
  baseURL:''
});

const requestInterceptorConfig = (config) => {
  const token = getAccessToken().token;
  if (token) {
    console.log("gettokensession",getAccessToken().token)
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};
const requestInterceptorReject = (error) => {
  return Promise.reject(error);
};
const responseInterceptorConfig = (response) => {
  return response;
};
const responseInterceptorReject = (error) => {
  const originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest._retry) {
    logOut();
    window.location.href = "/";
  } else {
    return Promise.reject(error);
  }
};

vytracAxios.interceptors.request.use(requestInterceptorConfig, requestInterceptorReject);
vytracAxios.interceptors.response.use(responseInterceptorConfig, responseInterceptorReject);

authUserAxios.interceptors.request.use(requestInterceptorConfig, requestInterceptorReject);
authUserAxios.interceptors.response.use(responseInterceptorConfig, responseInterceptorReject);
