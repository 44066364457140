import { Conversation, Message, Paginator, Participant } from '@twilio/conversations';
import { TwilioConversation } from './conversations-reducer';

export enum ConversationActionTypes {
  UPSERT_CONVERSATION = 'UPSERT_CONVERSATION',
  //? not sure whether this is okay.. but I feel I'm messing up with the sorting of the conversations. They might have to be sorted by last message
  ADD_CONVERSATION = 'ADD_CONVERSATION',
  SET_CONVERSATIONS = 'SET_CONVERSATIONS',
  SET_LAST_MESSAGE = 'SET_LAST_MESSAGE',
  SET_PARTICIPANTS = 'SET_PARTICIPANTS',
  SET_UNREAD_COUNT = 'SET_UNREAD_COUNT',
  SET_CONVERSATIONS_PAGINATOR = 'SET_CONVERSATIONS_PAGINATOR',
  APPEND_CONVERSATIONS = 'APPEND_CONVERSATIONS',
  REMOVE_CONVERSATION = 'REMOVE_CONVERSATION',
}

export type ConversationAction =
  | {
      type: ConversationActionTypes.UPSERT_CONVERSATION;
      payload: { conversation: TwilioConversation };
    }
  | {
      type: ConversationActionTypes.ADD_CONVERSATION;
      payload: { conversation: TwilioConversation };
    }
  | {
      type: ConversationActionTypes.APPEND_CONVERSATIONS;
      payload: { conversations: TwilioConversation[] };
    }
  | {
      type: ConversationActionTypes.SET_LAST_MESSAGE;
      payload: { conversation: TwilioConversation };
    }
  | { type: ConversationActionTypes.SET_PARTICIPANTS; payload: { participants: Participant[] } }
  | {
      type: ConversationActionTypes.SET_CONVERSATIONS;
      payload: { conversations: TwilioConversation[] };
    }
  | {
      type: ConversationActionTypes.SET_UNREAD_COUNT;
      payload: { conversationSID: string; unreadCount: number };
    }
  | {
      type: ConversationActionTypes.SET_CONVERSATIONS_PAGINATOR;
      payload: { paginator: Paginator<Conversation> };
    }
  | {
      type: ConversationActionTypes.REMOVE_CONVERSATION;
      payload: { conversationSID: string };
    };
