import { REQUIRED_MESSAGE } from 'util/formikUtils';
import * as Yup from 'yup';
import 'yup-phone';
import AccountSettingsFormikKeys from '../types/AccountSettingsFormikKeys';
import { phoneNumberRegex, zipCodeRegex } from 'util/regexs';

const AccountSettingsValidationSchema = Yup.object().shape({
  [AccountSettingsFormikKeys.GENERAL_EMAIL]: Yup.string().nullable().required(REQUIRED_MESSAGE),
  [AccountSettingsFormikKeys.GENERAL_PHONE]: Yup.string().nullable().required(REQUIRED_MESSAGE).matches(phoneNumberRegex, 'Phone number must be in the format XXX-XXX-XXXX'),
  [AccountSettingsFormikKeys.TECH_SUPPORT_EMAIL]: Yup.string().nullable().required(REQUIRED_MESSAGE),
  [AccountSettingsFormikKeys.ADDRESS]: Yup.string().nullable().required(REQUIRED_MESSAGE),
  [AccountSettingsFormikKeys.IOS_PATIENT_APP]: Yup.string().nullable().required(REQUIRED_MESSAGE),
  [AccountSettingsFormikKeys.IOS_PROVIDER_APP]: Yup.string().nullable().required(REQUIRED_MESSAGE),
  [AccountSettingsFormikKeys.ANDROID_PATIENT_APP]: Yup.string().nullable().required(REQUIRED_MESSAGE),
  [AccountSettingsFormikKeys.ANDROID_PROVIDER_APP]: Yup.string().nullable().required(REQUIRED_MESSAGE),
  [AccountSettingsFormikKeys.PROVIDER_WEB_PORTAL]: Yup.string().nullable().required(REQUIRED_MESSAGE),
  [AccountSettingsFormikKeys.COUNTRY_CODE]: Yup.string().nullable(),
  [AccountSettingsFormikKeys.TIMEZONE]: Yup.string().nullable(),
  [AccountSettingsFormikKeys.BRAND_NAME]: Yup.string().nullable().required(REQUIRED_MESSAGE),
  //Daniel bypass form account
  //[AccountSettingsFormikKeys.BRAND_REWARDS_REDEEM_LINK]: Yup.string().nullable().required(REQUIRED_MESSAGE),
  //[AccountSettingsFormikKeys.BRAND_REWARDS_STORE_FEED]: Yup.string().nullable().required(REQUIRED_MESSAGE),

});

export default AccountSettingsValidationSchema;
