import DeleteIcon from 'assets/icons/delete.png';
import { useAuthContext } from 'auth/store/AuthContext';
import GenericButton from 'components/GenericButton';
import PermissionsGate from 'components/PermissionsGate/PermissionsGate';
import { FC } from 'react';
import { getCurrentPermissionBySection } from 'util/permissionUtils';

interface PopulationActionElementProps {
  id: number;
  handleDeletePopulation: (id: number) => void;
}

const PopulationActionElement: FC<PopulationActionElementProps> = ({
  id,
  handleDeletePopulation,
}: PopulationActionElementProps) => {

    const currentSection = 'ADMINISTRATION_patient_settings';
    let permissions = {};
    const { currentUser } = useAuthContext();
    permissions = currentUser.permissions;
    const permissionKey = getCurrentPermissionBySection(permissions, currentSection);
    
  return (
    <div className="d-flex">
      <PermissionsGate can={permissionKey[0].type}>
        <GenericButton icon={DeleteIcon} alt="delete" onClick={() => handleDeletePopulation(id)} />
      </PermissionsGate>
    </div>
  );
};
export default PopulationActionElement;
