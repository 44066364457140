import { getTheme, getThemeStorage, setTheme } from "services/administrationService";
import { setPortalScheme } from "services/userService";

export const setBreadcrumTitle = () => {
    const theme = getThemeStorage();
    if(theme?.brand_name)
        return`${theme.brand_name}`
    return `VyTrac`;
};

export const updateTheme = async () => {
    const newTheme = await getTheme();
    setTheme(newTheme);
    setPortalScheme(newTheme.brand_color_scheme);
    location.reload();
};