import { useAuthContext } from 'auth/store/AuthContext';
import PermissionsGate from 'components/PermissionsGate/PermissionsGate';
import { FC } from 'react';
import { Form as BootstrapForm } from 'react-bootstrap';
import { getCurrentPermissionBySection } from 'util/permissionUtils';

interface PopulationStatusElementProps {
  id: number;
  handleUpdateIsActivePopulation: (handler: { id: number, value: boolean }) => void;
  status: boolean;
}

const PopulationStatusElement: FC<PopulationStatusElementProps> = ({
  handleUpdateIsActivePopulation,
  id,
  status,
}: PopulationStatusElementProps) => {
    const currentSection = 'ADMINISTRATION_patient_settings';
    let permissions = {};
    const { currentUser } = useAuthContext();
    permissions = currentUser.permissions;
    const permissionKey = getCurrentPermissionBySection(permissions, currentSection);
    
  return (
    <div onClick={() => handleUpdateIsActivePopulation({ id, value: !status })}>
      <PermissionsGate can={permissionKey[0].type}>
        <BootstrapForm.Check type="switch" checked={status} readOnly />
      </PermissionsGate>
    </div>
  );
};
export default PopulationStatusElement;
