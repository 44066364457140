import { useEffect, useReducer, useRef } from 'react';
import IAPIWebSocketMessage, {
  AlertTypeEnum,
  IAPIAlertWebSocketMessage,
  WebSocketMessageType,
} from 'types/ApiModels/WebSocket/WebSocket';
import { hostName } from 'util/apiUtils';
import {
  alertsInitialState,
  receiveAdherenceAlert,
  receiveCriticalAlert,
  receiveQuestionnaireAlert,
  receiveRedAlert,
  receiveYellowAlert,
  WebSocketAlertAction,
} from './Alerts';
import WebSocketContext, { WebSocketState } from './WebSocketContext';
import webSocketReducer from './WebSocketReducer';

const initialValue: WebSocketState = {
  alerts: alertsInitialState,
  chatMessages: [],
};

const mapToVytalAlert = (alert: IAPIAlertWebSocketMessage) => ({
  patientId: alert.patient_id,
  vytalId: alert.vytal_id,
});

const mapToQuestionnaireAlert = (alert: IAPIAlertWebSocketMessage) => ({
  patientId: alert.patient_id,
  questionnaireId: alert.questionnaire_id,
});

const dispatchMessage = (
  messages: IAPIWebSocketMessage[],
  dispatch: React.Dispatch<WebSocketAlertAction>
) => {
  messages.forEach((data) => {
    switch (data.type) {
      case WebSocketMessageType.ALERT:
        const content: IAPIAlertWebSocketMessage = data.content as IAPIAlertWebSocketMessage;
        switch (content.alert_type) {
          case AlertTypeEnum.VYTAL:
            switch (content.type) {
              case 'yellow':
                dispatch(receiveYellowAlert(mapToVytalAlert(content)));
                break;
              case 'red':
                dispatch(receiveRedAlert(mapToVytalAlert(content)));
                break;
              case 'critical':
                dispatch(receiveCriticalAlert(mapToVytalAlert(content)));
                break;
            }
            break;
          case AlertTypeEnum.QUESTIONNAIRE:
            dispatch(receiveQuestionnaireAlert(mapToQuestionnaireAlert(content)));
            break;
          case AlertTypeEnum.ADHERENCE:
            dispatch(receiveAdherenceAlert({ patientId: content.patient_id }));
        }
    }
  });
};

const WebSocketProvider = ({ children }) => {
  const [state, dispatch] = useReducer(webSocketReducer, initialValue);
  const ws = useRef<WebSocket>(null);

  const handleReceiveMessage = (event: MessageEvent) => {
    try {
      const data: { type: WebSocketMessageType; content: string }[] = JSON.parse(event.data);
      const parsedData: IAPIWebSocketMessage[] = data.map((item) => ({
        type: item.type,
        content: JSON.parse(item.content),
      }));
      dispatchMessage(parsedData, dispatch);
    } catch (e) {
      console.error('Web socket connection: ', event.data);
    }
  };

  useEffect(() => {
    ws.current = new WebSocket(`wss://${hostName}/notifications/`);
    ws.current;
    ws.current.onmessage = handleReceiveMessage;

    const wsCurrent = ws.current;

    return () => {
      wsCurrent.close();
    };
  }, []);

  return (
    <WebSocketContext.Provider value={{ state, dispatch }}>{children}</WebSocketContext.Provider>
  );
};
export default WebSocketProvider;
