import { getIn } from 'formik';
import { setToken } from 'services/tokenService';
import { Auth2factor, login, logOut } from 'services/userService';
import { LoginFormValues, loginViews } from 'types/Login';
import routes from 'util/routes';
import { LOGIN_EMAIL_PASSWORD, LOGIN_2MFA } from '../../../../shared/global-constants';
import { useAuthContext } from 'auth';

export const predefinedOption = 'User predefined';

export function getStyles(errors, fieldName) {
  if (getIn(errors, fieldName)) {
    return {
      borderBottom: '1px solid red',
      color: 'red',
    };
  }
}

export async function onSubmit(
  values: LoginFormValues,
  setView,
  history,
  setLoginValues,
  setRequestErrorMsg?: (msg: string) => void,
  setFailTrials?: (trial: number) => void | any,
  failTrials?: number,
  type? : string,
  needLogin?: boolean
) {
  const email_address = values['email'];
  const password = values['password'];
  const code = values['code'];
  const timezone = values.timezone === predefinedOption ? undefined : values.timezone;
  let data;

  try {
    type === LOGIN_EMAIL_PASSWORD
      ? data = await login({ email_address , password, code, timezone })
      : data = await Auth2factor({ email_address, code, timezone });

    if (data?.error) {
      if ([401, 403].includes(data?.error)) {
        if (type === LOGIN_EMAIL_PASSWORD) setRequestErrorMsg("The email or password are invalid");
        if (type === LOGIN_2MFA) setRequestErrorMsg("The OTP code is invalid");

        return false;
      }

      if ([500].includes(data?.error)) {
        setRequestErrorMsg("Application error");
        return false;
      }
    }
    

    if(needLogin) {
      history.push('/')
      logOut();
      return;
    }

    if (data.access || data.token) {
      setToken(data, values.rememberMe);
      history.push(routes[8].path);
    } else {
      setLoginValues({ ...values, emailToMessage: data.message });
      setView(loginViews.token);
    }

    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}