import { useAuthContext } from 'auth';
import VytracModal from 'components/Modal';
import { useCallback, useState } from 'react';
import { toggleNewConversationModal } from 'screens/messages/store/messages-action-creators';
import { useMessagesContext } from 'screens/messages/store/messages-context';
import { SendMessageModalBody } from 'screens/Patients/Content/components/PatientTabs/components/PatientMessages/PatientMessages';
import PatientGet from 'types/ApiModels/Patients/PatientGet';
import { PatientList } from './patient-list';

const NewDiscussionModalBody = ({ onClose, setSelectedChat }) => {
  const { currentUser } = useAuthContext();
  const [currentSelectedPatient, setcurrentSelectedPatient] = useState<PatientGet>(null);

  const handlePatientSelect = (patients: PatientGet[]) => {
    const [patient] = patients;
    setcurrentSelectedPatient(patient);
  };
  return (
    <section aria-label="modal body" className="d-flex flex-column gap">
      <PatientList onPatientSelect={handlePatientSelect} />
      {/* {currentSelectedPatient ? (
        <SendMessageModalBody
          handleClose={onClose}
          patientId={currentSelectedPatient.id}
          patientIdentity={currentSelectedPatient.user?.twilio_identity}
          providerIdentity={currentUser?.twilio_identity}
          providerUserId={currentUser?.id}
          setSelectedChat={setSelectedChat}
        />
      ) : null} */}
    </section>
  );
};
const NewDiscussionModal = ({ setSelectedChat }: { setSelectedChat: (sid: string) => void }) => {
  const {
    dispatch,
    state: { newConversationModal },
  } = useMessagesContext();

  const handleClose = useCallback(() => dispatch(toggleNewConversationModal()), [dispatch]);

  return (
    <VytracModal
      backdrop={false}
      title="Send a message to a patient"
      body={<NewDiscussionModalBody onClose={handleClose} setSelectedChat={setSelectedChat} />}
      onClose={handleClose}
      show={newConversationModal}
    />
  );
};
export default NewDiscussionModal;
