import { useEffect, useReducer, useState } from 'react';
import Card from 'components/Card';
import StickyNote from 'components/StickyNote';
import { setStickyNotes, stickyNotesInitialState, stickyNotesReducer } from '../store/sticky-notes';
import StickyNotesCardHeader from './sticky-notes-card-header';
import styles from './styles.module.css';
import { ShowAddStickyNote, ShowEditStickyNote } from '../store/sticky-notes/sticky-notes-action-creators';
import AddNewStickyNotesModal from './Modals/AddNewStickyNotes';
import { createStickyNote, editStickyNote, getTeamNotes } from 'services/patientService';
import EditStickyNotesModal from './Modals/EditStickyNotes';
import { useAuthContext } from 'auth/store/AuthContext';
import { getCurrentPermissionBySection } from 'util/permissionUtils';

export const editStickyNotes = { 
  id: 0,
  text: '',
};

const TeamNotesStickyNotes = ({ stickyNotes: stickyNotesProp, selectedPatientId }) => {
  const [{ stickyNotes, showAddStickyNotes, showEditStickyNotes }, dispatch] = useReducer(stickyNotesReducer, stickyNotesInitialState);
  const [idNote, setIdNote] = useState(false);
 
  const [{id, text}, setNote] = useState(editStickyNotes)
  const handleAddStickyNotes = async (values) => {
    const newNote = { patient_id: selectedPatientId, text: values.name };
    await createStickyNote(newNote); 
    const response = await getTeamNotes(selectedPatientId);
    dispatch(setStickyNotes(response.stickyNotes));
    
  }

  const handleEditStickyNotes = async (values) => {
    const newNote = { patient_id: selectedPatientId, text: values.name };
    await editStickyNote(newNote, id);
    const response = await getTeamNotes(selectedPatientId);
    dispatch(setStickyNotes(response.stickyNotes));
  }
  const hanldeShowEditStickyNotes = async (values, text) => {
    const dato = {id: values, text: text}
    setNote(dato)
    setIdNote(values)

    dispatch(ShowEditStickyNote())
  }

  useEffect(() => {
    dispatch(setStickyNotes(stickyNotesProp));
  }, [stickyNotesProp]);

  const currentSection = 'PATIENTS_patient_status';
  let permissions = {};
  const { currentUser } = useAuthContext();
  permissions = currentUser.permissions;
  const permissionKey = getCurrentPermissionBySection(permissions, currentSection);
  
  return permissionKey[0].type ==  'manage' ? (
    <Card
      className="card-bg-border w-100"
      bodyClassName={`${styles['sticky-note-card']}`}
      headers={[<StickyNotesCardHeader stickyNotesLength={stickyNotes.length} handleShowModal={() => dispatch(ShowAddStickyNote())} />]}
    >
      <AddNewStickyNotesModal
        show={showAddStickyNotes}
        onClose={() => dispatch(ShowAddStickyNote())}
        handleSubmit={handleAddStickyNotes}
        edit={false}
        text={''}
      />

      <EditStickyNotesModal
        show={showEditStickyNotes}
        onClose={() => dispatch(ShowEditStickyNote())}
        handleSubmit={handleEditStickyNotes}
        text={text}
      />
      {stickyNotes.length ? (
        stickyNotes.map(({ text, timestamp, id }) => (
          <StickyNote
            handleEditNote={hanldeShowEditStickyNotes}
            id={id}
            key={id}
            title={''}
            content={text}
            notificationDate={new Date(timestamp)}
          />
        ))
      ) : (
        <p className=" text-center font-italic">
          There are no sticky notes right now. Start adding some!
        </p>
      )}
    </Card>
  ) :
  <Card
      className="card-bg-border w-100"
      bodyClassName={`${styles['sticky-note-card']}`}
      headers={[<StickyNotesCardHeader stickyNotesLength={stickyNotes.length} handleShowModal={() => dispatch(ShowAddStickyNote())} />]}
    >

      {stickyNotes.length ? (
        stickyNotes.map(({ text, timestamp, id }) => (
          <StickyNote
            handleEditNote={null}
            id={id}
            key={id}
            title={''}
            content={text}
            notificationDate={new Date(timestamp)}
          />
        ))
      ) : (
        <p className=" text-center font-italic">
          There are no sticky notes right now. Start adding some!
        </p>
      )}
    </Card>
};
export default TeamNotesStickyNotes;
