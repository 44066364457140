import { invitePatient } from 'services/administrationService';
import { getTemplateCarePlanList } from 'services/templatesService';
import { addActionsToCarePlan } from 'services/userService';
import InvitePatient from 'types/ApiModels/Administration/InvitePatient';
import { Allergy, FamilyHistory } from 'types/ApiModels/Patients/Patient';
import { FamilyRelationship } from 'types/ApiModels/Patients/PatientEnums';

export const onSubmit = async (values: InvitePatient) => {
  console.log("Daniel estos son los datos del formik",values)
  const {
    populationsData,
    email,
    careTeam: care_team,
    firstName: first_name,
    middleName: middle_name,
    lastName: last_name,
    phoneNumber:phone_number,
    sexAssignedAtBirth: sex,
    populations,
    surgeries: formikSurgeries,
    allergies: formikAllergies,
    familyHistory: formikFamilyHistory,
    contactInformation: { primaryPhone: primary_phone, secondaryPhone: secondary_phone },
    address: addresses,
    demographicInfo: {
      genderIdentity: gender,
      maritalStatus: marital,
      race,
      ethnicity,
      language,
      religion,
    },
    insurance: formikInsurance,
    emergencyContacts,
    careChampions,
    healthScoring: { redAlert: missed_calls_red_alerts, yellowAlert: missed_calls_yellow_alerts },
    isAppUser,
  } = values;
  const date_birth = `${values.dateOfBirth.year}-${
    values.dateOfBirth.month < 10 ? '0' + values.dateOfBirth.month : values.dateOfBirth.month
  }-${values.dateOfBirth.day}`;
  const emergency_contacts = emergencyContacts.map((e) => ({
    first_name: e.firstName,
    last_name: e.lastName,
    relationship: e.relationship,
    phone_number: e.contactPhone,
    second_phone_number: e.secondaryPhone,
  }));
  const care_champions = careChampions.map((e) => ({
    full_name: e.fullName,
    phone_number: e.personalPhoneNumber,
    secondary_phone: e.secondaryPhoneNumber,
    email: e.email,
    relationship: e.relationship,
  }));
  const insurance = formikInsurance.map((e, idx) => ({
    insurance: e.insuranceId,
    is_main: idx === 0,
    suscriber_number: e.subscriberNumber,
    group_number: e.groupNumber,
  }));

  const surgeries = formikSurgeries.map((e) => ({
    name: e.name,
    details: e.details,
    date: `${e.date.year}-${e.date.month < 10 ? '0' + e.date.month : e.date.month}-${e.date.day}`,
  }));

  const family_history = formikFamilyHistory.map<PartialBy<FamilyHistory, 'id'>>((fh) => ({
    details: fh.details,
    name: fh.medicalCondition,
    relationship: fh.relationToPatient as FamilyRelationship,
    patient: undefined,
    deleted: undefined,
  }));

  const allergies = formikAllergies.map<Allergy>((a) => ({
    details: a.details,
    id: undefined,
    name: a.name,
    patient: undefined,
    deleted: undefined,
  }));

  const data = {
    email,
    type: 'patient',
    care_team,
    first_name,
    middle_name,
    last_name,
    phone_number,
    date_birth,
    sex,
    populations,
    surgeries,
    alergies: allergies,
    family_history,
    primary_phone,
    secondary_phone,
    addresses,
    gender,
    marital,
    race,
    ethnicity,
    language,
    religion,
    emergency_contacts,
    care_champions,
    insurance,
    missed_calls_red_alerts,
    missed_calls_yellow_alerts,
    is_app_user: isAppUser,
  };
  //let actions = populationsData;

  // if (actions.length == 0) {
  //   const templates = await getTemplateCarePlanList();
  //   const template = templates.find((t) => t.id === populations[0]);
  //   actions =
  //     template?.actions?.map((a) => ({
  //       ...a,
  //       template: template.id,
  //       is_part_of_rpm_plan: true,
  //       team_note: null,
  //       my_schedule_note: null,
  //       patient_message: null,
  //       recurrence: null,
  //     })) ?? [];
  // }

  Object.keys(data).forEach((key) => {
    if (data[key] === null || data[key] === undefined || data[key] === '' || data[key] === -1) {
      delete data[key];
    }
    if(data[key] === 'phone_number'){
      data['phone_number'].replace(/\)/g, '').replace(/\(/g, '').replace(/-/g, '').replace(/ /g, '').replace (/^/,'+1')
    }
  });

  // actions.forEach((action) => {
  //   Object.keys(action).forEach((key) => {
  //     if (key === 'recurrence' && !action[key]) {
  //       delete action[key];
  //     }
  //   });
  // });

  console.log("Daniel estos son los datos que se enviaran al back",data)

  const response = await invitePatient(data);

  const { patient_id } = response;

  //await addActionsToCarePlan(patient_id, actions);
};