export const formatMessageTimeStamp = (createdAt: Date) => {
  const hourCreatedFormatted = Intl.DateTimeFormat('en-US', {
    hour12: true,
    hour: '2-digit',
    minute: '2-digit',
  }).format(createdAt);
  const dateCreatedFormatted = Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: '2-digit',
    year: '2-digit',
  }).format(createdAt);

  return `${hourCreatedFormatted} - ${dateCreatedFormatted}`;
};
