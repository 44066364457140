import PatientRingLink from 'components/patient-ring-link';
import { KeywordTemplateCount, PatientNotesKeywords } from 'types/ApiModels/Administration';
import AvatarStatus from 'types/ApiModels/Patients/AvatarStatus';
import GenericColumn from 'types/Shared/GenericColumn';
import PatientKeywordsCountCell from '../patient-keywords-count-cell';

interface PatientNotesKeywordsRow {
  patient: {
    status: AvatarStatus;
    full_name: string;
    id: number;
    photo: string;
  };
  keywordsCount: number;
  keywordTemplates: KeywordTemplateCount[];
}

export const getPatientNotesKeywordsColumns = ({ handleKeywordCountClick }): GenericColumn[] => [
  {
    key: 'patient',
    title: 'patient',
    render: (row: PatientNotesKeywordsRow) =>
      PatientRingLink({
        avatar_status: row.patient.status,
        full_name: row.patient.full_name,
        patientId: row.patient.id,
        photo: row.patient.photo,
      }),
    headerClasses: 'flex-grow-1',
    tdClassName: 'flex-grow-1',
  },
  {
    key: 'keywordsCount',
    title: 'Keywords used',
    render: (row: PatientNotesKeywordsRow) =>
      PatientKeywordsCountCell({
        value: row.keywordsCount,
        onKeywordCountClick: handleKeywordCountClick(row.patient.id),
      }),
    cellTextAlign: 'center',
    headerClasses: 'flex-basis-20',
    tdClassName: 'flex-basis-20',
  },
];

export const mapPatientNotesKeywordsToRow = (
  pnk: PatientNotesKeywords
): PatientNotesKeywordsRow => ({
  patient: {
    full_name: pnk.full_name,
    id: pnk.id,
    photo: pnk.photo,
    status: pnk.status,
  },
  keywordsCount: pnk.keywords_templates_count,
  keywordTemplates: pnk.keywords_templates,
});
