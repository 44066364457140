import { FC, useEffect, useMemo, useState } from 'react';
import { checkInvitation } from 'services/userService';
import Invitation from 'types/ApiModels/Users/Invitation';
import { useQueryParams } from 'hooks';
import CheckingInvitation from './CheckingInvitation';
import InvalidInvitationFallback from './InvalidInvitationFallback';
import { RegisterContext } from './store';
import { InvitationStatus } from './types';
import { mapInvitationTypeToComponent } from './utils';
import ValidateEmailAndLastName from 'screens/RegisterProvider/ValidateEmailAndLastName';
import { type } from 'os';

const Register: FC = () => {
  const [email, setEmail] = useState<string>();
  const [hash, setHash] = useState<string>();
  const [invitationStatus, setInvitationStatus] = useState(InvitationStatus.PENDING);
  const [invitation, setInvitation] = useState<any>();
  const [providerOrPatient, setProviderOrPatient] = useState(false);


  const query = useQueryParams();

  useEffect(() => {
    const fetchCheckInvitation = async () => {
      //edge case: email has + symbol
      // const email = query.get('email').replace(/ /g, '+');
      const hash = query.get('hash');
      
      if (hash) {
        try {
          const { data } = await checkInvitation(email, hash);
          setHash(hash);
          setInvitation(data);
          setInvitationStatus(InvitationStatus.VALID);
        } catch (e) {
          console.error(e);
          setInvitationStatus(InvitationStatus.INVALID);
        }
      } else {
        setInvitationStatus(InvitationStatus.INVALID);
      }
    };
    fetchCheckInvitation();
  }, [query]);

  const CurrentRegisterPage = useMemo(() => {
    switch (invitationStatus) {
      case InvitationStatus.PENDING:
        return <CheckingInvitation/>;
      case InvitationStatus.INVALID:
        return <InvalidInvitationFallback/>; 
      case InvitationStatus.VALID:
        return  <ValidateEmailAndLastName invitation={invitation.type}/>
      default:
        break;
    }
  }, [invitation, invitationStatus]);

  return (
    <RegisterContext.Provider value={{ email, hash }}>
      {CurrentRegisterPage && CurrentRegisterPage}
    </RegisterContext.Provider>
  );
};
export default Register;
