import DatePicker from '@amir04lm26/react-modern-calendar-date-picker';
import { DayRange } from '@amir04lm26/react-modern-calendar-date-picker';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { ReactComponent as DownArrowIconSrc } from 'assets/icons/arrow_down.svg';
import { formatDottedDate } from '../../util/dateUtils';

interface IDateRangePicker {
  currentDateRange: DayRange;
  handleDateRangePickerChange?(value: DayRange): void;
  wrapperClassName?: string;
  calendarClassName?: string;
  inputClassName?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>,
}

const DateRangePicker = ({
  currentDateRange,
  handleDateRangePickerChange,
  wrapperClassName = '',
  calendarClassName = '',
  inputClassName = '',
  onClick = (e) => null,
}: IDateRangePicker) => {
  if (!currentDateRange) return <></>;


  // NOTE: Originally ref was set directly to {ref} but the types were not correct. 
  // I had to cast it to React.RefObject<HTMLInputElement>
  return (
    <DatePicker
      value={currentDateRange}
      onChange={handleDateRangePickerChange}
      wrapperClassName={wrapperClassName || ''}
      calendarClassName={calendarClassName || ''}
      calendarPopperPosition="bottom"
      colorPrimary="#393E48"
      renderInput={({ ref }) => (
        <div
          className={`d-flex align-items-center gap-sm ${inputClassName || ''}`}
          onClick={onClick}
        >
          <input
            readOnly
            className={'date-filter-input text-right'}
            ref={ref as React.RefObject<HTMLInputElement>}
            size={20}
            value={`${
              currentDateRange.from
                ? formatDottedDate(
                    new Date(
                      currentDateRange.from.year,
                      currentDateRange.from.month - 1,
                      currentDateRange.from.day
                    )
                  )
                : '-'
            } to ${
              currentDateRange.to
                ? formatDottedDate(
                    new Date(
                      currentDateRange.to.year,
                      currentDateRange.to.month - 1,
                      currentDateRange.to.day
                    )
                  )
                : '-'
            }`}
          />
          <DownArrowIconSrc
            fill="#8FAAC3"
            onClick={() => {
              ref.current.focus();
            }}
          />
        </div>
      )}
    />
  );
};

export default DateRangePicker;
