import DragDrop from 'components/Icons/DragDrop';
import ProviderTierItem from 'components/ProviderTierItem';
import vytracSelectStyle from 'components/VytracStyledSelect/vytracSelectStyle';
import { getIn, useFormikContext } from 'formik';
import { FC, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import AsyncSelect from 'react-select/async';
import { searchProviders } from 'services/providersService';
import colors from 'styles/colors';
import CareTeamTier from 'types/ApiModels/CareTeam/CareTeamTier';
import { Provider } from 'types/ApiModels/Providers/Provider';
import { getUserDisplayName } from 'util/userUtils';
import { debouncePromiseValue } from 'util/utils';
import { CareTeamCreateEditFormikFields } from '../../types';
import { mapProviderToCareTeamProvider } from '../../utils';
import styles from '../styles.module.css';
import { isInTier } from '../utils';

import ProviderTierListValidationError from './ProviderTierListValidationError';

interface ProviderTierListProps {
  fieldName: string;
  maxProviders?: number;
}

const ProviderTierList: FC<ProviderTierListProps> = ({
  fieldName,
  maxProviders,
}: ProviderTierListProps) => {
  const [selectedProvider, setSelectedProvider] = useState(null);
  const { setFieldValue, values } = useFormikContext<CareTeamCreateEditFormikFields>();

  const currentTier: CareTeamTier = useMemo(() => {
    return getIn(values, fieldName);
  }, [fieldName, values]);

  const handleDeleteProvider = (providerId: number) => {
    const newProviders = currentTier.providers.filter((p) => p.id !== providerId);
    setFieldValue(`${fieldName}.providers`, newProviders);
  };

  const loadProviderOptions: (string) => Promise<{ value: number; label: string; provider: Provider; disabled: boolean; }[]> = debouncePromiseValue(async (providerSearch: string) => {
    const options = await searchProviders(providerSearch);
    return options.results.map((p) => {
      const providerWasPickedInAnotherTier = isInTier(p.id, values.tiers);
      return {
        value: p.id,
        label: (
          <div
            className={
              providerWasPickedInAnotherTier || p.care_team ? styles['disabled-option-label'] : ''
            }
          >
            <p className="m-0 p-0">{getUserDisplayName(p)}</p>
            {providerWasPickedInAnotherTier ? (
              <b>(chosen in another tier)</b>
            ) : p.care_team ? (
              <b>(already member of another care team)</b>
            ) : (
              ''
            )}
          </div>
        ),
        provider: p,
        //If it has been selected already in any other tier we don't want to make that option available
        // We don't want to make it available if the provider has already a care team either
        disabled: providerWasPickedInAnotherTier || p.care_team,
      };
    });
  }, 500);

  const handleDragEnd = (result: DropResult) => {
    const newProvidersOrder = [...currentTier.providers];
    const [deleted] = newProvidersOrder.splice(result.source.index, 1);
    newProvidersOrder.splice(result.destination.index, 0, deleted);
    setFieldValue(`${fieldName}.providers`, newProvidersOrder);
  };
  return (
    <div className="d-flex flex-column ">
      {/** Tier list only show if there are any providers */}
      {currentTier != null ? (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId={`tier-providers-${fieldName}`}>
            {(droppableProvided) => (
              <div
                className="d-flex flex-column"
                {...droppableProvided.droppableProps}
                ref={droppableProvided.innerRef}
              >
                {currentTier.providers.map((p, idx) => (
                  <Draggable draggableId={p.id.toString()} index={idx} key={p.id}>
                    {(draggableProvided) => (
                      <div
                        className={`${styles['item-container']} d-flex justify-content-between m-1 align-items-center`}
                        {...draggableProvided.draggableProps}
                        ref={draggableProvided.innerRef}
                      >
                        <div
                          className={`${styles['item-container']} ${styles['flex-basis-drag-drop']} d-flex align-items-center`}
                        >
                          <div
                            className="d-flex justify-content-center align-items-center"
                            {...draggableProvided.dragHandleProps}
                          >
                            <DragDrop color={colors.lowRiskAlerts} height="10.25" width="6.25" />
                          </div>
                        </div>
                        <ProviderTierItem
                          handleDeleteProvider={() => handleDeleteProvider(p.id)}
                          provider={p}
                          key={p.id}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : null}
      <ProviderTierListValidationError name={`${fieldName}.providers`} />
      {getIn(values, `${fieldName}.providers`) != null ?
        maxProviders && getIn(values, `${fieldName}.providers`).length >= maxProviders ? null : (
          <div className={`${styles['item-container']} m-1`}>
            <AsyncSelect<{ value: number; label: string; provider: Provider; disabled: boolean }>
              styles={vytracSelectStyle}
              loadOptions={loadProviderOptions}
              noOptionsMessage={({ inputValue }) => {
                if (!inputValue) {
                  return 'Search providers by typing';
                }
                return 'No providers matched your search';
              }}
              placeholder="Search providers..."
              className="font-size-medium font-weight-md m-1 "
              isOptionDisabled={(o) => o.disabled}
              value={selectedProvider}
              onChange={(value) => {
                setFieldValue(`${fieldName}.providers`, [
                  ...currentTier.providers,
                  mapProviderToCareTeamProvider(value.provider),
                ]);
                setSelectedProvider(null);
              }}
            />
          </div>
        )
      :null}
      
    </div>
  );
};
export default ProviderTierList;
