import { JSONValue } from '@twilio/conversations';
import TeamNotesMessageAttributes from '../types/team-notes-message-attributes';

export const getMessageAttributes = (genericAttributes: JSONValue): TeamNotesMessageAttributes => {
  const messageAttributes: TeamNotesMessageAttributes = {
    alert: undefined,
    isThread: undefined,
    mentions: undefined,
  };
  if (typeof genericAttributes === 'object') {
    messageAttributes.mentions = genericAttributes?.['mentions'];
    messageAttributes.alert = genericAttributes?.['alert'];
    messageAttributes.isThread = genericAttributes?.['isThread'];
  }
  return messageAttributes;
};
