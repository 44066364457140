import { vytracAxios } from 'ajax';
import { AxiosError } from 'axios';
import { TrackingActivityTemplate } from 'types/ApiModels/Administration';
import { PaginatedResponse } from 'types/ApiModels/General';
import { ITrackingLog } from 'types/ApiModels/Tracking/TrackingLog';

const TRACKING_URI = 'tracking';

export const getTrackingActivities = async () => {
  try {
    const res = await vytracAxios.get<TrackingActivityTemplate[]>(`${TRACKING_URI}/activities/`);
    return res.data;
  } catch (error) {
    console.error(error);
  }
};

export const createTrackingActivityTemplateBulk = async (
  activityTemplates: Omit<TrackingActivityTemplate, 'id'>[]
): Promise<[res: TrackingActivityTemplate[], error: AxiosError]> => {
  if (!activityTemplates.length) return [null, null];
  try {
    const res = await vytracAxios.post<TrackingActivityTemplate[]>(
      `${TRACKING_URI}/activities/`,
      activityTemplates
    );
    return [res.data, null];
  } catch (error) {
    return [null, error];
  }
};

export const updateTrackingActivityTemplatesBulk = async (
  activityTemplates: TrackingActivityTemplate[]
) => {
  if (!activityTemplates.length) return [null, null];
  try {
    const res = await vytracAxios.put<TrackingActivityTemplate[]>(
      `${TRACKING_URI}/activities/`,
      activityTemplates
    );
    return [res.data, null];
  } catch (error) {
    return [null, error];
  }
};

export const patchTrackingActivityTemplatesBulk = async (
  activityTemplates: AtLeast<TrackingActivityTemplate, 'id'>[]
) => {
  if (!activityTemplates.length) return [null, null];
  try {
    const res = await vytracAxios.patch<TrackingActivityTemplate[]>(
      `${TRACKING_URI}/activities/`,
      activityTemplates
    );
    return [res.data, null];
  } catch (error) {
    return [null, error];
  }
};

export const deleteTrackingActivityTemplateBulk = async (activityIds: { id: number }[]) => {
  if (!activityIds.length) return [null, null];
  try {
    const res = await vytracAxios.delete(`${TRACKING_URI}/activities/`, { data: activityIds });
    return [res.data, null];
  } catch (error) {
    return [null, error];
  }
};

export const getTrackingLogs = async () => {
  try {
    const response = await vytracAxios.get<PaginatedResponse<ITrackingLog>>('tracking/');
    return response.data.results;
  } catch (exception) {
    throw exception;
  }
};

export const createTrackingLog = async (log: ITrackingLog) => {
  try {
    const response = await vytracAxios.post<ITrackingLog>('tracking/', log);
    return response.data;
  } catch (exception) {
    throw exception;
  }
};
