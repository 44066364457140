import { useAuthContext } from 'auth/store/AuthContext';
import Button from 'components/Button';
import Card from 'components/Card';
import PermissionsGate from 'components/PermissionsGate/PermissionsGate';
import SaveChangesConfirmationModal from 'components/SaveChangesConfirmationModal';
import { Form, Formik } from 'formik';
import { differenceWith, isEqual } from 'lodash';
import { useMemo, useRef, useState } from 'react';
import {
  useAdministrationContext,
  useAdministrationContextV2,
  useAdminRoles,
} from 'screens/Administration/store';
import { upsertAdminRoleBulk } from 'screens/Administration/store/Users/adminUsersActionCreators';
import {
  createRoleBulk,
  mapRolePermissionsToRole,
  mapRoleToRolePermissions,
  updateRoleBulk,
} from 'services/administrationService';
import { PermissionKeys } from 'types/ApiModels/Administration';
import RolePermissions from 'types/Roles/RolePermissions';
import { getCurrentPermissionBySection } from 'util/permissionUtils';
import TableBody from './TableBody';
import TableHeader from './TableHeader';
import validationSchema from './util/validationSchema';

export const RolesActions = () => {
  const { state } = useAdministrationContext();
  const [ShowConfirmModal, setShowConfirmModal] = useState(false);

  const currentSection = 'ADMINISTRATION_roles';
  let permissions = {};
  const { currentUser } = useAuthContext();
  permissions = currentUser.permissions;
  const permissionKey = getCurrentPermissionBySection(permissions, currentSection);

  return (
    <div className="d-flex">
      {/* <PermissionsGate can={[permissionKey[0].type]}> */}
        <Button
          variant="cancel"
          label="Cancel"
          className="mr-2"
          onClick={() => state.roles?.resetForm()}
          disabled={!state.roles?.hasUnsavedChanges}
        />
        <Button
          variant="confirm"
          label="Save Changes"
          className="mr-2"
          disabled={!state.roles?.hasUnsavedChanges}
          onClick={() => setShowConfirmModal(true)}
        />
        <Button label="Add new role" onClick={() => state.roles?.addRow()} />
        {ShowConfirmModal ? (
          <SaveChangesConfirmationModal
            onCancel={() => setShowConfirmModal(false)}
            show={ShowConfirmModal}
            onConfirm={() => {
              state.roles?.submitForm();
              setShowConfirmModal(false);
            }}
          />
        ) : null}
      {/* </PermissionsGate> */}
    </div>
  );
};

const Roles = () => {
  const formRef = useRef();
  const { dispatch } = useAdministrationContextV2();
  const { state } = useAdministrationContext();
  const { roles } = useAdminRoles();

  const roleList = useMemo(() => {
    return roles?.map(mapRoleToRolePermissions) ?? [];
  }, [roles]);

  const initialValues: { roles: RolePermissions[] } = {
    roles: roleList,
  };

  return (
    <div className="p-3">
      <Card
        headers={[<TableHeader key={0} title="Global Access Roles" />]}
        bodyClassName="bg-white p-0"
        className="bg-white"
      >
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm }) => {
            try {
              const toUpdateRoles = differenceWith(values.roles, roleList, isEqual).filter(
                (role) => role.id
              );
              const newRoles = values.roles.filter((role) => !role.id);

              await updateRoleBulk(toUpdateRoles);
              await createRoleBulk(newRoles);
              dispatch(upsertAdminRoleBulk(values.roles.map(mapRolePermissionsToRole)));
              resetForm();
            } catch (e) {
              console.error(e);
            }
          }}
          innerRef={formRef}
        >
          <Form>
            <TableBody formikRef={formRef} />
          </Form>
        </Formik>
      </Card>
    </div>
  );
};

export default Roles;
