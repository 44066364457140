import ListItem from 'components/ListItem';
import VytracModal from 'components/Modal';
import { Tag } from 'components/Tagitem';
import TagList from 'components/TagList';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAdminCareTeams } from 'screens/Administration/store/hooks';
import { CareTeam, CareTeamProvider } from 'types/ApiModels/CareTeam';
import { Provider } from 'types/ApiModels/Providers/Provider';
import { findInBook } from 'util/bookUtils';
import styles from '../styles.module.css';

interface CareTeamProviderModalProps {
  onClose: () => void;
  show: boolean;
}

//TODO: should come from reducer state
const currentCareTeam = { name: 'Dream team' };

const CareTeamProviderModal = ({ onClose, show }: CareTeamProviderModalProps) => {
  const history = useHistory();
  const [{ currentCareTeamId, careTeamsBook }] = useAdminCareTeams();

  const providersInCareTeam: CareTeamProvider[] = useMemo(() => {
    if (!careTeamsBook || !currentCareTeamId) return;
    const [, result] = findInBook(careTeamsBook, currentCareTeamId);
    const typedResult = result as CareTeam;
    const flat = typedResult.tiers.flatMap((t) => {
      return t.providers;
    });
    return flat;
  }, [currentCareTeamId, careTeamsBook]);

  return (
    <VytracModal
      backdrop={false}
      title={`Providers assigned to this team: ${currentCareTeam?.name}`}
      headerClassName="font-size-big font-weight-md m-0"
      body={
        <div className={`d-flex flex-column gap ${styles['modal-content-container']}`}>
          {providersInCareTeam?.map((ps) => {
            return (
              <div
                className={`d-flex justify-content-between rounded ${styles['modal-content-item']}`}
                key={ps.id}
              >
                <ListItem
                  user={{
                    first_name: ps.first_name,
                    last_name: ps.last_name,
                    username: null,
                    imageUrl: ps.photo_thumbnail,
                  }}
                  onClick={() => history.push(`/users/v2/${ps.id}`)}
                />
                <div>
                  <TagList
                    tagList={ps.specialities?.map<Tag>((s) => ({ text: s.name })) ?? []}
                    maxCount={1}
                  />
                </div>
              </div>
            );
          })}
        </div>
      }
      footer={undefined}
      onClose={onClose}
      show={show}
      centered
    />
  );
};
export default CareTeamProviderModal;
