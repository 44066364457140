import { useAuthContext } from 'auth/store/AuthContext';
import Button from 'components/Button';
import PermissionsGate from 'components/PermissionsGate/PermissionsGate';
import { useAdminPatientActivities } from 'screens/Administration/store/hooks';
import { getCurrentPermissionBySection } from 'util/permissionUtils';

const ActivitiesActions = () => {
  const [
    {
      createdTemplates: createdActivityTemplateIds,
      deletedTemplates: deletedActivityTemplates,
      updatedTemplates: updatedActivityTemplateIds,
      submitTemplates: submitPatientActivityTemplates,
      currentDefaultTemplateId: currentDefaultActivityTemplateId,
      resetChanges,
    },
    ,
  ] = useAdminPatientActivities();
  const isDirty =
    Boolean(
      createdActivityTemplateIds.length ||
        deletedActivityTemplates.length ||
        updatedActivityTemplateIds.length
    ) || currentDefaultActivityTemplateId !== null;

  const currentSection = 'ADMINISTRATION_patient_settings';
  let permissions = {};
  const { currentUser } = useAuthContext();
  permissions = currentUser.permissions;
  const permissionKey = getCurrentPermissionBySection(permissions, currentSection);

  return (
    <div className="d-flex gap-sm">
      <PermissionsGate can={permissionKey[0].type}>
        <Button label="cancel" variant="dull" disabled={!isDirty} onClick={resetChanges} />
        <Button
          label="save changes"
          variant="confirm"
          disabled={!isDirty}
          onClick={submitPatientActivityTemplates}
        />
      </PermissionsGate>
    </div>
  );
};
export default ActivitiesActions;
