import { KeyboardEvent, useEffect, useRef, useState } from 'react';
import styles from './styles.module.css';
import { MentionsInput, Mention, MentionItem } from 'react-mentions';
import MentionItemCustom from 'types/Shared/MentionItemCustom';

interface MessageInputPoweredProps {
  disabled?: boolean;
  handleSendMessage?: (message: string, mentions: MentionItemCustom[]) => void;
  mentionables?: { display: string; id: string }[];
  keywords?: { display: string; id: string }[];
}

const MessageInputPowered = ({
  disabled = false,
  handleSendMessage = () => undefined,
  keywords = [],
  mentionables = [],
}: MessageInputPoweredProps) => {
  const [messageBody, setMessageBody] = useState<string>('');
  const [value, setValue] = useState<string>('');
  const [currentMentions, setCurrentMentions] = useState<MentionItemCustom[]>();

  const handleSend = () => {
    handleSendMessage(messageBody, currentMentions);
    setValue('');
    setCurrentMentions([]);
    setMessageBody('');
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSend();
    }
  };
  const handleChange = (...args) => {
    const [event, , messageBody, mentions] = args;
    setMessageBody(messageBody);
    setValue(event.target.value);
    setCurrentMentions(mentions);
  };

  const displayTransforms = {
    keyword: (_: string, display: string) => `#${display}`,
    mention: (_: string, display: string) => `@${display}`,
  };

  //todo: add focus state to div

  return (
    <div className={styles['input-outer-container']}>
      <div className={`d-flex align-items-center ${styles['input-inner-container']}`}>
        <MentionsInput
          value={value}
          onChange={handleChange}
          classNames={styles}
          className="mentions"
          singleLine
          allowSuggestionsAboveCursor
          onKeyDown={handleKeyDown}
        >
          <Mention
            trigger="@"
            data={mentionables}
            appendSpaceOnAdd
            markup="@[__display__](__id__)"
            displayTransform={displayTransforms.mention}
            className={styles.mentions__mention}
          />
          <Mention
            trigger="#"
            data={keywords}
            appendSpaceOnAdd
            markup="#[__display__](__id__)"
            displayTransform={displayTransforms.keyword}
            className={styles.mentions__hashtag}
          />
        </MentionsInput>

        {!disabled && (
          <button
            className={styles.button}
            type="button"
            onClick={handleSend}
            disabled={!messageBody}
          >
            Send
          </button>
        )}
      </div>
    </div>
  );
};

export default MessageInputPowered;
