import {Client} from '@twilio/conversations';
import {ReactNode, useEffect, useState} from 'react';
import {TwilioContext} from './twilio-context';

const TwilioProvider = ({
  children,
  token,
}: {
  children: ReactNode;
  token: string;
}) => {
  const [twilioClient, setTwilioClient] = useState<Client>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [creationError, setCreationError] = useState('');

  useEffect(() => {
    if (!token) return;
    setIsLoading(true);
    let client: Client;
    try {
      client = new Client(token);
      client.on('stateChanged', (status) => {
        if (status === 'initialized') {
          setTwilioClient(client);
        } else {
          console.error('Error initializing twilio client');
        }
      });
    } catch (error) {
      const message = 'Chat client failed to start, please try again later';
      console.error(message, error);
      setCreationError(message);
    } finally {
      setIsLoading(false);
    }

    return () => {
      client?.shutdown();
    };
  }, [token]);

  return (
    <TwilioContext.Provider
      value={{client: twilioClient, creationError, isLoading}}>
      {children}
    </TwilioContext.Provider>
  );
};
export default TwilioProvider;
