import { useCallback, useContext, useEffect, useReducer, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { usePatientFormikContext } from 'screens/Patients/store';
import { getTeamNotes } from 'services/patientService';
import { TeamNotesMessagesContainer } from './team-notes-messages';
import TeamNotesSchedule from './team-notes-schedule';
import TeamNotesStickyNotes from './team-notes-sticky-notes';

const initialTeamNotes = { notes: [], schedules: [], stickyNotes: [] };

const TeamNotes = () => {

  const [{ schedules, stickyNotes, notes }, setTeamNotes] = useState(initialTeamNotes);
  const { selectedPatient } = usePatientFormikContext();

  const fetchTeamNotes = useCallback(async () => {
    //TODO:  check this request 🤔
    if (!selectedPatient?.id) return;
    const response = await getTeamNotes(selectedPatient.id);
    setTeamNotes(response);
  }, [selectedPatient?.id]);

  useEffect(() => {
    fetchTeamNotes();
  }, [fetchTeamNotes]);


  return (
    <Container className={`container-with-padding`} style={{ height: 'calc(100vh - 330px)' }}>
      <div id="calendar-portal" hidden></div>
      <Row className="h-100">
        <Col lg={8} className="d-flex">
          <TeamNotesMessagesContainer />
        </Col>
        <Col lg={4} className="d-flex flex-column gap">
          <Row>
            <TeamNotesStickyNotes stickyNotes={stickyNotes} selectedPatientId={selectedPatient.id} />
          </Row>
          <Row className="flex-grow-1 overflow-scroll">
            <TeamNotesSchedule schedules={schedules} />
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default TeamNotes;
