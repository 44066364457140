import { ChatData } from 'screens/messages/mocks/types';
import { getConversationAttributes } from 'screens/messages/utils';
import { ConversationSimplified } from 'store/twilio';
import { TwilioConversation } from 'store/twilio-conversations/conversations-reducer';

export const mapToChatData =
  (metadataCache, twilio_identity) =>
  (c: ConversationSimplified): ChatData => {
    return {
      last_active_time: c.lastMessage?.dateCreated?.toISOString() ?? null,
      last_message: c.lastMessage?.body,
      sid: c.sid,
      unread_messages: c.unreadCount,
      last_message_participant_sid: c.lastMessageParticipant?.participantSid,
      providerMetadata: metadataCache?.[twilio_identity],
      patientMetadata: metadataCache?.[c.attributes?.patient_identity],
      attributes: c.attributes,
    };
  };

export const mapToChatDataOld =
  (metadataCache, twilio_identity) =>
  (c: TwilioConversation): ChatData => {
    const conversationAttributes = getConversationAttributes(c.ref.attributes);
    return {
      last_active_time: c.lastMessage?.dateCreated?.toISOString() ?? null,
      last_message: c.lastMessage?.body,
      sid: c.ref.sid,
      unread_messages: c.unreadCount,
      last_message_participant_sid: c.lastMessage?.participantSid,
      providerMetadata: metadataCache?.[twilio_identity],
      patientMetadata: metadataCache?.[conversationAttributes?.patient_identity],
      attributes: conversationAttributes,
    };
  };
