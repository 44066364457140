import { Field, useFormikContext } from 'formik';
import { FC } from 'react';
import { parsePhoneNumber } from 'util/utils';
import MaskedInput from 'react-text-mask'

interface PhoneNumberFormikProps {
  name: string;
  className?: string;
  style?: React.CSSProperties;
  placeholder?: string;
}

const PhoneNumberFormik: FC<PhoneNumberFormikProps> = ({
  name,
  className,
  style,
  placeholder = "xxx-xxx-xxx",
}: PhoneNumberFormikProps) => {
  const { setFieldValue, handleChange } = useFormikContext();

  const handlePhoneNumberPaste = (setPhoneNumber: (val: string) => void) => (e: ClipboardEvent) => {
    e.preventDefault();
    const data = e.clipboardData.getData('text');
    const [parsed, isValid] = parsePhoneNumber(data);
    isValid && setPhoneNumber(parsed);
  };

  const handlePhoneNumberKeyDown = (e: KeyboardEvent) => {
    const allowedKeys = ['Backspace', 'Delete', '-', 'ArrowRight', 'ArrowLeft', 'Tab'];
    if (isNaN(parseInt(e.key)) && !allowedKeys.includes(e.key) && !e.ctrlKey && !e.shiftKey) {
      e.preventDefault();
      return;
    }
  };

  const phoneNumberMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  return (
    <Field
      name={name}
    >
      {({ field }) => (
        <MaskedInput
          onChange={handleChange}
          onKeyDown={handlePhoneNumberKeyDown}
          onPaste={handlePhoneNumberPaste((val) => setFieldValue(name, val))}
          {...field}
          mask={phoneNumberMask}
          className={`vytrac-input rounded px-2 ${className ?? ''}`}
          placeholder={placeholder}
          type="text"
          onBlur={null}
          style={style}
        />
      )}
    </Field>
  );
};
export default PhoneNumberFormik;
