import { PaginatedResponse } from 'types/ApiModels/General';
import { Provider } from 'types/ApiModels/Providers/Provider';
import { deleteFromBook } from 'util/bookUtils';
import { ProviderActionTypes } from '.';
import { ProviderAction } from './providersActionTypes';

export interface AdminProviderState {
  providersBook: Record<number, PaginatedResponse<Provider>>;
  providersBookFromTable: Record<number, PaginatedResponse<Provider>>;
  updatedIsActiveProviders: { id: number; value: boolean }[];
  resetProvidersForm: () => void;
  submitProvidersForm: () => void;
  deletedProviders: number[];
  showInviteProvider: boolean;
  selectedProvider: Provider;
  submitSingleProviderForm: () => void;
  resetSingleProviderForm: () => void;
  singleProviderFormDirty: boolean;
  removeSingleProvider: () => void;
  currentBookPage: number;
}

export const providerInitialState: AdminProviderState = {
  providersBook: null,
  updatedIsActiveProviders: [],
  resetProvidersForm: () => undefined,
  submitProvidersForm: () => undefined,
  deletedProviders: [],
  showInviteProvider: false,
  selectedProvider: null,
  submitSingleProviderForm: () => undefined,
  resetSingleProviderForm: () => undefined,
  singleProviderFormDirty: false,
  removeSingleProvider: () => undefined,
  providersBookFromTable: {},
  currentBookPage: 1,
};

const adminProvidersReducer = (
  state: AdminProviderState,
  action: ProviderAction
): AdminProviderState => {
  switch (action.type) {
    case ProviderActionTypes.SET_PROVIDERS_BOOK: {
      return { ...state, providersBook: action.payload.providersBook };
    }
    case ProviderActionTypes.APPEND_PROVIDERS_BOOK_PAGE: {
      const { current } = action.payload.providersPage;
      if (state.providersBook[current]) {
        return state;
      }
      return {
        ...state,
        providersBook: { ...state.providersBook, [current]: action.payload.providersPage },
      };
    }
    case ProviderActionTypes.SET_SELECTED_PROVIDER: {
      return { ...state, selectedProvider: action.payload.provider };
    }
    case ProviderActionTypes.SET_PROVIDERS_RESET_FORM: {
      //also reset updatedlist
      return {
        ...state,
        updatedIsActiveProviders: [],
        resetProvidersForm: action.payload.resetForm,
      };
    }
    case ProviderActionTypes.SET_PROVIDERS_SUBMIT_FORM: {
      return {
        ...state,
        submitProvidersForm: action.payload.submitForm,
      };
    }
    case ProviderActionTypes.RESET_UPDATED_PROVIDERS: {
      return {
        ...state,
        updatedIsActiveProviders: [],
      };
    }
    case ProviderActionTypes.RESET_DELETED_PROVIDERS: {
      return { ...state, deletedProviders: [] };
    }
    case ProviderActionTypes.TOGGLE_SHOW_INVITE_PROVIDER: {
      return { ...state, showInviteProvider: !state.showInviteProvider };
    }
    case ProviderActionTypes.SET_SUBMIT_SINGLE_PROVIDER_FORM: {
      return { ...state, submitSingleProviderForm: action.payload.submitSingleProvider };
    }
    case ProviderActionTypes.SET_RESET_SINGLE_PROVIDER_FORM: {
      return { ...state, resetSingleProviderForm: action.payload.resetSingleProviderForm };
    }
    case ProviderActionTypes.SET_SINGLE_PROVIDER_FORM_DIRTY: {
      return { ...state, singleProviderFormDirty: action.payload.singleProviderFormDirty };
    }
    case ProviderActionTypes.SET_SINGLE_PROVIDER_REMOVE: {
      return { ...state, removeSingleProvider: action.payload.removeSingleProvider };
    }
    case ProviderActionTypes.REMOVE_PROVIDER_FROM_BOOK: {
      let page, newProvidersResult;
      for (const [key, value] of Object.entries(state.providersBook)) {
        const providerIdx = value.results.findIndex((p) => p.id === action.payload.providerId);
        if (providerIdx !== -1) {
          page = key;
          newProvidersResult = value.results.filter((p) => p.id !== action.payload.providerId);
          break;
        }
      }
      const providersBookCopy = { ...state.providersBook };
      const providersBookPageCopy = { ...providersBookCopy[page] };
      providersBookPageCopy.results = newProvidersResult;
      providersBookCopy[page] = providersBookPageCopy;
      return { ...state, providersBook: providersBookCopy };
    }
    case ProviderActionTypes.SET_PROVIDERS_TABLE_BOOK: {
      return { ...state, providersBookFromTable: action.payload.tableBook };
    }
    case ProviderActionTypes.MATCH_PROVIDERS_TABLE_BOOK_WITH_BOOK: {
      return { ...state, providersBookFromTable: JSON.parse(JSON.stringify(state.providersBook)) };
    }
    case ProviderActionTypes.UPDATE_PROVIDER_TABLE_PAGE: {
      if (state.providersBookFromTable[action.payload.page]) return state;
      if (state.providersBook[action.payload.page])
        return {
          ...state,
          providersBookFromTable: {
            ...state.providersBookFromTable,
            [action.payload.page]: { ...state.providersBook[action.payload.page] },
          },
        };
      return state;
    }
    case ProviderActionTypes.UPDATE_PROVIDER_IS_ACTIVE: {
      const { providerId, page } = action.payload;
      //update book
      const pageCopy = { ...state.providersBookFromTable[page] };
      const providerIdx = pageCopy.results.findIndex((p) => p.id === providerId);
      const resultsCopy = [...pageCopy.results];
      resultsCopy[providerIdx] = {
        ...resultsCopy[providerIdx],
        active: !resultsCopy[providerIdx].active,
      };
      pageCopy.results = resultsCopy;
      const bookCopy = { ...state.providersBookFromTable };
      bookCopy[page] = pageCopy;
      //update is active
      const updatedIdx = state.updatedIsActiveProviders.findIndex((p) => p.id === providerId);
      let updatedIsActiveProviders;
      if (updatedIdx === -1) {
        updatedIsActiveProviders = [
          ...state.updatedIsActiveProviders,
          { id: providerId, value: resultsCopy[providerIdx] },
        ];
      } else {
        updatedIsActiveProviders = [...state.updatedIsActiveProviders];
        updatedIsActiveProviders[updatedIdx].value = resultsCopy[providerIdx].active;
      }
      return { ...state, providersBookFromTable: bookCopy, updatedIsActiveProviders };
    }
    case ProviderActionTypes.UPDATE_PROVIDER_DELETE: {
      //update book
      const { providerId, page } = action.payload;
      const newBook = deleteFromBook(state.providersBookFromTable, page, providerId);
      //update deleted
      const deletedProviders = [...state.deletedProviders, providerId];
      return { ...state, providersBookFromTable: newBook, deletedProviders };
    }
    case ProviderActionTypes.UPDATE_CURRENT_BOOK_PAGE: {
      return { ...state, currentBookPage: action.payload.page };
    }
    case ProviderActionTypes.SET_PROVIDERS_BOOK_AND_MATCH_TABLE: {
      const { providersBook } = action.payload;
      if (!providersBook) return state;
      /**
       * only match if the page has not been added already, that is.. if the table has already data in that page
       * we don't want to touch it.. But this is matching table as well which is kind of dangerous if we were doing changes
       * to the table and we decide to go to antoher page... is that okay?
       */
      const currentPages = Object.keys(state.providersBookFromTable);
      const incomingPages = Object.keys(providersBook);

      const extraPages = incomingPages.filter((ip) => !currentPages.includes(ip));
      if (extraPages.length) {
        const newProvidersBookFromTable = { ...state.providersBookFromTable };
        extraPages.forEach((extra) => {
          newProvidersBookFromTable[extra] = providersBook[extra];
        });
        return {
          ...state,
          providersBook,
          providersBookFromTable: newProvidersBookFromTable,
        };
      }
      return { ...state, providersBook: action.payload.providersBook };
    }
    default:
      return state;
  }
};
export default adminProvidersReducer;
