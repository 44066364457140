import React, { useCallback } from 'react';
import { Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { getSinglePatient } from 'services/patientService';
import PatientTopBar from '../PatientTopBar';
import { usePatientContext } from '../store/PatientContext';
import { usePatientFormikContext } from '../store';
import PatientListWithFilters from './components/PatientListWithFilters';
import PatienListWithFiltersV2 from './components/PatientListWithFilters/PatientListWithFiltersV2';
import PatientPreview from './components/PatientPreview';
import PatientTabs, { IPatientTab } from './components/PatientTabs';
import tabEnum from './components/PatientTabs/util/tabEnum';
import tabRouteResolverMap, {
  patientListRoute,
} from './components/PatientTabs/util/tabRouteResolverMap';

const DynamicTabs = () => {
  const { selectedPatient, setSelectedPatient } = usePatientFormikContext();
  const { tab, patientId } = useParams<IPatientTab>();

  const updatePatient = useCallback(
    async (patientId: number) => {
      setSelectedPatient(await getSinglePatient(patientId));
    },
    [setSelectedPatient]
  );

  const id = Number(patientId);
  if (isNaN(id)) {
    return <Redirect to={`/${patientListRoute}`} />;
  }

  if (selectedPatient?.id !== id) {
    updatePatient(id);
  }

  if (!tab) {
    return <Redirect to={tabRouteResolverMap[tabEnum.PATIENT_STATUS](patientId)} />;
  }

  if (!selectedPatient) return null;

  return (
    <>
      <PatientPreview selectedTab={tab} />
      <PatientTabs tab={tab} patientId={patientId} />
    </>
  );
};

const Content = () => {
  const {
    patientState: {
      patientNavigation: { actions, breadcrumbPathList },
    },
  } = usePatientContext();
  const match = useRouteMatch();

  return (
    <>
      <PatientTopBar actions={actions} breadcrumbPathList={breadcrumbPathList} />

      <Switch>
       
        {/* <PatientListWithFilters /> */}

        <Route path={match.path} exact>
          <PatientListWithFilters />
          {/* <PatienListWithFiltersV2/> */}
        </Route>
        <Route path={[`${match.path}/:patientId/:tab`, `${match.path}/:patientId`]}>
          <DynamicTabs />
        </Route> 
      </Switch>
    </>
  );
};
export default Content;
