import { useAuthContext } from "auth/store/AuthContext";
import { PERMISSION_TYPE } from "../../util/permissionUtils"

const hasPermission = (permissions, can) => can ==  PERMISSION_TYPE.MANAGE ? true : false;

export default function PermissionsGate({
  children,
  can
}) {
  const { currentUser } = useAuthContext();
  const permissionGranted = hasPermission(currentUser.permissions, can);
  if (!permissionGranted) return <></>
  return <>{children}</>;
}
