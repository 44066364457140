import Checkbox from 'components/form/Checkbox';
import { FormikContextType, FormikValues } from 'formik';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { RolePermissionOptions } from 'types/Roles/RolePermissions';
import { boolean } from 'yup';
import styles from './styles.module.css';
import { sectionsByType } from './util';

interface CustomPermissionsModalProps {
  formRef: MutableRefObject<FormikContextType<FormikValues>>;
  type?: string;
  onCancel: (type: string) => void;
  onConfirm: (type: string, index: number, values: []) => void;
  index: number;
}
const CustomPermissionsModal = ({
  formRef,
  type = 'templates',
  onCancel,
  onConfirm,
  index,
}: CustomPermissionsModalProps) => {
  const { values } = formRef.current;
  const [checks, setChecks] = useState<{ [key: string]: RolePermissionOptions }>({
    ...Object.keys(sectionsByType[type]).reduce((acc, key) => {
      acc[key] = values.roles[index][key] || RolePermissionOptions.none;
      return acc;
    }, {}),
  });
  const [checkAll, setCheckAll] = useState({
    [RolePermissionOptions.view]: false,
    [RolePermissionOptions.manage]: false,
  });
  useEffect(() => {
    Object.keys(sectionsByType[type]).forEach((section) => {
      if (checks[section] == RolePermissionOptions.none) {
        setCheckAll({
          [RolePermissionOptions.manage]: false,
          [RolePermissionOptions.view]: false,
        });
      } else if (checks[section] == RolePermissionOptions.view) {
        setCheckAll((prev) => ({ ...prev, [RolePermissionOptions.manage]: false }));
      }
    });
    if (
      Object.keys(sectionsByType[type]).every(
        (section) => checks[section] === RolePermissionOptions.manage
      )
    )
      setCheckAll({ [RolePermissionOptions.manage]: true, [RolePermissionOptions.view]: true });
    if (
      Object.keys(sectionsByType[type]).every(
        (section) => checks[section] === RolePermissionOptions.view
      )
    )
      setCheckAll({ [RolePermissionOptions.manage]: false, [RolePermissionOptions.view]: true });
  }, [checks]);

  const checkAllCheckboxes = (permissionType) => {
    if (!checkAll[permissionType]) {
      setChecks(
        Object.keys(sectionsByType[type]).reduce((acc, key) => {
          acc[key] = permissionType;
          return acc;
        }, {})
      );
    } else {
      setChecks(
        Object.keys(sectionsByType[type]).reduce((acc, key) => {
          acc[key] = RolePermissionOptions.none;
          return acc;
        }, {})
      );
    }
    if (permissionType == RolePermissionOptions.view) {
      setCheckAll((prev) => ({ ...prev, [permissionType]: !prev[permissionType] }));
    } else {
      setCheckAll((prev) => ({
        ...prev,
        [permissionType]: !prev[permissionType],
        [RolePermissionOptions.view]: true,
      }));
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.headers}>
        <div>Section</div>
        <div className="d-flex">
          <div className="mr-2">View</div>
          <div>Manage</div>
        </div>
      </div>
      <div className={styles.row}>
        <div>{type}</div>
        <div className="d-flex">
          <div className="mr-4">
            <Checkbox
              onChange={() => checkAllCheckboxes(RolePermissionOptions.view)}
              value={checkAll[RolePermissionOptions.view]}
            />
          </div>
          <div>
            <Checkbox
              onChange={() => checkAllCheckboxes(RolePermissionOptions.manage)}
              value={checkAll[RolePermissionOptions.manage]}
            />
          </div>
        </div>
      </div>
      {Object.keys(sectionsByType[type]).map((section, index) => (
        <div className={styles.subRow} key={index}>
          <div>{sectionsByType[type][section].name}</div>
          <div className="d-flex">
            <div className="mr-4">
              <Checkbox
                onClick={() => {
                  if (checks[section] !== RolePermissionOptions.none) {
                    setChecks({ ...checks, [section]: RolePermissionOptions.none });
                  } else {
                    setChecks({ ...checks, [section]: RolePermissionOptions.view });
                  }
                }}
                value={
                  checks[section] === RolePermissionOptions.view ||
                  checks[section] === RolePermissionOptions.manage
                }
              />
            </div>
            <div>
              <Checkbox
                onClick={() => {
                  if (checks[section] === RolePermissionOptions.manage) {
                    setChecks({ ...checks, [section]: RolePermissionOptions.view });
                  } else {
                    setChecks({ ...checks, [section]: RolePermissionOptions.manage });
                  }
                }}
                value={checks[section] === RolePermissionOptions.manage}
              />
            </div>
          </div>
        </div>
      ))}
      <div className="text-primary d-flex justify-content-between mt-3">
        <div className="cursor-pointer" onClick={() => onCancel(type)}>
          Cancel
        </div>
        <div className="cursor-pointer" onClick={() => onConfirm(type, index, checks as any)}>
          Set Permission
        </div>
      </div>
    </div>
  );
};
export default CustomPermissionsModal;
