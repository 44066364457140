import Button from 'components/Button';
import { LabeledField, TextInputFormik } from 'components/FormikComponents';
import VytracModal from 'components/Modal';
import { Formik } from 'formik';
import KeywordsTemplate from 'types/ApiModels/Administration/keywords-template';
import { REQUIRED_MESSAGE } from 'util/formikUtils';
import { boolean, object, string } from 'yup';

export interface AddKeywordTemplateFormikValues {
  name: string;
  is_active: boolean;
  id: number;
  is_default: boolean;
}

interface AddKeywordtemplateModalProps {
  handleClose;
  handleConfirm: (values: AddKeywordTemplateFormikValues) => void;
  show: boolean;
  current: KeywordsTemplate;
  idCreator: () => number;
  existingNames: string[];
}

const AddKeywordTemplateModal = ({
  handleClose,
  handleConfirm,
  show,
  current,
  idCreator,
  existingNames,
}: AddKeywordtemplateModalProps) => {
  const handleSubmit = (values: AddKeywordTemplateFormikValues) => {
    handleConfirm(values);
    handleClose();
  };
  return (
    <VytracModal
      onClose={handleClose}
      title={current ? 'Update keyword template' : 'Create keyword template'}
      body={
        <Formik
          initialValues={{
            id: current?.id ?? idCreator(),
            name: current?.name ?? '',
            is_active: current?.is_active ?? true,
            is_default: current?.is_default ?? false,
          }}
          onSubmit={handleSubmit}
          validationSchema={object().shape({
            name: string()
              .required(REQUIRED_MESSAGE)
              .notOneOf(existingNames, 'Name must be unique across other templates'),
            is_readonly: boolean(),
            is_active: boolean(),
          })}
        >
          {({ handleSubmit }) => (
            <>
              <div className="d-flex flex-column gap">
                <LabeledField label="Name" name="name" className="gap-sm">
                  <TextInputFormik
                    name="name"
                    placeholder="Activity name..."
                    className="font-size-medium w-100"
                  />
                </LabeledField>
              </div>
              <div className={`d-flex justify-content-between w-100 pt-3`}>
                <Button variant="dull" label={'cancel'} onClick={handleClose} />
                <Button
                  variant="confirm"
                  label={current ? 'Update template' : 'Add template'}
                  onClick={handleSubmit}
                />
              </div>
            </>
          )}
        </Formik>
      }
      show={show}
      centered
    />
  );
};
export default AddKeywordTemplateModal;
