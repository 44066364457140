import { Conversation, Message, Paginator, Participant } from '@twilio/conversations';
import { ConversationAction, ConversationActionTypes } from './conversations-action-types';

export interface TwilioConversation {
  ref: Conversation;
  lastMessage: Message;
  participants: Participant[];
  unreadCount: number;
}
export interface TwilioConversationsState {
  conversations: TwilioConversation[];
  conversationsPaginator: Paginator<Conversation>;
}

export const twilioConversationsInitialState: TwilioConversationsState = {
  conversations: [],
  conversationsPaginator: null,
};

/**
 * Defaults to conversation created if there is no last message (edge case)
 */
const sortConversationsByLastMessage = (a: TwilioConversation, b: TwilioConversation) => {
  const aCompare = a.ref.lastMessage?.dateCreated ?? a.ref.dateCreated;
  const bCompare = b.ref.lastMessage?.dateCreated ?? b.ref.dateCreated;
  return aCompare > bCompare ? -1 : 1;
};

const conversationsReducer = (
  state: TwilioConversationsState,
  action: ConversationAction
): TwilioConversationsState => {
  //TODO: add conversation sorting
  switch (action.type) {
    case ConversationActionTypes.SET_CONVERSATIONS: {
      action.payload.conversations.sort(sortConversationsByLastMessage);
      return {
        ...state,
        conversations: action.payload.conversations,
      };
    }
    case ConversationActionTypes.UPSERT_CONVERSATION: {
      const existingIdx = state.conversations.findIndex(
        (c) => c.ref.sid === action.payload.conversation.ref.sid
      );
      if (existingIdx !== -1) {
        //it exists, then we can delete the entry and add it to the top
        const copy = [...state.conversations];
        copy.splice(existingIdx, 1);
        copy.unshift(action.payload.conversation);
        return { ...state, conversations: copy };
      }
      return {
        ...state,
        conversations: [action.payload.conversation, ...state.conversations],
      };
    }

    case ConversationActionTypes.ADD_CONVERSATION: {
      const conversationsCopy = [...state.conversations];
      conversationsCopy.unshift(action.payload.conversation);
      return { ...state, conversations: conversationsCopy };
    }

    case ConversationActionTypes.SET_LAST_MESSAGE: {
      const conversationIdx = state.conversations.findIndex(
        (c) => c.ref.sid === action.payload.conversation.ref.sid
      );
      const copy = [...state.conversations];
      //if found we reuse participants else we provide them from payload
      if (conversationIdx !== -1) {
        const participants = copy[conversationIdx].participants;
        copy.splice(conversationIdx, 1);
        copy.unshift({ ...action.payload.conversation, participants });
      } else {
        copy.splice(conversationIdx, 1);
        copy.unshift(action.payload.conversation);
      }
      return { ...state, conversations: copy };
    }
    case ConversationActionTypes.SET_PARTICIPANTS: {
      const conversationSID = action.payload.participants?.[0]?.conversation.sid;
      if (!conversationSID) return state;
      const conversationIdx = state.conversations.findIndex((c) => c.ref.sid === conversationSID);
      const copy = [...state.conversations];
      const conversationCopy = { ...copy[conversationIdx] };
      conversationCopy.participants = action.payload.participants;
      copy[conversationIdx] = conversationCopy;
      return { ...state, conversations: copy };
    }
    case ConversationActionTypes.SET_UNREAD_COUNT: {
      const existingIdx = state.conversations.findIndex(
        (c) => c.ref.sid === action.payload.conversationSID
      );
      const copy = [...state.conversations];
      const conversationCopy = { ...copy[existingIdx] };
      conversationCopy.unreadCount = action.payload.unreadCount;
      copy[existingIdx] = conversationCopy;
      return { ...state, conversations: copy };
    }
    case ConversationActionTypes.SET_CONVERSATIONS_PAGINATOR: {
      return { ...state, conversationsPaginator: action.payload.paginator };
    }

    case ConversationActionTypes.REMOVE_CONVERSATION: {
      return {
        ...state,
        conversations: state.conversations.filter(
          (c) => c.ref.sid !== action.payload.conversationSID
        ),
      };
    }
    default:
      console.error(
        `Received a type that is not recognized by the reducer. This might be a code pitfall`
      );
      return state;
  }
};
export default conversationsReducer;
