import { useMemo } from 'react';
import { ConversationSelectors, useConversationsStore } from 'store/twilio';
import { useTwilioClient } from 'store/twilio-client';
import { useEncounterConversationsByPatient } from '../messages-content/patient-messages/chat/hooks';
import { useTwilioConversationsRework } from './use-twilio-conversations-rework';

export const usePatientMessages = (patientId: number) => {
  const { isLoading: isTwilioLoading } = useTwilioClient();
  const areConversationsLoading = useConversationsStore(
    ConversationSelectors.areConversationsLoading
  );
  const conversationsFromStore = useConversationsStore(ConversationSelectors.conversations);
  const conversations = useMemo(
    () => conversationsFromStore.filter((c) => c.attributes?.patient_id === patientId),
    [conversationsFromStore, patientId]
  );

  const { encounterConversations, isLoading: encounterConversationsAreLoading } =
    useEncounterConversationsByPatient(patientId);

  const encounterConversationSids = useMemo(() => {
    return encounterConversations?.map((ec) => ec.twilio_conversation_sid) ?? [];
  }, [encounterConversations]);

  useTwilioConversationsRework(encounterConversationSids);

  return useMemo(
    () => ({
      conversations,
      isLoading: isTwilioLoading || areConversationsLoading || encounterConversationsAreLoading,
    }),
    [areConversationsLoading, conversations, encounterConversationsAreLoading, isTwilioLoading]
  );
};
