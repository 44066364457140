import StarIcon from 'components/communication-icons/star-icon';
import { FC } from 'react';
import colors from 'styles/colors';

interface DeviceModelProps {
  isFdaApproved: boolean;
  name: string;
}

const DeviceModelCell: FC<DeviceModelProps> = ({ isFdaApproved, name }: DeviceModelProps) => {
  return (
    <div className="d-flex gap align-items-center">
      <StarIcon
        width="16"
        height={'15'}
        color={isFdaApproved ? colors.brightBlue : colors.grayProgressBar}
      />
      <p className="m-0 p-0">{name}</p>
    </div>
  );
};
export default DeviceModelCell;
