import { useAuthContext } from 'auth/store/AuthContext';
import Button from 'components/Button';
import PermissionsGate from 'components/PermissionsGate/PermissionsGate';
import { addBlankEmailSetting } from 'screens/Administration/store/EmailSettings/emailSettingActionCreators';
import { useAdminEmailSettings } from 'screens/Administration/store/hooks';
import { getCurrentPermissionBySection } from 'util/permissionUtils';

const EmailSettingsActions = () => {
  const [, dispatch] = useAdminEmailSettings();

  const currentSection = 'ADMINISTRATION_email_settings';
  let permissions = {};
  const { currentUser } = useAuthContext();
  permissions = currentUser.permissions;
  const permissionKey = getCurrentPermissionBySection(permissions, currentSection);

  return (
    
    <div className={`d-flex gap`}>
      <PermissionsGate can={permissionKey[0].type}>
        <Button label="add email" onClick={() => dispatch(addBlankEmailSetting())} />
      </PermissionsGate>
    </div>
  );
};

export default EmailSettingsActions;
