import { Message, Paginator } from '@twilio/conversations';
import { TwilioMessagesAction, TwilioMessagesActionTypes } from './twilio-messages-action-types';

export const setMessages = (messages: Message[]): TwilioMessagesAction => ({
  type: TwilioMessagesActionTypes.SET_MESSAGES,
  payload: {
    messages,
  },
});

export const setMessagesPaginator = (paginator: Paginator<Message>): TwilioMessagesAction => ({
  type: TwilioMessagesActionTypes.SET_MESSAGES_PAGINATOR,
  payload: {
    paginator,
  },
});

export const addMessage = (message: Message): TwilioMessagesAction => ({
  type: TwilioMessagesActionTypes.ADD_MESSAGE,
  payload: {
    message,
  },
});

export const prependMessages = (messages: Message[]): TwilioMessagesAction => ({
  type: TwilioMessagesActionTypes.PREPEND_MESSAGES,
  payload: {
    messages,
  },
});

export const setMessageLoadingStatus = (isLoading: boolean) : TwilioMessagesAction => (
  { type: TwilioMessagesActionTypes.SET_MESSAGE_LOADING_STATUS,
    payload: {
      isLoading
      }
}) 

//--AppendCreator
