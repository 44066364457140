import { useCallback, useMemo, useState } from 'react';
import { AsyncTypeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';
import { useHistory } from 'react-router-dom';
import tabEnum from 'screens/Patients/Content/components/PatientTabs/util/tabEnum';
import { baseTabRouteResolver } from 'screens/Patients/Content/components/PatientTabs/util/tabRouteResolverMap';
import { getPaginatedPatients } from 'services/patientService';

import PatientGet from 'types/ApiModels/Patients/PatientGet';
import { debouncePromiseValue } from 'util/utils';
import PatientSearchResult from '../PatientSearchResult';
import styles from './styles.module.css';

const PatientSearchBar = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<PatientGet[]>([]);

  const history = useHistory();

  const fetchPaginationState = useCallback(async (searchValue: string) => {
    try {
      const paginatedPatients = await getPaginatedPatients(searchValue, null, 1, 10);
      setOptions(paginatedPatients.results);
    } catch (exception) {
      throw exception;
    }
    setIsLoading(false);
  }, []);

  const debouncedFetch = useMemo(
    () => debouncePromiseValue(fetchPaginationState, 500),
    [fetchPaginationState]
  );

  const onPatientClick = useCallback(
    (patientId: number) => {
      history.push(baseTabRouteResolver(patientId.toString(), tabEnum.PATIENT_STATUS));
    },
    [history]
  );
  return (
    <AsyncTypeahead
      className="w-100"
      id="patient-search"
      isLoading={isLoading}
      options={options}
      placeholder="Patient search"
      filterBy={() => true}
      renderInput={({ inputRef, referenceElementRef, ...inputProps }) => (
        <div className={styles.searchbox}>
          <input
            ref={(ref) => {
              inputRef(ref);
              referenceElementRef(ref);
            }}
            {...inputProps}
          />
        </div>
      )}
      renderMenu={(results: PatientGet[], menuProps) => {
        const { newSelectionPrefix, paginationText, renderMenuItemChildren, ...striped } =
          menuProps;

        // Originally was <Menu {...striped} text="" className={styles.menu}> but text field is not a valid prop for Menu
        return (
          <Menu {...striped} className={styles.menu}>
            {results.map((result, index) => (
              <MenuItem option={result} position={index} key={index}>
                <PatientSearchResult patient={result} />
              </MenuItem>
            ))}
          </Menu>
        );
      }}
      //labelKey={(option: PatientGet) => `${option.user.first_name} ${option.user.last_name}`}
      labelKey={(option: PatientGet) => `${option.first_name}`}
      onSearch={(searchValue) => {
        setIsLoading(true);
        debouncedFetch(searchValue);
      }}
      onChange={(option: PatientGet[]) => onPatientClick(option[0].id)}
    />
  );
};
export default PatientSearchBar;
