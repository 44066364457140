import PopulationsModal from 'components/PopulationsModal/PopulationsModal';
import { Form, Formik, FormikContextType } from 'formik';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import InvitePatient from 'types/ApiModels/Administration/InvitePatient';
import StepHeader from './StepHeader';
import { Step1, Step2, Step3, StepFooter } from './Steps';
import initialValues from './utils/initialValues';
import { onSubmit } from './utils/submitForm';
import { InvitePatientValidationSchema } from './utils/validationSchema';

interface InvitePatientFormProps {
  onConfirm: () => void;
  onCancel: () => void;
  setShowPopulationsModal: Dispatch<SetStateAction<boolean>>;
  setPreviousPopulations: Dispatch<SetStateAction<number[]>>;
  previousPopulations: number[];
  showPopulationsModal: boolean;
  formRef: React.MutableRefObject<FormikContextType<InvitePatient>>;
}

export const InvitePatientForm = ({
  onCancel,
  onConfirm,
  setShowPopulationsModal,
  formRef,
}: InvitePatientFormProps) => {

  const [formError, setFormError] = useState<string>(null);

  const setModalFormErrors = useCallback((errors: string) => setFormError(errors), []);
  const clearModalFormErrors = useCallback(() => setFormError(null), []);

  useEffect(() => {
    return () => clearModalFormErrors();
  }, [formRef.current?.values]);

  const handleSubmit = useCallback(
    
    async (values: InvitePatient) => {
        try {
          await onSubmit(values);
          onConfirm();
        } catch (e) {
          console.log("Error", e);   
          let stringErrors = '';
          Object.keys(e.response.data).forEach((key) => {
            if (e.response.headers['content-type'] === 'application/json') {
              if (Array.isArray(e.response.data[key])) {
                stringErrors += e.response.data[key][0].toLowerCase() + ', ';
              } else {
              }
            }
          });
          stringErrors = stringErrors.substring(0, stringErrors.length - 2) + '.';
          setModalFormErrors('There were errors inviting the patient: ' + stringErrors);
          return;
      }
    },
    []
  );

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={InvitePatientValidationSchema[0]}
      onSubmit={handleSubmit}
    >
      <Form>
        <div className="d-flex pt-2 pl-2">
          {formError ? <div className="text-danger font-size-small">{formError}</div> : null}
        </div>
        <Step1/>
        <StepFooter onCancel={onCancel} confirmLabel="INVITE" cancelLabel="CANCEL" />;
      </Form>
    </Formik>
  );
};
