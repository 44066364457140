import { ReactNode, useReducer } from 'react';
import { ConversationsContext } from './conversations-context';
import conversationsReducer, { twilioConversationsInitialState } from './conversations-reducer';

const ConversationCtxProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(conversationsReducer, twilioConversationsInitialState);
  return (
    <ConversationsContext.Provider value={{ conversationState: state, dispatch }}>
      {children}
    </ConversationsContext.Provider>
  );
};

export default ConversationCtxProvider;
