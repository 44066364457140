import { useAuthContext } from 'auth/store/AuthContext';
import Button from 'components/Button';
import PermissionsGate from 'components/PermissionsGate/PermissionsGate';
import { resetCareTeamForm } from 'screens/Administration/store/CareTeam';
import { useAdminCareTeams } from 'screens/Administration/store/hooks';
import { getCurrentPermissionBySection } from 'util/permissionUtils';

const AdminCareTeamActions = () => {
  const [
    { deletedCareTeams, updatedCareTeams, createdCareTeams, submitCareTeamsForm, createCareTeam },
    ,
    dispatch,
  ] = useAdminCareTeams();

  const isTableDirty =
    deletedCareTeams.length || updatedCareTeams.length || createdCareTeams.length;

    const currentSection = 'ADMINISTRATION_providers';
    let permissions = {};
    const { currentUser } = useAuthContext();
    permissions = currentUser.permissions;
    const permissionKey = getCurrentPermissionBySection(permissions, currentSection);
    
  return (
    <div className="d-flex gap-sm">
      <PermissionsGate can={permissionKey[0].type}>
        <Button
          label="cancel"
          variant="cancel"
          disabled={!isTableDirty}
          onClick={() => {
            dispatch(resetCareTeamForm());
          }}
        />
        <Button
          label="save changes"
          variant="confirm"
          disabled={!isTableDirty}
          onClick={() => {
            submitCareTeamsForm()}}
        />
        <Button
          label="add new care team"
          onClick={() => {
            createCareTeam();
          }}
        />
      </PermissionsGate>
    </div>
  );
};
export default AdminCareTeamActions;
