import PatientProfileRing from 'components/patient/PatientProfileRing';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import AvatarStatus from 'types/ApiModels/Patients/AvatarStatus';

interface PatientRinkLinkProps {
  avatar_status: AvatarStatus;
  full_name: string;
  photo: string;
  patientId: number;
}

const PatientRingLink: FC<PatientRinkLinkProps> = ({
  avatar_status,
  full_name,
  photo,
  patientId,
}: PatientRinkLinkProps) => {
  const history = useHistory();

  const handlePatientClick = () => {
    history.push(`/patients/${patientId}/patient-status`);
  };
  return (
    <div className="d-flex gap-sm align-items-center">
      <PatientProfileRing
        currentPatient={{
          avatar_status,
          user: {
            imageUrl: photo,
          },
        }}
        size={26}
      />
      <button className="link" type="button" onClick={handlePatientClick}>
        {full_name}
      </button>
    </div>
  );
};
export default PatientRingLink;
