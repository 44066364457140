import { useAuthContext } from 'auth';
import { useCallback, useEffect } from 'react';
import { useProviderProfileContext } from 'screens/provider-profile/store';
import { setProviderProfileOptionsInfo } from 'screens/provider-profile/store/providerProfileActionCreators';
import { getRoles } from 'services/administrationService';
import { getProvidersTitles, getSingleProvider, getSpecialtiesV2 } from 'services/providersService';
import { getLanguages, getSexOptions } from 'services/userService';

export const useProfileMyAccountLists = () => {
  const authContext = useAuthContext();
  const {currentUser} = authContext;

  const {
    providerProfileState: { optionsInfo },
    dispatch,
  } = useProviderProfileContext();

  const fetchAllOptions = useCallback(async () => {
    // TODO: /api/providers/titles is not working
    const [currentProvider, genderOptions, languages, specialties, roles] = 
      await Promise.all([
        getSingleProvider(0, currentUser.id),
        getSexOptions(),
        getLanguages(),
        // getProvidersTitles(),
        getSpecialtiesV2(),
        getRoles(),
      ]);

    dispatch(
      setProviderProfileOptionsInfo({
        currentProvider,
        genderOptions,
        languages,
        roles,
        specialties: specialties.results,
        // titles: titles.results,
        titles: []
      })
    );
  }, [currentUser?.id, dispatch]);

  useEffect(() => {
    if (!optionsInfo.currentProvider && currentUser) {
      fetchAllOptions();
    }
  }, [currentUser, fetchAllOptions, optionsInfo.currentProvider]);
};
