import { PaginatedRequestHook, usePaginatedRequest } from 'hooks';
import { Dispatch } from 'react';
import PatientSettingItem from 'types/ApiModels/Administration/patient-setting-item';
import { useAdministrationContextV2 } from '../AdministrationContextV2';
import { AllAdminActions } from '../administrationReducer';
import { PatientActivitiesState } from '../patient-settings/patient-activities';

export const useAdminPatientActivities = (): [
  activitiesState: PatientActivitiesState,
  pagination: PaginatedRequestHook<PatientSettingItem>,
  dispatch: Dispatch<AllAdminActions>
] => {
  const {
    administrationStateV2: { patientActivities },
    dispatch,
  } = useAdministrationContextV2();

  const pagination = usePaginatedRequest(
    `administration/patientsettings/activities/`,
    12,
    patientActivities.book
  );

  return [patientActivities, pagination, dispatch];
};
