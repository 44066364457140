import Header from './components/Header';
import PatientPreviewTable from 'components/PatientPreviewTable';
import styles from './styles.module.css';
import FiltersButton from 'components/FiltersButton';
import SearchBar from 'components/SearchBar';
import { PaginationControls, QuickFilters, usePagination } from 'components/Pagination';
import { QuickFilter } from 'types/Shared/Pagination';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { usePatientContext } from 'screens/Patients/store/PatientContext';
import {
  setListFilter,
  setPatientCompleteList,
  setSearchValue,
} from 'screens/Patients/store/PatientList/patientListActionCreators';
import { getPaginatedPatients } from 'services/patientService';
import { useHistory } from 'react-router-dom';
import { baseTabRouteResolver } from '../PatientTabs/util/tabRouteResolverMap';
import { setActions, setBreadcrumbPathList } from 'util/commonContext/NavigationContext';
import { patientsInitialBreadcrumbPathList } from 'screens/Patients/util/initialBreadcrumbPathList';
import PatientGet from 'types/ApiModels/Patients/PatientGet';
import tabEnum from '../PatientTabs/util/tabEnum';
import { ELEMENTS_PER_PAGE } from 'components/Pagination/Context';
import { debouncePromiseValue } from 'util/utils';
import VytracSpinner from 'components/vytrac-spinner';
import PatientListActions from './Actions';

import {
  goToFirstPage,
  setIsLoading,
  setPaginationMaxCount,
  setPaginationMaxPage,
} from 'components/Pagination/store/action-creators';
import { PatientQuickFilter, QuickFilterLabel } from './QuickFilters';

const PatientListWithFilters = () => {
  const {
    dispatch: patientDispatch,
    patientState: { patientList },
  } = usePatientContext();

  const { paginationDispatch, paginationState } = usePagination();

  const history = useHistory();

  const firstRender = useRef<boolean>(true);
  const previousSearchValue = useRef<string>('');

  useEffect(() => {
    patientDispatch(setBreadcrumbPathList(patientsInitialBreadcrumbPathList));
    patientDispatch(setActions(PatientListActions));
  }, [patientDispatch]);

  const fetchPaginationState = useCallback(
    async (searchValue: string) => {
      try {
        const paginatedPatients = await getPaginatedPatients(
          searchValue,
          patientList.filter,
          paginationState.currentPageIndex + 1,
          ELEMENTS_PER_PAGE
        );

        //paginatedPatients.results = paginatedPatients;

        // console.log('Paginated patients', paginatedPatients)

        //patientDispatch(setPatientCompleteList(paginatedPatients.results));
        patientDispatch(setPatientCompleteList(paginatedPatients.results));
        paginationDispatch(setPaginationMaxPage(paginatedPatients.pages - 1));
        if (firstRender.current) {
          paginationDispatch(setPaginationMaxCount(paginatedPatients.count));
          firstRender.current = false;
        }
      } catch (exception) {
        throw exception;
      }
      paginationDispatch(setIsLoading(false));
    },
    [paginationState.currentPageIndex, patientList.filter, paginationDispatch, patientDispatch]
  );

  const debouncedFetch = useMemo(
    () => debouncePromiseValue(fetchPaginationState, 500),
    [fetchPaginationState]
  );

  // Fetch patients
  useEffect(() => {
    paginationDispatch(setIsLoading(true));
    if (patientList.searchValue !== previousSearchValue.current) {
      debouncedFetch(patientList.searchValue);
    } else {
      fetchPaginationState(patientList.searchValue);
    }
  }, [
    paginationState.currentPageIndex,
    patientList.searchValue,
    patientList.filter,
    debouncedFetch,
    paginationDispatch,
    patientDispatch,
  ]);

  useEffect(() => {
    paginationDispatch(goToFirstPage());
  }, [patientList.searchValue, paginationDispatch]);

  const onPatientClick = useCallback(
    async (patient: PatientGet) => {
      history.push(baseTabRouteResolver(patient.id.toString(), tabEnum.PATIENT_STATUS));
    },
    [history]
  );

  const filters: QuickFilter[] = Object.keys(PatientQuickFilter).map((key) => ({
    sortKey: PatientQuickFilter[key],
    label: QuickFilterLabel[key],
    onClick: () =>
      patientDispatch(
        setListFilter(
          patientList.filter === PatientQuickFilter[key] ? null : PatientQuickFilter[key]
        )
      ),
    selected: PatientQuickFilter[key] === patientList.filter,
  }));

  return (
    <>
      <Header />
      <div className={styles.main}>
        <QuickFilters filters={filters} />
        <div className="d-flex gap">
          <SearchBar onChange={(e) => patientDispatch(setSearchValue(e.target.value))} />
          <FiltersButton />
        </div>
      </div>
      <PatientPreviewTable
        patientList={patientList.complete}
        onPatientClick={onPatientClick}
        isLoading={paginationState.isLoading}
      />
      <div className={styles.footer}>
        <PaginationControls disablePagination={paginationState.isLoading} />
      </div>
      {paginationState.isLoading && (
        <div className="position-absolute" style={{ top: '50%', right: '50%' }}>
          <VytracSpinner />
        </div>
      )}
    </>
  );
};

export default PatientListWithFilters;
