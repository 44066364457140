import { SHOW_ADD_MODAL, SET_STICKY_NOTES, SHOW_EDIT_MODAL } from './sticky-notes-action-types';

export const setStickyNotes = (stickyNotes) => {
  return {
    type: SET_STICKY_NOTES,
    payload: stickyNotes,
  };
};

export const ShowAddStickyNote = () => {
  return {
    type: SHOW_ADD_MODAL,
  }
};

export const ShowEditStickyNote = () => {
  return {
    type: SHOW_EDIT_MODAL,
  }
};
