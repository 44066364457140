import { useAuthContext } from 'auth/store/AuthContext';
import CardWithLinkButton from 'components/CardWithLinkButton';
import { useState } from 'react';
import { getCurrentPermissionBySection } from 'util/permissionUtils';

const PatientActivityPoints = () => {
  const [patientActivityPoints, setPatientActivityPoints] = useState([]);

  const currentSection = 'ADMINISTRATION_dashboard';
  let permissions = {};
  const { currentUser } = useAuthContext();
  permissions = currentUser.permissions;
  const permissionKey = getCurrentPermissionBySection(permissions, currentSection);
  
  return permissionKey[0].type ==  'manage' ? 
  (
    <CardWithLinkButton
      header={{
        linkLabel: 'Open',
        text: 'Patient activity points',
      }}
    >
      {patientActivityPoints?.length ? null : 'No data available'}
    </CardWithLinkButton>
  ) :
  (
    <CardWithLinkButton
      header={{
        linkLabel: '',
        text: 'Patient activity points',
      }}
    >
      {patientActivityPoints?.length ? null : 'No data available'}
    </CardWithLinkButton>
  )
};
export default PatientActivityPoints;
