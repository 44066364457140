import PatientRingLink from 'components/patient-ring-link';
import { PatientSettingItem } from 'types/ApiModels/Administration';
import { TrackingActivityTemplate } from 'types/ApiModels/Administration';
import GenericColumn from 'types/Shared/GenericColumn';
import { parseMs } from 'util/dateUtils';

//I can't type this bc of the dynamic columns (tried with Record<string,number> but woulndn't allow me)
export const mapPatientTrackingActivityToRow = (pta: PatientSettingItem) => {
  const baseObj = {
    patient: {
      avatar_status: pta.status,
      full_name: pta.full_name,
      photo: pta.photo,
      patientId: pta.id,
    },
  };
  const activityTimes = pta.activities.map((a) => ({
    name: a.name,
    duration: parseMs(a.duration),
  }));
  for (const at of activityTimes) {
    baseObj[at.name] = at.duration;
  }
  return baseObj;
};

export const getTimeTrackingActivityColumns = (
  activityTemplates: TrackingActivityTemplate[]
): GenericColumn[] => {
  const fixed: GenericColumn[] = [
    {
      key: 'patient',
      title: 'Patient',
      render: (row) =>
        PatientRingLink({
          avatar_status: row.patient.avatar_status,
          full_name: row.patient.full_name,
          photo: row.patient.photo,
          patientId: row.patient.patientId,
        }),
      headerClasses: 'flex-grow-1',
      tdClassName: 'flex-grow-1',
    },
  ];

  const variable = activityTemplates.map<GenericColumn>((at) => {
    return {
      key: at.name,
      title: at.name,
      render: (row) => {
        const value = row[at.name];
        return value ?? 0;
      },
      cellTextAlign: 'center',
      headerClasses: 'flex-basis-10',
      tdClassName: 'flex-basis-10',
    };
  });

  return [...fixed, ...variable];
};
