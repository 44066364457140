import { useAuthContext } from 'auth/store/AuthContext';
import Button from 'components/Button';
import VytracModal from 'components/Modal';
import { SCOPES } from 'components/PermissionsGate/permission-maps';
import PermissionsGate from 'components/PermissionsGate/PermissionsGate';
import { FC, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useAdminPopulations } from 'screens/Administration/store/hooks';
import TabEnum from 'screens/Templates/util/TabEnum';
import TabRouteMap from 'screens/Templates/util/TabRouteMap';
import { PermissionKeys } from 'types/ApiModels/Administration/permission-keys';
import { getCurrentPermissionBySection } from 'util/permissionUtils';

interface AddPopulationProps {
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
}
const AddPopulationModal: FC<AddPopulationProps> = ({ show, onClose, onConfirm }) => {
  return (
    <VytracModal
      show={show}
      title="Add Population"
      body={
        <span className="font-size-big">
          You will be redirected to the template section. All the unsaved changes will be lost.
          Would you like to proceed?
        </span>
      }
      onClose={onClose}
      footer={
        <div className="d-flex w-100 justify-content-between">
          <Button label="cancel" variant="cancel" onClick={onClose} />
          <Button label="confirm" variant="confirm" onClick={onConfirm} />
        </div>
      }
    />
  );
};

const AdminPopulationActions = ({ history }: RouteComponentProps) => {
  const [{ deletedPopulations, updatedIsActivePopulations, resetChanges, submitChanges }] =
    useAdminPopulations();
  const [showAddPopulation, setShowAddPopulation] = useState<boolean>(false);
  const isNotDirty = deletedPopulations.length === 0 && updatedIsActivePopulations.length === 0;
  const onModalConfirm = () => {
    history.push(`${TabRouteMap[TabEnum.CAREPLAN_TEMPLATES]}/create`);
  };
  const currentSection = 'ADMINISTRATION_patient_settings';
  let permissions = {};
  const { currentUser } = useAuthContext();
  permissions = currentUser.permissions;
  const permissionKey = getCurrentPermissionBySection(permissions, currentSection);

  return (
    <div className={`d-flex gap`}>
      <PermissionsGate can={permissionKey[0].type}>
        <Button label="cancel" variant="dull" disabled={isNotDirty} onClick={resetChanges} />
        <Button
          label="save changes"
          variant="confirm"
          disabled={isNotDirty}
          onClick={submitChanges}
        />
        <Button label="add population" onClick={() => setShowAddPopulation(true)} />
        <AddPopulationModal
          show={showAddPopulation}
          onClose={() => setShowAddPopulation(false)}
          onConfirm={onModalConfirm}
        />
      </PermissionsGate>
    </div>
  );
};
export default withRouter(AdminPopulationActions);
