import { Dispatch, useCallback, useEffect } from 'react';
import { getKeywordsTemplates } from 'services/administrationService';
import { useAdministrationContextV2 } from '../AdministrationContextV2';
import {
  NotesKeywordsAction,
  NotesKeywordsActionCreators,
  NotesKeywordsState,
} from '../patient-settings/notes-keywords';

const { setTemplates } = NotesKeywordsActionCreators;

export const useKeywordsTemplates = (): [
  state: NotesKeywordsState,
  dispatch: Dispatch<NotesKeywordsAction>
] => {
  const {
    administrationStateV2: { notesKeywords },
    dispatch,
  } = useAdministrationContextV2();

  const fetchNotesKeywordsTemplates = useCallback(async () => {
    const res = await getKeywordsTemplates();
    dispatch(setTemplates(res));
  }, [dispatch]);

  useEffect(() => {
    fetchNotesKeywordsTemplates();
  }, [fetchNotesKeywordsTemplates]);

  return [notesKeywords, dispatch];
};
