import { Client, Conversation } from '@twilio/conversations';
import { useCallback, useEffect, useMemo, useState } from 'react';

type ConversationStatus = 'loading' | 'rejected' | 'active';

const useTwilioConversation = (twilioClient: Client, conversationSID: string) => {
  const [conversation, setConversation] = useState<Conversation>(null);
  const [status, setStatus] = useState<ConversationStatus>(
    conversationSID ? 'loading' : 'rejected'
  );
  const [participantInfoBySID, setParticipantInfoBySID] =
    useState<Record<string, ParticipantInfo>>();

  const isLoading = useMemo(() => status === 'loading', [status]);

  const getConversation = useCallback(async (client: Client, sid: string) => {
    try {
      setStatus('loading');
      const res = await client.getConversationBySid(sid);
      setConversation(res);
      setStatus('active');
    } catch (reason) {
      //An error means the client that requested the conversation has no access to it
      setStatus('rejected');
      console.error('Error when trying to fetch conversation with sid:', sid, reason);
    }
  }, []);

  useEffect(() => {
    if (!twilioClient || !conversationSID) return;
    getConversation(twilioClient, conversationSID);
  }, [conversationSID, getConversation, twilioClient]);

  useEffect(() => {
    if (!conversation) return;
    conversation.getParticipants().then((ps) => {
      const participantInfoBySID = ps.reduce((r, p) => {
        r[p.sid] = {
          identity: p.identity,
        };
        return r;
      }, {});
      setParticipantInfoBySID(participantInfoBySID);
    });
  }, [conversation]);

  return useMemo(
    () => ({
      isLoading,
      participantInfoBySID,
      conversation,
      status,
    }),
    [conversation, isLoading, participantInfoBySID, status]
  );
};

export default useTwilioConversation;
