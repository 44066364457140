import { useAuthContext } from 'auth/store/AuthContext';
import Button from 'components/Button';
import PermissionsGate from 'components/PermissionsGate/PermissionsGate';
import { useCallback, useMemo } from 'react';
import {
  resetDirtyForms,
  setOpenAddFormModal,
  updateDirtyForms,
} from 'screens/Administration/store/Forms';
import { useAdminForms } from 'screens/Administration/store/hooks';
import { updateAdminForm } from 'services/administrationService';
import { getCurrentPermissionBySection } from 'util/permissionUtils';

export const FormsActions = () => {
  const [{ currentForm, dirtyForms }, dispatch] = useAdminForms();

  const isDirty = useMemo(() => Object.keys(dirtyForms).length > 0, [dirtyForms]);
  const handleUpdateDirtyForms = useCallback(async () => {
    Object.keys(dirtyForms).forEach(async (key) => {
      const parsedForm = {
        ...dirtyForms[key],
        population: dirtyForms[key].population.map((pop) => pop.id),
      };
      await updateAdminForm(parsedForm, parseInt(key));
      dispatch(updateDirtyForms());
    });
  }, [dirtyForms, dispatch]);

  const currentSection = 'ADMINISTRATION_forms';
  let permissions = {};
  const { currentUser } = useAuthContext();
  permissions = currentUser.permissions;
  const permissionKey = getCurrentPermissionBySection(permissions, currentSection);

  return (
    <div className="d-flex gap">
      <PermissionsGate can={permissionKey[0].type}>
        <Button
          label="Cancel"
          variant="cancel"
          disabled={!isDirty}
          onClick={() => dispatch(resetDirtyForms())}
        />
        <Button
          label="Save changes"
          variant="confirm"
          disabled={!isDirty}
          onClick={handleUpdateDirtyForms}
        />
        {!currentForm && (
          <Button label="Add form" onClick={() => dispatch(setOpenAddFormModal(true))} />
        )}
      </PermissionsGate>
    </div>
  );
};
