import { Field } from 'formik';
import {
  FC,
  FocusEventHandler,
  HTMLInputTypeAttribute,
  LegacyRef,
  MutableRefObject,
  ReactNode,
  useRef,
} from 'react';
import styles from './styles.module.css';

interface TextInputFormikProps {
  name: string;
  className?: string;
  autoFocus?: boolean;
  selectOnFocus?: boolean;
  style?: React.CSSProperties;
  placeholder?: string;
  type?: HTMLInputTypeAttribute;
  children?: ReactNode;
  inputContainerClassName?: string;
  disabled?: boolean;
}

const TextInputFormik: FC<TextInputFormikProps> = ({
  name,
  className,
  autoFocus,
  selectOnFocus,
  style,
  placeholder,
  children,
  type = 'text',
  inputContainerClassName = 'vytrac-input rounded px-2',
  disabled
}: TextInputFormikProps) => {
  const inputRef = useRef<HTMLInputElement>();
  return (
    <Field name={name}>
      {({ field }) => (
        <div className={inputContainerClassName}>
          <input
            {...field}
            className={`vytrac-input rounded ${className ?? ''}`}
            name={name}
            ref={inputRef}
            onFocus={() => {
              if (selectOnFocus) inputRef.current.select();
            }}
            autoFocus={autoFocus}
            style={style}
            placeholder={placeholder}
            type={type}
            disabled={disabled}
          />
          {children}
        </div>
      )}
    </Field>
  );
};
export default TextInputFormik;
