
import Button from 'components/Button';
import { Form, Formik, useFormikContext } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useAdministrationContextV2 } from 'screens/Administration/store';
import {
  setAccountSettingsFormDirty,
  setAccountSettingsFormReset,
  setAccountSettingsFormSubmit,
} from 'screens/Administration/store/AccountSettings';
import { getAccount, getTheme, setTheme, updateAccount } from 'services/administrationService';
import { getCurrentUserInfo } from 'services/userService';
import SaveChangesConfirmationModal from 'components/SaveChangesConfirmationModal';
import { IAccount } from 'types/Administration/Account/Account';
import { updateTheme } from 'util/themeUtils';
import FirstColumn from './FirstColumn/FirstColumn';
import LastColumn from './LastColumn/LastColumn';
import { emptyAccountSettings } from './utils';
import AccountSettingsValidationSchema from './utils/AccountSettingsValidationSchema';
import { useAuthContext } from 'auth/store/AuthContext';
import { getCurrentPermissionBySection, PAGE } from 'util/permissionUtils';
import PermissionsGate from 'components/PermissionsGate/PermissionsGate';


export const AccountSettingsActions = () => {
  const [ShowConfirmModal, setShowConfirmModal] = useState(false);
  const {
    administrationStateV2: {
      accountSettings: {
        dirtyAccountSettingsForm,
        resetAccountSettingsForm,
        submitAccountSettingsForm,
      },
    },
  } = useAdministrationContextV2();

  const currentSection = PAGE.ADMINISTRATION.ACCOUNT_SETTINGS;
  let permissions = {};
  const { currentUser } = useAuthContext();
  permissions = currentUser.permissions;
  const permissionKey = getCurrentPermissionBySection(permissions, currentSection);

  return (
    <div className="d-flex">
      <PermissionsGate can={permissionKey[0].type}>
      <Button
        variant="cancel"
        label="Cancel"
        className="mr-2"
        onClick={resetAccountSettingsForm}
        disabled={!dirtyAccountSettingsForm}
      />
      <Button
        variant="confirm"
        label="Save Changes"
        className="mr-2"
        disabled={!dirtyAccountSettingsForm}
        onClick={()=>setShowConfirmModal(true)}
        type="submit"
      />
      {ShowConfirmModal ? (
              <SaveChangesConfirmationModal
                onCancel={() => setShowConfirmModal(false)}
                show={ShowConfirmModal}
                onConfirm={() => {
                  submitAccountSettingsForm();
                  setShowConfirmModal(false)
                }
                }
              />
            ) : null}
    </PermissionsGate>
    </div>
  );
};

const AccountSettingsContent = (account, formik) => {
  const { dispatch } = useAdministrationContextV2();
  const { handleSubmit, dirty, resetForm } = useFormikContext<IAccount>();

  useEffect(() => {
    dispatch(setAccountSettingsFormSubmit(handleSubmit));
  }, [handleSubmit]);

  useEffect(() => {
    dispatch(setAccountSettingsFormDirty(dirty));
  }, [dirty]);

  useEffect(() => {
    dispatch(setAccountSettingsFormReset(resetForm));
  }, [resetForm]);

  return (
    <Row>
      <Col className="px-3" lg={9}>
        <FirstColumn account={account}/>
      </Col>
      <Col className="px-3" lg={3}>
        <LastColumn />
      </Col>
    </Row>
  );
};

const AccountSettings = () => {
  const [account, setAccount] = useState<IAccount>(emptyAccountSettings);

  const onSubmit = useCallback((values) => {
    const updateAccountSettings = async () => {
      await updateAccount(values);
      updateTheme();
    };
    updateAccountSettings()
  }, []);

  useEffect(() => {
    const fetchAccountSettings = async () => {
      const data = await getAccount();
      setAccount(data);
    };
    fetchAccountSettings();
    return () => setAccount(emptyAccountSettings);
  }, []);

  return (
    <Formik
      validationSchema={AccountSettingsValidationSchema}
      onSubmit={(values, { resetForm }) => {
        onSubmit(values);
        resetForm({ values });
      }}
      enableReinitialize
      initialValues={account}
    >
        <Form>
          <AccountSettingsContent account={account} />
        </Form>
    </Formik>
  );
};

export default AccountSettings;
