import { useEffect, useRef, useState } from 'react';
import { getPatientInfoByIdentities } from 'services/conversations-service';
import { ConversationMetadata } from 'types/ApiModels/conversations';

export const useConversationMetadata = (
  identities: string[],
  metadataFeed?: Record<string, ConversationMetadata>,
  source?: string
) => {
  const [metadata, setMetadata] = useState<Record<string, ConversationMetadata>>(null);
  const conversationMetadataCacheByIdentityRef = useRef<Record<string, ConversationMetadata>>({});

  useEffect(() => {
    if (!metadataFeed) return;
    Object.entries(metadataFeed).forEach(([key, value]) => {
      if (conversationMetadataCacheByIdentityRef.current[key]) return;
      conversationMetadataCacheByIdentityRef.current[key] = value;
    });
    setMetadata(conversationMetadataCacheByIdentityRef.current);
  }, [metadataFeed, source]);

  useEffect(() => {
    if (!identities?.length) return;
    const keys = Object.keys(conversationMetadataCacheByIdentityRef.current);
    const exclusion = identities.filter((id) => !keys.includes(id));
    if (!exclusion.length) return;
    getPatientInfoByIdentities(exclusion).then(([r]) => {
      if (!r.length) return;
      r.forEach((cm) => {
        conversationMetadataCacheByIdentityRef.current[cm.twilio_identity] = cm;
      });
      setMetadata(conversationMetadataCacheByIdentityRef.current);
    });
  }, [identities]);

  return metadata;
};
