import { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Role } from 'types/ApiModels/Administration';
import { Provider, Title } from 'types/ApiModels/Providers/Provider';
import Specialty from 'types/ApiModels/Providers/Specialty';
import { Language } from 'types/ApiModels/Users';
import Sex from 'types/ApiModels/Users/Sex';
import ProviderActiveStatus from '../ProviderActiveStatus';
import ProviderAssignedPatients from '../ProviderAssignedPatients';
import ProviderIdentification from '../ProviderIdentification';
import ProviderInvitations from '../ProviderInvitations';
import ProviderPatientCases from '../ProviderPatientCases';
import ProviderPersonalInformation from '../ProviderPersonalInformation';
import ProviderSatisfaction from '../ProviderSatisfaction';
import ProviderSpecialties from '../ProviderSpecialties';

interface ProviderProfileFormProps {
  selectedProvider: Provider;
  genderOptions: Sex[];
  languages: Language[];
  roles: Role[];
  titles: Title[];
  specialties: Specialty[];
  allowProfilePicUpload?: boolean;
  active?: boolean;
}

const ProviderProfileForm: FC<ProviderProfileFormProps> = ({
  selectedProvider,
  genderOptions,
  languages,
  roles,
  titles,
  specialties,
  allowProfilePicUpload = false,
  active,
}: ProviderProfileFormProps) => {
  //? I think this one needs a context to wrap around all the things used within so that we don't prop drill
  return (
    <Row className="w-100">
      <Col md={9} className="d-flex flex-column gap-md pl-0">
        <Row className="flex-grow-1">
          <ProviderIdentification
            selectedProvider={selectedProvider}
            genderOptions={genderOptions}
            languages={languages}
            titles={titles}
            roles={roles}
            allowProfilePicUpload={allowProfilePicUpload}
          />
        </Row>
        <Row>
          <ProviderPersonalInformation />
        </Row>
        <Row>
          <ProviderAssignedPatients />
        </Row>
      </Col>
      <Col md={3} className="d-flex flex-column gap-md">
        <Row>
          <ProviderSatisfaction selectedProvider={selectedProvider} satisfaction={{good: 50, bad: 10}}/>
        </Row>
        <Row>
          <ProviderSpecialties
            fieldName="specialties"
            selectedProvider={selectedProvider}
            specialties={specialties}
          />
        </Row>
        <Row>
          <ProviderActiveStatus activeStatus={active}/>
        </Row>
        <Row>
          <ProviderPatientCases selectedProvider={selectedProvider} />
        </Row>
        <Row>
          <ProviderInvitations />
        </Row>
      </Col>
    </Row>
  );
};
export default ProviderProfileForm;
