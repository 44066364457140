import { useAuthContext } from 'auth/store/AuthContext';
import Button from 'components/Button';
import PermissionsGate from 'components/PermissionsGate/PermissionsGate';
import React from 'react';
import { useHistory } from 'react-router-dom';
import TabEnum from 'screens/Templates/util/TabEnum';
import TabRouteMap from 'screens/Templates/util/TabRouteMap';
import { getCurrentPermissionBySection } from 'util/permissionUtils';
import styles from './styles.module.css';

const Actions = () => {
  const history = useHistory();

  const currentSection = 'TEMPLATES_questionnaires';
  let permissions = {};
  const { currentUser } = useAuthContext();
  permissions = currentUser.permissions;
  const permissionKey = getCurrentPermissionBySection(permissions, currentSection);

  return (
    <div className={styles.questionnaireTemplateActions}>
      <PermissionsGate can={permissionKey[0].type}>
        <Button
          label="create new questionnaire"
          onClick={() => history.push(`${TabRouteMap[TabEnum.QUESTIONNAIRES]}/create`)}
        />
      </PermissionsGate>
    </div>
  );
};
export default Actions;
