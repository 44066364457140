import React from 'react';
import UserBubble from '../UserBubble';
const PatientListItem = ({ patient = { firstName: '', lastName: '' }, className }) => {
  return (
    <div className={`d-flex align-items-center ${className || ''}`}>
      <UserBubble
        user={{ first_name: patient.firstName, last_name: patient.lastName }}
        containerClassNameList={['ml-0', 'rounded-circle']}
        imgClassNameList={['ml-0', 'rounded-circle']}
      />
      {patient.firstName} {patient.lastName}
    </div>
  );
};
export default PatientListItem;
