import providerIcon from 'assets/icons/provider-icon.svg';
import { useAuthContext } from 'auth/store/AuthContext';
import CardWithLinkButton from 'components/CardWithLinkButton';
import { useCallback, useEffect } from 'react';
import { useAdministrationContext, useAdministrationContextV2 } from 'screens/Administration/store';
import { setProviderSignups } from 'screens/Administration/store/Dashboard/dashboardActionCreators';
import { toggleShowInviteProvider } from 'screens/Administration/store/Providers';
import { getProvidersSignups } from 'services/administrationService';
import { getCurrentPermissionBySection } from 'util/permissionUtils';
import styles from '../AdminDashboard.module.css';
import ProviderSignups from './ProviderSignups';

const NewProvidersSignups = () => {
  const {
    administrationStateV2: {
      dashboard: { providerSignups },
    },
    dispatch,
  } = useAdministrationContextV2();

  const fetchProvidersSignups = useCallback(async () => {
    if (!providerSignups) {
      const res = await getProvidersSignups();
      dispatch(setProviderSignups(res.results));
    }
  }, [dispatch, providerSignups]);

  useEffect(() => {
    fetchProvidersSignups();
  }, [fetchProvidersSignups]);


  const currentSection = 'ADMINISTRATION_dashboard';
  let permissions = {};
  const { currentUser } = useAuthContext();
  permissions = currentUser.permissions;
  const permissionKey = getCurrentPermissionBySection(permissions, currentSection);

  return permissionKey[0].type ==  'manage' ? 
      (
        <CardWithLinkButton
        bodyClassName={`d-flex flex-column ${styles['dashboard-card']}`}
        header={{
          linkLabel: 'Invite provider',
          text: 'New provider sign-ups',
          onClick: () => {
            dispatch(toggleShowInviteProvider());
          },
        }}
      >
        {!providerSignups?.length ? (
          <div className="d-flex flex-column gap-md justify-content-center align-items-center w-100 flex-grow-1">
            <img src={providerIcon} alt="no new provider sign-ups" />
            <div>There are no new registered providers</div>
          </div>
        ) : (
          <ProviderSignups providerSignups={providerSignups} />
        )}
      </CardWithLinkButton>
      )
      
      :  (
        <CardWithLinkButton
        bodyClassName={`d-flex flex-column ${styles['dashboard-card']}`}
        header={{
          linkLabel: '',
          text: 'New provider sign-ups',
          onClick: () => {},
        }}
      >
        {!providerSignups?.length ? (
          <div className="d-flex flex-column gap-md justify-content-center align-items-center w-100 flex-grow-1">
            <img src={providerIcon} alt="no new provider sign-ups" />
            <div>There are no new registered providers</div>
          </div>
        ) : (
          <ProviderSignups providerSignups={providerSignups} />
        )}
      </CardWithLinkButton>
      );
};
export default NewProvidersSignups;
