import { useAuthContext } from 'auth';
import { useMemo, useRef } from 'react';
import { VirtuosoHandle } from 'react-virtuoso';
import { usePatientFormikContext } from 'screens/Patients/store';
import { registerMessageMention } from 'services/conversations-service';
import { useTwilioConversation } from 'services/twilio';
import { useTwilioClient } from 'store/twilio-client';
import { useTwilioMessages } from 'store/twilio-messages/hooks';
import { CareTeamProvider, CareTeamTier } from 'types/ApiModels/CareTeam';
import { ConversationMetadata, MessageSimplified } from 'types/ApiModels/conversations';
import MentionItemCustom from 'types/Shared/MentionItemCustom';
import { TeamNotesMessages } from './team-notes-messages';

const useProvidersMetadata = (tiers: CareTeamTier[]) => {
  return useMemo(() => {
    const providersSortedByHighestTier: CareTeamProvider[] = [];
    const careTeamMetadataByIdentity = tiers?.reduce<Record<string, ConversationMetadata>>(
      (result, t) => {
        providersSortedByHighestTier.push(...t.providers);
        t.providers.forEach((p) => {
          result[p.twilio_identity] = {
            twilio_identity: p.twilio_identity,
            full_name: `${p?.first_name} ${p?.last_name}`,
            id: p.id,
            photo_thumbnail: p.photo_thumbnail,
          };
        });
        return result;
      },
      {}
    );
    return { careTeamMetadataByIdentity, providersSortedByHighestTier };
  }, [tiers]);
};

export const TeamNotesMessagesContainer = () => {
  const { currentUser } = useAuthContext();
  const virtuosoHandlerRef = useRef<VirtuosoHandle>();
  const { client, isLoading: isTwilioLoading } = useTwilioClient();
  const { selectedPatient } = usePatientFormikContext();
  const { conversation, isLoading, participantInfoBySID, status } = useTwilioConversation(
    client,
    selectedPatient?.twilio_conversation_sid
  );
  const {
    messagesState: { messages, areMessagesLoading },
    loadMoreMessages,
  } = useTwilioMessages(conversation);
  const providersMetadata = useProvidersMetadata(selectedPatient?.care_team?.tiers);

  const getMetadataByParticipantSID = (participantSID: string) => {
    return providersMetadata?.careTeamMetadataByIdentity?.[
      participantInfoBySID[participantSID]?.identity
    ];
  };

  const handleSendMessage = async (message: string, mentions: MentionItemCustom[]) => {
    if (!message) return;
    //todo: if there are any mentions (any type) we should send them through to backend
    const [keywordIds, mentionIds] = mentions?.reduce<[keywordIds: string[], mentionIds: number[]]>(
      (result, m) => {
        const [type, id] = m.id.split('-');

        const functionByType = {
          keyword: () => {
            result[0].push(id);
          },
          mention: () => {
            result[1].push(parseInt(id));
          },
        };
        functionByType[type]();
        return result;
      },
      [[], []]
    ) ?? [[], []];
    const attributes = {
      mentions: mentions as never,
    };
    const lastMessageIndex = await conversation
      .prepareMessage()
      .setBody(message)
      .setAttributes(attributes)
      .build()
      .send();
    const lastMessage = (await conversation.getMessages(1, lastMessageIndex)).items?.[0];
    await conversation.updateLastReadMessageIndex(lastMessageIndex);
    (keywordIds?.length || mentionIds?.length) &&
      (await registerMessageMention({
        keywords: keywordIds,
        mentions: mentionIds,
        patient_id: selectedPatient.id,
        twilio_message_sid: lastMessage.sid,
        twilio_conversation_sid: conversation.sid,
        twilio_message_index: lastMessage.index,
      }));
  };

  const handleSendMessageSubthread = async (message: string, subthreadSID: string) => {
    //todo: see when conversation has no subthread yet how should we create it ?. add conversation type to these.
    const subthread = await client.getConversationBySid(subthreadSID);
    const lastMessageIndex = await subthread.prepareMessage().setBody(message).build().send();
    conversation.updateLastReadMessageIndex(lastMessageIndex);
  };

  const handleScrollToOption = async (option: MessageSimplified) => {
    // need to find the message first to be able to scroll to (maybe it is out of the scope so we must look for it recursively)
    let foundMessageIdx = messages.findIndex((m) => m.sid === option.sid);
    while (foundMessageIdx === -1) {
      const loaded = await loadMoreMessages();
      if (!loaded) break;
      foundMessageIdx = loaded.findIndex((lm) => lm.sid === option.sid);
    }
    if (foundMessageIdx === -1) return;
    // since load more messages does things async, we need to delay this to the next tick rather than doing it immediatelly after loadmoremessages otherwise we're stuck with a weird no-scroll behaviour
    setImmediate(() => {
      virtuosoHandlerRef.current.scrollToIndex(foundMessageIdx);
    });
  };
  return (
    <TeamNotesMessages
      conversationSid={conversation?.sid}
      messages={messages}
      loadMoreMessages={loadMoreMessages}
      getMetadataByParticipantSID={getMetadataByParticipantSID}
      selfMetadata={providersMetadata.careTeamMetadataByIdentity === undefined ?  {id: 0, full_name: '??', photo_thumbnail: '??', twilio_identity: '??'} : providersMetadata.careTeamMetadataByIdentity[currentUser?.twilio_identity]}
      scrollToOption={handleScrollToOption}
      handleSendMessage={handleSendMessage}
      participants={providersMetadata.providersSortedByHighestTier.map((p) => ({
        first_name: p.first_name,
        last_name: p.last_name,
        imageUrl: p.photo_thumbnail,
        id: p.id,
      }))}
      isLoading={isLoading || isTwilioLoading}
      hasNoPermissions={status === 'rejected' || !selectedPatient?.twilio_identity}
      virtuosoHandler={virtuosoHandlerRef}
    />
  );
};
