import axios from 'axios';

export const getTimezone = async () => {
  try {
    const response = await axios.get('https://worldtimeapi.org/api/timezone', {
      transformRequest: (data, headers) => {
        delete headers.common['Authorization'];
        return data;
      },
    });
    return response.data;
  } catch (exception) {
    throw exception;
  }
};
