import Button from 'components/Button';
import VyTracModal from 'components/Modal';
import { SearchBarAlt } from 'components/SearchBar';
import { FC, useState } from 'react';
import { PopulationWithCount } from 'types/ApiModels/Patients/Population';
import styles from '../BulletedModals.module.css';

interface PopulationsModalProps {
  populations: PopulationWithCount[];
  assignedPopulationIds: number[];
  handleClose: () => void;
  handleConfirm: (populationIds: number[]) => void;
  show: boolean;
}

const PopulationsModal: FC<PopulationsModalProps> = ({
  populations,
  assignedPopulationIds,
  handleClose,
  handleConfirm,
  show,
}: PopulationsModalProps) => {
  const [currentlySelected, setCurrentlySelected] = useState(assignedPopulationIds);
  const [search, setSearch] = useState('');  

  const handleSelect = (selectedId: number) => {
    setCurrentlySelected((ids) => {
      const existingIdx = ids.findIndex((id) => id === selectedId);
      return existingIdx === -1 ? [...ids, selectedId] : ids.filter((id) => id !== selectedId);
    });
  };
  return (
    <VyTracModal
      show={show}
      title="Assign a population"
      onClose={handleClose}
      body={
        <div className="d-flex flex-column gap">
          <SearchBarAlt onChange={(e) => setSearch(e.target.value)} value={search} />
          <div>
            {populations?.flatMap((s) => {
              if (!s.population.toLowerCase().includes(search.toLowerCase())) return [];

              const isSelected = currentlySelected?.some((asId) => s.id === asId);
              return [
                <div
                  key={s.id}
                  className={`d-flex gap justify-content-between ${styles['container']} cursor-pointer`}
                  onClick={() => handleSelect(s.id)}
                >
                  <div className="d-flex align-items-center">
                    <div
                      className={`${styles['custom-input']} ${isSelected ? styles.selected : ''}`}
                    ></div>
                  </div>
                  <div className="flex-grow-1 font-size-medium">{s.population}</div>
                </div>,
              ];
            })}
          </div>
        </div>
      }
      footer={
        <div className="d-flex w-100 justify-content-between">
          <Button label="CANCEL" variant="cancel" onClick={handleClose} />
          <Button label="CONFIRM" onClick={() => handleConfirm(currentlySelected)} />
        </div>
      }
    />
  );
};
export default PopulationsModal;
