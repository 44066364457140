import { useEffect, useState } from 'react';
import { getTokenForIdentity } from 'services/conversations-service';
import { getTwilioTokenFromLocalStorage, saveTwilioTokenToLocalStorage } from 'services/twilio';

export const useTwilioToken = (identity: string) => {
  const [token, setToken] = useState<string>();

  const getToken = async (identity: string) => {
    const currentToken = await getTwilioTokenFromLocalStorage();
    const isExpired = currentToken ? new Date(currentToken.expirationDate) < new Date() : false;
    if (!currentToken || isExpired) {
      getTokenForIdentity(identity).then(([res]) => {
        setToken(res.token);
        saveTwilioTokenToLocalStorage(res.token, res.expiration_date_utc, identity);
      });
      return;
    }
    setToken(currentToken.value);
  };

  useEffect(() => {
    if (!identity) return;
    getToken(identity);
  }, [identity]);

  return token;
};
