import { useAuthContext } from 'auth/store/AuthContext';
import Button from 'components/Button';
import PermissionsGate from 'components/PermissionsGate/PermissionsGate';
import { useNotesKeywords } from 'screens/Administration/store/hooks';
import { getCurrentPermissionBySection } from 'util/permissionUtils';

const NotesKeywordsActions = () => {
  const [
    {
      createdTemplates,
      deletedTemplates,
      updatedTemplates,
      submitTemplates,
      currentDefaultKeywordTemplateId,
      resetTemplateChanges,
    },
    ,
  ] = useNotesKeywords();
  const isDirty =
    Boolean(createdTemplates.length || deletedTemplates.length || updatedTemplates.length) ||
    currentDefaultKeywordTemplateId !== null;

  const currentSection = 'ADMINISTRATION_patient_settings';
  let permissions = {};
  const { currentUser } = useAuthContext();
  permissions = currentUser.permissions;
  const permissionKey = getCurrentPermissionBySection(permissions, currentSection);

  return (
    <div className="d-flex gap-sm">
      <PermissionsGate can={permissionKey[0].type}>
        <Button label="cancel" variant="dull" disabled={!isDirty} onClick={resetTemplateChanges} />
        <Button
          label="save changes"
          variant="confirm"
          disabled={!isDirty}
          onClick={submitTemplates}
        />
      </PermissionsGate>
    </div>
  );
};
export default NotesKeywordsActions;
