import { FC } from 'react';

interface PatientKeywordsCountCellProps {
  value: number;
  onKeywordCountClick: () => void;
}

const PatientKeywordsCountCell: FC<PatientKeywordsCountCellProps> = ({
  value,
  onKeywordCountClick,
}: PatientKeywordsCountCellProps) => {
  return (
    <button
      className="unstyled-button link"
      type="button"
      onClick={onKeywordCountClick}
      disabled={value === 0}
    >
      {value}
    </button>
  );
};
export default PatientKeywordsCountCell;
