import { authUserAxios, fakeAuthUserAxios, vytracAxios } from 'ajax';
import { AxiosError } from 'axios';
import { IAccount } from 'types/Administration/Account/Account';
import {
  EmailSetting,
  EmailShortCode,
  EmailTypeTuple,
  KeywordsTemplate,
} from 'types/ApiModels/Administration';
import { APIAccount } from 'types/ApiModels/Administration/Account';
import {
  PatientSignup,
  ProviderSignup,
  RoleSignup,
} from 'types/ApiModels/Administration/Dashboard';
import { PatientNotesKeywords } from 'types/ApiModels/Administration';
import PatientSettingItem from 'types/ApiModels/Administration/patient-setting-item';
import Role from 'types/ApiModels/Administration/Role';
import {
  CareTeam,
  CareTeamCreateUpdateRequest,
  CareTeamPatient,
  CurrentProviderCareTeam,
} from 'types/ApiModels/CareTeam';
import { PaginatedResponse } from 'types/ApiModels/General';
import { PopulationWithCount } from 'types/ApiModels/Patients/Population';
import { ProviderInviteRequest } from 'types/ApiModels/Providers/Provider';
import RolePermissions, {
  emptyRolePermissions,
  RolePermissionOptions,
} from 'types/Roles/RolePermissions';
import { parseError } from 'util/requestUtils';
import { objectToUrlParams } from 'util/utils';
import { IAPIAdminForm } from 'types/ApiModels/Administration/Form';
import { ITheme } from 'types/ApiModels/Administration/Theme/Theme';
import { PermissionKeys } from 'types/ApiModels/Administration';
import { useAuthContext } from 'auth';
import { getAccessToken } from './tokenService';

const URI = 'administration';

export const mapRoleToRolePermissions = (role: Role): RolePermissions => {
  if (!role.permissions) {
    return emptyRolePermissions;
  }
  const perms = role.permissions.reduce(
    (accum, curr) => {
      let ret: RolePermissions = { ...accum };
      if (curr.section.includes('PATIENTS_')) {
        ret.PATIENTS = RolePermissionOptions.custom;
      } else if (curr.section.includes('TEMPLATES_')) {
        ret.TEMPLATES = RolePermissionOptions.custom;
      } else if (curr.section.includes('ADMINISTRATION_')) {
        ret.ADMINISTRATION = RolePermissionOptions.custom;
      }
      ret = {
        ...ret,
        [curr.section]:
          curr.type === 'manage' ? RolePermissionOptions.manage : RolePermissionOptions.view,
      };
      return ret;
    },
    { ...emptyRolePermissions } as RolePermissions
  );
  perms.name = role.name;
  perms.id = role.id;
  perms.active = role.active;
  return perms;
};

export const mapRolePermissionsToRole = (role: RolePermissions): Role => {
  Object.keys(role).forEach((key) => {
    if (role[key] === RolePermissionOptions.none) {
      delete role[key];
    }
  });
  return {
    id: role.id as any,
    name: role.name,
    active: role.active,
    permissions: Object.keys(role)
      .filter((key) => key !== 'active' && key !== 'name' && key !== 'id')
      .map((key) => ({
        section: key as PermissionKeys,
        type: role[key] === RolePermissionOptions.manage ? 'manage' : 'view',
      })),
  };
};


export const mapToAccount = (apiAccount: APIAccount): IAccount => {
  const parsedData: IAccount = {
    generalEmail: apiAccount.general_email,
    generalPhone: apiAccount.general_phone,
    techSupportEmail: apiAccount.techSupportEmail,
    address: apiAccount.address,
    apt: apiAccount.apt,
    city: apiAccount.city,
    state: apiAccount.state,
    zip_code: apiAccount.zip_code,
    iosPatientApp: apiAccount.ios_patient_app,
    iosProviderApp: apiAccount.ios_provider_app,
    androidPatientApp: apiAccount.android_patient_app,
    androidProviderApp: apiAccount.android_provider_app,
    careChampionAndroidApp: apiAccount.careChampionAndroidApp,
    careChampioniosApp: apiAccount.careChampionAndroidApp,
    patientWebPortal: apiAccount.patient_web_portal,
    countryCode: apiAccount.country_code,
    timezone: apiAccount.timezone,
    dateFormat: apiAccount.date_format,
    nationalProviderIdentifier: apiAccount.national_provider_identifier,
    signature: apiAccount.signature,
    updatedAt: apiAccount.updated_at,
    ipaddress: apiAccount.ipaddress,
    brandName: apiAccount.brand_name,
    brandLogo: apiAccount.brand_logo,
    brandPracticeIcon: apiAccount.brand_practice_icon,
    brandColorScheme: apiAccount.brand_color_scheme,
    brandRewardsStoreFeed: apiAccount.brand_rewards_store_feed,
    brandRewardsRedeemLink: apiAccount.brand_rewards_redeem_link,
    updatedBy: apiAccount.updated_by,
  };
  return parsedData;
};

export const createFormData = (account: IAccount): FormData => {
  const data = new FormData();
  data.append('general_email', account.generalEmail);
  data.append('general_phone', account.generalPhone);
  data.append('tech_support_email', account.techSupportEmail);
  
  data.append('street_address_line_1', account.address);
  data.append('apt', account.apt);
  data.append('city', account.city);
  data.append('state', account.state);
  data.append('zip_code', account.zip_code);

  data.append('ios_patient_app', account.iosPatientApp);
  data.append('ios_provider_app', account.iosProviderApp);
  data.append('android_patient_app', account.androidPatientApp);
  data.append('android_provider_app', account.androidProviderApp);
  data.append('care_champion_android_app', account.careChampionAndroidApp);
  data.append('care_champion_ios_app', account.careChampioniosApp);
  data.append('patient_web_portal', account.patientWebPortal);
  data.append('country', account.countryCode);
  data.append('timezone', account.timezone);
  data.append('date_format', account.dateFormat);
  data.append('national_provider_identifier', account.nationalProviderIdentifier);
  data.append('updated_at', account.updatedAt);
  account.ipaddress && data.append('ipaddress', account.ipaddress);
  data.append('brand_name', account.brandName);
  account.brandPracticeIcon && data.append('brand_practice_icon', account.brandPracticeIcon);
  data.append('brand_color_scheme', account.brandColorScheme);
  data.append('brand_rewards_store_feed', account.brandRewardsStoreFeed);
  data.append('brand_rewards_redeem_link', account.brandRewardsRedeemLink);
  data.append('updated_by', account.updatedBy);

  data.append('name', account.name);
  data.append('office_phone_number',account.generalPhone)
  data.append('fax_phone_number',account.generalPhone)
  data.append('after_hours_phone_number',account.generalPhone)
  data.append('hours',account.generalPhone)
  //account.brandLogo && data.append('logo', account.brandlogo);
  data.append('logo', "https://www.google.com/");
  //account.signature && data.append('signature', account.signature);
  data.append('signature', "firma");

  

  return data;
};

export const invitePatient = async (data) => {
  const result = await vytracAxios.post('/users/invitation/patient/', data);
  return result.data;
};

export const inviteProvider = async ({
  first_name,
  last_name,
  cellPhoneNumber,
  email,
  specialties,
  roles,
  careTeamId,
  careTeamTierId,
  user_id,
}: ProviderInviteRequest): Promise<[any, any]> => {
  try {
    //const result = await vytracAxios.post('/users/register/', {
    const result = await vytracAxios.post('/users/invitation/staff/', {
      "first_name": first_name,
      "last_name": last_name,
      "cell_phone_number": cellPhoneNumber,
      "email_address": email,
      "specialties": specialties[0],
      "role_id": roles[0],
      "care_team": careTeamId,
      "care_team_tier": careTeamTierId,
      "user_id": user_id,
      "type":"provider"
    });
    return [result.data, undefined];
  } catch (exception) {
    const errorData = parseError(exception);
    return [undefined, errorData];
  }
};

export const getAccount = async () => {
  try {
    const result = await authUserAxios.get<APIAccount>('practice/'+getAccessToken().userid+'/');
    return mapToAccount(result.data);
  } catch (error) {
    throw error;
  }
};

export const getTheme = async () => {
  try {
    const result = await vytracAxios.get<ITheme>('administration/account/theme/');
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const setTheme = (theme: ITheme) => {
  localStorage.setItem('theme', JSON.stringify(theme));
};

export const getThemeStorage = () => {
  const theme = JSON.parse(localStorage.getItem('theme'));
  // if(!theme){
  //   theme = await getTheme()
  //   setTheme(theme);
  //   return JSON.parse(theme)
  // }
  return theme;
};

export const updateAccount = async (account: IAccount) => {
  try {
    const apiAccount = createFormData(account);
    const result = await authUserAxios.put<APIAccount>('practice/update/'+getAccessToken().userid+"/", apiAccount);
    return mapToAccount(result.data);
  } catch (error) {
    throw error;
  }
};

export const getRoles = async () => {
  try {
    const result = await vytracAxios.get<Role[]>(`${URI}/roles/v2/`);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const updateRole = async (id, newValue) => {
  const apiRole = mapRolePermissionsToRole(newValue);
  return await vytracAxios.put(`${URI}/roles/v2/${id}/`, apiRole);
};

export const updateRoleBulk = async (newValues: RolePermissions[]) => {
  const apiRoles = newValues.map((newValue) => mapRolePermissionsToRole(newValue));
  return await vytracAxios.put(`${URI}/roles/v2/`, apiRoles);
};

export const createRole = async (newValue) => {
  const apiRole = mapRolePermissionsToRole(newValue);
  return await vytracAxios.post(`${URI}/roles/v2/ `, apiRole);
};
export const createRoleBulk = async (newValues: RolePermissions[]) => {
  const apiRoles = newValues.map((newValue) => mapRolePermissionsToRole(newValue));
  return await vytracAxios.post(`${URI}/roles/v2/`, apiRoles);
};

export const getProvidersSignups = async (size = undefined) => {
  //const uri = `${URI}/dashboard/providers/${size ? `size=${size}` : ''}`;
  const uri = `${URI}/dashboard/providers/${size ? `size=${size}` : ''}`;
  try {
    const res = await vytracAxios.get<PaginatedResponse<ProviderSignup>>(uri);
    return res.data;
  } catch (error) {
    console.error(error);
  }
};

export const getPatientsSignups = async (size = undefined) => {
  const uri = `${URI}/dashboard/patients/${size ? `size=${size}` : ''}`;
  try {
    const res = await vytracAxios.get<PaginatedResponse<PatientSignup>>(uri);
    return res.data;
  } catch (error) {
    console.error(error);
  }
};

export const getRolesSignups = async (size = undefined) => {
  const uri = `${URI}/dashboard/roles/${size ? `size=${size}` : ''}`;
  try {
    const res = await vytracAxios.get<PaginatedResponse<RoleSignup>>(uri);
    return res.data;
  } catch (error) {}
};

export const getPopulations = async () => {
  try {
    const res = await vytracAxios.get<PopulationWithCount[]>(`/patient/populations/`);
    const populationResponse = JSON.stringify(res.data);
    return JSON.parse(populationResponse).results;
  } catch (error) {
    console.error(error);
  }
};

export const updatePopulations = async (
  body: { id: number; is_active: boolean }[]
): Promise<[updated: PopulationWithCount[], error: any]> => {
  if (body.length === 0) return [null, null];
  try {
    const res = await vytracAxios.put<PopulationWithCount[]>(
      `${URI}/patientsettings/populations/`,
      body
    );
    return [res.data, null];
  } catch (error) {
    return [null, (error as AxiosError).response.data];
  }
};

export const deletePopulations = async (
  ids: { id: number }[]
): Promise<[deleted: any, error: any]> => {
  if (ids.length === 0) return [null, null];
  try {
    const res = await vytracAxios.delete<PopulationWithCount[]>(
      `${URI}/patientsettings/populations/`,
      { data: ids }
    );
    return [res.data, null];
  } catch (error) {
    return [null, (error as AxiosError).response.data];
  }
};

export const getEmailSettings = async () => {
  try {
    const res = await vytracAxios.get<EmailSetting[]>(`${URI}/emails/`);
    return res.data;
  } catch (error) {
    console.error(error);
  }
};

export const updateEmailSetting = async (
  emailSetting: EmailSetting
): Promise<[data: EmailSetting, error: any]> => {
  try {
    const res = await vytracAxios.put(`${URI}/emails/${emailSetting.id}/`, emailSetting);
    return [res.data, null];
  } catch (error) {
    return [null, (error as AxiosError).response.data];
  }
};

export const deleteEmailSetting = async (
  emailSettingId: number
): Promise<[data: any, error: any]> => {
  try {
    const res = await vytracAxios.delete(`${URI}/emails/${emailSettingId}/`);
    return [res.data, null];
  } catch (error) {
    return [null, (error as AxiosError).response.data];
  }
};

export const createEmailSetting = async (
  emailSetting: EmailSetting
): Promise<[data: EmailSetting, error: any]> => {
  try {
    const res = await vytracAxios.post<EmailSetting>(`${URI}/emails/`, emailSetting);
    return [res.data, null];
  } catch (error) {
    return [null, (error as AxiosError).response.data];
  }
};

export const getEmailSettingsTypes = async () => {
  try {
    const res = await vytracAxios.get<EmailTypeTuple[]>(`${URI}/emails/types/`);
    return res.data;
  } catch (error) {
    console.error(error);
  }
};

export const getEmailSettingsShortcodes = async () => {
  try {
    const res = await vytracAxios.get<EmailShortCode[]>(`${URI}/emails/shortcodes/`);
    return res.data;
  } catch (error) {
    console.error(error);
  }
};

export const searchCareTeams = async (search: string, page = null) => {
  try {
    const res = await vytracAxios.get<PaginatedResponse<CareTeam>>(
      `${URI}/careteams/`
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getCareTeamPatients = async (careTeamId: number) => {
  try {
    const res = await vytracAxios.get<PaginatedResponse<CareTeamPatient>>(
      `${URI}/careteams/${careTeamId}/patients/`
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const deleteCareTeams = async (careTeamIds: number[]) => {
  try {
    const res = await vytracAxios.delete(`${URI}/careteams/`, {
      data: careTeamIds.map((ctid) => ({ id: ctid })),
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const createCareTeams = async (careTeams: CareTeamCreateUpdateRequest[]) => {
  try {
    const res = await vytracAxios.post<CareTeam[]>(
      `${URI}/careteams/`,
      careTeams.map((ct) => ({ tiers: ct.tiers, name: ct.name }))
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const updateCareTeams = async (careTeams: CareTeamCreateUpdateRequest[]) => {
  try {
    const res = await vytracAxios.put(`${URI}/careteams/`, careTeams);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const bulkUpdateCareTeams = async (
  careTeams: CareTeamCreateUpdateRequest[]
): Promise<[res: CareTeam[], error: any]> => {
  if (!careTeams.length) return [null, null];
  try {
    const res = await updateCareTeams(careTeams);
    return [res, null];
  } catch (error) {
    return [null, (error as AxiosError).response.data];
  }
};

export const searchPatientTrackingActivities = async (name: string) => {
  try {
    const searchParam = objectToUrlParams({ name });
    const res = await vytracAxios.get<PaginatedResponse<PatientSettingItem>>(
      `${URI}/patientsettings/activities/${searchParam}`
    );
    return res.data;
  } catch (error) {
    console.error(error);
  }
};

export const searchNotesKeywordPatients = async (search: string) => {
  try {
    const searchParam = objectToUrlParams({ search });
    const res = await vytracAxios.get<PaginatedResponse<PatientNotesKeywords>>(
      `${URI}/patientsettings/noteskeywords/${searchParam}`
    );
    return res.data;
  } catch (error) {
    console.error(error);
  }
};

export const getKeywordsTemplates = async () => {
  try {
    const res = await vytracAxios.get<KeywordsTemplate[]>(`${URI}/keywordstemplates/`);
    return res.data;
  } catch (error) {
    console.error(error);
  }
};

export const createKeywordsTemplatesBulk = async (
  templates: Omit<KeywordsTemplate, 'id'>[]
): Promise<[created: KeywordsTemplate[], error: unknown]> => {
  if (!templates?.length) return [null, null];
  try {
    const res = await vytracAxios.post<KeywordsTemplate[]>(`${URI}/keywordstemplates/`, templates);
    return [res.data, null];
  } catch (error) {
    return [null, error];
  }
};

export const patchKeywordsTemplatesBulk = async (
  templates: AtLeast<KeywordsTemplate, 'id'>[]
): Promise<[created: KeywordsTemplate[], error: unknown]> => {
  if (!templates?.length) return [null, null];
  try {
    const res = await vytracAxios.patch<KeywordsTemplate[]>(`${URI}/keywordstemplates/`, templates);
    return [res.data, null];
  } catch (error) {
    return [null, error];
  }
};

export const deleteKeywordTemplatesBulk = async (templateIds: { id: number }[]) => {
  if (!templateIds?.length) return [null, null];
  try {
    const res = await vytracAxios.delete(`${URI}/keywordstemplates/`, {
      data: templateIds,
    });
    return [res.data, null];
  } catch (error) {
    return [null, error];
  }
};

export const getAdminForms = async () => {
  try {
    const res = await vytracAxios.get<IAPIAdminForm[]>(`${URI}/adminform/`);
    return res.data;
  } catch (error) {
    console.error(error);
  }
};

export const createAdminForm = async (form: IAPIAdminForm) => {
  try {
    const res = await vytracAxios.post<IAPIAdminForm>(`${URI}/adminform/`, form);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const updateAdminForm = async (form: IAPIAdminForm, id: number) => {
  try {
    const res = await vytracAxios.put<IAPIAdminForm>(`${URI}/adminform/${id}/`, form);
    return res.data;
  } catch (error) {
    throw error;
  }
};
export const deleteAdminForm = async (id: number) => {
  try {
    const res = await vytracAxios.delete(`${URI}/adminform/${id}/`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getCurrentProviderCareTeam = async () => {
  try {
    const res = await vytracAxios.get<CurrentProviderCareTeam>(`${URI}/careteams/current/`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const getDefaultGoalThresholds = async () => {
  try {
    const res = await vytracAxios.get(`${URI}/goalsthresholds/`);
    return res.data;
  } catch (error) {
    throw error;
  }
};



export const getSpecialities = async () => {
  try {
    const res = await vytracAxios.get(`${URI}/specialities/list/`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

