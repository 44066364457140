// print the environment variables

const config = {
    apiUrl: `${process.env.REACT_APP_API_URL || ''}/${process.env.REACT_APP_ENDPOINT_PREFIX || 'api/' }`,
    // "REACT_APP_TIME_INECTIVE_MODAL": 360000,
    // "REACT_APP_TIME_LOG_OUT": 240000
    showInactiveModalTime: parseInt(process.env.REACT_APP_TIME_INECTIVE_MODAL) || 360000,
    logOutTime: parseInt(process.env.REACT_APP_TIME_LOG_OUT) || 240000,
};

export { config };
