import { REQUIRED_MESSAGE } from 'util/formikUtils';
import { array, number, object, string } from 'yup';
const phoneNumberRegex = /^(\+\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

export const InviteProviderValidationSchema = object().shape({
  first_name:string().required(REQUIRED_MESSAGE),
  last_name:string().required(REQUIRED_MESSAGE),
  cellPhoneNumber: string().matches(phoneNumberRegex, 'number phone not match the correct format'),
  email: string()
    .email("Provider's email must be a valid email address")
    .required("Provider's email is required"),
  specialtiesIds: array()
    .of(number())
    .required('You must select at least one specialty')
    .min(1, 'You must select at least one specialty'),
  rolesIds: array()
    .of(number())
    .required('You must select at least one role')
    .min(1, 'You must select at least one role'),
});
