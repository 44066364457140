import ProviderProfileForm from 'components/Provider/Account/ProviderProfileForm';
import SaveChangesConfirmationModal from 'components/SaveChangesConfirmationModal';
import { Formik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { ProviderDetailsFormValues } from 'screens/Administration/AdministrationContent/AdminTabs/AdminProviders/ProviderDetails/ProviderDetails';
import { mapProviderFormToProvider } from 'screens/Administration/AdministrationContent/AdminTabs/AdminProviders/ProviderDetails/utils';
import ProviderDetailsValidationSchema from 'screens/Administration/AdministrationContent/AdminTabs/AdminProviders/ProviderDetails/utils/ProviderDetailsValidationSchema';
import { updateProvider } from 'services/providersService';
import { convertDateToDatePickValue } from 'util/dateUtils';
import { localIdCreator } from 'util/utils';
import {
  setProviderProfileAction,
  setProviderProfileFormDirty,
  setProviderProfileFormReset,
  setProviderProfileSubmitForm,
} from '../store/providerProfileActionCreators';
import { useProviderProfileContext } from '../store/ProviderProfileContextProvider';
import { useProfileMyAccountLists } from './hooks';
import ProfileMyAccountActions from './profile-my-account-actions';

const createLocalAddressId = localIdCreator();

const ProfileMyAccount: FC = () => {
  const {
    providerProfileState: {
      optionsInfo: { currentProvider, genderOptions, languages, roles, specialties, titles },
    },
    dispatch,
  } = useProviderProfileContext();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [commitingValues, setCommitingValues] = useState<ProviderDetailsFormValues>();

  useEffect(() => {
    dispatch(setProviderProfileAction(ProfileMyAccountActions));
  }, [dispatch]);

  useProfileMyAccountLists();

  const handleSaveAccountChanges = async () => {
    const newProviderValues = mapProviderFormToProvider(commitingValues, currentProvider.active);
    const res = await updateProvider(currentProvider.id, newProviderValues);
    setShowConfirmModal(false);
  };

  const numberToArray = n => [...`${n}`].map(i => parseInt(i)).filter(Number);

  if (!currentProvider) return <></>;

  return (
    <Formik
      initialValues={{
        identification: {
          firstName: currentProvider.first_name ?? '',
          lastName: currentProvider.last_name ?? '',
          middleName: currentProvider.middle_name ?? '',
          dateOfBirth: currentProvider.date_birth
            ? convertDateToDatePickValue(new Date(currentProvider.date_birth))
            : null,
          gender: currentProvider?.sex ?? null,
          title: currentProvider.title ?? "",
          language: currentProvider.language ?? "", //currentProvider?.languages.map((l) => l.id),  # bypass to list waiting for back
          accessRoles: numberToArray(currentProvider.rol), //selectedProvider.roles.map((r) => r.id), # bypass to list waiting for back
        },
        personalInformation: {
          contactInformation: {
            email: currentProvider.email_address,
            phoneNumber1: currentProvider.cell_phone_number ?? '',
            phoneNumber2: currentProvider.office_extension ?? '',
          },
          addressInformation: currentProvider.address
            ? currentProvider.address.map((a) => ({
                home: a.street_address_line_1,
                apt: a.apt,
                state: a.state,
                zipCode: a.zip_code,
                city: a.city,
                //id: createLocalAddressId(),
                id:a.id ?? createLocalAddressId()
              }))
            : [{ home: '', apt: '', state: '', city: '', zipCode: '', id: createLocalAddressId() }],
        },
        active:currentProvider.active,
        specialties: numberToArray(currentProvider.speciality)??[],//selectedProvider.speciality.map((s) => s.id), # bypass to list waiting for back
      }}
      // validationSchema={ProviderDetailsValidationSchema}
      onSubmit={(values) => {
        setCommitingValues(values);
        setShowConfirmModal(true);
      }}
      validationSchema={ProviderDetailsValidationSchema}
    >
      {({ submitForm, resetForm, dirty }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          dispatch(setProviderProfileFormReset(resetForm));
          dispatch(setProviderProfileSubmitForm(submitForm));
        }, [resetForm, submitForm]);

        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          dispatch(setProviderProfileFormDirty(dirty));
        }, [dirty]);
        return (
          <>
            <ProviderProfileForm
              selectedProvider={currentProvider}
              genderOptions={genderOptions}
              languages={languages}
              roles={roles}
              titles={titles}
              specialties={specialties}
              allowProfilePicUpload
            />
            <SaveChangesConfirmationModal
              show={showConfirmModal}
              onCancel={() => setShowConfirmModal(false)}
              onConfirm={handleSaveAccountChanges}
            />
          </>
        );
      }}
    </Formik>
  );
};
export default ProfileMyAccount;
