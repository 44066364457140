import SatisfactionBar from 'components/SatisfactionBar';
import { Tag } from 'components/Tagitem';
import TagList from 'components/TagList';
//import Adherence from 'types/ApiModels/Patients/Adherence';
import GenericColumn from 'types/Shared/GenericColumn';
import AdminPatientAdherenceElement from '../../../AdminPatientAdherenceElement';
import AdminPatientElement from '../../../AdminPatientElement/AdminPatientElement';
import AdminPatientProfileCompletedElement from '../../../AdminPatientProfileCompletedElement';

export interface AdminPatientRow {
  score?: number;
  patient?: {
    firstName?: string;
    lastName?: string;
    profileImage?: string;
    id?: number;
  };
  issuesNumber?: number;
  profileCompleted?: {
    completed?: number;
    totalSections?: number;
  };
  alertsNumber?: number;
  //adherence?: Adherence;
  adherence?: string;
  populations?: Tag[];
  timeSpent?: string;
  satisfaction?: number;
  username?: string;
  email_address?: string;
}

enum adminPatientTableColumnKey {
  username = 'username',
  score = 'score',
  patient = 'patient',
  issuesNumber = 'issuesNumber',
  profileCompleted = 'profileCompleted',
  alertsNumber = 'alertsNumber',
  adherence = 'adherence',
  populations = 'populations',
  timeSpent = 'timeSpent',
  satisfaction = 'satisfaction',
}

export const adminPatientTableColumns: GenericColumn[] = [
  { key: adminPatientTableColumnKey.username, title: 'user name', hidden: true },
  { key: adminPatientTableColumnKey.score, title: 'score' },
  { key: adminPatientTableColumnKey.patient, title: 'patient', render: AdminPatientElement },
  { key: adminPatientTableColumnKey.issuesNumber, title: '# of issues' },
  {
    key: adminPatientTableColumnKey.profileCompleted,
    title: 'profile completed',
    render: AdminPatientProfileCompletedElement,
  },
  { key: adminPatientTableColumnKey.alertsNumber, title: '# of alerts' },
  {
    key: adminPatientTableColumnKey.adherence,
    title: 'adherence',
    render: AdminPatientAdherenceElement,
  },
  {
    key: adminPatientTableColumnKey.populations,
    title: 'populations',
    render: ({ populations }: AdminPatientRow) => TagList({ tagList: populations, maxCount: 2 }),
  },
  { key: adminPatientTableColumnKey.timeSpent, title: 'time spent' },
  {
    key: adminPatientTableColumnKey.satisfaction,
    title: 'satisfaction',
    render: ({ satisfaction }) => SatisfactionBar({ value: satisfaction }),
  },
];

export enum adminPatientFilterKeys {
  mostAlerts = 'most alerts',
  oldestFirst = 'oldest first',
  mostTags = 'most tags',
  timeSpent = 'time spent',
  upcomingAppointments = 'upcoming appointments',
  primaryInTeam = 'primary in team',
}
