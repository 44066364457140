import { ShowEditStickyNote } from './sticky-notes-action-creators';
import { SHOW_ADD_MODAL, SET_STICKY_NOTES, SHOW_EDIT_MODAL } from './sticky-notes-action-types';

export const stickyNotesInitialState = { 
  stickyNotes: [],
  showAddStickyNotes: false,
  showEditStickyNotes: false,
};

const stickyNotesReducer = (state = stickyNotesInitialState, action) => {
  switch (action.type) {
    case SET_STICKY_NOTES:
      return { ...state, stickyNotes: action.payload };
    case SHOW_ADD_MODAL:
      return { ...state, showAddStickyNotes: !state.showAddStickyNotes };
    case SHOW_EDIT_MODAL:
        return { ...state, showEditStickyNotes: !state.showEditStickyNotes };
    default:
      return state;
  }
};

export default stickyNotesReducer;
