import { ReactNode, useEffect, useState } from 'react';
import { getSingleProvider } from 'services/providersService';
import { Provider } from 'types/ApiModels/Providers/Provider';
import { MinifiedUser } from 'types/ApiModels/Users';
import { AuthContext } from './store';

interface AuthProvider {
  children: JSX.Element | JSX.Element[];
}
// http://localhost:3000/forgot-password?email=test@mail.com&hash_token=12345678
const AuthProvider = ({ children }: { children: ReactNode }) => {
  const path = window.location.pathname
  const queryParams = new URLSearchParams(window.location.search);
  const email = queryParams.get('email');
  const hashToken = queryParams.get('hash_token');
  // console.log(path, email, hashToken);
  const [currentUser, setCurrentUser] = useState<MinifiedUser>();
  const [providerInfo, setProviderInfo] = useState<Provider>();

  useEffect(() => {
    if (currentUser && !providerInfo) {
      getSingleProvider(currentUser.provider_id,currentUser.id).then(setProviderInfo);
    }
  }, [currentUser, providerInfo]);

  useEffect(() => {
    console.log('hey from auth provider', { currentUser });
  }, [currentUser]);

  return (
    <AuthContext.Provider value={{ currentUser, providerInfo, setCurrentUser, setProviderInfo, path, email, hashToken }}>
      {children}
    </AuthContext.Provider>
  );
};
export default AuthProvider;
