import { useEffect, useMemo, useState } from 'react';
import { Redirect } from 'react-router-dom';
import RegisterLoginLayout from 'screens/Common/RegisterLoginLayout';
import { getTheme, setTheme } from 'services/administrationService';
import { getAccessToken } from 'services/tokenService';
import { VerificationCode } from 'types/ApiModels/Login';
import { loginViews } from 'types/Login';
import { LoginContext } from './store';
import LoginComponentByEnum from './utils/loginComponentByEnum';

const Login = () => {
  const [redirect, setRedirect] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [validCode, setValidCode] = useState<VerificationCode | undefined>();
  const [currentView, setCurrentView] = useState<loginViews>(loginViews.login);
  const LoginComponent = useMemo(() => LoginComponentByEnum[currentView], [currentView]);
  const [loginValues, setLoginValues] = useState({
    username: '',
    password: '',
    email: '',
    phone_number: '',
    code: '',
  });
  const [forgotPasswordProcess, setForgotPasswordProcess] = useState(false);

  useEffect(() => {
    const fetchTheme = async () => {
      const theme = await getTheme();
      setTheme(theme);
    };
    if (getAccessToken().token) {
      setRedirect(true);
    }
    fetchTheme();
  }, []);

  const handleChangeView = (view: loginViews) => {
    setCurrentView(view);
  };

  const handleLoginValues = (values: any) => {
    setLoginValues(values);
  };

  return (
    <>
      {redirect ? (
        <Redirect to="/administration" />
      ) : (
        <LoginContext.Provider
          value={{
            changeView: handleChangeView,
            setLoginValues: handleLoginValues,
            loginValues,
            userEmail,
            setUserEmail,
            isResettingPassword,
            setIsResettingPassword,
            validCode,
            setValidCode,
            forgotPasswordProcess,
            setForgotPasswordProcess
          }}
        >
          <RegisterLoginLayout>
            <LoginComponent
              previousValues={loginValues}
              sePreviousValues={handleLoginValues}
              changeView={handleChangeView}
              isResetting={isResettingPassword}
              setIsResetting={setIsResettingPassword}
              userEmail={userEmail}
              setValidCode={setValidCode}
              onCancel={() => {
                isResettingPassword && setIsResettingPassword(false);
                handleChangeView(loginViews.login);
              }}
            />
          </RegisterLoginLayout>
        </LoginContext.Provider>
      )}
    </>
  );
};
export default Login;
