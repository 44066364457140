import { useAuthContext } from 'auth';
import AuthGuard from 'auth/AuthGuard';
import AuthProvider from 'auth/AuthProvider';
import { canAccess } from 'auth/can';
import { PaginationProvider } from 'components/Pagination';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route, Switch, useHistory, withRouter } from 'react-router-dom';
import Login from 'screens/Login';
import { NotFound } from 'screens/not-found';
import Register from 'screens/Register';
import './App.scss';
import routes from './util/routes';
import WebSocketProvider from './websockets/WebSocketProvider';
import { useEffect, useState } from 'react';
import { logOut } from 'services/userService';
import SessionExpirationModal from 'util/SessionExpirationModal';
import { addEventListeners,  removeEventListeners } from 'util/eventListenerUtil'
import ResetPassword from 'screens/Login/ResetPassword';
import packageJson from "build.json";
import moment from "moment";
import { config } from 'config/environment';
const client = new QueryClient();
const RouteList = () => {
  const { currentUser } = useAuthContext();
  if (!currentUser) return <></>;
  return (
    <>
      {routes.flatMap(({ path, exact, component: Component, relatedPermission }) => {
        if (
          relatedPermission &&
          !canAccess(currentUser.permissions, { access: relatedPermission, do: 'view' })
        )
          return [];
        return [
          <Route key={path} exact={exact} path={path}>
            <Component />
          </Route>,
        ];
      })}
    </>
  );
};

const App = () => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
 
  const whiteList = ['/', '/register', '/forgot-password/']
  let timeLogOut = config.logOutTime
  let timeShowModal = config.showInactiveModalTime
  const history = useHistory();
  const [verifyCache, setVerifyCache] = useState(history.location.pathname === '/');
  const [timer,setTimer] = useState(false);
  
  // Idle Timer
  useEffect(()=>{
    // withClearCache()
    for (const element of whiteList) {
      if (element ===history.location.pathname){
        setTimer(false)
        return
      }
    }
    setTimer(true)
  },[history.location.pathname])

  window.addEventListener('beforeunload', function (e) {
    e.preventDefault();
  });

  const buildDateGreaterThan = (latestDate, currentDate) => {
    const momLatestDateTime = moment(latestDate);
    const momCurrentDateTime = moment(currentDate);
  
    if (momLatestDateTime.isAfter(momCurrentDateTime)) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if(verifyCache){
      fetch(`/meta.json?time=${new Date().getTime()}`)
      .then((response) => response.json())
      .then((meta) => {
        const latestVersionDate = meta.buildDate;
        const currentVersionDate = packageJson.buildDate;
        const shouldForceRefresh = buildDateGreaterThan(
          latestVersionDate,
          currentVersionDate
        );
        setVerifyCache(false)
        if (shouldForceRefresh) {
          caches.keys().then((names) => {
            for (const name of names) {
              caches.delete(name);
            }
            if(names.length > 0)
              window.location.reload();
          });
          
        } 
      });
    }
  }, []);


  useEffect(() => {
    const createTimeout1 = () => setTimeout(()=>{
      if (timer){
        setShowConfirmModal(true);
      }
      else{
        timeLogOut = 0;
        timeShowModal = 0;
      }
    },timeShowModal)
    const createTimeout2 = () => setTimeout(() => {
        setShowConfirmModal(false);
        logOut();
        timeLogOut = 0;
        timeShowModal = 0;
        setShowConfirmModal(false);
        history.push('/');
    },timeLogOut)
    const listener = () => {
      if(!showConfirmModal){
        clearTimeout(timeout)
        timeout = createTimeout1();
      }
    }
    // Initialization
    //let timeout = showConfirmModal  ? createTimeout2() : createTimeout1()
    let timeout = timer ? (showConfirmModal  ? createTimeout2() : createTimeout1()) : null
    addEventListeners(listener);
    // Cleanup
    return () => {
      removeEventListeners(listener);
      clearTimeout(timeout);
    }
  },[showConfirmModal,timer])
 
  return (
    <QueryClientProvider client={client}>
      <AuthProvider>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/forgot-password" component={ResetPassword} />
          <WebSocketProvider>
            <PaginationProvider>
              <AuthGuard>
                <RouteList />
              </AuthGuard>
            </PaginationProvider>
          </WebSocketProvider>
        </Switch>
      </AuthProvider>
      {showConfirmModal == true ? (
        <SessionExpirationModal
          onConfirm={() => setShowConfirmModal(false)}
          show={showConfirmModal}
          />
            ) : null}
    </QueryClientProvider>
  );
};

export default withRouter(App);