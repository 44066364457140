import { Conversation, Message, Paginator } from '@twilio/conversations';
import { getPatientInfoByIdentities } from 'services/conversations-service';
import { ConversationMetadata } from 'types/ApiModels/conversations';
import ConversationAttributes from 'types/Shared/conversation-attributes';
import create from 'zustand';
import State from 'zustand';

//We're going to use a zustand global state so that we can easily get the conversations wherever we want without having to define multiple places where the conversations are fetcher or whatnot
//I think we will need a hook that definitely is within the twilio context scope so that we can get the conversations there

export interface ConversationSimplified {
  sid: string;
  unreadCount: number;
  lastMessage: {
    body: string;
    idx: number;
    dateCreated: Date;
  };
  lastMessageParticipant: {
    participantSid: string;
    identity: string;
  };
  participants: {
    identity: string;
    participantSid: string;
  }[];
  attributes: ConversationAttributes;
}

const getConversationUniqueIdentities = (
  conversations: Pick<ConversationSimplified, 'participants'>[]
) =>
  conversations.reduce<string[]>((res, c) => {
    const participantIds = c.participants.flatMap((p) => {
      if (res.includes(p.identity)) return [];
      return [p.identity];
    });
    return [...res, ...participantIds];
  }, []);

enum StateKeys {
  conversations = 'conversations',
  conversationsMetadataByIdentity = 'conversationsMetadataByIdentity',
  upsertConversation = 'upsertConversation',
  upsertBulkConversations = 'upsertBulkConversations',
  deleteConversation = 'deleteConversation',
  hydrateMetadata = 'hydrateMetadata',
  getMetadataByParticipantSid = 'getMetadataByParticipantSid',
  areConversationsLoading = 'areConversationsLoading',
  setConversationsLoading = 'setConversationsLoading',
  //how will we handle pagination? Whats dangerous about these is that we must make these to be set at the mount of each view
  currentConversationPaginator = 'currentConversationPaginator',
  setCurrentConversationPaginator = 'setCurrentConversationPaginator',
  currentMessagePaginator = 'currentMessagePaginator',
  setCurrentMessagePaginator = 'setCurrentMessagePaginator',
  clearConversationsStore = 'clearConversationsStore',
}

// NOTE: Had to remove State from the interface because it was causing a type error. Zustand's State is not a proper interface.
interface ConversationsState /*extends State*/ {
  [StateKeys.conversations]: ConversationSimplified[];
  [StateKeys.upsertConversation]: (conversation: ConversationSimplified) => void;
  [StateKeys.upsertBulkConversations]: (conversations: ConversationSimplified[]) => void;
  [StateKeys.deleteConversation]: (conversationSid: string) => void;
  [StateKeys.conversationsMetadataByIdentity]: Record<string, ConversationMetadata>;
  [StateKeys.hydrateMetadata]: (externalIdentities?: string[]) => void;
  [StateKeys.getMetadataByParticipantSid]: (participantSid: string) => ConversationMetadata;
  [StateKeys.areConversationsLoading]: boolean;
  [StateKeys.setConversationsLoading]: (isLoading: boolean) => void;
  [StateKeys.currentConversationPaginator]: Paginator<Conversation>;
  [StateKeys.setCurrentConversationPaginator]: (paginator: Paginator<Conversation>) => void;
  [StateKeys.setCurrentMessagePaginator]: (paginator: Paginator<Message>) => void;
  [StateKeys.currentMessagePaginator]: Paginator<Message>;
  [StateKeys.clearConversationsStore]: () => void;
}

export const ConversationSelectors = {
  [StateKeys.conversations]: (state: ConversationsState) => state.conversations,
  [StateKeys.upsertConversation]: (state: ConversationsState) => state.upsertConversation,
  [StateKeys.upsertBulkConversations]: (state: ConversationsState) => state.upsertBulkConversations,
  [StateKeys.deleteConversation]: (state: ConversationsState) => state.deleteConversation,
  [StateKeys.areConversationsLoading]: (state: ConversationsState) => state.areConversationsLoading,
  [StateKeys.setConversationsLoading]: (state: ConversationsState) => state.setConversationsLoading,
  [StateKeys.conversationsMetadataByIdentity]: (state: ConversationsState) =>
    state.conversationsMetadataByIdentity,
  [StateKeys.getMetadataByParticipantSid]: (state: ConversationsState) =>
    state.getMetadataByParticipantSid,
  [StateKeys.hydrateMetadata]: (state: ConversationsState) => state.hydrateMetadata,
  [StateKeys.currentConversationPaginator]: (state: ConversationsState) =>
    state.currentConversationPaginator,
  [StateKeys.setCurrentConversationPaginator]: (state: ConversationsState) =>
    state.setCurrentConversationPaginator,
  [StateKeys.setCurrentMessagePaginator]: (state: ConversationsState) =>
    state.setCurrentMessagePaginator,
  [StateKeys.currentMessagePaginator]: (state: ConversationsState) => state.currentMessagePaginator,
  [StateKeys.clearConversationsStore]: (state: ConversationsState) => state.clearConversationsStore,
};

const sortDescendingByDate = (a: ConversationSimplified, b: ConversationSimplified) =>
  a.lastMessage.dateCreated > b.lastMessage.dateCreated ? -1 : 1;

/**
 *
 */
const getUpdatedConversation = (
  conversationsBase: ConversationSimplified[],
  conversation: ConversationSimplified
) => {
  const foundIdx = conversationsBase.findIndex((cs) => cs.sid === conversation.sid);
  const newConversations =
    foundIdx !== -1 ? [...conversationsBase] : [...conversationsBase, conversation];
  if (foundIdx !== -1) {
    newConversations[foundIdx] = conversation;
  }
  return newConversations.sort(sortDescendingByDate);
};

export const useConversationsStore = create<ConversationsState>((set, get) => ({
  conversations: [],
  conversationsMetadataByIdentity: {},
  deleteConversation: (conversationSid: string) => {
    set({ conversations: get().conversations.filter((c) => c.sid !== conversationSid) });
  },
  areConversationsLoading: false,
  setConversationsLoading: (isLoading) => {
    set({ areConversationsLoading: isLoading });
  },
  hydrateMetadata: async (externalIdentities: string[]) => {
    const identities = getConversationUniqueIdentities(
      externalIdentities
        ? [
            get().conversations,
            [
              {
                participants: externalIdentities.map((id) => ({
                  identity: id,
                  participantSid: undefined,
                })),
              },
            ],
          ].flat()
        : get().conversations
    );
    const keys = Object.keys(get().conversationsMetadataByIdentity);
    const exclusion = identities.filter((id) => !keys.includes(id));
    if (!exclusion.length) return;
    const [res, error] = await getPatientInfoByIdentities(exclusion);
    if (error) {
      console.error('Error while trying to get metadata from conversations');
      return;
    }
    const metadataCopy = { ...get().conversationsMetadataByIdentity };
    res.forEach((m) => {
      metadataCopy[m.twilio_identity] = m;
    });
    set({ conversationsMetadataByIdentity: metadataCopy });
  },
  getMetadataByParticipantSid: (participantSid: string) => {
    //I assume here that this participant is part of our conversations otherwise we would not be requesting it
    // conversations => participants => get identity of participant with participantSid
    let identityFound: string;
    for (const c of get().conversations) {
      const participant = c.participants.find((p) => p.participantSid === participantSid);
      if (participant) {
        identityFound = participant.identity;
        break;
      }
    }
    return get().conversationsMetadataByIdentity[identityFound];
  },
  upsertConversation: (conversation: ConversationSimplified) => {
    const newConversations = getUpdatedConversation(get().conversations, conversation);
    set({ conversations: newConversations });
  },
  upsertBulkConversations: (conversations: ConversationSimplified[]) => {
    //we need to go one by one checking whether they already exist and if so replace the reference with the new one.
    let newConversations = get().conversations;
    conversations.forEach((c) => {
      newConversations = getUpdatedConversation(newConversations, c);
    });
    set({ conversations: newConversations });
  },
  currentConversationPaginator: null,
  setCurrentConversationPaginator: (currentConversationPaginator) => {
    set({ currentConversationPaginator });
  },
  currentMessagePaginator: null,
  setCurrentMessagePaginator: (currentMessagePaginator) => {
    set({ currentMessagePaginator });
  },
  clearConversationsStore: () => {
    set({
      conversations: [],
      conversationsMetadataByIdentity: {},
      currentMessagePaginator: null,
      setCurrentMessagePaginator: null,
      areConversationsLoading: false,
    });
  },
}));
