import { useAuthContext } from 'auth';
import UserBubble from 'components/UserBubble';
import { FC, useState } from 'react';
import { AsyncTypeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';
import { searchPatients } from 'services/patientService';
import PatientGet from 'types/ApiModels/Patients/PatientGet';

interface PatientListProps {
  onPatientSelect: (patient: PatientGet[]) => void;
}

const PatientSearchResult = ({
  user,
}: {
  user: {
    first_name: string;
    last_name: string;
    photo_thumbnail: string;
  };
}) => {
  return (
    <div aria-label="search result" className="d-flex align-items-center gap-sm">
      <UserBubble
        user={{
          first_name: user?.first_name,
          last_name: user?.last_name,
          imageUrl: user?.photo_thumbnail,
        }}
      />
      <p className="m-0 py-2 font-size-medium">
        {user?.first_name} {user?.last_name}
      </p>
    </div>
  );
};

export const PatientList: FC<PatientListProps> = ({ onPatientSelect }: PatientListProps) => {
  const { providerInfo } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [patientOptions, setPatientOptions] = useState<PatientGet[]>([]);

  const onPatientSearch = async (search: string) => {
    setIsLoading(true);
    const searchResult = await searchPatients({ search, care_team: providerInfo.care_team.id });
    setPatientOptions(searchResult);
    setIsLoading(false);
  };

  return (
    <AsyncTypeahead
      id="search patient"
      //labelKey={(option: PatientGet) => `${option?.user?.first_name} ${option?.user?.last_name}`}
      labelKey={(option: PatientGet) => `${option?.first_name}`}
      options={patientOptions}
      isLoading={isLoading}
      onSearch={onPatientSearch}
      className="font-size-medium"
      inputProps={{ className: 'font-size-big' }}
      placeholder="Find a patient..."
      onChange={onPatientSelect}
      renderMenu={(results: PatientGet[], menuProps) => {
        const { newSelectionPrefix, paginationText, renderMenuItemChildren, ...stripped } =
          menuProps;

        // Originally was <Menu {...stripped} text=""> but text field is not a valid prop for Menu
        return (
          <Menu {...stripped}>
            {results.map((result, index) => (
              <MenuItem option={result} position={index} key={index}>
                <PatientSearchResult
                  // user={{
                  //   first_name: result?.user?.first_name,
                  //   last_name: result?.user?.last_name,
                  //   photo_thumbnail: result?.user?.photo_thumbnail,
                  // }}
                  user={{
                    first_name: result?.first_name,
                    last_name: '',
                    photo_thumbnail: '',
                  }}
                />
              </MenuItem>
            ))}
          </Menu>
        );
      }}
    />
  );
};
