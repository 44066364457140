/**
 *
 * @param {string} chartName
 * @param {Array} dataset
 * @param {{label:string,data:object}} keys
 * @param {string[]} backgroundColors array of backgroundcolors in the same order as the data .If the array is shorter than the amount of data, the latest data will get the last color of the array
 * @param {string[]} borderColors array of bordercolors
 * @returns
 */
export const mapToPieChartData = (
  chartName,
  dataset,
  keys,
  //if this can be in the data, better, we'd just need a key(in the keys obj) to where it is.
  backgroundColors,
  borderColors = [],
) => {
  let bgColorsPointer = 0;
  let borderColorsPointer = 0;
  return dataset.reduce(
    (res, curr) => {
      res.labels.push(curr[keys.label]);
      res.datasets[0].data.push(curr[keys.data]);

      const currentBg =
        backgroundColors[bgColorsPointer] ||
        backgroundColors[backgroundColors.length - 1];
      const currentBorderColor = borderColors?.length
        ? borderColors?.[borderColorsPointer] ||
          borderColors[borderColors.length - 1]
        : undefined;

      res.datasets[0].backgroundColor.push(currentBg);
      borderColors?.length &&
        res.datasets[0].borderColor.push(currentBorderColor);

      bgColorsPointer++;
      borderColorsPointer++;
      return res;
    },
    {
      labels: [],
      datasets: [
        {
          chartName,
          data: [],
          backgroundColor: [],
          borderColor: [],
        },
      ],
    },
  );
};

/**
 *
 * @param {*} chartRef
 * @param {"mousemove"|"mouseout"} type
 * @param {*} idx
 */
export const triggerMouseEventOnPie = (chartRef, type, idx) => {
  const pieData = chartRef.getDatasetMeta(0);
  const rect = chartRef.canvas.getBoundingClientRect();
  const point = pieData.data[idx].getCenterPoint();

  const mouseEvent = new MouseEvent(type, {
    clientX: rect.left + point.x,
    clientY: rect.top + point.y,
  });

  const node = chartRef.canvas;

  node.dispatchEvent(mouseEvent);
};
