const TWILIO_LOCAL_KEY = 'twilio';
export const saveTwilioTokenToLocalStorage = (
  value: string,
  expirationDate: string,
  identity: string
) => {
  window.localStorage.setItem(
    TWILIO_LOCAL_KEY,
    JSON.stringify({ value, expirationDate, identity })
  );
};

export const getTwilioTokenFromLocalStorage = () => {
  return JSON.parse(localStorage.getItem(TWILIO_LOCAL_KEY)) as {
    value: string;
    expirationDate: string;
    identity: string;
  };
};

export const clearTwilioTokenFromLocalStorage = () => {
  window.localStorage.removeItem(TWILIO_LOCAL_KEY);
};
