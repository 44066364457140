import Button from 'components/Button';
import { TextInputFormik } from 'components/FormikComponents';
import VyTracModal from 'components/Modal';
import { ErrorMessage, Form, Formik } from 'formik';
import { FC } from 'react';
import { useAdministrationContextV2 } from 'screens/Administration/store';
import { AddEditSpecialtyValidationSchema } from './utils/ValidationSchemas';

interface AddNewSpecialtyModalProps {
  show: boolean;
  onClose: () => void;
  handleSubmit: (values: { name: string }) => void;
}

const AddNewSpecialtyModal: FC<AddNewSpecialtyModalProps> = ({
  show,
  onClose,
  handleSubmit,
}: AddNewSpecialtyModalProps) => {
  const {
    administrationStateV2: {
      specialties: { list: specialtiesList },
    },
  } = useAdministrationContextV2();
  return (
    <VyTracModal
      backdrop={false}
      title="Add new specialty"
      headerClassName="font-size-big font-weight-md m-0"
      body={
        <Formik
          initialValues={{
            name: '',
          }}
          validationSchema={AddEditSpecialtyValidationSchema(
            specialtiesList?.map((s) => s.text) ?? []
          )}
          onSubmit={(values) => {
            handleSubmit(values);
            onClose();
          }}
        >
          {({ errors, touched, isValid }) => (
            <Form className="d-flex flex-column gap">
              <div className={`d-flex justify-content-between align-items-center gap`}>
                <div className={`d-flex font-size-medium`}>Specialty name</div>
                <div className="flex-grow-1">
                  <TextInputFormik className="w-100 font-size-medium" name="name" />
                </div>
              </div>
              {errors.name && touched.name ? (
                <div className="d-flex">
                  <div className="d-flex font-size-medium"></div>
                  <ErrorMessage name="name">
                    {(msg) => <div className="text-danger font-size-small">{msg}</div>}
                  </ErrorMessage>
                </div>
              ) : null}
              <div className="d-flex w-100 justify-content-between">
                <Button label="cancel" variant="cancel" onClick={onClose} />
                <Button label="ADD" type="submit" disabled={!isValid} />
              </div>
            </Form>
          )}
        </Formik>
        // <InviteProviderForm
        //   onConfirm={onConfirm}
        //   rolesList={rolesList}
        //   onCancel={onClose}
        //   specialtiesList={mappedSpecialties}
        // />
      }
      footer={undefined}
      onClose={onClose}
      show={show}
      centered
    />
  );
};
export default AddNewSpecialtyModal;
