import { LabeledField } from 'components/FormikComponents';
import TagList from 'components/TagList';
import { useFormikContext } from 'formik';
import { FC, useMemo } from 'react';
import addTagIcon from 'assets/icons/add_tag_icon.svg';
import deleteTagIcon from 'assets/icons/remove_tag_icon.svg';
import styles from '../styles.module.css';
import { Role } from 'types/ApiModels/Administration';
import { Tag } from 'components/Tagitem'; 

interface ProviderAccessRolesFieldProps {
  roles: Role[];
  name: string;
  label: string;
  handleAdd: () => void;
  disabled;
}

const ProviderAccessRolesField: FC<ProviderAccessRolesFieldProps> = ({
  roles,
  name,
  label,
  handleAdd,
  disabled
}: ProviderAccessRolesFieldProps) => {
  const { values, setFieldTouched, setFieldValue } =
    useFormikContext<{ identification: { accessRoles: number[] } }>();

  const currentlySelectedRoles = useMemo(
    () => roles?.filter((r) => values.identification.accessRoles.includes(r.id)) ?? [],
    [roles, values.identification.accessRoles]
  );

  const handleRemoveRole = (roleId: number) => {
    setFieldTouched(name, true);
    setFieldValue(
      name,
      values.identification.accessRoles.filter((rid) => rid !== roleId),
      true
    );
  };

  const isDisabled = disabled ? styles['disabled-container'] : '';
  

  return (
    <LabeledField className={`${styles['field-container']} flex-grow-1 ${isDisabled}`} name={name} label={label}>
      <TagList
        className="w-100 bg-light-gray py-1 rounded"
        tagList={[
          ...currentlySelectedRoles.map<Tag>((s) => ({
            text: s.name,
            icon: (
              <img
                src={deleteTagIcon}
                onClick={() => handleRemoveRole(s.id)}
                height={16}
                width={16}
                className="cursor-pointer"
              />
            ),
          })),
          {
            text: 'Add',
            icon: <img src={addTagIcon} height={16} width={16} />,
            className: 'cursor-pointer',
            onClick: handleAdd,
          },
        ]}
      />
    </LabeledField>
  );
};
export default ProviderAccessRolesField;
