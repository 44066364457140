import VytracModal from 'components/Modal';
import { Formik } from 'formik';
import { FC } from 'react';
import { CareTeam } from 'types/ApiModels/CareTeam';
import { negativeLocalIdCreator } from 'util/utils';
import { CareTeamCreateEditFormikFields } from '../types';
import AddTierButton from './AddTierButton';
import CareTeamCreateEditFooter from './CareTeamCreateEditFooter';
import CareTeamNameInput from './CareTeamNameInput';
import PrimaryPhysicianTierComponent from './CareTeamTierComponent/PrimaryPhysicianTierComponent';
import CareTeamTiersDnD from './CareTeamTiersDnD';
import styles from './styles.module.css';
import { CareTeamCreateValidationSchema, CareTeamEditValidationSchema } from './utils';

interface CareTeamCreateEditModalProps {
  currentCareTeam: CareTeam;
  show: boolean;
  onConfirm: (careTeam: CareTeamCreateEditFormikFields) => void;
  onCancel: () => void;
  onDelete: (careTeamId: number, patientsCount: number, providersCount: number) => void;
}

const createLocalId = negativeLocalIdCreator();

const CareTeamCreateEditModal: FC<CareTeamCreateEditModalProps> = ({
  currentCareTeam,
  show,
  onConfirm,
  onCancel,
  onDelete,
}: CareTeamCreateEditModalProps) => {
  const handleSubmitForm = (values: CareTeamCreateEditFormikFields) => {
    
    onConfirm(values);
  };

  const isEditing = Boolean(currentCareTeam);

  return (
    <VytracModal
      show={show}
      onClose={onCancel}
      title={`${currentCareTeam ? 'Edit' : 'Create'} care team`}
      size="lg"
      body={
        <Formik<CareTeamCreateEditFormikFields>
          initialValues={{
            id: currentCareTeam?.id,
            name: currentCareTeam?.name,
            tiers: currentCareTeam?.tiers
              ? currentCareTeam.tiers
              : [{ name: 'Primary physician', number: 1, providers: [], id: 0 }],
          }}
          onSubmit={handleSubmitForm}
          validationSchema={
            isEditing ? CareTeamEditValidationSchema : CareTeamCreateValidationSchema
          }
          validateOnMount
        >
          {({ handleSubmit }) => {
            return (
              <>
                <CareTeamNameInput />
                <div className={styles['care-team-tiers-container']}>
                  <div className={`d-flex mt-2 py-2 ${styles['table-header']}`}>
                    <div className={`${styles['flex-basis-priority']} pl-1`}>Priority</div>
                    <div className="flex-grow-1 pl-1">Provider tiers</div>
                  </div>
                  <PrimaryPhysicianTierComponent isEditing={isEditing} />
                  <CareTeamTiersDnD isEditing={isEditing} />
                  <AddTierButton createLocalId={createLocalId} />
                  <CareTeamCreateEditFooter
                    handleRemoveTeam={() =>
                      onDelete(
                        currentCareTeam.id,
                        currentCareTeam.patients_count,
                        currentCareTeam.providers_count
                      )
                    }
                    handleSubmit={handleSubmit}
                    isNew={!Boolean(currentCareTeam)}
                    onCancel={onCancel}
                  />
                </div>
              </>
            );
          }}
        </Formik>
      }
      centered
    />
  );
};
export default CareTeamCreateEditModal;
