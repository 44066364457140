import shieldLogo from 'assets/images/secure-shield.png';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useEffect, useState, Fragment, SetStateAction } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useHistory } from 'react-router-dom';
import { setBreadcrumTitle } from 'util/themeUtils';
import { getStyles, handleOnSubmitValidateData } from '../utils';
import RegisterLoginLayout from 'screens/Common/RegisterLoginLayout';
import { LOGIN_ATTEMPT } from '../../../shared/global-constants';
import { sendTechnicalSupportEmail, chooseTypeToSendCode, chooseTypeToSendCodePost, login, changePasswordUser } from '../../../services/userService';
import VytracModal from '../../../components/Modal';
import './styles.css';
import moment from 'moment';
import ProfileImage from '../../../components/ProfileImage'
import profileImage from '../../../assets/images/dashboard/profile.png';
// import { ReactComponent as MessageIcon } from 'assets/icons/dashboard/messages_icon.svg';
// import { ReactComponent as NoMobileAppIcon } from 'assets/icons/no_mobile_app.svg';
import CallBtn from 'assets/icons/call_btn.png';
import MessageIcon from 'assets/icons/text_icon.png';
import MailIcon from 'assets/icons/mail_icon.png';
import eyeCloseIcon from 'assets/icons/eye_closed.svg';
import eyeIcon from 'assets/icons/eye_opened.svg';
import VerificationToken from 'screens/Common/VerificationToken';
import { Token } from 'react-bootstrap-typeahead';
import { VerificationCode } from 'types/ApiModels/Login';
import DatePickerFormik from 'components/FormikComponents/date-picker-formik';
import { convertDateToDatePickValue } from 'util/dateUtils';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';


type ValidateProps =  {
    invitation: string
}

const ValidateEmailAndLastName = ({invitation}: ValidateProps) => {
    const [step, setStep] = useState(1);
    const [failsTrials, setFailTrials] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [validCode, setValidCode] = useState<VerificationCode | undefined>();
    const [isResettingPassword, setIsResettingPassword] = useState(false);
    const [numberRequired, setNumberRequired] = useState(false);
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);

    const [userInfo, setUserInfo] = useState({
        userId: null,
        id: null,
        name: null,
        rol: null,
        emailAddress: null,
        cellPhoneNumber: null,
        password: null
    });
    const email = queryParams.get('email')
    const [typeToSendCode, setTypeToSendCode] = useState(null);

    const stringDateComponent = () => {
        const date = moment().format('dddd, MMMM DD, YYYY');
        const time = moment().format('h:mm a');

        return (
            <Fragment>
                <p className='date-text'>{date}</p>
                <p className='time-text'>{time}</p>
            </Fragment>
        );
    };
    
    const getDataType = (e) => {
        if (!userInfo?.userId) {
            console.log('Not exists the userId yet');
            return;
        }

        const type = e.target.getAttribute('data-type');
        setTypeToSendCode(type);
        setShowModal(false);
        const number_code = (type == 'sms' ? userInfo.cellPhoneNumber : 1);
        chooseTypeToSendCodePost(userInfo?.userId, type, number_code);
        changePasswordUser(userInfo.userId, queryParams.get('hash'), userInfo.password, userInfo.cellPhoneNumber)
        setStep(3)
        //changeView(loginViews.token);
        //history.push('login')
    };

    const handleLoginValues = (values: any) => {
        setUserInfo(values);
    };

    const bodyModal = (email: string) => {
        return (
            <Fragment>
                <ProfileImage
                    sex="M"
                    className='modal-body_profile_avatar'
                    src={profileImage}
                />
                <div style={{ 'padding': '20px' }}>
                    <p className='modal-body_user_name'>{userInfo?.name}</p>
                    <p className='modal-body_subtitle'>Choose authentication method:</p>
                    <div className='modal-body_methods_group'>
                        {/* Email */}
                        <img src={MailIcon} className='icons-modal' />
                        <span className='modal-body_group_contact_way' data-type='email' onClick={getDataType}>{userInfo.emailAddress}</span>
                    </div>
                    {(userInfo.cellPhoneNumber != '') &&
                        <div className='modal-body_methods_group'>
                            {/* Text Message */}
                            <img src={MessageIcon} className='icons-modal' />
                            <span className='modal-body_group_contact_way' data-type='sms' onClick={getDataType}>{userInfo.cellPhoneNumber}</span>
                        </div>
                    }
                    {/* <div>
                        Whatsapp
                        <img src={CallBtn} className='icons-modal'/>
                        <span data-type='whatsapp' onClick={getDataType}>+1-844-453-98-54</span>
                    </div> */}
                </div>
            </Fragment>
        );
    };

    const LAST_NAME_MIN_LENGTH = 4;

    return (
        <div>
            {step == 1 &&
                <RegisterLoginLayout>
                    <div className="d-flex h-100 flex-column">
                        <div>
                            <Row className="vertical-container">
                                <Col className="line-container">
                                    <div className="grey-line-inv"></div>
                                </Col>
                                <Col className="line-container">
                                    <div className="green-line-inv"></div>
                                </Col>
                            </Row>

                            <div className="centered-logo">
                                <div className="logo-container">
                                    <img className="shield-logo" alt="" src={shieldLogo} />
                                </div>
                            </div>

                            <p className="text-left normal-text top25 dynamic-font-heading" style={{ marginTop: 30 }}>
                                Welcome to <span className="bold-blue text-bolder">{setBreadcrumTitle()}</span>
                            </p>

                            {failsTrials < LOGIN_ATTEMPT &&
                                <p className="text-left smaller-text dynamic-font-normal">
                                    Please confirm your Email and Last Name
                                </p>
                            }
                        </div>

                        {(failsTrials > 0 && failsTrials <= LOGIN_ATTEMPT) &&
                            <div className="dynamic-font-normal text-danger">Attempt {failsTrials} of {LOGIN_ATTEMPT}</div>
                        }

                        {(failsTrials === LOGIN_ATTEMPT) &&
                            <div className="dynamic-font-normal text-danger">Login failed: A system administrator will contact you for assistance</div>
                        }




                        {(failsTrials < LOGIN_ATTEMPT) &&
                            <Formik
                                initialValues={{ email: '', lastName: '' ,dateOfBirth:''}}
                                onSubmit={async (e) => {
                                    if (!e.email || !e.lastName || e.lastName.length < LAST_NAME_MIN_LENGTH) return;
                                    if (invitation=="patient" && e.dateOfBirth=='') return;
                                    let birth_day="1899-01-01";
                                    if (invitation=="patient"){
                                        birth_day=e.dateOfBirth["year"]+"-"+e.dateOfBirth["month"]+"-"+e.dateOfBirth["day"]
                                    }

                                    try {
                                        const response = await handleOnSubmitValidateData(e.email, e.lastName, birth_day);
                                        if (response.status === 202) {
                                            const counter = failsTrials + 1
                                            if (counter === 3) await sendTechnicalSupportEmail(email);
                                            setFailTrials(counter);
                                            return;
                                        }
                                        setFailTrials(0)
                                        const { data: { user_id, id, email_address, name, cell_phone_number, rol } } = response;
                                        setUserInfo({ userId: user_id, id, emailAddress: email_address, name, rol: rol, cellPhoneNumber: cell_phone_number, password: null });
                                        setStep(2)
                                        //setShowModal(true);
                                    } catch (error) {
                                        console.error(error);
                                        // setErrorMessage('Error in the aplication');
                                    }
                                }}
                            >
                                {({ errors, touched, handleChange, handleBlur, setFieldValue, isSubmitting }) => (
                                    <Form className="d-flex flex-column flex-grow-1">
                                        <p className={`grey-text dynamic-font-normal`}>Email</p>
                                        <Field
                                            onChange={(evt) => {
                                                handleChange(evt);
                                            }}
                                            onBlur={handleBlur}
                                            className="text-field dynamic-font-normal"
                                            type="text"
                                            style={touched['email'] && getStyles(errors, 'email')}
                                            name="email"
                                        />
                                        <ErrorMessage name="email">
                                            {(msg) => <div className="dynamic-font-normal text-danger">{msg}</div>}
                                        </ErrorMessage>
                                        <br />
                                        <p className={`grey-text dynamic-font-normal`}>Last Name</p>
                                        <Field
                                            onChange={(evt) => {
                                                handleChange(evt);
                                            }}
                                            onBlur={handleBlur}
                                            className="text-field dynamic-font-normal"
                                            type="text"
                                            style={touched['lastName'] && getStyles(errors, 'lastName')}
                                            name="lastName"
                                        />
                                        <ErrorMessage name="lastName">
                                            {(msg) => <div className="dynamic-font-normal text-danger">{msg}</div>}
                                        </ErrorMessage>
                                        <br />
                                        {invitation=="patient" && (
                                            <>
                                                <p className={`grey-text dynamic-font-normal`}>Date Of Birth</p>
                                                <div className={`flex-grow-1 d-flex flex-column gap`}>
                                                    <DatePickerFormik
                                                        name="dateOfBirth"
                                                        inputClassName={'vytrac-input'}
                                                        inputContainerClass={`px-2 vytrac-input rounded d-flex justify-content-between align-items-center`}
                                                        inputComputedStyle={getStyles(errors, "dateOfBirth")}
                                                        maximumDate={convertDateToDatePickValue(new Date())}
                                                    >
                                                        <CalendarIcon />
                                                    </DatePickerFormik>
                                                    <ErrorMessage name="dateOfBirth">
                                                        {(msg) => <div className="text-danger font-size-small">{msg}</div>}
                                                    </ErrorMessage>
                                                </div>
                                            </>
                                        )
                                        }
                                        <Row className="d-flex justify-content-between">
                                            <Button onClick={() => console.log('ok')} className="dynamic-font-normal text-bold" variant="light">
                                                CANCEL
                                            </Button>
                                            <Button
                                                disabled={failsTrials >= 3}
                                                type="submit"
                                                className="dynamic-font-normal text-bold"
                                            >
                                                CONTINUE
                                            </Button>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>
                        }
                    </div>
                </RegisterLoginLayout>
            }

            {step == 2 &&
                <RegisterLoginLayout>
                    <div className="d-flex h-100 flex-column">
                        <div>
                            <div className="d-flex w-100 mb-5">
                                <div className="flex-grow-1">
                                    <div className="green-line"></div>
                                </div>
                                <div className="flex-grow-1">
                                    <div className="grey-line"></div>
                                </div>
                            </div>
                            <p className="text-left dynamic-font-heading mb-5">
                                Welcome to <span className="bold-blue text-bolder">{setBreadcrumTitle()}</span>
                            </p>

                            <p className="text-left smaller-text dynamic-font-normal">
                                Create your login credentials to access the Vytrac platform
                            </p>

                            {(failsTrials > 0 && failsTrials <= LOGIN_ATTEMPT) &&
                                <div className="dynamic-font-normal text-danger">Attempt {failsTrials} of {LOGIN_ATTEMPT}</div>
                            }

                            {(failsTrials >= LOGIN_ATTEMPT) &&
                                <div className="dynamic-font-normal text-danger">Login failed: A system administrator will contact you for assistance</div>
                            }

                            {(numberRequired === true) &&
                                <div className="dynamic-font-normal text-danger">cell phone is required for admin users</div>
                            }

                        </div>


                        {(failsTrials < LOGIN_ATTEMPT) &&

                            <Formik
                                enableReinitialize={true}
                                initialValues={{ email: userInfo.emailAddress, password: '', confirm_password: '', phone: userInfo.cellPhoneNumber }}
                                onSubmit={async (e) => {
                                    setNumberRequired(false)
                                    if (e.password === e.confirm_password && e.email === userInfo.emailAddress) {
                                        userInfo.password = e.password;
                                        userInfo.cellPhoneNumber = e.phone;
                                        if (userInfo.rol <= 2 && e.phone == '') {
                                            setNumberRequired(true)
                                        }
                                        else {
                                            setShowModal(true)
                                        }

                                    }
                                    else {
                                        const counter = failsTrials + 1
                                        setFailTrials(counter)
                                    }
                                }}
                            >
                                {({ errors, touched, handleChange, handleBlur, setFieldValue, isSubmitting }) => (
                                    <Form className="d-flex flex-column flex-grow-1">
                                        {/** Email */}
                                        <p className={`grey-text dynamic-font-normal`}>Email</p>
                                        <Field
                                            onChange={(evt) => {
                                                handleChange(evt);
                                            }}
                                            onBlur={handleBlur}
                                            className="text-field dynamic-font-normal"
                                            type="text"
                                            disabled={true}
                                            style={touched['email'] && getStyles(errors, 'email')}
                                            name="email"
                                        />
                                        <ErrorMessage name="email">
                                            {(msg) => <div className="dynamic-font-normal text-danger">{msg}</div>}
                                        </ErrorMessage>

                                        {/** password */}
                                        <p className={` grey-text dynamic-font-normal mt-4 mb-0`}>Password</p>
                                        <div>
                                            <Field
                                                onChange={(evt) => {
                                                    handleChange(evt);
                                                }}
                                                onBlur={handleBlur}
                                                style={touched['password'] && getStyles(errors, 'password')}
                                                className="text-field dynamic-font-normal"
                                                type={!showPassword ? 'password' : 'text'}
                                                name="password"
                                                onPaste={(e) => {
                                                    e.preventDefault()
                                                    return false;
                                                }}
                                                onCopy={(e) => {
                                                    e.preventDefault()
                                                    return false;
                                                }}
                                            />
                                            <img
                                                className="textfield-button"
                                                onClick={() => setShowPassword((prev) => !prev)}
                                                src={!showPassword ? eyeIcon : eyeCloseIcon}
                                                style={{ transform: 'scale(2)' }}
                                                alt=""
                                            />
                                            <ErrorMessage name="password">
                                                {(msg) => <div className="dynamic-font-normal text-danger">{msg}</div>}
                                            </ErrorMessage>
                                        </div>

                                        {/** ConfirmPass */}
                                        <p className={` grey-text dynamic-font-normal mt-4 mb-0`}>Confirm password</p>
                                        <div>
                                            <Field
                                                onChange={(evt) => {
                                                    handleChange(evt);
                                                }}
                                                onBlur={handleBlur}
                                                style={touched['confirm_password'] && getStyles(errors, 'confirm_password')}
                                                className="text-field dynamic-font-normal"
                                                type={!showConfirmPassword ? 'password' : 'text'}
                                                name="confirm_password"
                                                onPaste={(e) => {
                                                    e.preventDefault()
                                                    return false;
                                                }}
                                                onCopy={(e) => {
                                                    e.preventDefault()
                                                    return false;
                                                }}
                                            />
                                            <img
                                                className="textfield-button"
                                                onClick={() => setShowConfirmPassword((prev) => !prev)}
                                                src={!showConfirmPassword ? eyeIcon : eyeCloseIcon}
                                                style={{ transform: 'scale(2)' }}
                                                alt=""
                                            />
                                            <ErrorMessage name="confirm_password">
                                                {(msg) => <div className="dynamic-font-normal text-danger">{msg}</div>}
                                            </ErrorMessage>
                                        </div>
                                        {/** phone */}
                                        <p className={`grey-text dynamic-font-normal`}>Add a phone number</p>
                                        <Field
                                            onChange={(evt) => {
                                                handleChange(evt);
                                            }}
                                            onBlur={handleBlur}
                                            className="text-field dynamic-font-normal"
                                            type="text"
                                            style={touched['phone'] && getStyles(errors, 'phone')}
                                            name="phone"
                                            onPaste={(e) => {
                                                e.preventDefault()
                                                return false;
                                            }}
                                            onCopy={(e) => {
                                                e.preventDefault()
                                                return false;
                                            }}
                                        />
                                        <ErrorMessage name="phone">
                                            {(msg) => <div className="dynamic-font-normal text-danger">{msg}</div>}
                                        </ErrorMessage>
                                        <div>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <Button onClick={() => console.log('cancel process no implemented')} className="dynamic-font-normal text-bold" variant="light">
                                                CANCEL
                                            </Button>
                                            <Button
                                                type="submit"
                                                className="dynamic-font-normal text-bold"
                                            >
                                                CONTINUE
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        }
                    </div>
                    <VytracModal
                        title={stringDateComponent()}
                        show={showModal}
                        body={bodyModal(email)}
                        onClose={() => setShowModal(false)}
                        centered={true}
                        size={"sm"}
                        headerClassName="header-modal_select_option_code"
                    ></VytracModal>
                </RegisterLoginLayout>
            }
            {step == 3 &&
                <RegisterLoginLayout>
                    <VerificationToken
                        typeSendCode={typeToSendCode}
                        previousValues={userInfo}
                        changeView={getDataType}
                        setPreviousValues={handleLoginValues}
                        isResetting={isResettingPassword}
                        setIsResetting={setIsResettingPassword}
                        userEmail={userInfo.emailAddress}
                        setValidCode={setValidCode}
                        userId={userInfo.userId}
                    />
                </RegisterLoginLayout>
            }
        </div>
    );

    // return (<>hola</>);
};

export default ValidateEmailAndLastName;
