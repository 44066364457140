import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { Provider } from 'types/ApiModels/Providers/Provider';
import { MinifiedUser } from 'types/ApiModels/Users';
import { validateContext } from 'util/utils';

interface AuthContextState {
  currentUser: MinifiedUser;
  providerInfo: Provider;
  setCurrentUser: Dispatch<SetStateAction<MinifiedUser>>;
  setProviderInfo: Dispatch<SetStateAction<Provider>>;
  path?: string;
  hashToken?: string;
  email?: string;
}
export const AuthContext = createContext<AuthContextState>({} as AuthContextState);

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  validateContext(context);
  return context;
};
