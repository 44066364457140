import Card from 'components/Card';
import SwitchInput from 'components/SwitchInput';
import { useFormikContext } from 'formik';
import { useState } from 'react';



interface ProviderActiveStatusProps {
  activeStatus: boolean;
}

const statusField = {
  label: 'active'
};


const ProviderActiveStatus = ({ activeStatus }:ProviderActiveStatusProps) => {
  const [active, setActive] = useState(activeStatus);
  const [vacationMode, setVacationMode] = useState(false);
  const { setFieldTouched, values, setFieldValue } = useFormikContext<{ Active: boolean }>();

  const handleActiveChange = () => {
    setFieldValue(statusField.label,!active)
    setActive((a) => !a);
  };
  const handleVacationModeChange = () => {
    setVacationMode((vm) => !vm);
  };
  return (
    <Card
      className="w-100 card-bg-border"
      bodyClassName="d-flex flex-column gap"
      headers={['Active status']}
    >
      <SwitchInput label="Active" onChange={handleActiveChange} value={active} />
      <SwitchInput label="Vacation mode" onChange={handleVacationModeChange} value={vacationMode} />
    </Card>
  );
};
export default ProviderActiveStatus;
