import { useCallback, useEffect } from 'react';
import { getRoles } from 'services/administrationService';
import { useAdministrationContextV2 } from '../AdministrationContextV2';
import { setAdminRoles } from '../Users';

export const useAdminRoles = () => {
  const {
    administrationStateV2: {
      users: { roles },
    },
    dispatch,
  } = useAdministrationContextV2();

  const fetchRoles = useCallback(async () => {
    const roles = await getRoles();
    dispatch(setAdminRoles(roles));
  }, [dispatch]);

  useEffect(() => {
    if (!roles) {
      fetchRoles();
    }
  }, [fetchRoles, roles]);

  return { roles };
};
