import { useAuthContext } from 'auth/store/AuthContext';
import Button from 'components/Button';
import { ControlButtonLink } from 'components/ControlButton';
import PermissionsGate from 'components/PermissionsGate/PermissionsGate';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useAdministrationContextV2 } from 'screens/Administration/store';
import { toggleShowInviteProvider } from 'screens/Administration/store/Providers';
import { getCurrentPermissionBySection } from 'util/permissionUtils';
import AdminCareTeam from './AdminCareTeam';
import AdminSpecialty from './AdminSpecialty';
import ProviderDetails from './ProviderDetails';
import ProvidersTable from './ProvidersTable';
import create from 'zustand'

export const AdminProvidersActions = () => {
  const {
    administrationStateV2: {
      providers: {
        resetProvidersForm,
        submitProvidersForm,
        updatedIsActiveProviders,
        deletedProviders,
      },
    },
    dispatch,
  } = useAdministrationContextV2();

  const currentSection = 'ADMINISTRATION_providers';
  let permissions = {};
  const { currentUser } = useAuthContext();
  permissions = currentUser.permissions;
  const permissionKey = getCurrentPermissionBySection(permissions, currentSection)

  return (
    <div className="d-flex gap-sm">
      <PermissionsGate can={permissionKey[0].type}>
            <Button
              label="cancel"
              variant="cancel"
              disabled={updatedIsActiveProviders.length === 0 && deletedProviders.length === 0}
              onClick={() => {
                resetProvidersForm();
              }}
            />
            <Button
              label="save changes"
              variant="confirm"
              disabled={updatedIsActiveProviders.length === 0 && deletedProviders.length === 0}
              onClick={submitProvidersForm}
            />
            <Button
              label="add new provider"
              onClick={() => {
                dispatch(toggleShowInviteProvider());
              }}
            />
      </PermissionsGate>
    </div>
  );
};

export const adminProvidersControlsList = [
  {
    label: 'Provider list',
    enumValue: 'providerList',
    route: 'all',
  },
  {
    label: 'provider specialties',
    enumValue: 'providerSpecialties',
    route: 'specialties',
  },
  {
    label: 'care team',
    enumValue: 'careTeam',
    route: 'careteam',
  },
];

interface PersistPaginationState {
  tempPage: number,
  goToPage: boolean,
  changeGoToPage: (value: boolean) => void,
  change: (page: number) => void
}

export const usePersistPagination = create<PersistPaginationState>()((set) => ({
  tempPage: null,
  goToPage: false,
  changeGoToPage: (value: boolean) => {
    set({ goToPage: value })
  },
  change: (page: number) => {
    set({ tempPage: page });
  }
}))

const AdminProviders = () => {
  const { path, url } = useRouteMatch();

  const tempPage = usePersistPagination(state => state)

  return (
    <div className="container-with-padding d-flex gap flex-column">
      <div className="d-flex gap">
        {adminProvidersControlsList.map((apcl) => (
          <ControlButtonLink
            key={apcl.enumValue}
            to={`${url}${apcl.route ? '/' + apcl.route : ''}`}
            callback={apcl.route == "all" && (() => tempPage.changeGoToPage(true))}
          >
            {apcl.label}
          </ControlButtonLink>
        ))}
      </div>
      <Switch>
        <Route path={`${path}/all`} exact>
          <ProvidersTable />
        </Route>
        <Route path={`${path}/all/:providerId`}>
        <ProviderDetails />
        </Route>
        <Route path={`${path}/specialties`}>
          <AdminSpecialty />
        </Route>
        <Route path={`${path}/careteam`}>
          <AdminCareTeam />
        </Route>
      </Switch>
    </div>
  );
};

export default AdminProviders;
