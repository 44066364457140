import React from 'react';
import { Table } from 'react-bootstrap';
import PatientGet from 'types/ApiModels/Patients/PatientGet';
//import PatientRow from './components/Row';
import PatientRowV2 from './components/Row/PatientRowV2'
import styles from './styles.module.css';

interface IPatientPreviewTable {
  patientList?: PatientGet[];
  hideTHead?: boolean;
  onPatientClick?: (patient: PatientGet) => void;
  isLoading?: boolean;
}

const PatientPreviewTable = ({
  patientList = [],
  hideTHead = false,
  onPatientClick = () => null,
  isLoading = false,
}: IPatientPreviewTable) => {
  return (
    <Table className={`mb-0 ${styles.main}`} responsive borderless>
      {!hideTHead && (
        <thead>
          <tr>
            <th>score</th>
            <th>patient</th>
            <th>patient id</th>
            <th>date of birth</th>
            <th>home phone number</th>
            <th>email address</th>
            <th>language preference</th>
            <th>contact preferred</th>
            <th>program start date</th>
            <th>program end date</th>
            <th>active</th>
            <th>others</th>
          </tr>
        </thead>
      )}
      <tbody style={{ opacity: isLoading && '50%' }}>
        {patientList.map((patient, index) => (
          // <PatientRow key={index} patient={patient} onPatientClick={onPatientClick} />
          <PatientRowV2 key={index} patient={patient} onPatientClick={onPatientClick} />
        ))}
      </tbody>
    </Table>
  );
};

export default PatientPreviewTable;
