import { Fragment, useState, useRef, useEffect } from 'react';
import { browserName, browserVersion } from "react-device-detect";
import { Button, Col, Row } from 'react-bootstrap';
import passwordLogo from 'assets/images/password.png';
import { useLoginContext } from '../store';
import { loginViews } from 'types/Login';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { ForgotPasswordValidationSchema } from '../ValidationSchemas';
import { getStyles } from '../LoginForm/util';
import { forgotPassword, getCurrentDeviceInfo, sendVerificationCodeEmail } from 'services/userService';
import { setBreadcrumTitle } from 'util/themeUtils';
import VytracModal from '../../../components/Modal';
import moment from 'moment';
import ProfileImage from '../../../components/ProfileImage'
import profileImage from '../../../assets/images/dashboard/profile.png';
import CallBtn from 'assets/icons/call_btn.png';
import MessageIcon from 'assets/icons/text_icon.png';
import MailIcon from 'assets/icons/mail_icon.png';
import {
  sendTechnicalSupportEmail,
  chooseTypeToSendCode,
  checkEmail,
  requestCode
} from '../../../services/userService';
import { useHistory } from 'react-router-dom';
import { LOGIN_ATTEMPT } from 'shared/global-constants';
import e from 'express';

interface ForgotPasswordValues {
  email: string;
}

const ForgotPassword = () => {
  const { changeView, setUserEmail, setIsResettingPassword } = useLoginContext();
  const [userInfo, setUserInfo] = useState({
    userId: null,
    id: null,
    name: null,
    emailAddress: null,
    cellPhoneNumber: null,
  });
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState(null);
  const [failsTrials, setFailTrials] = useState(0);
  const [userDeviceInfo, setUserDeviceInfo] = useState({
    ip: null,
    browser: null,
    OS:null,
    city: null,
    region: null,
    country: null,
    country_calling_code:null,
    activity_type:null,
    email:null
  });


  const bodyModal = () => {
    return (
      <Fragment>
        <div style={{ 'padding': '20px' }}>
          <p className='modal-body_subtitle'>An email has been sent to {userDeviceInfo.email} with a link that will allow you to change your password.</p>
        </div>
      </Fragment>
    );
  };

  useEffect(() => {
    const os = window.navigator.platform
    const userdata=getCurrentDeviceInfo().then(info=>{
      const { data: { ip,city,region,country_name,country_calling_code } } = info;
      setUserDeviceInfo({ip:ip, browser:browserName, OS:os, city:city, region:region, country:country_name, country_calling_code:country_calling_code,email:email,activity_type:"forgot password"});
    })
  },[])


  const handleSubmit = async ({ email }: ForgotPasswordValues) => {
    userDeviceInfo.email=email;
    try{
      const res= await forgotPassword(email,userDeviceInfo).then(e=>{
        if (e.status!=200){
          const tmp=failsTrials+1
          setFailTrials(tmp)
        }else{
          setShowModal(true)
        }
      })
    } catch(error) {
      
    }
  };

  return (
    <div className="d-flex flex-column justify-content-between h-100">
      <div>
        <Row className="vertical-container">
          <Col className="line-container">
            <div className="green-line"></div>
          </Col>
          <Col className="line-container">
            <div className="grey-line"></div>
          </Col>
        </Row>
        <div className="centered-logo">
          <div className="logo-container">
            <img className="shield-logo" alt="" src={passwordLogo} />
          </div>
        </div>
        <p className="text-left normal-text dynamic-font-heading" style={{ marginTop: 30 }}>
          Forgot my password
        </p>
        <p className="text-left smaller-text dynamic-font-normal">
          Enter the email you used to register your {setBreadcrumTitle()} account.
        </p>
      </div>
      {(failsTrials > 0 && failsTrials <= LOGIN_ATTEMPT) &&
        <div className="dynamic-font-normal text-danger">The email is invalid: Attempt {failsTrials} of {LOGIN_ATTEMPT}</div>
      }
      {(failsTrials > LOGIN_ATTEMPT) &&
        <div className="dynamic-font-normal text-danger">A system administrator will contact you.</div>
      }
      <div className="d-flex flex-column flex-grow-1">
        <Formik
          initialValues={{ email: '' }}
          onSubmit={async (e) => await handleSubmit(e)}
          validationSchema={ForgotPasswordValidationSchema}
          validateOnBlur
        >
          {({ touched, errors }) => (
            <Form className="d-flex flex-column gap-lg flex-grow-1 justify-content-between">
              <div className="d-flex flex-grow-1">
                <div className="d-flex flex-grow-1 flex-column justify-content-center">
                  <p className="grey-text dynamic-font-normal">Email</p>
                  <Field
                    name="email"
                    className="text-field dynamic-font-normal"
                    style={touched['email'] && getStyles(errors, 'email')}
                    ref={email}
                  />
                  <ErrorMessage name="email">
                    {(msg) => <div className="dynamic-font-normal text-danger">{msg}</div>}
                  </ErrorMessage>
                </div>
              </div>
              <Row className="d-flex justify-content-between">
                <Button
                  onClick={() => changeView(loginViews.login)}
                  type="button"
                  variant="light"
                  className="dynamic-font-normal text-bold"
                >
                  CANCEL
                </Button>
                <Button type="submit" className="dynamic-font-normal text-bold">
                  NEXT
                </Button>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
      <VytracModal
        show={showModal}
        body={bodyModal()}
        footer={
            <Button onClick={() => setShowModal(false)}>confirm</Button>
        }
        onClose={() => setShowModal(false)}
        centered={true}
        size={"sm"}
        headerClassName="header-modal_select_option_code"
      ></VytracModal>
    </div>
  );
};
export default ForgotPassword;
