import VyTracModal from 'components/Modal';
import { useMemo } from 'react';
import { useAdminRoles, useAdminSpecialties } from 'screens/Administration/store';
import InviteProviderForm from './InviteProviderForm';
import styles from './styles.module.css';

const InviteProviderModal = ({ show, onClose, onConfirm }) => {
  const { roles } = useAdminRoles();
  const [{ list: specialties }] = useAdminSpecialties();

  const rolesList = useMemo(() => 
  roles?.map((r) => ({ label: r.name, value: r.id })) ?? [], [roles]
  );

  const mappedSpecialties = useMemo(
    () => specialties?.map((s) => ({ label: s.text, value: s.id })) ?? [],
    [specialties]
  );

  return (
    <VyTracModal
      backdrop={false}
      title="Invite New Provider"
      headerClassName={styles.header}
      body={
        <InviteProviderForm
          onConfirm={onConfirm}
          rolesList={rolesList}
          onCancel={onClose}
          specialtiesList={mappedSpecialties}
        />
      }
      footer={undefined}
      onClose={onClose}
      show={show}
      centered
    />
  );
};
export default InviteProviderModal;
