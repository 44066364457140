import addTagIcon from 'assets/icons/add_tag_icon.svg';
import deleteTagIcon from 'assets/icons/remove_tag_icon.svg';
import Card from 'components/Card';
import SpecialtiesModal from 'components/BulletedModals/SpecialtiesModal';
import { Tag } from 'components/Tagitem';
import TagList from 'components/TagList';
import { ErrorMessage, useFormikContext } from 'formik';
import { useMemo, useState } from 'react';
import { Provider } from 'types/ApiModels/Providers/Provider';
import Specialty from 'types/ApiModels/Providers/Specialty';

interface ProviderSpecialtiesProps {
  selectedProvider: Provider;
  fieldName: string;
  specialties: Specialty[];
}

const specialtiesField = {
  label: 'Specialties',
  name: 'specialties',
};

const ProviderSpecialties = ({
  selectedProvider,
  fieldName,
  specialties,
}: ProviderSpecialtiesProps) => {
  const { setFieldTouched, values, setFieldValue } = useFormikContext<{ specialties: number[] }>();

  const [showSpecialtiesModal, setShowSpecialtiesModal] = useState(false);

  const currentlySelectedSpecialties = useMemo(
    // () => specialties.filter((s) => values.specialties.includes(selectedProvider.speciality)),
    () => specialties.filter(s => s.id == selectedProvider.speciality),
    [specialties, values.specialties]
  );

  const handleConfirm = (ids) => {
    setFieldTouched(specialtiesField.name, true);
    setFieldValue(specialtiesField.name, ids, true);
    setShowSpecialtiesModal(false);
  };

  const handleRemoveSpecialty = (specialtyId: number) => {
    setFieldTouched(specialtiesField.name, true);
    setFieldValue(
      specialtiesField.name,
      values.specialties.filter((sid) => sid !== specialtyId),
      true
    );
  };

  return (
    <Card className="w-100 card-bg-border" headers={[specialtiesField.label]}>
      <TagList
        className="flex-wrap gap-sm"
        tagList={[
          ...currentlySelectedSpecialties.map<Tag>((s) => ({
            text: s.text,
            icon: (
              <img
                src={deleteTagIcon}
                height={16}
                width={16}
                className="cursor-pointer"
                onClick={() => handleRemoveSpecialty(s.id)}
              />
            ),
          })),
          {
            text: 'Add',
            icon: <img src={addTagIcon} height={16} width={16} />,
            className: 'cursor-pointer',
            onClick: () => {
              setShowSpecialtiesModal(true);
            },
          },
        ]}
      />
      <ErrorMessage name={fieldName}>
        {(msg) => <div className="text-danger font-size-small">{msg}</div>}
      </ErrorMessage>
      {showSpecialtiesModal ? (
        <SpecialtiesModal
          assignedSpecialtiesIds={values.specialties}
          specialties={specialties}
          handleClose={() => setShowSpecialtiesModal(false)}
          handleConfirm={handleConfirm}
          show={showSpecialtiesModal}
        />
      ) : null}
    </Card>
  );
};
export default ProviderSpecialties;
