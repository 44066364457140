import Button from 'components/Button';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.css';
import TabRouteMap from '../../../../../../util/TabRouteMap';
import TabEnum from '../../../../../../util/TabEnum';
import { useAuthContext } from 'auth/store/AuthContext';
import { getCurrentPermissionBySection } from 'util/permissionUtils';
import PermissionsGate from 'components/PermissionsGate/PermissionsGate';

const Actions = () => {
  const history = useHistory();

  const currentSection = 'TEMPLATES_care_plan_templates';
  let permissions = {};
  const { currentUser } = useAuthContext();
  permissions = currentUser.permissions;
  const permissionKey = getCurrentPermissionBySection(permissions, currentSection);
  
  return (
    <div className={styles.rpmTemplateActions}>
      <PermissionsGate can={permissionKey[0].type}>
        <Button
          label="create new template"
          onClick={() => history.push(`${TabRouteMap[TabEnum.CAREPLAN_TEMPLATES]}/create`)}
        />
      </PermissionsGate>
    </div>
  );
};
export default Actions;
