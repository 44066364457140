import { useAuthContext } from 'auth/store/AuthContext';
import EditDeleteActionButtons from 'components/EditDeleteActionButtons';
import UserBubble from 'components/UserBubble';
import UserBubbleGroup from 'components/UserBubbleGroup';
import { CareTeam, CareTeamProvider } from 'types/ApiModels/CareTeam';
import { Provider } from 'types/ApiModels/Providers/Provider';
import GenericColumn from 'types/Shared/GenericColumn';
import { getCurrentPermissionBySection } from 'util/permissionUtils';
import { getUserFullName } from 'util/userUtils';
import { CareTeamNameCell, PatientsCell, ProvidersCell } from '../AdminCareTeamTableCells';
import { careTeamColumnKey, CareTeamRow } from '../types';

const mapColumnKeyToLabel: Record<careTeamColumnKey, string> = {
  [careTeamColumnKey.id]: 'id',
  [careTeamColumnKey.name]: 'Care team name',
  [careTeamColumnKey.team_lead]: 'team lead',
  [careTeamColumnKey.members]: 'members',
  [careTeamColumnKey.patients_count]: 'patients',
  [careTeamColumnKey.providers_count]: 'providers',
  [careTeamColumnKey.action]: 'action',
};

export const mapCareTeamProviderToUserBubble = (m: CareTeamProvider) => { 
  return m === null ?
  ({
  id: 0,
  first_name: '?',
  imageUrl: '?',
  last_name: '?',
  }) : 
  ({
    id: m.id,
    first_name: m.first_name ?? '?',
    imageUrl: m.photo_thumbnail,
    last_name: m.last_name ?? '?',
  })

};

export const mapProviderToCareTeamProvider = (
  p: Provider
): Pick<
  CareTeamProvider,
  'first_name' | 'id' | 'last_name' | 'photo_thumbnail' | 'specialities'
> => ({
  first_name: p.first_name,
  id: p.id,
  last_name: p.last_name,
  photo_thumbnail: p.photo,
  specialities: p.specialities,
});

export const mapCareTeamToCareTeamRow = (ct: CareTeam): CareTeamRow => {
  const [team_lead, members] = ct.tiers.reduce(
    (res, current) => {
      if (current.number === 1) {
        return [current, res[1]];
      } else {
        res[1].push(...current.providers);
        return res;
      }
    },
    [null, []]
  );

  return {
    id: ct.id,
    name: ct.name,
    members,
    team_lead,
    patients_count: ct.patients_count,
    providers_count: ct.providers_count,
  };
};


export const createCareTeamColumn = ({
  styles,
  handleEdit,
  handleDeleteWithPrevent,
  handlePatientCountClick,
  handleProviderCountClick
}): GenericColumn[] => {

  return [
    { key: careTeamColumnKey.id, hidden: true, title: mapColumnKeyToLabel[careTeamColumnKey.id] },
    {
      key: careTeamColumnKey.name,
      title: mapColumnKeyToLabel[careTeamColumnKey.name],
      headerClasses: 'flex-grow-1',
      tdClassName: 'flex-grow-1',
      render: (row: CareTeamRow) =>{
        return CareTeamNameCell({
          membersNames: row.members.map((m) => getUserFullName(mapCareTeamProviderToUserBubble(m))),
          teamLeadName: row.team_lead == null ? '' : getUserFullName(mapCareTeamProviderToUserBubble(row.team_lead)),
          name: row.name,
        })
      
      },
    },
    {
      key: careTeamColumnKey.team_lead,
      title: mapColumnKeyToLabel[careTeamColumnKey.team_lead],
      tdClassName: styles['flex-basis-10'],
      headerClasses: styles['flex-basis-10'],
      render: (row: CareTeamRow) =>
        UserBubble({ user: mapCareTeamProviderToUserBubble(row.team_lead), includeMargin: false }),
    },
    {
      key: careTeamColumnKey.members,
      title: mapColumnKeyToLabel[careTeamColumnKey.members],
      tdClassName: styles['flex-basis-10'],
      headerClasses: styles['flex-basis-10'],
      render: (row: CareTeamRow) =>
        UserBubbleGroup({
          users: row.members.map(mapCareTeamProviderToUserBubble),
          maxCount: 3,
        }),
    },
    {
      key: careTeamColumnKey.patients_count,
      title: mapColumnKeyToLabel[careTeamColumnKey.patients_count],
      tdClassName: styles['flex-basis-5'],
      headerClasses: styles['flex-basis-5'],
      render: (row: CareTeamRow) =>
        row.id > 0
          ? PatientsCell({
              count: row.patients_count,
              handlePatientCountClick: () => handlePatientCountClick(row.id),
            })
          : row.patients_count.toString(),
    },
    {
      key: careTeamColumnKey.providers_count,
      title: mapColumnKeyToLabel[careTeamColumnKey.providers_count],
      tdClassName: styles['flex-basis-5'],
      headerClasses: styles['flex-basis-5'],
      render: (row: CareTeamRow) =>
        row.id > 0
          ? ProvidersCell({
              count: row.providers_count,
              handleProviderCountClick: () => handleProviderCountClick(row.id),
            })
          : row.providers_count.toString(),
    },
    {
      key: careTeamColumnKey.action,
      title: mapColumnKeyToLabel[careTeamColumnKey.action],
      tdClassName: styles['flex-basis-5'],
      headerClasses: styles['flex-basis-5'],
      render: (row: CareTeamRow) => 
        EditDeleteActionButtons({
          handleDelete: () => {
            handleDeleteWithPrevent(row.id, row.patients_count, row.providers_count);
          },
          handleEdit: () => handleEdit(row.id),
        })
    },
  ];
};
