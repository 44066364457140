import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { VerificationCode } from 'types/ApiModels/Login';
import { loginViews } from 'types/Login';

interface LoginContextState {
  changeView: (view: loginViews) => void;
  setLoginValues: (values: any) => void;
  loginValues: any;
  setUserEmail: Dispatch<SetStateAction<string>>;
  userEmail: string;
  isResettingPassword: boolean;
  setIsResettingPassword: Dispatch<SetStateAction<boolean>>;
  validCode?: VerificationCode;
  setValidCode: Dispatch<SetStateAction<VerificationCode | undefined>>;
  forgotPasswordProcess: boolean | undefined;
  setForgotPasswordProcess: Dispatch<SetStateAction<boolean>>;
}

const initialLoginContextState: LoginContextState = {
  changeView: (view) => undefined,
  loginValues: {},
  setLoginValues: (values) => undefined,
  setUserEmail: (email) => undefined,
  userEmail: '',
  isResettingPassword: false,
  setIsResettingPassword: (flag) => undefined,
  setValidCode: (code) => undefined,
  forgotPasswordProcess: false,
  setForgotPasswordProcess: (flag) => undefined,
};

export const LoginContext = createContext<LoginContextState>(initialLoginContextState);

export const useLoginContext = () => useContext(LoginContext);
