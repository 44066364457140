import passwordLogo from 'assets/images/password.png';
import { ErrorMessage, Form, Formik } from 'formik';
import { CSSProperties, useEffect, useState, useCallback } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { checkToken, requestPasswordReset } from 'services/userService';
import { loginViews } from 'types/Login';
import PasswordInput from '../../../components/PasswordInput';
import { useLoginContext } from '../store';
import { ResetPasswordValidationSchema } from '../ValidationSchemas';
import RegisterLoginLayout from 'screens/Common/RegisterLoginLayout';
import { checkInvitation } from '../../../services/userService';
import { useHistory } from 'react-router-dom';
import Card from 'components/Card';

const invalidColor = 'red';

enum resetPasswordFields {
  password = 'password',
  confirmPassword = 'confirmPassword',
}

const ResetPassword = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const hashToken = queryParams.get('hash_token');
  const { changeView, setIsResettingPassword } = useLoginContext();
  const [showSuccessComponent, setShowSuccessComponent] = useState(false);
  const [validToken, setValidToken] = useState(false);
  const history = useHistory();

  // useEffect(() => {}, [showSuccessComponent]);

  useEffect(() => {
    if (!hashToken){
      console.error('Not exists the hash token or the email address in the url');
      return;
    }
  }, []);

  useEffect(() => {
    (async function() {
      try{
        const resp=await checkToken(hashToken).then(e=>{
          if (e.status==200){
            setValidToken(true);
          }
        })
      } catch(error) {
        console.error(error);
        
        if (error?.response?.status && error.response?.status === 500) {
          const errorMsg = 'Error in check token';
          console.error(errorMsg);
          throw new Error(errorMsg);
        }
        if (error?.response?.status && error.response?.status === 404) {
          const errorMsg = 'Token is invalid';
          console.error(errorMsg);
          throw new Error(errorMsg);
        }
      }
      
    })();
  }, [])
  

  const successComponent = () => {
  
    return (
      <Card
        className={`vytrac-card`}
        bodyClassName="d-flex flex-column gap-md text-center"
      >
        <h1 className="font-size-extra-large font-weight-md">
          &#127881; <br />
         Successful change password
        </h1>
        <p className="font-size-large">you will be redirected in few seconds...</p>  
        <div className="d-flex flex-column gap-lg justify-content-center align-items-center">
          {/* Extra content */}
        </div>
      </Card>
    );
  };

  const tokenExpirate = () => {
  
    return (
      <Card
        className={`vytrac-card}`}
        bodyClassName="d-flex flex-column gap-md text-center"
      >
        <h1 className="font-size-extra-large font-weight-md">
          the token is invalid or expired
        </h1>
        <p className="font-size-large">Verify that the password change email is the most recent or try again</p>  
        <div className="d-flex flex-column gap-lg justify-content-center align-items-center">
          {/* Extra content */}
        </div>
      </Card>
    );
  };


  const handleSubmit = async ({ password }) => {
    try {
      const resp = await requestPasswordReset(password, hashToken);

      if (resp?.status === 200) {
        setShowSuccessComponent(true);
        setTimeout(() => history.push('/'), 5000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const invalidStyle: CSSProperties = { borderBottomColor: invalidColor };

  return (
    <RegisterLoginLayout>
      {validToken ? 
        showSuccessComponent  
        ? successComponent():(<div className="d-flex flex-column h-100">
        <div>
          <Row className="vertical-container">
            <Col className="line-container">
              <div className="grey-line-inv"></div>
            </Col>
            <Col className="line-container">
              <div className="green-line-inv"></div>
            </Col>
          </Row>

          <div className="centered-logo">
            <div className="logo-container">
              <img className="shield-logo" alt="" src={passwordLogo} />
            </div>
          </div>

          <p className="text-left normal-text dynamic-font-heading" style={{ marginTop: 30 }}>
            Reset my password
          </p>

          <p className="text-left smaller-text dynamic-font-normal">Set your new password</p>
        </div>

        <Formik
          initialValues={{
            [resetPasswordFields.password]: '',
            [resetPasswordFields.confirmPassword]: '',
          }}
          onSubmit={handleSubmit}
          validationSchema={ResetPasswordValidationSchema}
        >
          {({ errors, touched, isValid }) => (
            <Form className="d-flex flex-column flex-grow-1">
              <div className="d-flex flex-column flex-grow-1">
                <div className="flex-grow-1 d-flex flex-column justify-content-center">
                  <p className="grey-text dynamic-font-normal">Password</p>
                  <PasswordInput
                    name={resetPasswordFields.password}
                    invalidStyle={
                      touched[resetPasswordFields.password] &&
                      errors[resetPasswordFields.password] &&
                      invalidStyle
                    }
                  />
                  <p
                    className="grey-text dynamic-font-normal"
                    style={{
                      fontSize: 8,
                      marginTop: 5,
                      color:
                        touched[resetPasswordFields.password] && errors[resetPasswordFields.password]
                          ? invalidColor
                          : '',
                    }}
                  >
                    {touched[resetPasswordFields.password] && errors[resetPasswordFields.password] ? (
                      <ErrorMessage name={resetPasswordFields.password} />
                    ) : null}
                  </p>
                  <p className="grey-text dynamic-font-normal">Confirm password</p>
                  <PasswordInput
                    name={resetPasswordFields.confirmPassword}
                    invalidStyle={
                      touched[resetPasswordFields.confirmPassword] &&
                      errors[resetPasswordFields.confirmPassword] &&
                      invalidStyle
                    }
                  />
                  <p
                    className="grey-text dynamic-font-normal"
                    style={{
                      fontSize: 8,
                      marginTop: 5,
                      color:
                        touched[resetPasswordFields.confirmPassword] &&
                        errors[resetPasswordFields.confirmPassword]
                          ? invalidColor
                          : '',
                    }}
                  >
                    {touched[resetPasswordFields.confirmPassword] &&
                    errors[resetPasswordFields.confirmPassword] ? (
                      <ErrorMessage name={resetPasswordFields.confirmPassword} />
                    ) : null}
                  </p>
                </div>
              </div>

              <Row className="d-flex justify-content-between">
                <Button
                  onClick={() => {
                    setIsResettingPassword(false);
                    return changeView(loginViews.login);
                  }}
                  variant="light"
                  className="dynamic-font-normal text-bold"
                >
                  CANCEL
                </Button>
                <Button disabled={!isValid} type="submit" className="dynamic-font-normal text-bold">
                  SET NEW PASSWORD
                </Button>
              </Row>
            </Form>
          )}
        </Formik>
      </div>):tokenExpirate()}
    </RegisterLoginLayout>
  );
};
export default ResetPassword;
