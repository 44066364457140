import PatientProfileRing from '../../../patient/PatientProfileRing/PatientProfileRing';
import styles from './styles.module.css';
import Alerts from './components/Alerts/Alerts';
import SatisfactionBar from '../../../SatisfactionBar';
import AdminPatientAdherenceElement from 'screens/Administration/AdministrationContent/AdminTabs/AdminPatients/AdminPatientAdherenceElement';
import TagList from 'components/TagList';
import { Tag } from 'components/Tagitem';
import TimeSpentContainer from './components/TimeSpentContainer';
import moment from 'moment';
import { ReactComponent as NoMobileAppIcon } from 'assets/icons/no_mobile_app.svg';
import PatientGet from 'types/ApiModels/Patients/PatientGet';
import NotesIndicator from 'components/NotesIndicator';
import { CareTeam } from 'types/ApiModels/CareTeam';
import UserBubbleGroup from 'components/UserBubbleGroup';
import UserBubble, { UserBubbleUser } from 'components/UserBubble';
import SwitchInput from 'components/SwitchInput';
import { useState } from 'react';

export const getPrimaryPhysician = (careTeam: CareTeam): UserBubbleUser => {
  if (!careTeam?.tiers[0].providers[0]) return { first_name: null, last_name: null };
  const { first_name, last_name } = careTeam?.tiers[0].providers[0];
  return { first_name, last_name };
};

export const getOtherPhysicians = (careTeam: CareTeam): UserBubbleUser[] => {
  const otherPhysicians: UserBubbleUser[] = [];
  if (!careTeam) return [{ first_name: null, last_name: null }];

  for (const tier of careTeam.tiers) {
    if (tier.number === 1) continue;
    tier.providers.forEach((provider) =>
      otherPhysicians.push(
        provider
          ? { first_name: provider.first_name, last_name: provider.last_name }
          : { first_name: null, last_name: null }
      )
    );
  }
  return otherPhysicians;
};

interface IRow {
  patient: PatientGet;
  onPatientClick: (patient: PatientGet) => void;
}

const PatientRowV2 = ({ patient, onPatientClick }: IRow) => {

  const [active, setActive] = useState(false);

  const handleActiveChange = () => {
    setActive((a) => !a);
  };
  
  return (
    <tr className={styles.main}>
      {patient && (
        <>
          <td className={styles['regular-font-size']}>{patient.health_score}</td>
          <td className={styles['name']}>
            <div
              className="d-flex align-items-center cursor-pointer"
              onClick={() => onPatientClick(patient)}
            >
              {/* <PatientProfileRing size={36} currentPatient={patient} /> */}
              {/* <PatientProfileRing size={36} currentPatient={patient} />
              {!patient.has_mobile_app && (
                <NoMobileAppIcon style={{ marginLeft: '5px', height: '20px', minWidth: '20px' }} />
              )} */}

              <span className={`${styles.patientName} ml-2`}>
                 {patient.first_name} {patient.last_name}
              </span>
            </div>
          </td>
          <td className={styles['regular-font-size']}>#{patient.id}</td>
          <td className={styles['regular-font-size']}>
            {moment(patient.date_of_birth).format('MM.DD.YYYY')}
          </td>
          <td>
              <span className={styles['regular-font-size']}>
                {patient.home_phone_number}
              </span>
            {/* <Alerts alertsSummary={patient.alerts_summary} /> */}
          </td>

          <td className={styles['regular-font-size']}>
            
              <span className={styles['regular-font-size']}>
                {patient.email_address}
              </span>
            {/* <AdminPatientAdherenceElement adherence={patient.adherence} /> */}
          </td>
          <td>
              <span className={styles['regular-font-size']}>
                {patient.language_preference}
              </span>
            {/* <TagList
              tagList={
                patient.populations?.map<Tag>((p) => ({
                  text: p.name,
                })) ?? []
              }
              maxCount={2}
            /> */}
          </td>
          <td>            
            <span className={styles['regular-font-size']}>
                {patient.contact_preferred}
            </span>
            {/* <TimeSpentContainer
              className={styles['regular-font-size']}
              timeSpent={patient.timespent}
            /> */}
          </td>
          <td>
            <span className={styles['regular-font-size']}>
              {moment(patient.program_start_date).format('MM.DD.YYYY')}
            </span>
            {/* <NotesIndicator hasNotes={false} /> */}
          </td>
          <td>
            <span className={styles['regular-font-size']}>
              {moment(patient.program_end_date).format('MM.DD.YYYY')}
            </span>            
            {/* <SatisfactionBar value={Number(patient.satisfaction)} /> */}
          </td>
          <td className='align-middle'>
            
            <SwitchInput label="" onChange={handleActiveChange} value={patient.active} />
          
            {/* <UserBubble user={getPrimaryPhysician(patient.care_team)} /> */}
          </td>
          <td>            
            {/* <UserBubbleGroup users={getOtherPhysicians(patient.care_team)} maxCount={3} /> */}
          </td>
        </>
      )}
    </tr>
  );
};

export default PatientRowV2;
