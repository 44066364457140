import ListItem from 'components/ListItem';
import VyTracModal from 'components/Modal';
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAdminSpecialties } from 'screens/Administration/store';
import { getProvidersBySpecialty } from 'services/providersService';
import { Provider } from 'types/ApiModels/Providers/Provider';

interface ProvidersBySpecialtyModalProps {
  show: boolean;
  onClose: () => void;
}

const ProvidersBySpecialtyModal: FC<ProvidersBySpecialtyModalProps> = ({
  show,
  onClose,
}: ProvidersBySpecialtyModalProps) => {
  const [providersForSpecialty, setProvidersForSpecialty] = useState<Provider[]>([]);
  const [{ currentSpecialtyId, list: specialtiesList }] = useAdminSpecialties();

  useEffect(() => {
    if (!currentSpecialtyId) return;
    const fetchProvidersBySpecialty = async () => {
      const result = await getProvidersBySpecialty(currentSpecialtyId);
      setProvidersForSpecialty(result.results);
    };
    fetchProvidersBySpecialty();
  }, [currentSpecialtyId]);

  const currentSpecialty = specialtiesList?.find((s) => s.id === currentSpecialtyId);
  const history = useHistory();

  return (
    <VyTracModal
      backdrop={false}
      title={`Providers with this specialty: ${currentSpecialty?.text}`}
      headerClassName="font-size-big font-weight-md m-0"
      body={
        <div className="d-flex flex-column gap">
          {providersForSpecialty?.map((ps) => (
            <ListItem
              key={ps.user_id}
              user={{
                first_name: ps.first_name,
                last_name: ps.last_name,
                username: ps.username,
                imageUrl: ps.photo,
              }}
              onClick={() => history.push(`/users/v2/${ps.id}`)}
            />
          ))}
        </div>
      }
      footer={undefined}
      onClose={onClose}
      show={show}
      centered
    />
  );
};
export default ProvidersBySpecialtyModal;
