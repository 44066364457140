import { KeywordsTemplate } from 'types/ApiModels/Administration';
import { PatientNotesKeywords } from 'types/ApiModels/Administration';
import { PaginatedResponse } from 'types/ApiModels/General';
import { NotesKeywordsAction, NotesKeywordsActionTypes } from './notes-keywords-action-types';

export const setBook = (
  book: Record<number, PaginatedResponse<PatientNotesKeywords>>
): NotesKeywordsAction => ({
  type: NotesKeywordsActionTypes.SET_BOOK_NK,
  payload: {
    book,
  },
});

export const setTemplates = (templates: KeywordsTemplate[]): NotesKeywordsAction => ({
  type: NotesKeywordsActionTypes.SET_TEMPLATES_NK,
  payload: {
    templates,
  },
});

export const setSubmit = (submit): NotesKeywordsAction => ({
  type: NotesKeywordsActionTypes.SET_SUBMIT_NK,
  payload: {
    submit,
  },
});

export const clearChanges = (): NotesKeywordsAction => ({
  type: NotesKeywordsActionTypes.CLEAR_CHANGES_NK,
});

export const upsertCreated = (createdId: number): NotesKeywordsAction => ({
  type: NotesKeywordsActionTypes.UPSERT_CREATED_NK,
  payload: {
    createdId,
  },
});

export const removeCreated = (createdId: number): NotesKeywordsAction => ({
  type: NotesKeywordsActionTypes.REMOVE_CREATED_NK,
  payload: {
    createdId,
  },
});

export const upsertUpdated = (updatedId: number): NotesKeywordsAction => ({
  type: NotesKeywordsActionTypes.UPSERT_UPDATED_NK,
  payload: {
    updatedId,
  },
});

export const appendDeleted = (deletedId: number): NotesKeywordsAction => ({
  type: NotesKeywordsActionTypes.APPEND_DELETED_NK,
  payload: {
    deletedId,
  },
});

export const setCurrentDefaultId = (id: number): NotesKeywordsAction => ({
  type: NotesKeywordsActionTypes.SET_CURRENT_DEFAULT_ID_NK,
  payload: {
    id,
  },
});

export const setReset = (reset: () => void): NotesKeywordsAction => ({
  type: NotesKeywordsActionTypes.SET_RESET_CHANGES_NK,
  payload: {
    reset,
  },
});

export const upsertTemplate = (templates: KeywordsTemplate[]): NotesKeywordsAction => ({
  type: NotesKeywordsActionTypes.UPSERT_TEMPLATES_NK,
  payload: {
    templates,
  },
});

export const syncRemoveTemplates = (templateIds: number[]): NotesKeywordsAction => ({
  type: NotesKeywordsActionTypes.SYNC_REMOVE_TEMPLATES_NK,
  payload: {
    templateIds,
  },
});
