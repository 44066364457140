enum ConversationType {
  /**
   *
   */
  CareTeamRequest = 'care-team-request',
  CareTeamRequestFollowUp = 'care-team-request-followup',
  CareTeamMainThread = 'team-notes-main-thread',
  CareTeamSubThread = 'team-notes-sub-thread',
}
export default ConversationType;
