import ProviderProfileForm from 'components/Provider/Account/ProviderProfileForm';
import SaveChangesConfirmationModal from 'components/SaveChangesConfirmationModal';
import { Form, Formik } from 'formik';
import useChangeLastBreadcrumb from 'hooks/use-change-last-breadcrumb';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  useAdministrationContext,
  useAdministrationContextV2,
  useAdminRoles,
  useAdminSpecialties,
} from 'screens/Administration/store';
import {
  removeProviderFromBook,
  setRemoveSingleProvider,
  setResetSingleProviderForm,
  setSelectedProvider,
  setSingleProviderFormDirty,
  setSubmitSingleProviderForm,
} from 'screens/Administration/store/Providers';
import {
  deleteProvider,
  getProvidersTitles,
  getSingleProvider,
  updateProvider,
} from 'services/providersService';
import { getLanguages, getSexOptions } from 'services/userService';
import { useAppQuery } from 'store/use-app-query';
import { Title } from 'types/ApiModels/Providers/Provider';
import { Language } from 'types/ApiModels/Users';
import Sex, { SexLabelId } from 'types/ApiModels/Users/Sex';
import DatePickerValue from 'types/Shared/DatePicker';
import { convertDateToDatePickValue } from 'util/dateUtils';
import { localIdCreator } from 'util/utils';
import { usePersistPagination } from '../AdminProviders';
import ProviderDetailsActions from './ProviderDetailsActions';
import { mapProviderFormToProvider } from './utils';
import ProviderDetailsValidationSchema from './utils/ProviderDetailsValidationSchema';

//need some way to identify addresses
export const createLocalAddressId = localIdCreator();

export interface ProviderDetailsFormValues {
  identification: {
    firstName?: string;
    lastName?: string;
    middleName?: string;
    dateOfBirth?: DatePickerValue;
    gender?: SexLabelId;
    title?: any;
    language?: any;
    accessRoles?: number[];
  };
  personalInformation: {
    contactInformation?: {
      email?: string;
      phoneNumber1?: string;
      phoneNumber2?: string;
    };
    addressInformation: {
      id?: number;
      home?: string;
      apt?: string;
      zipCode?: string;
      state?: string;
      city?: string;
    }[];
  };
  active?:boolean;
  specialties?: number[];
}

const ProviderDetails = () => {
  const { setActions } = useAdministrationContext();
  const history = useHistory();
  const { roles } = useAdminRoles();
  const { providerId } = useParams<{ providerId: string }>();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showConfirmRemoveProviderModal, setShowConfirmRemoveProviderModal] = useState(false);
  const [commitingValues, setCommitingValues] = useState<ProviderDetailsFormValues>();
  const {
    administrationStateV2: {
      providers: { selectedProvider },
    },
    dispatch,
  } = useAdministrationContextV2();

  const numberToArray = n => [...`${n}`].map(i => parseInt(i)).filter(Number);

  const [{ list: specialties }] = useAdminSpecialties();

  const { data: languages } = useAppQuery<Language[]>('languages');
  const { data: genders } = useAppQuery<Sex[]>('sex');
  const [titles, setTitles] = useState<Title[]>([]);

  const fetchTitles = async () => {
    const titles = await getProvidersTitles();
    titles && setTitles(titles.results);
  };

  const tempPage = usePersistPagination((state) => state)

  useEffect(() => {
    selectedProvider? null : 
      providerId? fetchProvider(parseInt(providerId)):
      history.push('/administration/providers/all');
    fetchTitles();
  }, []);

  const fetchProvider = useCallback(
    async (providerId: number) => {
      const res = await getSingleProvider(0,providerId);
      dispatch(setSelectedProvider(res));
    },
    [dispatch]
  );

  useEffect(() => {
    const providerIdNumber = parseInt(providerId);
    if (isNaN(providerIdNumber)) return;
    if (!selectedProvider) {
      fetchProvider(providerIdNumber);
    }
  }, [fetchProvider, providerId, selectedProvider]);

  useEffect(() => {
    if (selectedProvider) {
      setActions(ProviderDetailsActions);
    }
  }, [selectedProvider]);

  useChangeLastBreadcrumb(`${selectedProvider?.first_name} ${selectedProvider?.last_name}`, [
    { title: 'Providers', url: '/administration/providers/all' },
    { title: 'Providers List', url: "/administration/providers/all", callback: () => tempPage.changeGoToPage(true) }
  ]);

  useEffect(() => {
    dispatch(
      setRemoveSingleProvider(() => {
        setShowConfirmRemoveProviderModal(true);
      })
    );
  }, [dispatch, providerId]);

  if (!selectedProvider) return <></>;

  const handleCommitProviderChanges = async () => {
    setShowConfirmModal(false);
    const newProviderValues = mapProviderFormToProvider(
      commitingValues,
      commitingValues.active
    );
    if (newProviderValues.title_id===0){
      newProviderValues.title_id=""
    }
    const res = await updateProvider(selectedProvider.id, newProviderValues);
    setShowConfirmModal(false);
  };

  const handleRemoveCurrentProvider = async () => {
    const res = await deleteProvider(selectedProvider.id);
    dispatch(removeProviderFromBook(selectedProvider.id));
    dispatch(setSelectedProvider());
    history.push('/administration/providers/all');
  };

  

  return (
    <Formik<ProviderDetailsFormValues>
      initialValues={{
        identification: {
          firstName: selectedProvider.first_name ?? '',
          lastName: selectedProvider.last_name ?? '',
          middleName: selectedProvider.middle_name ?? '',
          dateOfBirth: selectedProvider.date_birth
            ? convertDateToDatePickValue(new Date(selectedProvider.date_birth))
            : null,
          gender: selectedProvider?.sex ?? null,
          title: selectedProvider.title ?? "",
          language: selectedProvider.language ?? "", //selectedProvider?.languages.map((l) => l.id),  # bypass to list waiting for back
          accessRoles: numberToArray(selectedProvider.rol), //selectedProvider.roles.map((r) => r.id), # bypass to list waiting for back
        },
        personalInformation: {
          contactInformation: {
            email: selectedProvider.email_address,
            phoneNumber1: selectedProvider.cell_phone_number ?? '',
            phoneNumber2: selectedProvider.office_extension ?? '',
          },
          addressInformation: selectedProvider.address
            ? selectedProvider.address.map((a) => ({
                home: a.street_address_line_1,
                apt: a.apt,
                state: a.state,
                zipCode: a.zip_code,
                city: a.city,
                //id: createLocalAddressId(),
                id:a.id ?? createLocalAddressId()
              }))
            : [{ home: '', apt: '', state: '', city: '', zipCode: '', id: createLocalAddressId() }],
        },
        active:selectedProvider.active,
        specialties: numberToArray(selectedProvider.speciality)??[],//selectedProvider.speciality.map((s) => s.id), # bypass to list waiting for back
      }}
      validationSchema={ProviderDetailsValidationSchema}
      onSubmit={(values) => {
        setCommitingValues(values);
        setShowConfirmModal(true);
      }}
    >
      {({ submitForm, resetForm, dirty }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          dispatch(setSubmitSingleProviderForm(submitForm));
          dispatch(setResetSingleProviderForm(resetForm));
        }, [resetForm, submitForm]);

        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          dispatch(setSingleProviderFormDirty(dirty));
        }, [dirty]);
        return (
          <Form>
            <ProviderProfileForm
              selectedProvider={selectedProvider}
              genderOptions={genders ?? []}
              languages={languages ?? []}
              roles={roles}
              titles={titles}
              specialties={specialties}
              active={selectedProvider.active}
            />
            <SaveChangesConfirmationModal
              onCancel={() => setShowConfirmModal(false)}
              onConfirm={handleCommitProviderChanges}
              show={showConfirmModal}
            />
            <SaveChangesConfirmationModal
              onCancel={() => setShowConfirmRemoveProviderModal(false)}
              show={showConfirmRemoveProviderModal}
              onConfirm={handleRemoveCurrentProvider}
              message={`Are you sure you want to delete provider: ${selectedProvider.first_name}`}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
export default ProviderDetails;
